import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, throwError } from "rxjs";
import { Alliance } from "src/app/models/Alliance/Alliance";
import { AllianceRef } from "src/app/models/Alliance/AllianceRef";
import { BuildingsService } from "src/app/services/Buildings/buildings.service";
import { UsersService } from 'src/app/services/Users/user.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AllianceService {
  private apiUrl = environment.apiEndpoint;
  private alliance = new BehaviorSubject<Alliance>(null);
  public allianceUpdate$ = this.alliance.asObservable();
  private allianceNeedUpdate = false;
  private allianceLockUpdate = false;

  constructor(
    private readonly http: HttpClient,
    private readonly buildingsService: BuildingsService,
    private readonly userService: UsersService,
  ) {
    setInterval((val) => {
      //refresh alliance
      if (userService.currentAllianceId())
        if (this.allianceNeedUpdate === true) {
          if (!this.allianceLockUpdate) {
            this.allianceLockUpdate = true;
            this.getAllianceById(userService.currentAllianceId()).subscribe({
              next: (alliance) => {
                this.alliance.next(alliance);
                this.allianceNeedUpdate = false;
                this.allianceLockUpdate = false;
              },
              error: (err) => {
                this.allianceLockUpdate = true;
                return throwError(() => err);
              }
            });
          }
        }
    }, 1000);
  }

  public updateAlliance() {
    this.allianceNeedUpdate = true;
  }

  public getAllAlliance(): Observable<Alliance[]> {
    return this.http.get<Alliance[]>(this.apiUrl + `alliances`);
  }

  public getAllianceById(allianceId: string): Observable<Alliance> {
    return this.http.get<Alliance>(this.apiUrl + `alliances/${allianceId}`);
  }

  public getAllianceRefById(allianceId: string): Observable<AllianceRef> {
    return this.http.get<AllianceRef>(this.apiUrl + `alliances/${allianceId}/ref`);
  }

  public createNewAlliance(leaderId: string, allianceRef: AllianceRef): Observable<string> {
    return this.http.post<string>(this.apiUrl + `alliances/owner/${leaderId}/create`, allianceRef);
  }

  public modifyAllianceDescription(allianceId: string, description: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${allianceId}/description`, description);
  }

  public modifyAllianceImage(allianceId: string, image: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${allianceId}/image`, image);
  }

  public invitePlayer(allianceId: string, playerId: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${allianceId}/invite`, playerId);
  }

  public requestJoinAlliance(allianceId: string, playerId: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${playerId}/join`, allianceId);
  }

  public acceptInvite(allianceId: string, playerId: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${playerId}/join`, allianceId);
  }

  public declineInvite(allianceId: string, playerId: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${playerId}/refuse`, allianceId);
  }

  public leaveAlliance(allianceId: string, playerId: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${playerId}/leave`, allianceId);
  }

  public editRoleName(allianceId: string, roleId: number, roleName: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${allianceId}/role/${+roleId}/edit`, roleName);
  }

  public promotePlayer(allianceId: string, playerId: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${allianceId}/promote`, playerId);
  }

  public demotePlayer(allianceId: string, playerId: string): Observable<Alliance> {
    return this.http.put<Alliance>(this.apiUrl + `alliances/${allianceId}/demote`, playerId);
  }
}
