import { GlobalPosition } from "src/app/models/GlobalPosition";

export class FleetMove {
  bombardRound: number;
  bombardTargets: number[];
  objective: string;
  /**
   * Set pillageRate of resources
   *
   * @name string The resource name.
   * @name number The ratio of the named resource.
   *
   */
  pillageRate: Map<string, number>;
  returnPosition: GlobalPosition;
  target: GlobalPosition;
  unexpectedStrategy: string;

}
