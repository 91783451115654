import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, Routes } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { ProfilePictureSelectorComponent } from 'src/app/main/users/profile/profile-picture-selector/profile-picture-selector.component';
import { GovernmentRef } from 'src/app/models';
import { BaseRef } from "src/app/models/base/BaseRef";
import { Reputation } from "src/app/models/owner/Reputation";
import { AllianceService } from "src/app/services/Alliance/alliance.service";
import { BaseService } from "src/app/services/bases/base.service";
import { GovernmentService } from "src/app/services/Government/government.service";
import { ReputationService } from "src/app/services/reputation/reputation.service";
import { UsersService } from 'src/app/services/Users/user.service';
import { ShowAllianceComponent } from "../../alliance/showAlliance/showAlliance.component";


@Component({
  selector: "app-profil",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnDestroy, OnInit {
  static routes: Routes = [{ path: "", component: ProfileComponent }];
  private readonly destroy$ = new Subject();

  bases: BaseRef[];
  government: GovernmentRef;
  selectedGovernment;
  public user;
  descriptionTextLines = [];
  reputation: Reputation;

  constructor(
    private readonly router: Router,
    private readonly baseService: BaseService,
    private readonly reputationService: ReputationService,
    private readonly governmentService: GovernmentService,
    private readonly userService: UsersService,
    private readonly modalService: NgbModal,
    public readonly allianceService: AllianceService,
  ) {
  }

  ngOnInit() {
    //Récup de l'owner
    this.userService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.descriptionTextLines = user.description.split('\n');
          setTimeout(() => this.calcHeightDescription(), 100);
          this.governmentService.getGovernmentRef(user.governmentId).subscribe((government) => {
            this.government = government;
            this.selectedGovernment = government;
          });
        }
      });
    this.getBases();
    //Récup de la réputation
    this.reputationService.getReputation(this.user.id).subscribe((reputation) => {this.reputation = reputation});
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }



  calcHeightDescription() {
    let target = document.getElementById('ownerDescriptionForm');
    if (target) {
      this.descriptionTextLines = target['value'].split('\n');
      let height = document.getElementById('ownerDescriptionLabel').offsetHeight
      height = Math.max(height, 45);
      if (height > 0) {
        target.style.setProperty('--ownerDescription', height + 5 + "px");
      }
    }
  }

  private getBases(): void {
    this.baseService.getBases().subscribe((bases) => { this.bases = bases });
    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base && this.bases) {
          this.bases.filter((bases) => bases.id === base.id)[0].name = base.name;
        }
      });
  }

  saveDescription(elementId) {
    this.userService.postDescription(document.getElementById(elementId)['value']).subscribe((success) => {
      this.userService.updateUser();
    });
  }

  saveBaseName() {
    for (let key in document.getElementById("renameBaseName").children) {
      let child = document.getElementById("renameBaseName").children[key]
      if (child.id) {
        let base = this.bases.filter((base) => base.id === child.id)[0];
        if (base.name !== child["value"]) {
          this.baseService.renameBase(child.id, child["value"]).subscribe((success) => {
            this.baseService.updateBase(child.id);
            this.userService.updateUser();
          });
        }
      }
    }
  }

  changeImg() {
    let modal = this.modalService.open(ProfilePictureSelectorComponent, {
      ariaLabelledBy: "profile-picture-selector.",
      scrollable: true,
      size: "sm",
      windowClass: "profile-picture-selector. backModalImg",
      backdrop: true,
    });
    modal.closed.subscribe((result) => {
      if (result) {
        this.userService.postImgURL(result).subscribe((success) => {
          this.userService.updateUser();
        });
      }
    });
  }

  showAlliance() {
    this.allianceService.getAllianceById(this.user.allianceId).subscribe((alliance) => {
      let showAllianceComponent = this.modalService.open(ShowAllianceComponent, {
        ariaLabelledBy: "modal-show-alliance",
        scrollable: true,
        size: "xl",
        windowClass: "showAlliance backModalImg",
      });
      showAllianceComponent.componentInstance.allianceRef = alliance;
    });
  }

  change(target) {
    this.descriptionTextLines = target.value.split('\n');
    setTimeout(() => this.calcHeightDescription(), 100);
  }
}
