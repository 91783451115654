import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Message } from 'src/app/models/message/Message';
import { MessageThread } from 'src/app/models/message/MessageThread';
import { AlertService } from 'src/app/services/alert/alert.service';
import { MessageService } from 'src/app/services/message/message.service';
import { OptionsService } from 'src/app/services/options/options.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';

@Component({
  selector: 'app-message-thread',
  templateUrl: './message-thread.component.html',
  styleUrls: ['./message-thread.component.scss']
})
export class MessageThreadComponent implements OnInit, OnDestroy {

  public messages: Message[];
  instances: NgbModalRef[];

  @Input()
  public messageThread: MessageThread;

  private readonly destroy$ = new Subject();


  constructor(
    public readonly modalService: NgbModal,
    private readonly optionsService: OptionsService,
    private readonly messageService: MessageService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    public readonly utilsService: UtilsService,
  ) {
    let destroy$ = new Subject();
    this.modalService.activeInstances
      .pipe(takeUntil(destroy$))
      .subscribe((instances) => {
        this.instances = instances
      })
  }

  ngOnInit() {
    //
    if (this.messageThread && this.messageThread.id) {
      this.messageService.getMessagesInThread(this.messageThread.id).subscribe((messages) => {
        for (let message of messages) {
          message.date = new Date(Date.parse(message.date + ''));
        }
        this.messages = messages;
      });
      this.messageService.sendMessageInThread(this.messageThread.id, "test").subscribe((message)=> {
        console.error(message);
      })
    }
  }

  close(reason, event) {
    if (event.shiftKey) {
      this.modalService.dismissAll()
    } else {
      this.utilsService.closeModalByEvent(event);
    }
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }
}
