import { Component, ElementRef, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Ship } from "src/app/models/Ship";

@Component({
  selector: "app-ship-detail",
  templateUrl: "./unit-detail.component.html",
  styleUrls: ["./unit-detail.component.scss"],
})
export class UnitDetailComponent implements OnInit {
  units: Ship[] = [];

  constructor(
    public readonly modal: NgbActiveModal,
    private el: ElementRef,
  ) { }

  ngOnInit() { }

  public addUnit(unit: Ship) {
    if (!this.units.includes(unit)) {
      this.units.push(unit);
    }
    this.el.nativeElement.closest('.modal').focus();
  }

  move(from, to) {
    this.units.splice(to, 0, this.units.splice(from, 1)[0]);
  }

  remove(unit) {
    this.units.splice(this.units.indexOf(unit), 1);
    if (this.units.length < 1) {
      this.modal.dismiss('Cross click')
    }
  }
}
