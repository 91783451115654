import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Reputation } from 'src/app/models/owner/Reputation';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReputationService {
  private apiUrl = environment.apiEndpoint;

  constructor(private readonly http: HttpClient) {}

  public getReputation(ownerId: string): Observable<Reputation> {
    return this.http.get<Reputation>(this.apiUrl + `reputations/owner/${ownerId}`);
  }
}
