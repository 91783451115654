<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">
    Profil du joueur <label *ngIf="profile">{{profile.name}}</label>
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>

<div class="modal-body">
  <div class="d-flex">
    <div>
      <img *ngIf="profile && profile.imageLocation" alt="Card image cap" class="govImage"
        src="{{'/assets/img/' + profile.imageLocation }}" />
      <img *ngIf="profile && !profile.imageLocation && selectedGovernment" class="govImage"
        src="{{'/assets/img/govs/' + selectedGovernment.shortName + '.png'}}" />
      <div *ngIf="profile && profile.baseList" class="baseList">
        <label *ngFor="let base of profile.baseList" id="{{base.id}}" name="userDescriptionForm">{{base.name}}</label>
      </div>
    </div>

    <div class="profile d-flex flex-column align-items-center">
      <div *ngIf="profile" class="ownerName">
        {{profile.name}}
      </div>
      <div (click)="showAlliance()" *ngIf="profile && profile.allianceName" class="allianceName">
        {{profile.allianceName}}
      </div>
      <div *ngIf="profile" class="ownerDescription">
        <label id="ownerDescriptionForm" name="ownerDescriptionForm">{{profile.description}}</label>
      </div>
    </div>
  </div>

</div>
