import { Component, OnDestroy, OnInit } from "@angular/core";
import { Routes } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { Base } from "src/app/models/base/Base";
import { BaseService } from "src/app/services/bases/base.service";
import { BuildingsService } from "src/app/services/Buildings/buildings.service";
import { EventService } from "src/app/services/event/event.service";
import { OptionsService } from "src/app/services/options/options.service";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { BuildableComponent } from "./buildable/buildable.component";
import { BuildingComponent } from "./building/building.component";

@Component({
  selector: "app-bases",
  templateUrl: "./bases.component.html",
  styleUrls: ["./bases.component.scss"],
})
export class BasesComponent implements OnInit, OnDestroy {
  static routes: Routes = [{ path: "", component: BasesComponent }];
  private readonly destroy$ = new Subject();
  private debug: boolean = false;

  base: Base;
  resources = {};
  closeResult = "";
  timerMap: Map<number, string> = new Map<number, string>();

  abc;

  constructor(
    private readonly modalService: NgbModal,
    private readonly baseService: BaseService,
    private readonly buildingsService: BuildingsService,
    private readonly eventService: EventService,
    private readonly optionsService: OptionsService,
    public readonly utilsService: UtilsService,
  ) {
    //Maj du component chaque fois qu'il y a une modif de la base dans le service
    baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
          this.resources = base.ressources;
          this.constructionTimer();
        }
      });

    // timer toutes les secondes qui met à jour les ressources approximativement
    baseService.resourcesUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedResources) => {
        if (updatedResources.size > 0) {
          this.resources = updatedResources;
        }
      });
    // if(baseService.getCurrentBaseId()) {
    //   baseService.updateBase(baseService.getCurrentBaseId());
    // }
  }

  ngOnInit() {
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  openBuildables(selectedSlot: number) {
    let buildableModal = this.modalService
      .open(BuildableComponent, {
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
        size: "lg",
        windowClass: "base_openBuildable backModalImg",
      });
    buildableModal.componentInstance.selectedSlot = selectedSlot;
    buildableModal.componentInstance.base = this.base;

    this.buildingsService
      .getBuildable(this.base.id, selectedSlot)
      .subscribe((buildings) => {
        buildableModal.componentInstance.buildings = buildings;
      });
  }

  openBuilding(selectedSlot: number) {
    let buildingModal = this.modalService.open(BuildingComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "base_openBuilding backModalImg",
    });
    buildingModal.componentInstance.selectedSlot = selectedSlot;
    buildingModal.componentInstance.base = this.base;
    buildingModal.componentInstance.selectedBuildingID = this.base.slots[selectedSlot].built.building.id;
  }

  constructionTimer() {
    if (!this.abc) {
      this.abc = this.utilsService.timer
        .pipe(takeUntil(this.destroy$))
        .subscribe((val) => {
          for (let [slotKey, slot] of Object.entries(this.base.slots)) {
            if (slot && slot.construction) {
              let then = Date.parse(slot.construction + '')
              let ms = then - Date.now();
              this.timerMap.set(+slotKey, ms + '');
            } else {
                if(this.timerMap.has(+slotKey)) {
                  this.timerMap.delete(+slotKey);
                }
            }
          }
        });
    }
  }

  private confirmCancelModal;
  confirmCancel(emplacement: number) {
    this.baseService.cancel(this.base.id, emplacement).subscribe((base) => {
      this.baseService.updateBase();
      this.eventService.updateBaseEventNeeded();
      this.confirmCancelModal.dismiss();
    });
  }

  cancelBuilding(emplacement: number, content) {
    content.slot = emplacement;
    this.confirmCancelModal = this.modalService
      .open(content, {
        ariaLabelledBy: "base_confirmCancel",
        scrollable: true,
        windowClass: "base_confirmCancel",
      });
  }
}
