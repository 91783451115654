import { Owner } from "../owner/Owner";

export class User extends Owner {
  active: boolean;
  allianceId: string;
  allianceName: string;
  centaurium: number;
  governmentId: string;
  lastConnect: string;
}
