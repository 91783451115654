import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { FormationDetailComponent } from "src/app/main/fleets/fleets/formation/formation-detail.component";
import { UnitDetailComponent } from "src/app/main/fleets/unit-detail/unit-detail.component";
import { Base } from "src/app/models/base/Base";
import { Building } from "src/app/models/Building/Building";
import { Built } from "src/app/models/Building/Built";
import { Research } from 'src/app/models/research/Research';
import { Ship } from "src/app/models/Ship";
import { BaseService } from "src/app/services/bases/base.service";
import { BuildingsService } from "src/app/services/Buildings/buildings.service";
import { EventService } from "src/app/services/event/event.service";
import { FleetService } from "src/app/services/fleets/fleet.service";
import { ResearchService } from 'src/app/services/research/research.service';
import { ShipService } from "src/app/services/ships/ship.service";
import { UsersService } from "src/app/services/Users/user.service";
import { User } from "src/app/models";
import { ResourceCumul } from "src/app/models/base/resources/ResourceCumul";
import { GlobalPosition } from "src/app/models/GlobalPosition";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { ResourceDetailComponent } from "../../resource-bar/resource-detail/resource-detail.component";
import { EffectDetailComponent } from "../../effects/effect-detail/effect-detail.component";
import { EffectRef } from "src/app/models/Effect/EffectRef";
import { EffectService } from "src/app/services/effect/effect.service";

@Component({
  templateUrl: "./building.component.html",
  styleUrls: ["./building.component.scss"],
})
export class BuildingComponent implements OnInit, OnDestroy {

  @Input()
  base: Base;

  selectedBuilding: Building;

  @Input()
  selectedSlot: number = -1;

  @Input()
  selectedBuildingID;

  requestedResearch: Map<Research, number> = new Map<Research, number>();

  @Input()
  buildableShips: Ship[];

  @Input()
  buildableTurrets: Ship[];

  selectedBuilt: Built;
  resources: Map<string, ResourceCumul>;
  public user: User = new User();

  public isRequirementsFulfilled: boolean = false;
  public isCostRequirements: boolean = false;
  public isBuildingsRequirements: boolean = false;
  public isResearchRequirements: boolean = false;

  private readonly destroy$ = new Subject();

  constructor(
    private readonly modalService: NgbModal,
    public readonly buildingsService: BuildingsService,
    private readonly baseService: BaseService,
    private readonly shipService: ShipService,
    private readonly eventService: EventService,
    private readonly fleetService: FleetService,
    private readonly researchService: ResearchService,
    public readonly modal: NgbActiveModal,
    private readonly userService: UsersService,
    public readonly utilsService: UtilsService,
    private readonly effectService: EffectService,
  ) {

  }

  ngOnInit() {
    this.selectedBuilt = this.base.slots[this.selectedSlot].built;
    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
          this.selectedBuilt = base.slots[this.selectedSlot].built;
        }
      });


    this.userService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
      });


    if (!this.buildingsService.buildingsMap.has(this.selectedBuildingID) || !this.buildingsService.buildingsMap.get(this.selectedBuildingID).levels[2]) {
      this.buildingsService.getBuilding(this.selectedBuildingID).subscribe((building) => {
        this.selectedBuilding = building;
        this.loadRequirements();
      });
    } else {
      this.selectedBuilding = this.buildingsService.buildingsMap.get(this.selectedBuildingID)
      this.loadRequirements();
    }
  }

  loadRequirements() {
    if (this.selectedBuilding.levels[this.base.slots[this.selectedSlot].built.level + 1]) {
      this.isBuildingsRequirements = true;
      for (let key in this.selectedBuilding.levels[this.base.slots[this.selectedSlot].built.level + 1].buildingReq) {
        if (!this.buildingsService.buildingsMap.has(key)) {
          this.buildingsService.getBuilding(key).subscribe((buildingReq) => { });
        }
        if (this.buildingsService.buildingsMap.get(key)["currentLevel"] >= 0) {
          this.isBuildingsRequirements = this.isBuildingsRequirements && (this.selectedBuilding.levels[this.base.slots[this.selectedSlot].built.level + 1].buildingReq[key] <= this.buildingsService.buildingsMap.get(key)["currentLevel"]);
        } else {
          this.isBuildingsRequirements = false;
        }
      }
      this.researchService.researchUpdate$
        .pipe(takeUntil(this.destroy$))
        .subscribe((researchElements) => {
          this.isResearchRequirements = true;
          for (let key in this.selectedBuilding.levels[this.base.slots[this.selectedSlot].built.level + 1].researchReq) {
            if (researchElements) {
              let researchElement = researchElements.filter(researchElement => researchElement.id === key)[0];
              this.requestedResearch.set(researchElement, this.selectedBuilding.levels[this.base.slots[this.selectedSlot].built.level + 1].researchReq[key]);
              if (this.user.researchMap[key] >= 0) {
                this.isResearchRequirements = this.isResearchRequirements && (this.selectedBuilding.levels[this.base.slots[this.selectedSlot].built.level + 1].researchReq[key] <= this.user.researchMap[key])
              } else {
                this.isResearchRequirements = false;
              }
            }
          }
        })
    }
    this.baseService.resourcesUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedResources) => {
        if (updatedResources.size > 0) {
          this.resources = updatedResources;
          this.isCostRequirements = true;
          for (let res of updatedResources.keys()) {
            let resource = updatedResources.get(res);
            if (this.selectedBuilding && this.selectedBuilding.levels[this.selectedBuilt.level + 1]) {
              this.isCostRequirements = this.isCostRequirements && (resource.clientAmount > this.selectedBuilding.levels[this.selectedBuilt.level + 1].buildingCost[res]);
            } else {
              this.isCostRequirements = false;
            }
          }
          this.isRequirementsFulfilled = this.isCostRequirements && this.isBuildingsRequirements && this.isResearchRequirements;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  buildShips(ship: Ship) {
    this.shipService.buildShips(this.base.id, ship).subscribe((ship) => {
      this.baseService.updateBase();
      this.eventService.updateBaseEventNeeded();
      this.eventService.updatePlayerEventNeeded();
    });
  }

  maxBuildableUnits(unit: Ship) {
    unit.buildQuantity = Number.MAX_SAFE_INTEGER;
    for (let resource in unit.cost) {
      let amount = Math.floor(this.base.ressources[resource].clientAmount / unit.cost[resource])
      unit.buildQuantity = Math.min(unit.buildQuantity, amount);
    }
  }

  turretDetail() {
    //Open Modal
    let modal = this.modalService.open(FormationDetailComponent, {
      ariaLabelledBy: "formation-detail",
      scrollable: true,
      size: "xl",
      windowClass: "formation-detail backModalImg"
    });

    modal.componentInstance.getMainFleet = this.fleetService.getTurretFleet(this.base.id);
    modal.componentInstance.formationMaxLines = 1;
    modal.componentInstance.hasSecondaryFleet = false;
    modal.componentInstance.getCurrentUnits = "getBuiltTurrets";
  }

  upgrade() {
    this.baseService
      .buildNew(
        this.base.id,
        this.selectedSlot,
        this.base.slots[this.selectedSlot].built.level + 1,
        this.selectedBuilding.id
      )
      .subscribe((base) => {
        this.baseService.updateBase();
        this.eventService.updateBaseEventNeeded();
        this.shouldRefreshUser(this.base.slots[this.selectedSlot].built.building.id);
        this.modalService.dismissAll();
      });
  }

  private confirmDisableModal;
  confirmDisable() {
    this.baseService.disableBuilt(this.base.id, this.selectedSlot).subscribe((base) => {
      this.baseService.updateBase();
      this.eventService.updateBaseEventNeeded();
      this.shouldRefreshUser(this.base.slots[this.selectedSlot].built.building.id);
      this.confirmDisableModal.dismiss();
    });
  }

  shouldRefreshUser(buildId) {
    let isUserSensitive = false;
    for (let key in this.buildingsService.buildingsMap.get(buildId).levels[0].effects) {
      let effect = this.buildingsService.buildingsMap.get(buildId).levels[0].effects[key];
      if(effect.target === "OWNER") {
        isUserSensitive = true;
      }
    }
    if (isUserSensitive) {
      this.userService.updateUser()
    }
  }

  hasEffect(effectName) {
    return this.selectedBuilding.levels[this.selectedBuilt.level].cumulatedEffects[effectName] && (this.base.effects[effectName.split('-')[0]] || this.user.effects[effectName.split('-')[0]])
  }


  disableBuilding(content) {
    this.confirmDisableModal = this.modalService
      .open(content, {
        ariaLabelledBy: "base_confirmDisable",
        scrollable: true,
        windowClass: "base_confirmDisable",
      });
  }


  enableBuilding() {
    this.baseService.enableBuilt(this.base.id, this.selectedSlot).subscribe((base) => {
      this.baseService.updateBase();
      this.eventService.updateBaseEventNeeded();
      this.shouldRefreshUser(this.base.slots[this.selectedSlot].built.building.id);
    });
  }

  private confirmDestroyModal;
  destroyBuilding(content) {
    this.confirmDestroyModal = this.modalService
      .open(content, {
        ariaLabelledBy: "base_confirmDestroy",
        scrollable: true,
        windowClass: "base_confirmDestroy",
      });
  }

  confirmDestroy() {
    this.baseService.destroyBuilding(this.base.id, this.selectedSlot)
      .subscribe((base) => {
        this.baseService.updateBase();
        this.eventService.updateBaseEventNeeded();
        this.shouldRefreshUser(this.base.slots[this.selectedSlot].built.building.id);
        this.modalService.dismissAll();
      });
  }
  unitDetailModal;
  unitDetails(unit: Ship) {
    if (!this.unitDetailModal) {
      this.unitDetailModal = this.modalService.open(UnitDetailComponent, {
        ariaLabelledBy: "ShipDetail",
        scrollable: true,
        size: "lg",
        windowClass: "fleet-ShipDetail backModalImg",
        backdrop: false,
      });
    }
    this.unitDetailModal.componentInstance.addUnit(unit);
    this.unitDetailModal.hidden.subscribe(() => {
      this.unitDetailModal = null;
    });
  }

  change(event) {
    if (event.nextId == 2) {
      this.shipService.getBuildableShips()
        .subscribe((ships) => {
          if (ships) {
            ships.forEach((ship) => {
              ship.buildQuantity = 1;
            });
            this.buildableShips = ships;
          }
        });
    } else if (event.nextId == 3) {
      this.shipService.getBuildableTurrets()
        .subscribe((turrets) => {
          if (turrets) {
            turrets.forEach((turret) => {
              turret.buildQuantity = 1;
            });
            this.buildableTurrets = turrets;
          }
        });
    }
  }

  clickOnEffect(effect) {
    console.error({effectCode:effect.value});
    const modal = this.modalService.open(EffectDetailComponent, {
      ariaLabelledBy: "effectDetail",
      scrollable: true,
      size: "xl",
      windowClass: "effectDetail backModalImg",
      backdrop: true,
    });

    modal.componentInstance.effect = effect.value;
    modal.componentInstance.isFromUser = !this.effectService.getEffects(effect.value.code).local;
  }

  clickOnResource(resource) {
    console.error({resource:resource});
    const modal = this.modalService.open(ResourceDetailComponent, {
      ariaLabelledBy: "resourceDetail",
      scrollable: true,
      size: "xl",
      windowClass: "resourceDetail backModalImg",
      backdrop: true,
    });

    modal.componentInstance.resourceCode = resource.key;
  }

  getEffects(effectCode: string): EffectRef {
    return this.effectService.getEffects(effectCode);
  }
}


