import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Base } from 'src/app/models/base/Base';
import { Fleet } from 'src/app/models/Fleet/Fleet';
import { FleetService } from 'src/app/services/fleets/fleet.service';

@Component({
  selector: 'app-refuel',
  templateUrl: './refuel.component.html',
  styleUrls: ['./refuel.component.scss']
})
export class RefuelComponent implements OnInit {
  @Input()
  public fleet: Fleet;

  @Input()
  public sourceFleet: Fleet;

  @Input()
  public base: Base;

  public frmFuel: FormGroup;
  get amount() {
    return this.frmFuel.get("amount");
  }
  get sourceChoice() {
    return this.frmFuel.get("sourceChoice");
  }

  public sourceChoices: string[] = [];
  public preChoice: string;

  constructor(
    protected readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal, // needed in HTML
    protected readonly fleetService: FleetService
  ) { }

  ngOnInit(): void {
    if (this.base) {
      this.sourceChoices.push("BASE");
    }
    if (this.sourceFleet) {
      this.sourceChoices.push("FLEET");
    }


    this.frmFuel = this.fb.group({
      amount: [this.fleet.cumulatedFuelTank || 0, Validators.required],
      sourceChoice: [this.sourceChoices[0] || "DEFAULT", Validators.required],
    });
  }

  public onSubmit(): boolean {
    // Vérifier la validité du formulaire
    if (this.frmFuel.valid) {
      if (this.sourceChoice.value == "BASE") {
        this.fleetService.refuel(this.fleet.id, this.amount.value).subscribe(() => {
          this.modal.close();
        })
      } else if (this.sourceChoice.value == "FLEET") {
        this.fleetService.transferFuel(this.sourceFleet.id, this.fleet.id, this.amount.value).subscribe(() => {
          this.modal.close();
        })
      }
    } else {
      //TODO : afficher le pourquoi de ca fonctionne pas
    }
    return false;
  }

}
