import { Injectable } from "@angular/core";
import { GovernmentRef } from "src/app/models";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class GovernmentService {
  private apiUrl = environment.apiEndpoint;
  constructor(private readonly http: HttpClient) {}

  public getGovernments(): Observable<GovernmentRef[]> {
    return this.http.get<GovernmentRef[]>(this.apiUrl + `govs`);
  }

  public getGovernment(id : string): Observable<GovernmentRef> {
    return this.http.get<GovernmentRef>(this.apiUrl + `govs/${id}`);
  }

  public getGovernmentRef(id : string): Observable<GovernmentRef> {
    return this.http.get<GovernmentRef>(this.apiUrl + `govs/${id}/ref`);
  }
}
