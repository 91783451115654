<div *ngFor="let resource of resources | keyvalue" class="col-md resource-item flex-column dropdown"
  (click)="openDetail(resource.key)">
  <label class="resource-value d-flex flex-fill">{{ utilsService.handleResource(resource.value) }}</label>
  <label class="d-flex flex-fill">
    <img alt={{resource.key}} class="resourceImg d-flex" src="/assets/img/resources/{{resource.key}}.png"
      title={{resource.key}} />
    <ngb-progressbar [max]=resource.value.storage [striped]="true" [value]=resource.value.clientAmount
      [ngClass]="utilsService.resourceColour.get(resource.key)" type={{utilsService.resourceColour.get(resource.key)}}
      class="resource-progressbar d-flex flex-fill"></ngb-progressbar>
  </label>
  <div class="dropdown-content resource-content" [ngClass]="utilsService.resourceColour.get(resource.key)">
    <label class="d-flex flex-fill flex-column" style="left:0;">
      <label class="d-flex flex-fill">Production horaire : {{resource.value.production -
        resource.value.consumption}}</label>
      <label class="d-flex flex-fill">Stockage actuel : {{utilsService.floor(resource.value.clientAmount)}}</label>
      <label class="d-flex flex-fill">Stockage maximum : {{resource.value.storage}}</label>
    </label>
  </div>
</div>
<div *ngIf="user" class='my-auto col-md' data-toggle="tooltip" data-placement="bottom" [title]="user.money">
<i class='my-auto mx-2 bx bxs-bank'></i>
<label class="text-justify text-white">{{utilsService.nFormatter(user.money)}}</label>
</div>
