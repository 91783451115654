<div *ngIf="!effectDetail" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>
<div *ngIf="effectDetail" class="modal-header">
  <h4 class="modal-title me-3 ">
    {{ (effectRef||effect).libelle }}
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset" ngbAutofocus>
  </button>
</div>

<div *ngIf="effectDetail" class="modal-body d-flex flex-column">
  <table class="table table-hover table-dark effect-Detail">
    <tbody>
      <tr class="justify-content-around p-2">
        <th class="underline">Type d'effet </th>
        <td>
          <div class="text-center">
            {{ convertType[effectRef.type] }}
          </div>
        </td>
      </tr>
      <tr *ngIf="effectDetail.buildingEffect && effectDetail.buildingEffect['length'] > 0" class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
    </tbody>
  </table>
  <table class="table table-hover table-dark effect-Detail">
    <tbody>
      <tr class="justify-content-around p-2">
        <th class="underline">Valeur totale actuelle </th>
        <th>
          <div class="text-center">
            {{ effectDetail.cumulatedAmount }}
          </div>
        </th>
      </tr>
      <tr *ngIf="effectDetail.buildingEffect && effectDetail.buildingEffect['length'] > 0" class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr *ngIf="effectDetail.buildingEffect && effectDetail.buildingEffect['length'] > 0" class="justify-content-around p-2">
        <th colspan="2" class="underline">Effects liés aux batiments </th>
      </tr>
      <tr *ngFor="let buildingEffect of effectDetail.buildingEffect | keyvalue" class="justify-content-around p-2">
        <td>{{ buildingEffect.value.libelleSource }}</td>
        <td class="text-center">{{ buildingEffect.value.amount }}</td>
      </tr>
      <tr *ngIf="effectDetail.externalEffect && effectDetail.externalEffect['length'] > 0" class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr *ngIf="effectDetail.externalEffect && effectDetail.externalEffect['length'] > 0" class="justify-content-around p-2">
        <th colspan="2" class="underline">Effects externes</th>
      </tr>
      <tr *ngFor="let externalEffect of effectDetail.externalEffect | keyvalue" class="justify-content-around p-2">
        <td>{{ externalEffect.value.libelleSource }}</td>
        <td class="text-center">{{ externalEffect.value.amount }}</td>
      </tr>
      <tr *ngIf="effectDetail.researchEffect && effectDetail.researchEffect['length'] > 0" class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr *ngIf="effectDetail.researchEffect && effectDetail.researchEffect['length'] > 0" class="justify-content-around p-2">
        <th colspan="2" class="underline">Effects des recherches</th>
      </tr>
      <tr *ngFor="let researchEffect of effectDetail.researchEffect | keyvalue" class="justify-content-around p-2">
        <td>{{ researchEffect.value.libelleSource }}</td>
        <td class="text-center">{{ researchEffect.value.amount }}</td>
      </tr>
    </tbody>
  </table>
</div>
