import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alliance } from 'src/app/models/Alliance/Alliance';
import { AllianceService } from 'src/app/services/Alliance/alliance.service';
import { ShowAllianceComponent } from '../showAlliance/showAlliance.component';

@Component({
  selector: 'app-list-alliances',
  templateUrl: './list-alliances.component.html',
  styleUrls: ['./list-alliances.component.scss']
})
export class ListAlliancesComponent implements OnInit {
  alliances: Alliance[];
  originalAlliances: Alliance[];

  constructor(
    private readonly allianceService: AllianceService,
    public readonly modal: NgbActiveModal,
    private readonly modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.allianceService.getAllAlliance().subscribe(alliances => {
      this.originalAlliances = alliances
      this.alliances = JSON.parse(JSON.stringify(alliances));
    });
  }

  sortByNumber(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.alliances = this.alliances.sort((a, b) => {
      return (parseInt(b[orderType], 16) - parseInt(a[orderType], 16)) * order;
    })
  }



  showOrder(orderType) {
    let ths = document.getElementsByTagName('app-market')[0].getElementsByTagName('th');
    let order = document.getElementById(orderType).getElementsByTagName('i')[0].classList.contains('bx-sort-down');
    for (let i = 0; i < ths.length; i++) {
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-down')
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-up')
    }
    if (order) {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-up')
    } else {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-down')
    }
    return order;
  }

  sortByText(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.alliances = this.alliances.sort((a, b) => {
      return (a[orderType].toLocaleLowerCase() > b[orderType].toLocaleLowerCase() ? 1 : -1) * order;
    })
  }

  onSearch(value) {
    this.alliances = this.originalAlliances.filter((offer) => {
      for (let key in offer) {
        if ((offer[key] + '').toLocaleLowerCase().includes(value)) {
          return true;
        }
      }

      return false;
    });
  }

  openAlliance(alliance) {
    let showAllianceComponent = this.modalService.open(ShowAllianceComponent, {
      ariaLabelledBy: "modal-show-alliance",
      scrollable: true,
      size: "xl",
      windowClass: "showAlliance backModalImg",
    });
    showAllianceComponent.componentInstance.allianceRef = alliance;
    showAllianceComponent.dismissed.subscribe((success)=> {
      this.modal.dismiss();
    })
  }

}
