import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EffectRef } from 'src/app/models/Effect/EffectRef';
import { EffectSource } from 'src/app/models/Effect/EffectSource';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EffectService {
  private apiUrl = environment.apiEndpoint;


  private knownEffects: EffectRef[];


  constructor(
    private readonly http: HttpClient,
  ) {
    this.getKnownEffects().subscribe();
  }

  public getEffectSourceByBase(effectCode: string, baseId: string): Observable<EffectSource> {
    return this.http.get<EffectSource>(this.apiUrl + `effects/detail/${effectCode}/base/${baseId}`);
  }

  public getEffectSourceByOwner(effectCode: string, ownerId: string): Observable<EffectSource> {
    return this.http.get<EffectSource>(this.apiUrl + `effects/detail/${effectCode}/owner/${ownerId}`);
  }

  public getKnownEffects(): Observable<EffectRef[]> {
    return this.http.get<EffectRef[]>(this.apiUrl + `effects/refs`).pipe(
      map(effects => {
        this.knownEffects = effects;
        return effects;
      }),
    );
  }

  public getEffects(effectCode: string): EffectRef {
    if(this.knownEffects)
      return this.knownEffects.find((effect) => effect.code == effectCode);
      else return new EffectRef();
  }
}
