import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/app/models/Alert';
import { Quest } from 'src/app/models/Quest/Quest';
import { AlertService } from 'src/app/services/alert/alert.service';
import { BaseService } from 'src/app/services/bases/base.service';
import { QuestsService } from 'src/app/services/Quests/quests.service';
import { UsersService } from 'src/app/services/Users/user.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';

@Component({
  selector: 'app-quests',
  templateUrl: './quests.component.html',
  styleUrls: ['./quests.component.scss']
})
export class QuestsComponent implements OnInit {
  availableQuests: Quest[];
  activeQuests: Quest[];
  selectedQuest: Quest;
  constructor(
    private readonly questService: QuestsService,
    private readonly userService: UsersService,
    public readonly utilsService: UtilsService,
    private readonly alertService: AlertService,
    private readonly baseService: BaseService,
  ) { }

  ngOnInit(): void {

    this.questService.availableQuestsAnnounced$.subscribe((quests) => {
      this.availableQuests = quests;
    })
    this.questService.activeQuestsAnnounced$.subscribe((quests) => {
      this.activeQuests = quests;
    })
    this.questService.refreshActiveQuests();
    this.questService.refreshAvailableQuests();
  }

  closeWindow(reason, source) {
    source.target.parentElement.parentElement.remove()
    let backdrop = document.getElementsByClassName('modal-backdrop')
    if (backdrop.length > 0) {
      document.getElementsByClassName('modal-backdrop')[backdrop.length - 1].remove();
    }
  }

  onCollapse(target) {
    target.parentElement.classList.toggle('collapsed');
  }

  openQuest(quest) {
    if (!quest.locked)
      this.selectedQuest = quest;
    else
      this.selectedQuest = null
  }

  refuseQuest(quest) {
    this.questService.refuseQuest(this.userService.currentUserId(), quest.id).subscribe((value) => {
      let alert = new Alert();
      alert.timeout = 2000;
      if (value) {
        this.selectedQuest = null;
        this.questService.refreshAvailableQuests();
        alert.type = "warning";
        alert.msg = `la quête a été refusée !`;
      } else {
        alert.type = "danger";
        alert.msg = `la quête n'a pas pu être refusée !`;
      }
      this.alertService.addAlert(alert);
    });
  }

  acceptQuest(quest) {
    this.questService.acceptQuest(this.userService.currentUserId(), quest.id).subscribe((value) => {
      let alert = new Alert();
      alert.timeout = 2000;
      if (value) {
        this.selectedQuest = null;
        this.questService.refreshAvailableQuests();
        this.questService.refreshActiveQuests();
        alert.type = "success";
        alert.msg = `la quête a été acceptée !`;
      } else {
        alert.type = "danger";
        alert.msg = `la quête n'a pas pu être acceptée !`;
      }
      this.alertService.addAlert(alert);
    });
  }

  abandonQuest(quest) {
    this.questService.abandonQuest(this.userService.currentUserId(), quest.id).subscribe((value) => {
      let alert = new Alert();
      alert.timeout = 2000;
      if (value) {
        this.selectedQuest = null;
        this.questService.refreshActiveQuests();
        alert.type = "warning";
        alert.msg = `la quête a été abondonnée !`;
      } else {
        alert.type = "danger";
        alert.msg = `la quête n'a pas pu être abondonnée !`;
      }
      this.alertService.addAlert(alert);
    });
  }

  finishQuest(quest) {
    this.questService.finishQuest(this.userService.currentUserId(), quest.id).subscribe((value) => {
      let alert = new Alert();
      alert.timeout = 2000;
      if (value) {
        this.selectedQuest = null;
        this.questService.refreshActiveQuests();
        this.questService.refreshAvailableQuests();
        this.baseService.updateBase();
        alert.type = "success";
        alert.msg = `la quête a été validé, la récompense a été distribuée !`;
      } else {
        alert.type = "danger";
        alert.msg = `la quête n'a pas pu être validée !`;
      }
      this.alertService.addAlert(alert);
    });
  }
}
