import { Injectable } from '@angular/core';
import { Menu } from 'src/app/models/Menu/Menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menu: Menu[];
  knownMenu: Map<string, Menu>;
  constructor() {
    this.initKnownMenu();
    this.menu = JSON.parse(localStorage.getItem('MenuDatas')) || this.initMenu();
    this.saveMenu();

  }

  saveMenu() {
    localStorage.setItem('MenuDatas', JSON.stringify(this.menu || this.knownMenu));
    //this.menu = JSON.parse(localStorage.getItem('MenuDatas'));
  }
  initMenu(): Menu[] {
    let menu = [];
    menu.push(this.knownMenu.get("messages"))
    menu.push(this.knownMenu.get("base"))
    menu.push(this.knownMenu.get("fleet"))
    menu.push(this.knownMenu.get("planet"))
    menu.push(this.knownMenu.get("ranking"))
    menu.push(this.knownMenu.get("profile"))
    menu.push(this.knownMenu.get("options"))
    menu.push(this.knownMenu.get("quests"))
    menu.push(this.knownMenu.get("technoTree"))
    menu.push(this.knownMenu.get("discord"))
    menu.push(this.knownMenu.get("bookmark"))
    return JSON.parse(JSON.stringify(menu)) as Menu[]

  }

  initKnownMenu() {
    this.knownMenu = new Map<string, Menu>();
    let menu: Menu = {
      icon: 'bx-chat',
      color: 'white',
      name: 'Messages',
      description: 'Messages',
      redirect: 'messages',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('messages', menu);
    menu = {
      icon: 'bx-buildings',
      color: 'white',
      name: 'Bases',
      description: 'Bases',
      redirect: 'base',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('base', menu);
    menu = {
      icon: 'bxs-plane-alt',
      color: 'white',
      name: 'Flotte',
      description: 'Flotte',
      redirect: 'fleet',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('fleet', menu);
    menu = {
      icon: 'bx-planet',
      color: 'white',
      name: 'Planète',
      description: 'Planète',
      redirect: 'planet',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('planet', menu);
    menu = {
      icon: 'bx-dna',
      color: 'white',
      name: 'Recherche',
      description: 'Recherche',
      redirect: 'research',
      disabled: false,
      requires: ['user', ['RESEARCH_MAX_LINE', 1]]
    };
    this.knownMenu.set('research', menu);
    menu = {
      icon: 'bxs-bank',
      color: 'white',
      name: 'Marché',
      description: 'Marché',
      redirect: 'market',
      disabled: false,
      requires: ['base', ['PARALLEL_OFFER', 1]]
    };
    this.knownMenu.set('market', menu);
    menu = {
      icon: 'bx-user-plus',
      color: 'white',
      name: 'Alliance',
      description: 'Alliance',
      redirect: 'alliance',
      disabled: false,
      requires: ['user', ['JOIN_ALLIANCE_UNLOCKED', 1]]
    };
    this.knownMenu.set('alliance', menu);
    menu = {
      icon: 'bx-book-open',
      color: 'white',
      name: 'Quêtes',
      description: 'Quêtes',
      redirect: 'quests',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('quests', menu);
    menu = {
      icon: 'bx-trophy',
      color: 'white',
      name: 'Classement',
      description: 'Classement',
      redirect: 'ranking',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('ranking', menu);
    menu = {
      icon: 'bx-user',
      color: 'white',
      name: 'Profil',
      description: 'Profil',
      redirect: 'profile',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('profile', menu);
    menu = {
      icon: 'bx-cog',
      color: 'white',
      name: 'Paramètres',
      description: 'Paramètres',
      redirect: 'options',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('options', menu);
    menu = {
      icon: 'bx-library',
      color: 'white',
      name: 'Arbre Techno',
      description: 'Arbre Techno',
      redirect: 'technoTree',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('technoTree', menu);
    menu = {
      icon: 'bxl-discord',
      color: 'white',
      name: 'Discord',
      description: 'Discord',
      redirect: 'discord',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('discord', menu);
    menu = {
      icon: 'bxs-bookmark-alt-plus',
      color: 'white',
      name: 'Modifier',
      description: 'Personnaliser le menu',
      redirect: 'bookmark',
      disabled: false,
      requires: ['', ['', 0]]
    };
    this.knownMenu.set('bookmark', menu);
  }
}
