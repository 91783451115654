<div *ngIf="!report" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="report">
  <div class="modal-header">
    <h4 class="modal-title me-3" id="modal-basic-title">
      {{ report.libelle }} du {{ report.date | date: 'full': '' : 'fr-FR' }} - {{ report.id }}
    </h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button">
    </button>
  </div>


  <div class="modal-body d-flex ">
    <button class="btn btn-success mx-auto" (click)="accept()">accepter l'invitation</button>
    <button class="btn btn-danger mx-auto" (click)="decline()">refuser l'invitation</button>
  </div>
</div>
