import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, Routes } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { takeUntil, timer } from "rxjs";
import { GovernmentRef, User } from "src/app/models";
import { Alert } from "src/app/models/Alert";
import { GovernmentService } from "src/app/services/Government/government.service";
import { UsersService } from "src/app/services/Users/user.service";

@Component({
  selector: "app-users-create",
  templateUrl: "./users-create.component.html",
  styleUrls: ["./users-create.component.scss"],
})
export class UsersCreateComponent implements OnInit {
  static routes: Routes = [{ path: "", component: UsersCreateComponent }];

  governments: GovernmentRef[];
  selectedGovernment: GovernmentRef;

  public frmUser: FormGroup;
  get pseudo() {
    return this.frmUser.get("pseudo");
  }
  get description() {
    return this.frmUser.get("description");
  }

  public alerts: Map<number, Alert> = new Map<number, Alert>();

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly keycloakService: KeycloakService,
    private readonly usersService: UsersService,
    private readonly governmentService: GovernmentService,
    private readonly userService: UsersService,
  ) {
    document.getElementsByTagName('body')[0].classList.add('overflow-auto');
  }

  ngOnInit() {
    // Instancier le ReactiveForm
    this.frmUser = this.fb.group({
      pseudo: [undefined, Validators.required],
      description: [undefined, Validators.required],
    });
    this.usersService.userUpdate$.subscribe({
      next: (user) => {
        if (user) {
          this.router.navigate(["/main"]);
          document.getElementsByTagName('body')[0].classList.remove('overflow-auto');
        } else {
          localStorage.removeItem('currentBase')
          localStorage.removeItem('currentMenu')
          localStorage.removeItem('RummageHistory')
          localStorage.removeItem('user')
          this.getGovernments();
        }
      },
      error: (error) => {
        localStorage.removeItem('currentBase')
        localStorage.removeItem('currentMenu')
        localStorage.removeItem('RummageHistory')
        localStorage.removeItem('user')
        this.getGovernments();
      }
    });
    if (document.getElementsByClassName('mainLoading')) {
      (<HTMLElement>document.getElementsByClassName('mainLoading')[0])['className'] = '';
    }

    // remove alerts when enough time has passed
    const alertTimer = timer(10000, 5000);
    const alertSubscribe = alertTimer.subscribe((val) => {
      let now = Date.now();
      if (this.alerts) {
        for (let alert of this.alerts.keys()) {
          if (now > (alert + this.alerts.get(alert).timeout)) {
            if (!this.alerts.get(alert).focus) {
              this.removeAlert(alert);
            }
          }
        }
      }
    });
  }

  private getGovernments(): void {
    this.governmentService.getGovernments().subscribe((governments) => { this.governments = governments });
  }

  public selectGovernment(government: GovernmentRef): void {
    this.selectedGovernment = government;
  }

  public onSubmit(): void {
    // Vérifier la validité du formulaire
    if (this.frmUser.valid && this.selectedGovernment) {
      if (this.selectedGovernment.freeBaseCount != 0) {
        // Créer l'objet User
        const user = Object.assign(new User(), {
          id: this.keycloakService.getUsername,
          name: this.pseudo.value,
          description: this.description.value,
          governmentId: this.selectedGovernment.id,
        });
        this.userService.postUser(user).subscribe({
          next: (response) => {
            window.location.href = window.location.origin;
          },
          error: (error) => {
            let alert = new Alert();
            alert.type = "danger";
            alert.msg = (error.error.message || error.message);
            alert.timeout = 10000;;
            if (error.error.message.startsWith("CODE don't respect a constraint[code=")) {
              this.elmBlinkRed(document.getElementById("code"))
              alert.msg = "Le code est incorrect";
            }
            this.alerts.set(Date.now(), alert);
          }
        });
      }
    } else {
      for (let control in this.frmUser.controls) {
        if (this.frmUser.controls[control].status === "INVALID") {
          setTimeout(() => {
            let alert = new Alert();
            alert.type = "danger";
            alert.msg = "\"" + control + "\" n'a pas de valeur valide";
            alert.timeout = 10000;
            this.alerts.set(Date.now(), alert);
            this.elmBlinkRed(document.getElementById(control))
          }, 0);
        }

      }
      if (!this.selectedGovernment) {
        setTimeout(() => {
          this.elmBlinkRed(document.getElementById("government"))
          let alert = new Alert();
          alert.type = "danger";
          alert.msg = "vous n'avez pas sélectionné de gouvernement";
          alert.timeout = 10000;
          this.alerts.set(Date.now(), alert);
        }, 0);
      }
    }
  }

  elmBlinkRed(elm) {
    if (elm) {
      let border = "1px dashed red";
      elm.style.border = border;
      for (let index = 0; index < 11; index++) {
        setTimeout(() => {
          if (elm.style.border) {
            elm.style.border = "";
          } else {
            elm.style.border = border;
          }
        }, index * 250);
      }
    }
  }

  public removeAlert(index) {
    this.alerts.delete(index);
  }
}
