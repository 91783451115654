import { Component, OnInit } from "@angular/core";
import { Routes, Router } from "@angular/router";
import { GovernmentService } from "src/app/services/Government/government.service";
import { GovernmentRef } from "src/app/models";

@Component({
  selector: "app-government",
  templateUrl: "./government.component.html",
  styleUrls: ["./government.component.scss"],
})
export class GovernmentComponent implements OnInit {
  static routes: Routes = [{ path: "", component: GovernmentComponent }];

  governments: GovernmentRef[];

  constructor(
    private readonly router: Router,
    private readonly governmentService: GovernmentService,
  ) {}

  ngOnInit() {
    this.getGovernments();
  }

  private getGovernments(): void {
    this.governmentService
      .getGovernments()
      .subscribe((governments) =>
        this.governments = governments
      );
  }
}
