<table class="table table-striped table-hover table-dark test">
  <tbody>
    <tr class="">
      <td class="form-switch px-5">
        <input class="form-check-input align-self-center" type="checkbox" id="SwitchShowReportsRead"
          (change)="reloadReport()" [(ngModel)]="showLu">
        <label class="form-check-label" for="SwitchShowReportsRead"> Afficher les rapports lus</label>
      </td>

      <td class="">
        <label>
          Type de rapports :
        </label>
        <select id="baseFilter" (change)="filterReports($event.target)">
          <option *ngFor="let filter of filters" [selected]="filter.selected" [value]="filter.id">
            {{ filter.value }}
          </option>
        </select>
      </td>

      <td class="">
        <!-- <button (click)="readAllReports()" class="btn btn-secondary">
              marquer tous lus
            </button> -->
        <button *ngIf="reports && reports[0]" [ngClass]="showLu ?'d-none':''"
          title="ouvre et supprime tous les rapports affichés" (click)="openAllReports()"
          class="btn btn-secondary mx-2">
          ouvrir et supprimer tous les messages filtrés
        </button>
      </td>
    </tr>
    <tr *ngFor="let report of reports" class="justify-content-around p-2">
      <td *ngIf="report.position">
        {{ report.libelle }} - {{ report.id }}, en {{ report.position.coordinates }}
      </td>
      <td *ngIf="!report.position">
        {{ report.libelle }} - {{ report.id }}
      </td>
      <td>
        <div>
          {{ translateReportType(report.type) }}
        </div>
        <div>
          {{ report.date | date: 'full': '' : 'fr-FR' }}
        </div>
      </td>
      <td>
        <button (click)="openReport(report)" class="btn btn-primary">
          Lire
        </button>
        <button (click)="deleteReport(report)" class="btn btn-outline-danger"
          [ngStyle]="{'visibility':(showLu || !deleteUnreadReport)?'visible':'hidden'}">
          Supprimer
        </button>
      </td>
    </tr>
    <tr [ngStyle]="{'display':(!inError)?'':'none'}" class="loading">
    </tr>

  </tbody>
</table>
