<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">Personnaliser le menu
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>

<div class="modal-body d-flex flex-row" (dragover)="(false)">
  <div class="sidebar open mx-2">
    <div class="logo-details">
      <i class='bx bx-menu-alt-left'></i>
      <div class="logo_name">Menu</div>
      <i class='bx bx-menu' id="btn"></i>
    </div>
    <b class="nav-list">
      <li class="alwaysOn">
        <i class='bx bx-search' (click)="$event.target['parentElement'].children[1].focus()"></i>
        <input class="inputSearch" type="search" placeholder="Search...">
        <span class="tooltip">Rechercher</span>
      </li>
      <li class="alwaysOn">
        <select class="form-control" id="baseName">
          <option>
            Liste de bases
          </option>
        </select>
      </li>
      <!-- <li>
      <a (click)="redirect('planet')" [ngbTooltip]="checkTool('Planète')" placement="right" container="body"
        #tooltip="ngbTooltip">
        <i class='bx bx-planet'></i>
        <span class="links_name">Planète</span>
      </a>
    </li> -->
      <li *ngFor="let menu of menuService.menu" (dragend)="cleanAfterDrag()" [ngStyle]="{'--menuColor':menu.color}"
        (drop)="drop($event)" (dragstart)="selectMenuToMove($event, menu)" (dragover)="dragover($event)"
        draggable="true" class="currentMenu">
        <a>
          <i class='bx links-icon' [class]="menu.icon"></i>
          <span class="links_name">{{menu.name}}</span>
          <i class='bx bx-palette' [(colorPicker)]="menu.color" (colorPickerChange)="onColorPickerChange(menu, $event)"
            (colorPickerClose)="saveMenu()" [cpUseRootViewContainer]="true" [cpOKButton]="true"></i>
        </a>
      </li>
    </b>
  </div>
  <b class="sidebar open mx-2 knownMenu">
    <li *ngFor="let menu of menuService.knownMenu | keyvalue" (dragend)="cleanAfterDrag()"
      [ngStyle]="{'--menuColor':menu.value.color}" (drop)="remove($event)" (dragstart)="selectMenuToAdd($event, menu)"
      (drag)="drag($event)" draggable="true">
      <a class="d-flex">
        <i class='bx links-icon my-auto' [class]="menu.value.icon"></i>
        <div class="">
          <div class="links_name">{{menu.value.name}}</div>
          <div class="links_name links_description">{{menu.value.description}}</div>
          <div class="d-none redirectText">{{menu.value.redirect}}</div>
        </div>
      </a>
    </li>
  </b>
</div>
