import { Component, OnDestroy, OnInit } from "@angular/core";
import { Routes } from "@angular/router";
import { NgbModal, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { ShowProfileComponent } from "src/app/main/users/profile/show-profile/show-profile.component";
import { User } from "src/app/models";
import { Alert } from "src/app/models/Alert";
import { Base } from "src/app/models/base/Base";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { FleetRef } from "src/app/models/Fleet/FleetRef";
import { AlertService } from "src/app/services/alert/alert.service";
import { BaseService } from "src/app/services/bases/base.service";
import { EventService } from "src/app/services/event/event.service";
import { FleetService } from "src/app/services/fleets/fleet.service";
import { OptionsService } from "src/app/services/options/options.service";
import { UsersService } from "src/app/services/Users/user.service";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { RefuelComponent } from "../refuel/refuel.component";
import { FleetStorageComponent } from "./fleet-storage/fleet-storage.component";
import { FleetTravelComponent } from "./fleet-travel/fleet-travel.component";
import { FormationDetailComponent } from "./formation/formation-detail.component";

@Component({
  selector: "app-fleets",
  templateUrl: "./fleets.component.html",
  styleUrls: ["./fleets.component.scss"],
})
export class FleetsComponent implements OnInit, OnDestroy {
  static routes: Routes = [{ path: "", component: FleetsComponent }];

  active = 1;
  private searchText: string;
  private user: User;
  public localFleets: Fleet[];
  public userFleets: Fleet[] = [];
  private usableFleets: Fleet[];
  public otherFleets: FleetRef[];
 // private allFleets: Fleet[];
 // private allFleetsFiltered: Fleet[];
  public turrets: Fleet;
  private selectedFleet: Fleet;
  private isNameUdpating = false;
  private base: Base;
  public timerMap: Map<string, string> = new Map<string, string>();
  private refreshTimer;
  private readonly destroy$ = new Subject();
  public debug: boolean = false;

  constructor(
    private readonly modalService: NgbModal,
    private readonly userService: UsersService,
    private readonly fleetService: FleetService,
    private readonly baseService: BaseService,
    private readonly alertService: AlertService,
    private readonly optionsService: OptionsService,
    private readonly eventService: EventService,
    public readonly utilsService: UtilsService,
  ) {
    //Maj du component chaque fois qu'il y a une modif de la base dans le service
    baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
          if (this.user) {
            this.refreshFleet();
          }
          this.refreshExternFleet();
          this.moveFleetTimer();
          this.refreshTurrets();
        }
      });


    this.userService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.fleetService.updateFleets();
        }
      });


    this.fleetService.fleetUpdate$.subscribe((fleets) => {
      this.userFleets = fleets;
      let filteredFleet: Fleet[] = [];
      let filteredUsableFleet: Fleet[] = [];

      for (let key in fleets) {
        let fleet = fleets[key];
        if (fleet.currentBaseId == this.baseService.getCurrentBaseId()) {
          filteredFleet.push(fleet);
        }
        if (fleet.status != 'MOVING' && fleet.status != 'FIGHTING' && fleet.status != 'TRANSITING' && fleet.amount > 0) {
          filteredUsableFleet.push(fleet);
        }
      };
      this.localFleets = filteredFleet;
      this.usableFleets = filteredUsableFleet;
    });
  }

  cancel(fleetId) {
    this.fleetService.cancelTravelByFleetId(fleetId).subscribe(() => {
      this.eventService.updateBaseEventNeeded();
      this.eventService.updatePlayerEventNeeded();
      this.refreshFleet();

    });
  }

  ngOnInit() {
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  //Temporaire : quand la gestion des event sera faite, y aura plus besoin de refresh hors event
  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 1) {
      this.refreshFleet();
      this.refreshTurrets();
    } else if (changeEvent.nextId === 2) {
      this.refreshFleet();
    } else if (changeEvent.nextId === 3) {
      this.refreshExternFleet();
    } else if (changeEvent.nextId === 4) {
      this.refreshAllFleet();
    }
  }

  refreshTurrets() {
    this.fleetService.getTurretFleet(this.baseService.getCurrentBaseId()).subscribe((fleet) => {
      this.turrets = fleet;
    });
  }

  openTurrets(selectedFleet: Fleet) {
    //Open Modal
    let modal = this.modalService.open(FormationDetailComponent, {
      ariaLabelledBy: "fleet-detail",
      scrollable: true,
      size: "xl",
      windowClass: "fleet-detail backModalImg"
    });
    modal.componentInstance.getMainFleet = this.fleetService.getTurretFleet(selectedFleet.currentBaseId);
    modal.componentInstance.user = this.user;
    modal.componentInstance.formationMaxLines = 1;
    modal.componentInstance.isTurrets = true;
    modal.componentInstance.getCurrentUnits = "getBuiltTurrets";
    modal.hidden.subscribe(() => this.refreshTurrets());
//       this.baseService.getBaseById(this.selectedFleet.currentBaseId).subscribe((base) => {
//         this.selectedFleetBase = base;
//
//         modal.componentInstance.mainFleet = this.selectedFleet;
//         modal.componentInstance.selectedFleetBase = this.selectedFleetBase;
//       });
//     });
  }

  addFleet() {
    this.fleetService
      .createFleet(this.baseService.getCurrentBaseId(), "Flotte")
      .subscribe(() => {
        this.refreshFleet();
      });
  }

  openFleet(selectedFleet: Fleet) {
    //Open Modal
    let modal = this.modalService.open(FormationDetailComponent, {
      ariaLabelledBy: "fleet-detail",
      scrollable: true,
      size: "xl",
      windowClass: "fleet-detail backModalImg"
    });
    modal.componentInstance.formationMaxLines = this.base.effects['FLEET_MAX_LINE'];
    modal.componentInstance.user = this.user;
    modal.componentInstance.isTurrets = false;
    modal.componentInstance.getMainFleet = this.fleetService.getFleetById(selectedFleet.id);
    modal.componentInstance.getCurrentUnits = "getBuiltShips";
    modal.hidden.subscribe(() => this.refreshFleet());
  }

  moveFleet(selectedFleet: Fleet) {
    let modal = this.modalService.open(FleetTravelComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "fleet-travel backModalImg",
      backdrop: false,
    });
    this.fleetService.getFleetById(selectedFleet.id).subscribe((fleet) => {
      this.selectedFleet = fleet;
      /* if(fleet.currentBaseId){
        this.baseService.getBaseById(this.selectedFleet.currentBaseId).subscribe(base =>{
          this.selectedFleetBase = base;
            //Open Modal
            let modal = this.modalService.open(FleetTravelComponent, {ariaLabelledBy: 'modal-basic-title', scrollable: true, size: 'xl', windowClass:'game-modal', backdropClass: 'light-blue-backdrop'});
            modal.componentInstance.selectedFleet = this.selectedFleet;
            modal.componentInstance.fleets = this.fleets;
            modal.componentInstance.base = this.selectedFleetBase;
        });
      }else{*/
      //Open Modal
      modal.componentInstance.fleets = this.usableFleets;
      modal.componentInstance.base = null;
      modal.componentInstance.selectedFleet = this.selectedFleet;

      modal.hidden.subscribe(() => this.refreshFleet());
      // }
    });
  }

  quickRefuel(selectedFleet: Fleet) {
    let modal = this.modalService.open(RefuelComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "sm",
      windowClass: "fleet-refuel backModalImg",
      backdrop: true,
    });
    modal.componentInstance.fleet = selectedFleet;
    modal.componentInstance.sourceFleet = null;
    modal.componentInstance.base = this.base;

    modal.result.finally(() => {
      this.baseService.updateBase();
      this.refreshFleet();
    })

  }
  quickUnloadFuel(selectedFleet: Fleet) {
    this.fleetService.refuel(selectedFleet.id, -2).subscribe((fleet) => {
      this.selectedFleet = fleet;
      this.baseService.updateBase();
    });
  }


  attackFleet(content, selectedFleet: Fleet) {
    let modal = this.modalService.open(FleetTravelComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "fleet-travel backModalImg",
      backdrop: false,
    });
    if (this.usableFleets && this.usableFleets[0]) {
      this.fleetService.getFleetById(this.usableFleets[0].id).subscribe((fleet) => {
        modal.componentInstance.fleets = this.usableFleets;
        modal.componentInstance.selectedTarget = selectedFleet.position;
        modal.componentInstance.selectedFleet = fleet;
        modal.componentInstance.recalculateDistance();

        modal.hidden.subscribe(() => this.refreshFleet());
      });
    } else {
      let alert = new Alert();
      alert.type = "info";
      alert.msg = "il n'y a pas de flottes utilisable actuellement";
      alert.timeout = 10000;
      this.alertService.addAlert(alert);
      modal.dismiss();
    }
  }

  refreshFleet() {
    this.fleetService.updateFleets();
  }

  refreshExternFleet() {
    this.fleetService
      .getFleetsByBaseId(this.baseService.getCurrentBaseId())
      .subscribe((fleets) => {
        let filteredFleet: FleetRef[] = [];

        fleets.forEach((fleet) => {
          if (fleet.ownerId != this.user.id) {
            filteredFleet.push(fleet);
          }
        });
        this.otherFleets = filteredFleet;
      });
  }

  search(fleet: Fleet) {
    for (let elm in fleet) {
      if (fleet[elm] && fleet[elm].toLowerCase && fleet[elm].toLowerCase().includes(("" + this).toLocaleLowerCase())) {
        return true;
      }
    }
    if (fleet.position && fleet.position.coordinates.includes(("" + this).toLocaleLowerCase())) {
      return true;
    }
    return false;
  }

  refreshAllFleet() {
    /*this.fleetService.getAllFleets().subscribe((fleets) => {
      let allFleets: Fleet[] = [];
      fleets.forEach((fleet) => {
        if (fleet.ownerId != this.user.id) {
          allFleets.push(fleet);
        }
      });
      this.allFleets = allFleets;
      this.allFleetsFiltered = allFleets;
    });/** */
  }

  private updateTimestamp = Date.now();
  moveFleetTimer() {
    if (!this.refreshTimer) {
      this.refreshTimer = this.utilsService.timer
        .pipe(takeUntil(this.destroy$))
        .subscribe(val => {
          let shouldRefresh = false;
          for (let fleet of this.userFleets) {
            if (fleet.travelTime) {
              let then = Date.parse(fleet.travelTime + '');
              let ms = then - Date.now();
              this.timerMap.set(fleet.id, ms + '');
              if (ms < 0) { // fix server lag
                if (ms > -5000) { // if event if behind for less than 5 sec => refresh each second
                  shouldRefresh = true;
                } else if (ms > -60000) {// if event if behind for less than 60 sec => refresh every 10 seconds
                  if ((val % 10) === 0) {
                    shouldRefresh = true;
                  }
                } else {  // if event if behind for less than 60 sec => refresh every minutes
                  if ((val % 60) === 0) {
                    shouldRefresh = true;
                  }
                }
                if ((this.updateTimestamp + 300000) < Date.now()) { //fix chromium perf where after 5 minutes, timer works only once per minutes
                  shouldRefresh = true;
                }
              }
            } else {
              if (this.timerMap.has(fleet.id)) {
                this.timerMap.delete(fleet.id);
              }
            }
          }

          if (this.turrets) {
            if (this.turrets.travelTime) {
              let then = Date.parse(this.turrets.travelTime + '');
              let ms = then - Date.now();
              this.timerMap.set(this.turrets.id, ms + '');

              if (ms < 0) { // fix server lag
                if (ms > -5000) { // if event if behind for less than 5 sec => refresh each second
                  shouldRefresh = true;
                } else if (ms > -60000) {// if event if behind for less than 60 sec => refresh every 10 seconds
                  if ((val % 10) === 0) {
                    shouldRefresh = true;
                  }
                } else {  // if event if behind for less than 60 sec => refresh every minutes
                  if ((val % 60) === 0) {
                    shouldRefresh = true;
                  }
                }
                if ((this.updateTimestamp + 300000) < Date.now()) { //fix chromium perf where after 5 minutes, timer works only once per minutes
                  shouldRefresh = true;
                }
              }
            } else {
              delete (this.timerMap[this.turrets.id]);
            }
          }
          if (shouldRefresh === true && ((this.updateTimestamp + 2000) < Date.now())) {
            this.updateTimestamp = Date.now();
            this.refreshFleet();
            this.refreshTurrets();
          }
        });
    }
  }

  onSearch(text) {
    this.searchText = text || "";
   // this.allFleetsFiltered = this.allFleets.filter(this.search, this.searchText)
  }

  openUserProfile(ownerId) {
    let showProfileComponent = this.modalService.open(ShowProfileComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "base_openBuilding backModalImg",
    });
    showProfileComponent.componentInstance.ownerId = ownerId;
  }

  storageSquadrons(fleet: Fleet) {
    let showStorageComponent = this.modalService.open(FleetStorageComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "storage_fleet backModalImg",
    });
    showStorageComponent.componentInstance.selectedFleet = fleet;
    showStorageComponent.hidden.subscribe(() => this.refreshFleet());

  }
}
