import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Alert } from 'src/app/models/Alert';
import { MessageThread } from 'src/app/models/message/MessageThread';
import { UserProfile } from 'src/app/models/user/UserProfile';
import { AlertService } from 'src/app/services/alert/alert.service';
import { MessageService } from 'src/app/services/message/message.service';
import { OptionsService } from 'src/app/services/options/options.service';
import { UsersService } from 'src/app/services/Users/user.service';

@Component({
  selector: 'app-message-thread-create',
  templateUrl: './message-thread-create.component.html',
  styleUrls: ['./message-thread-create.component.scss']
})
export class MessageThreadCreateComponent implements OnDestroy {
  public users: UserProfile[];
  public usersFiltered: UserProfile[];

  private readonly destroy$ = new Subject();
  private debug: boolean = false;
  public messageThread: MessageThread = new MessageThread();

  constructor(
    private readonly modalService: NgbModal,
    private readonly userService: UsersService,
    private readonly optionsService: OptionsService,
    private readonly alertService: AlertService,
    public readonly modal: NgbActiveModal,
    private readonly messageService: MessageService
  ) {
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
    this.messageThread.participantsId = [];
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  change(value, source) {
    this.messageThread[source] = value;
  }

  confirmNewThread() {
    this.messageThread.senderId = this.userService.currentUserId();
    this.messageService.createThread(this.messageThread).subscribe(() => {
      console.error('alliance created')
      let alert = new Alert();
      alert.type = "info";
      alert.msg = `votre discussion à bien été créée`;
      alert.timeout = 10000;
      this.modal.close();
    });
  }

  invite(modal) {
    this.modalService.open(modal, {
      ariaLabelledBy: "modal-select-users",
      scrollable: true,
      size: "xl",
      windowClass: "selectUsers backModalImg",
    });
    this.userService.getProfiles().subscribe((users) => {
      this.users = users;
      this.usersFiltered = JSON.parse(JSON.stringify(users));
    })
  }

  filterUserName(userName) {
    this.usersFiltered = JSON.parse(JSON.stringify(this.users)).filter((user) => user['name'].toLowerCase().includes(userName.toLowerCase()));
  }

  select(userName) {
    console.warn(userName)
    let user = this.users.filter((user) => {
      return user.name === userName;
    });
    if (user[0]) {
      user[0]['selected'] = !user[0]['selected'];
    }

    user = this.usersFiltered.filter((user) => {
      return user.name === userName;
    });
    console.warn(user[0])
    if (user[0]) {
      user[0]['selected'] = !user[0]['selected'];
    }
  }

  invitePlayers(target) {
    let invitedPlayers = [];
    this.users.forEach((user) => {
      if (user['selected'] === true) {
        this.messageThread.participantsId.push(user.id)
      }
      user['selected'] = false;
    });

    target.parentElement.parentElement.parentElement.parentElement.remove()
    let backdrop = document.getElementsByClassName('modal-backdrop')
    if (backdrop.length > 0) {
      document.getElementsByClassName('modal-backdrop')[backdrop.length - 1].remove();
    }
  }
}
