<div *ngIf="!report" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="close('Cross click', $event)" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="report" [ngClass]="!getReport ? 'modal-body': ''"
  class="d-flex flex-wrap justify-content-between modal-header">
  <h4 class="modal-title me-3" id="modal-basic-title">
    {{ report.libelle }} du {{ report.date | date: 'fullDate': '' : 'fr-FR' }} à {{ report.date | date: 'mediumTime': ''
    : 'fr-FR' }}
  </h4>
  <div *ngIf="getReport">
    <button (click)="close('Cross click', $event)" aria-label="Close" class="btn btn-close" type="button"></button>
  </div>
</div>

<div *ngIf="report" class="modal-body d-flex flex-column report" [ngClass]="{'my-3': !getReport}">
  <label *ngIf="!fleetName"> déplacement d'une flotte sur la base ({{report.position.coordinates}}) ayant pour finalité "{{utilsService.translateConstants(report.finalStatus)}}"</label>
  <label *ngIf="fleetName"> déplacement de votre flotte {{fleetName}} sur la base ({{report.position.coordinates}}) ayant pour finalité "{{utilsService.translateConstants(report.finalStatus)}}"</label>
  <div *ngIf="isJSONNotEmpty(report.deliveredRessources)">
    <label class="align-middle">
      la flotte y a livré
    </label>
    <label *ngFor="let amount of report.deliveredRessources | keyvalue">
      <label [ngbTooltip]="amount.value+''" #tooltip="ngbTooltip" container="body" class="p-1 d-flex flex-column">
        <img class="resourceImg" src="/assets/img/resources/{{ amount.key }}.png" />
        {{ utilsService.nFormatter(amount.value) }}
      </label>
    </label>
  </div>
</div>
