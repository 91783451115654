<table *ngIf="offers" class="table table-striped table-hover table-dark planet-table custom-bottom">
  <tbody>
    <tr>
      <td>
        <label>
          Type de transaction :
        </label>
        <select id="offerTypeFilter" (change)="onChange($event)">
          <option *ngFor="let filter of filters" [selected]="filter.selected" [value]="filter.id">
            {{ filter.value }}
          </option>
        </select>
      </td>
      <td>
        <input class="inputSearch" type="search" (input)="onSearch($event.target['value'])" placeholder="Recherche...">
      </td>
      <td>
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="SwitchIsSell" (change)="refreshOffers()" [(ngModel)]="isSell">
          <label class="form-check-label" for="SwitchIsSell"> Type d'offres : {{isSell ? "Vente" : "Achat"}}</label>
        </div>
      </td>
      <td>
        <button class="btn btn-outline-warning" (click)="createOffer()">Nouvelle Offre</button>
      </td>
      <td>
      </td>
      <td>
      </td>
    </tr>
    <tr>
      <th id="type" (click)="sortByText('type')">
        <i class='bx'></i>
        Type de transaction
      </th>
      <th id="productName" (click)="sortByText('productName')">
        <i class='bx'></i>
        Objet
      </th>
      <th id="amount" (click)="sortByNumber('amount')">
        <i class='bx'></i>
        Quantité restante
      </th>
      <th id="unitPrice" (click)="sortByNumber('unitPrice')">
        <i class='bx'></i>
        Prix unitaire
      </th>
      <th id="ownerId" (click)="sortByText('ownerId')" colspan="3">
        <i class='bx'></i>
        Commerçant
      </th>
    </tr>
    <tr *ngFor="let offer of offers" [ngClass]="offer.sell === true ? 'blue': 'red'"
      class="justify-content-around p-2 openUserProfile">
      <td class="align-middle">
        {{offer.type}}
      </td>
      <td class="align-middle">
        {{offer.productName}}
      </td>
      <td class="align-middle">
        {{offer.amount}}
      </td>
      <td class="align-middle">
        {{offer.unitPrice}}
      </td>
      <td class="align-middle">
        {{offer.ownerName}}
      </td>
      <td class="align-middle">
        <button *ngIf="currentUserId !== offer.ownerId" class="btn btn-outline-success" (click)="fulfillOffer(offer)">répondre à une offre</button>
      </td>
    </tr>
  </tbody>
</table>
