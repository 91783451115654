export class FleetRef {
  amount: number;
  id: string;
  name: string;
  ownerId: string;
  ownerName: string;
  power: number;
  status: string;
  type: string;
}
