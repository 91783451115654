<div *ngIf="!(researchList && resources)" class="loading"></div>
<div *ngIf="researchList && resources" class="d-flex justify-content-evenly flex-wrap custom-bottom">
  <div *ngFor="let research of researchList" class="d-flex flex-column research transparent_bg">
    <img (click)="researchDetail(research)" alt="Recherche" class="researchImg" src="{{
        '/assets/img/research/' +
          research.name +
          '.png'
      }}" />
    <div *ngIf="!user.pendingResearch[research.id]" class="c card-body d-flex flex-column">
      <h5 *ngIf="user.researchMap[research.id] > -1" class="card-title text-center middle"
        i18n="BaseComponent|Research Location (when populated)">
        {{ research.name }} - lvl {{ user.researchMap[research.id]+1 }}
      </h5>
      <h5 *ngIf="!(user.researchMap[research.id] > -1)" class="card-title text-center middle"
        i18n="BaseComponent|Research Location (when populated)">
        {{ research.name }}
      </h5>
      <div *ngIf="research.levels[(user.researchMap[research.id]+1 || 0) ]"
        class="d-flex flex-wrap justify-content-center p-1 resource card-text">
        <div
          *ngFor="let researchCost of research.levels[(user.researchMap[research.id]+1) || 0].cost | keyvalue: utilsService.originalOrder">
          <div [ngClass]="{
                  insufficientResource: researchCost.value > resources.get(researchCost.key).clientAmount
                }" class="p-1 d-flex flex-column">
            <img class="resourceImg" src="/assets/img/resources/{{ researchCost.key }}.png" />
            {{ researchCost.value }}
          </div>
        </div>
      </div>

      <ul *ngIf="researchMap && research.levels[(user.researchMap[research.id] +1 || 0)]">
        <div class="underline">Prérequis:</div>
        <li
          *ngFor="let requestedBuilding of research.levels[(user.researchMap[research.id]+1) || 0].buildingReq | keyvalue"
          i18n="BaseComponent|Building required for the upgrade"
          [ngClass]="{ insufficientResource: !(buildingsService.buildingsMap.get(requestedBuilding.key)['currentLevel']>=0) || (requestedBuilding.value > (buildingsService.buildingsMap.get(requestedBuilding.key)['currentLevel'])) }">
          {{ buildingsService.buildingsMap.get(requestedBuilding.key).name }} : lvl
          {{ requestedBuilding.value + 1 }}
        </li>
        <li
          *ngFor="let requestedResearch of research.levels[(user.researchMap[research.id]+1) || 0].researchReq | keyvalue"
          i18n="BaseComponent|Building required for the upgrade"
          [ngClass]="{ insufficientResource: !(user.researchMap[requestedResearch.key] >=0) || (requestedResearch.value > (user.researchMap[requestedResearch.key])) }">
          {{ researchMap.get(requestedResearch.key).name }} : lvl
          {{ requestedResearch.value + 1 }}
        </li>
      </ul>
      <div class="hidden flex-grow-1">null</div>


      <div *ngIf="research.levels[(user.researchMap[research.id]+1 || 0) ] && user.effects['RESEARCH_SPEED']"
        class="text-center underline">
        Temps de recherche
      </div>
      <div *ngIf="research.levels[(user.researchMap[research.id]+1 || 0) ] && user.effects['RESEARCH_SPEED']"
        class="text-center">
        {{ research.levels[(user.researchMap[research.id]+1) || 0].researchTime * 1000 /
        (base.effects['RESEARCH_SPEED'] /100.0)
        | timer: "UTC" }} s
      </div>
      <button *ngIf="research && research.levels[(user.researchMap[research.id]+1 || 0) ]"
        (click)="launchResearch(research)" [disabled]="!isRequirementsFulfilled[research.id]"
        class="btn btn-outline-success buildingButton middle" i18n="BaseComponent|Start research the selected">
        Rechercher
      </button>
    </div>

    <div *ngIf="user.pendingResearch[research.id]" class="card-body d-flex flex-column">
      <h5 class="card-title flex-grow-10 text-center middle" i18n="BaseComponent|Research Location (when populated)">
        {{ research.name }} - lvl
        {{ (user.researchMap[research.id]+1) || 1 }}
      </h5>
      <div class="hidden flex-grow-1">null</div>
      <div class="text-center">
        Recherche en cours {{ +timerMap.get(research.id) || 0 | timer: 'UTC'}}
      </div>
      <button class="btn btn-outline-success buildingButton middle hidden"
        i18n="BaseComponent|Start research the selected">
        null
      </button>
    </div>
  </div>
</div>
