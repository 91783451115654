import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'src/app/models';
import { Base } from 'src/app/models/base/Base';
import { UsersService } from 'src/app/services/Users/user.service';
import { BaseService } from 'src/app/services/bases/base.service';
import { EffectService } from 'src/app/services/effect/effect.service';
import { EffectDetailComponent } from '../effect-detail/effect-detail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EffectRef } from 'src/app/models/Effect/EffectRef';

@Component({
  selector: 'app-effects',
  templateUrl: './effects.component.html',
  styleUrls: ['./effects.component.scss']
})
export class EffectsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  base: Base;
  user: User;

  constructor(
    private readonly effectService: EffectService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    private readonly modalService: NgbModal,
  ) {
  }


  ngOnInit() {
    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
        }
      });
    this.userService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.user = user
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }


  clickOnEffect(effect, fromUser) {
    const modal = this.modalService.open(EffectDetailComponent, {
      ariaLabelledBy: "effectDetail",
      scrollable: true,
      size: "xl",
      windowClass: "effectDetail backModalImg",
      backdrop: true,
    });

    modal.componentInstance.effect = {code:effect.key, libelle: effect.key};
    modal.componentInstance.isFromUser = !this.effectService.getEffects(effect.key).local;
  }

  getEffects(effectCode: string): EffectRef {
    return this.effectService.getEffects(effectCode);
  }
}
