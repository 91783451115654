import { OwnerRef } from "./OwnerRef";

export class Owner extends OwnerRef {
  baseListId: Map<string, string>;
  centaurium: number;
  description: string;
  diplomacyId: string;
  effectSources: Map<string, string>;
  effects: Map<string, number>;
  id: string;
  imageLocation: string;
  mainBaseId: string;
  messageBoxId: string;
  money: number;
  name: string;
  newMessagesAmount: number;
  pendingResearch: Map<string, Date>;
  reports: Map<string, boolean>;
  researchMap: Map<string, number>;
}
