import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from 'src/app/models/Alert';

@Injectable({
  providedIn: "root",
})

export class AlertService {
  public alerts = new Subject<Alert>();

  public alertsAnnounced$ = this.alerts.asObservable();


  public addAlert(alert: Alert){
    this.alerts.next(alert);
  }
  constructor() {}
}
