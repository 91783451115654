import { User } from "./user/User";

export class GovernmentRef {
  description: string;
  freeBaseCount: number;
  id: string;
  leader: User;
  mainBaseId: string;
  members: Map<string, User>
  name: string;
  shortName: string;
}
