import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Effect } from 'src/app/models/Effect/Effect';
import { EffectRef } from 'src/app/models/Effect/EffectRef';
import { EffectSource } from 'src/app/models/Effect/EffectSource';
import { Base } from 'src/app/models/base/Base';
import { UsersService } from 'src/app/services/Users/user.service';
import { BaseService } from 'src/app/services/bases/base.service';
import { EffectService } from 'src/app/services/effect/effect.service';

@Component({
  selector: 'app-effect-detail',
  templateUrl: './effect-detail.component.html',
  styleUrls: ['./effect-detail.component.scss']
})
export class EffectDetailComponent implements OnInit, OnDestroy {
  @Input()
  public effect: Effect;
  public effectRef: EffectRef;
  @Input()
  public isFromUser: boolean = false;

  public effectDetail: EffectSource;
  private readonly destroy$ = new Subject();

  convertType = {
     'AMOUNT': "La somme de toutes les sources" ,
     'LEVEL': "La valeur la plus élevée de toutes les sources"
     };
  constructor(
    public readonly modal: NgbActiveModal,
    private readonly effectService: EffectService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
  ) {
  }



  ngOnInit(): void {
    this.effectRef = this.effectService.getEffects(this.effect.code);

    if (this.isFromUser) {
      this.effectService.getEffectSourceByOwner(this.effect.code, this.userService.currentUserId()).subscribe((effectDetail) => {
        this.effectDetail = effectDetail;
      });
    } else {
      this.effectService.getEffectSourceByBase(this.effect.code, this.baseService.getCurrentBaseId()).subscribe((effectDetail) => {
        this.effectDetail = effectDetail;
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

}
