<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Squadron-detailComponent|Squadron details">Sélection d'image de
    profil</h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div class="modal-body">
  <!--  <label class="modal-body loading"></label>-->
  <img *ngFor="let img of imgTable" (click)="selectImg($event.target['alt'])" alt="{{img}}"
    src="{{'/assets/img/'+img}}">
</div>
