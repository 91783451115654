import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { ResourceCumul } from "src/app/models/base/resources/ResourceCumul";
import { Offer } from "src/app/models/Market/Offer";
import { BaseService } from "src/app/services/bases/base.service";
import { MarketService } from "src/app/services/Market/market.service";
import { OptionsService } from "src/app/services/options/options.service";
import { UsersService } from "src/app/services/Users/user.service";

@Component({
  selector: "app-fulfill-offer",
  templateUrl: "./fulfillOffer.component.html",
  styleUrls: ["./fulfillOffer.component.scss"],
})
export class FulfillOfferComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  private debug: boolean = false;

  public offer: Offer;
  resources: Map<string, ResourceCumul> = new Map<string, ResourceCumul>();
  amountFulfilled: number;

  constructor(
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    private readonly optionsService: OptionsService,
    public readonly modal: NgbActiveModal,
    private readonly marketService: MarketService,
  ) {
    baseService.resourcesUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedResources) => {
        if (updatedResources.size > 0) {
          this.resources = updatedResources;
        }
      });
  }

  ngOnInit(): void {
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
    if (this.offer)
      this.amountFulfilled = this.offer.amount;
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  refreshamount(amount) {
    this.amountFulfilled = amount;
  }

  fulfillOffer() {
    this.marketService.fulfillOffer(this.baseService.getCurrentBaseId(), this.offer.id, this.userService.currentUserId(), this.amountFulfilled).subscribe((offers) => {
      this.modal.dismiss();
    });
  }

}
