import { KeyValue } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil, timer } from "rxjs";
import { SquadronReportComponent } from "src/app/main/message-box/report/squadron-report/squadron-report.component";
import { ShowProfileComponent } from "src/app/main/users/profile/show-profile/show-profile.component";
import { Alert } from "src/app/models/Alert";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { FightReport } from "src/app/models/reports/FightReport";
import { FightRound } from "src/app/models/reports/FightRound";
import { Squadron } from "src/app/models/Squadron";
import { AlertService } from "src/app/services/alert/alert.service";
import { BaseService } from "src/app/services/bases/base.service";
import { OptionsService } from "src/app/services/options/options.service";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { ReportService } from "src/app/services/reports/report.service";
// import { Fight3DComponent } from "./fight-3d/fight-3d.component";

@Component({
  selector: "app-fight-detail",
  templateUrl: "./fight-detail.component.html",
  styleUrls: ["./fight-detail.component.scss"],
})
export class FightDetailComponent implements OnInit, OnDestroy {
  public report: FightReport;

  @Input()
  public getReport;

  public squadrons: Map<string, Squadron> = new Map<string, Squadron>();
  timerTooltip = timer(500);
  tooltipContext;
  instances: NgbModalRef[];
  private readonly destroy$ = new Subject();
  public debug: boolean = false;

  constructor(
    public readonly modalService: NgbModal,
    private readonly optionsService: OptionsService,
    private readonly reportService: ReportService,
    private readonly baseService: BaseService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    public readonly utilsService: UtilsService,
  ) {
    let destroy$ = new Subject();
    this.modalService.activeInstances
      .pipe(takeUntil(destroy$))
      .subscribe((instances) => {
        this.instances = instances
      })
  }

  ngOnInit() {
    if (this.getReport) {
      this.getReport.subscribe((report) => {
        report.date = new Date(Date.parse(report.date + ''));
        this.report = report;
        setTimeout(() => this.buildTables(), 0);
      });
      this.debug = this.optionsService.getOption('debug');
      this.optionsService.optionsAnnounced$
        .pipe(takeUntil(this.destroy$))
        .subscribe((option) => {
          if (option["debug"]) {
            this.debug = option["debug"] === "true";
          }
        });
    } else {
      let urlSegments = this.router.url.split('/');
      if (urlSegments[1] === this.router.config[4].path.split('/')[0]) {
        this.reportService.getFightReportByUuid(urlSegments[2]).subscribe((report) => {
          report.date = new Date(Date.parse(report.date + ''));
          this.report = report;
          setTimeout(() => this.buildTables(), 0);

          let mainLoading = document.getElementsByClassName('mainLoading');
          if (mainLoading && mainLoading[0]) {
            mainLoading[0].classList.add('d-none');
          }
        })
        this.utilsService.timer
        .pipe(takeUntil(this.destroy$))
        .subscribe((val) => {
          document.getElementsByClassName('my-3')[0]['style'].setProperty('--headerHeigth', (document.getElementsByClassName('modal-header')[0].clientHeight +10) +'px')
        })
      }
    }
  }

  close(reason, event) {
    if (event.shiftKey) {
      this.modalService.dismissAll()
    } else {
      this.utilsService.closeModalByEvent(event);
    }
  }

  ngOnDestroy() {
    if (this.tooltipContext) {
      this.tooltipContext.unsubscribe();
    }
    this.destroy$.next("");
    this.destroy$.complete();
  }

  // openFight3DComponent() {

  //   let fight3DComponent = this.modalService.open(Fight3DComponent, {
  //     windowClass: "report-Fight3D backModalImg",
  //     ariaLabelledBy: "report-Fight3D",
  //     scrollable: true,
  //     size: "lg",
  //     backdrop: false,
  //   });
  //   fight3DComponent.componentInstance.report = JSON.parse(JSON.stringify(this.report));
  // }

  buildTables() {
    for (let fleet in this.report.attackInitialFleet) {
      fleet = this.report.attackInitialFleet[fleet];
      for (let squadronId in fleet['squadrons']) {
        let squadron = fleet['squadrons'][squadronId];
        this.squadrons.set(squadronId, squadron);
        if (this.report.attackFinalFleet[fleet['id']] && this.report.attackFinalFleet[fleet['id']]['squadrons'][squadronId]) {
          this.report.attackFinalFleet[fleet['id']]['squadrons'][squadronId].fleetName = fleet['name'];
        } else {
          this.report.attackInitialFleet[fleet['id']]['squadrons'][squadronId].fleetName = fleet['name'];
          this.report.attackInitialFleet[fleet['id']]['squadrons'][squadronId].fleetId = fleet['id'];
        }
      }
    }
    for (let fleet in this.report.defenseInitialFleet) {
      fleet = this.report.defenseInitialFleet[fleet];
      for (let squadronId in fleet['squadrons']) {
        let squadron = fleet['squadrons'][squadronId];
        this.squadrons.set(squadronId, squadron);
        if (this.report.defenseFinalFleet[fleet['id']] && this.report.defenseFinalFleet[fleet['id']]['squadrons'][squadronId]) {
          this.report.defenseFinalFleet[fleet['id']]['squadrons'][squadronId].fleetName = fleet['name'];
        } else {
          this.report.defenseInitialFleet[fleet['id']]['squadrons'][squadronId].fleetName = fleet['name'];
          this.report.defenseInitialFleet[fleet['id']]['squadrons'][squadronId].fleetId = fleet['id'];
        }
      }
    }

    for (let key in this.report.rounds) {
      let round = this.report.rounds[key];
      for (let fleetId in round.defenseMove) {
        if (!this.report.defenseInitialFleet[fleetId]) {
          this.report.defenseInitialFleet[fleetId] = new Fleet();
          this.report.defenseInitialFleet[fleetId].name = 'Inconnue'
          this.report.defenseFinalFleet[fleetId] = this.report.defenseInitialFleet[fleetId]
        }
        //squadrons
        if (!this.report.defenseInitialFleet[fleetId].squadrons) {
          this.report.defenseInitialFleet[fleetId].squadrons = [];
        }
        for (let squadId in round.defenseMove[fleetId].newSquadPos) {
          if (!this.report.defenseInitialFleet[fleetId].squadrons[squadId]) {
            this.report.defenseInitialFleet[fleetId].squadrons[squadId] = new Squadron()
          }
        }
      }
      for (let fleetId in round.attackMove) {
        if (!this.report.attackInitialFleet[fleetId]) {
          this.report.attackInitialFleet[fleetId] = new Fleet();
          this.report.attackInitialFleet[fleetId].name = 'Inconnue'
        }
        //squadrons
        if (!this.report.attackInitialFleet[fleetId].squadrons) {
          this.report.attackInitialFleet[fleetId].squadrons = [];
          for (let squadId in round.attackMove[fleetId].newSquadPos) {
            if (!this.report.attackInitialFleet[fleetId].squadrons[squadId]) {
              this.report.attackInitialFleet[fleetId].squadrons[squadId] = new Squadron()
            }
          }
        }
      }
    }

    for (let fleetId in this.report.attackInitialFleet) {
      let fleet = this.report.attackInitialFleet[fleetId];
      fleet['formationMaxLines'] = 1;
      for (let squadronId in fleet.squadrons) {
        fleet['formationMaxLines'] = Math.max(fleet['formationMaxLines'], fleet.squadrons[squadronId].line + 1)
      }
    }

    for (let fleetId in this.report.defenseInitialFleet) {
      let fleet = this.report.defenseInitialFleet[fleetId];
      fleet['formationMaxLines'] = 1;
      for (let squadronId in fleet.squadrons) {
        fleet['formationMaxLines'] = Math.max(fleet['formationMaxLines'], fleet.squadrons[squadronId].line + 1)
      }
    }
  }

  //Pour que le keyvalue soit dans le bon ordre
  originalOrder = (
    a: KeyValue<number, FightRound>,
    b: KeyValue<number, FightRound>
  ): number => {
    return 0;
  };


  context(tooltip, context) {
    tooltip.close();
    if (this.tooltipContext) {
      this.tooltipContext.unsubscribe();
    }
    if ((context.before) || (context.fleet)) {
      this.tooltipContext = this.timerTooltip
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          tooltip.open(context);
        });
    }
  }
  onMouseLeave(tooltip) {
    tooltip.close();
    if (this.tooltipContext) {
      this.tooltipContext.unsubscribe();
    }
  }

  isJSONNotEmpty(json) {
    for (let prop in json) {
      return true;
    }
    return false;
  }

  unitDetailModal;
  squadronDetails(squad) {
    let squadron = JSON.parse(JSON.stringify(squad));
    if (!this.unitDetailModal) {
      this.unitDetailModal = this.modalService.open(SquadronReportComponent, {
        windowClass: "report-SquadronReport backModalImg",
        ariaLabelledBy: "report-SquadronReport",
        scrollable: true,
        size: "lg",
        backdrop: false,
      });
    }
    if (this.report.attackInitialFleet[squadron.fleetId]) {
      if (!this.report.attackFinalFleet[squadron.fleetId] || !this.report.attackFinalFleet[squadron.fleetId]['squadrons'][squadron.id]) {
        squadron.amount = 0;
      }
    } else {
      if (!this.report.defenseFinalFleet[squadron.fleetId] || !this.report.defenseFinalFleet[squadron.fleetId]['squadrons'][squadron.id]) {
        squadron.amount = 0;
      }

    }
    this.unitDetailModal.componentInstance.addSquadron(squadron);
    this.unitDetailModal.hidden.subscribe(() => {
      this.unitDetailModal = null;
    });
  }

  openUserProfile(ownerId) {
    let showProfileComponent = this.modalService.open(ShowProfileComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "base_openBuilding backModalImg",
    });
    showProfileComponent.componentInstance.ownerId = ownerId;
  }

  flipdebugRounds() {
    this.debug = !this.debug;
  }

  shareReport() {
    let alert = new Alert();
    let result = navigator.clipboard.writeText(window.location.origin + `/fight/${this.report.uuid}`);
    result.then((reason) => {
      alert.type = "success";
      alert.msg =
        `Le lien vers le rapport de combat a bien été copié
          il ne peut pas encore etre partagé à d'autres mais le lien pourrai vous être utile`;
      alert.timeout = 2000;
      this.alertService.addAlert(alert);/** */

    })
      .catch((error) => {
        console.error(error)
        if (error) {
          alert['datas'] = { error: error };
        }
        alert.type = "danger";
        alert.msg = "Le lien vers le rapport de combat n'a pas pu être copié";
        alert.timeout = 10000;
        this.alertService.addAlert(alert);/** */
      })
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }
}
