import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Offer } from "src/app/models/Market/Offer";
import { Observable } from "rxjs";
import { Market } from "src/app/models/Market/Market";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class MarketService {
  private apiUrl = environment.apiEndpoint;
  constructor(
    private readonly http: HttpClient,
  ) { }

  public createOffer(offer: Offer): Observable<Offer> {
    return this.http.post<Offer>(this.apiUrl + `markets`, offer);
  }

  public getMarketOffers(marketId: string, selling: boolean): Observable<Offer[]> {
    return this.http.get<Offer[]>(this.apiUrl + `markets/${marketId}?isSell=${selling}`);
  }

  public getMarketProductOffers(marketId: string, productId: string, selling: boolean): Observable<Offer[]> {
    return this.http.get<Offer[]>(this.apiUrl + `markets/${marketId}/product/${productId}?isSell=${selling}`);
  }

  public getByBase(baseId: string): Observable<Market[]> {
    return this.http.get<Market[]>(this.apiUrl + `markets/base/${baseId}`);
  }

  public fulfillOffer(baseId: string, offerId: string, ownerId: string, amount: number): Observable<Market[]> {
    return this.http.post<Market[]>(this.apiUrl + `markets/owner/${ownerId}/base/${baseId}/offer/${offerId}/fulfill`, +amount);
  }
}
