import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private icon = '/assets/img/favicon.png';

  constructor(
    private readonly http: HttpClient,
  ) {

  }

  askForPermission() {
    Notification.requestPermission().then((permission) => {
    })
  }

  sendNotification(body) {
    if (Notification.permission === "granted") {
      new Notification('une Flotte en approche', { body: body, icon: this.icon });
    }
  }
}
