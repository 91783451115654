import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { bufferCount, filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

interface Point { x: number, y: number };

@Directive({
  selector: '.modal-header'
})
export class DraggableDirective implements AfterViewInit, OnDestroy {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  mouseSubscription: Subscription;
  touchSubscription: Subscription;

  start: Point;
  offset: Point = { x: 0, y: 0 };
  limits = { min: { x: 0, y: 0 }, max: { x: 0, y: 0 } };

  private clickCount = 2;
  private clickTimespan = 250;

  ngAfterViewInit() {
    setTimeout(() => {
      this.makeItDraggable();
    }, 0);
  }

  private makeItDraggable() {
    let modalDialogElement = this.el.nativeElement.closest(".modal");
    let modalImgElement = this.el.nativeElement.closest(".backModalImg");

    if (modalImgElement) {
      this.renderer.setStyle(modalImgElement, 'background', 'linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), '+getComputedStyle(document.body).backgroundImage);
      if (window.innerWidth * 1.0 / window.innerHeight > 1.25) {
        this.renderer.setStyle(modalImgElement, 'background-size', window.innerWidth + 'px');
      } else {
        this.renderer.setStyle(modalImgElement, 'background-size', 1.25 * window.innerHeight + 'px');
      }
    }
    if (!modalDialogElement) {
      console.error('DragModalDirective cannot find the parent element with class modal-dialog')
      return;
    }
    this.renderer.addClass(this.el.nativeElement, 'draggable');

    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.limits.min.x = (window.innerWidth / 2 + 2 * (entry.contentRect.width / 7));
        this.limits.min.y = window.innerHeight - (entry.contentRect.top + entry.contentRect.bottom + 100);

        this.limits.max.x = window.innerWidth / 2 + 2 * (entry.contentRect.width / 7);
        this.limits.max.y = 100;

        if (modalImgElement) {
          this.renderer.setStyle(modalImgElement, 'background-position-x', -modalImgElement.getBoundingClientRect().x + 'px');
          this.renderer.setStyle(modalImgElement, 'background-position-y', -modalImgElement.getBoundingClientRect().y + 'px');
        }
      });
    });

    observer.observe(this.el.nativeElement);
    const resize$ = fromEvent(window, 'resize', {passive: true})
    //mouseEvents
    const mousedown$ = fromEvent(this.el.nativeElement, 'mousedown', {passive: true})
    const mousemove$ = fromEvent(document, 'mousemove', {passive: true});
    const mouseup$ = fromEvent(document, 'mouseup', {passive: true})
    let allowMouseMovement = false;
    const mousedrag$ = mousedown$.pipe(
      tap(($event: MouseEvent) => {
        if (window.getSelection) { window.getSelection().removeAllRanges(); }
        // 'target' if FireFox, 'path' if Chrome
        let path = (<HTMLElement>($event['target'] || $event['path'][0]));
        if (path.classList.contains('draggable') || (path.tagName === 'H4' && path.parentElement.closest('.draggable'))) {
          this.start = {
            x: $event.x - this.offset.x,
            y: $event.y - this.offset.y
          };
          allowMouseMovement = true;
        } else {
          allowMouseMovement = false;
        }
      }),
      mergeMap(down => mousemove$.pipe(
        takeUntil(mouseup$)
      ))
    );
    this.mouseSubscription = mousedrag$.subscribe(($event: MouseEvent) => {
      if (allowMouseMovement) {
        this.offset = {
          x: $event.x - this.start.x,
          y: $event.y - this.start.y
        }
        this.offset.x = Math.min(this.offset.x, this.limits.min.x);
        this.offset.y = Math.min(this.offset.y, this.limits.min.y);
        this.offset.x = Math.max(this.offset.x, -this.limits.max.x);
        this.offset.y = Math.max(this.offset.y, -this.limits.max.y);
        //    background-repeat-x: repeat;
        this.renderer.setStyle(modalDialogElement, 'transform', `translate(calc(-50% + ${this.offset.x}px), ${this.offset.y}px)`);
        this.renderer.setStyle(modalDialogElement, '-webkit-transform', `translate(calc(-50% + ${this.offset.x}px), ${this.offset.y}px)`);
        if (modalImgElement) {
          this.renderer.setStyle(modalImgElement, 'background-position-x', -modalImgElement.getBoundingClientRect().x + 'px');
          this.renderer.setStyle(modalImgElement, 'background-position-y', -modalImgElement.getBoundingClientRect().y + 'px');
        }
      }
    })
    mousedown$.pipe(
      map(() => new Date().getTime()),
      // Emit the last `clickCount` timestamps.
      bufferCount(this.clickCount, 1),
      // `timestamps` is an array the length of `clickCount` containing the last added `timestamps`.
      filter((timestamps) => {
        // `timestamps[0]` contains the timestamp `clickCount` clicks ago.
        // Check if `timestamp[0]` was within the `clickTimespan`.
        return timestamps[0] > new Date().getTime() - this.clickTimespan;
      })
    )
      // Will emit after `clickCount` clicks if the first one happened within the `clickTimespan`.
      // Won't wait until `clickTimespan` runs out to emit.
      .subscribe(() => {
        if (allowMouseMovement) {
          this.renderer.setStyle(modalDialogElement, 'transform', `translate(calc(-50%), 0px)`);
          this.renderer.setStyle(modalDialogElement, '-webkit-transform', `translate(calc(-50%), 0px)`);
          if (modalImgElement) {
            this.renderer.setStyle(modalImgElement, 'background-position-x', -modalImgElement.getBoundingClientRect().x + 'px');
            this.renderer.setStyle(modalImgElement, 'background-position-y', -modalImgElement.getBoundingClientRect().y + 'px');
          }
          this.offset = { x: 0, y: 0 };
        }
      })

    //touchEvents
    const touchstart$ = fromEvent(this.el.nativeElement, 'touchstart', {passive: true})
    const touchmove$ = fromEvent(document, 'touchmove', {passive: true});
    const touchend$ = fromEvent(document, 'touchend', {passive: true});
    let allowTouchMovement = false;
    const touchdrag$ = touchstart$.pipe(
      tap(($event: TouchEvent) => {
        if (window.getSelection) { window.getSelection().removeAllRanges(); }
        // 'target' if FireFox, 'path' if Chrome
        let path = (<HTMLElement>($event['target'] || $event['path'][0]));
        if (path.classList.contains('draggable') || (path.tagName === 'H4' && path.parentElement.closest('.draggable'))) {
          this.start = {
            x: $event.touches[0].clientX - this.offset.x,
            y: $event.touches[0].clientY - this.offset.y
          };/** */
          allowTouchMovement = true;
        } else {
          allowTouchMovement = false;
        }
      }),
      mergeMap(down => touchmove$.pipe(
        takeUntil(touchend$)
      ))
    );
    this.touchSubscription = touchdrag$.subscribe(($event: TouchEvent) => {
      if (allowTouchMovement) {
        this.offset = {
          x: $event.touches[0].clientX - this.start.x,
          y: $event.touches[0].clientY - this.start.y
        }
        this.offset.x = Math.min(this.offset.x, this.limits.min.x);
        this.offset.y = Math.min(this.offset.y, this.limits.min.y);
        this.offset.x = Math.max(this.offset.x, -this.limits.max.x);
        this.offset.y = Math.max(this.offset.y, -this.limits.max.y);

        this.renderer.setStyle(modalDialogElement, 'transform', `translate(calc(-50% + ${this.offset.x}px), ${this.offset.y}px)`);
        this.renderer.setStyle(modalDialogElement, '-webkit-transform', `translate(calc(-50% + ${this.offset.x}px), ${this.offset.y}px)`);
        if (modalImgElement) {
          this.renderer.setStyle(modalImgElement, 'background-position-x', -modalImgElement.getBoundingClientRect().x + 'px');
          this.renderer.setStyle(modalImgElement, 'background-position-y', -modalImgElement.getBoundingClientRect().y + 'px');
        }
      }
    })
    touchstart$.pipe(
      map(() => new Date().getTime()),
      // Emit the last `clickCount` timestamps.
      bufferCount(this.clickCount, 1),
      // `timestamps` is an array the length of `clickCount` containing the last added `timestamps`.
      filter((timestamps) => {
        // `timestamps[0]` contains the timestamp `clickCount` clicks ago.
        // Check if `timestamp[0]` was within the `clickTimespan`.
        return timestamps[0] > new Date().getTime() - this.clickTimespan;
      })
    )
      // Will emit after `clickCount` clicks if the first one happened within the `clickTimespan`.
      // Won't wait until `clickTimespan` runs out to emit.
      .subscribe(() => {
        if (allowTouchMovement) {
          this.renderer.setStyle(modalDialogElement, 'transform', `translate(calc(-50%), 0px)`);
          this.renderer.setStyle(modalDialogElement, '-webkit-transform', `translate(calc(-50%), 0px)`);
          if (modalImgElement) {
            this.renderer.setStyle(modalImgElement, 'background-position-x', -modalImgElement.getBoundingClientRect().x + 'px');
            this.renderer.setStyle(modalImgElement, 'background-position-y', -modalImgElement.getBoundingClientRect().y + 'px');
          }
          this.offset = { x: 0, y: 0 };
        }
      })
    resize$.subscribe((event) => {
      //TODO : empeher la fenetre de dépasser quand la window change de taille
      this.renderer.setStyle(modalDialogElement, 'transform', `translate(calc(-50%), 0px)`);
      this.renderer.setStyle(modalDialogElement, '-webkit-transform', `translate(calc(-50%), 0px)`);
      if (modalImgElement) {
        this.renderer.setStyle(modalImgElement, 'background-position-x', -modalImgElement.getBoundingClientRect().x + 'px');
        this.renderer.setStyle(modalImgElement, 'background-position-y', -modalImgElement.getBoundingClientRect().y + 'px');
      }
      this.offset = { x: 0, y: 0 };
    });
  }

  ngOnDestroy() {
    if (this.touchSubscription)
      this.touchSubscription.unsubscribe();
    if (this.mouseSubscription)
      this.mouseSubscription.unsubscribe();
  }
}
