import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot, Router,
  RouterStateSnapshot
} from "@angular/router";
import { KeycloakAuthGuard, KeycloakService } from "keycloak-angular";
import { Alert } from "src/app/models/Alert";
import { AlertService } from "../alert/alert.service";

@Injectable()
export class AuthenticationService extends KeycloakAuthGuard {
  constructor(
    private http: HttpClient,
    protected router: Router,
    protected keycloakService: KeycloakService,
    private readonly alertService: AlertService,
  ) {
    super(router, keycloakService);

    keycloakService.isLoggedIn().then((loggedIn) => {
      if (loggedIn) {
        this.router.navigate([this.router.config[1].path])
      }
    });
  }
  public doLogout(): void {
    localStorage.removeItem('currentMenu');
    localStorage.removeItem('currentBase');
    this.keycloakService.logout(window.location.origin);
  }

  public doLogin(): void {
    if (this.isStillLoggedIn()) {
      this.router.navigate([this.router.config[1].path])
    } else {
      this.keycloakAngular.login({ redirectUri: window.location.origin }).catch((e) => console.error(e));
    }
  }

  public isStillLoggedIn(): boolean {
    if (this.authenticated === undefined) {
      return false;
    }
    return this.authenticated;
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const requiredRoles: string[] = route.data.roles;

      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      } else {
        if (!this.roles || this.roles.length === 0) {
          this.router.navigate(["/"])
          return reject(true);
        }
        if (requiredRoles.every((role) => this.roles.indexOf(role) > -1)) {
          return resolve(true);
        } else {
          this.router.navigate(["/"])
          let alert = new Alert();
          alert.type = "danger";
          alert.msg = "vous n'avez pas l'authorisation d'aller ici";
          alert.timeout = 10000;
          this.alertService.addAlert(alert);
          return resolve(false);
        }
      }
    });
  }

  public doRegister() {
    this.keycloakAngular.register({ redirectUri: window.location.origin + "/" + this.router.config[1].path }).catch((e) => console.error(e));
  }
}
