import { KeyValue } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil, timer } from "rxjs";
import { SquadronReportComponent } from "src/app/main/message-box/report/squadron-report/squadron-report.component";
import { FightRound } from "src/app/models/reports/FightRound";
import { RummageReport } from "src/app/models/reports/RummageReport";
import { Ship } from "src/app/models/Ship";
import { Squadron } from "src/app/models/Squadron";
import { BaseService } from "src/app/services/bases/base.service";
import { OptionsService } from "src/app/services/options/options.service";
import { ShipService } from "src/app/services/ships/ship.service";

@Component({
  selector: "app-rummage-detail",
  templateUrl: "./rummage-detail.component.html",
  styleUrls: ["./rummage-detail.component.scss"],
})
export class RummageDetailComponent implements OnInit, OnDestroy {
  public report: RummageReport;
  isEmpty: boolean = false;

  @Input()
  public getReport;

  public squadrons: Map<string, Squadron> = new Map<string, Squadron>();
  timerTooltip = timer(500);
  tooltipContext;
  public knownShips: Ship[];

  private readonly destroy$ = new Subject();
  private debug: boolean = false;

  constructor(
    private readonly modalService: NgbModal,
    public readonly modal: NgbActiveModal,
    private readonly baseService: BaseService,
    private readonly optionsService: OptionsService,
    private readonly shipService: ShipService,
  ) { }

  ngOnInit() {
    this.getReport.subscribe((report) => {
      report.date = new Date(Date.parse(report.date + ''));
      this.report = report;
      setTimeout(() => this.buildTables(), 0);
    });
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });

    this.shipService.knownShipsUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ships) => {
        if (ships) {
          this.knownShips = ships;
        }
      })

  }

  ngOnDestroy() {
    if (this.tooltipContext) {
      this.tooltipContext.unsubscribe();
    }
    this.destroy$.next("");
    this.destroy$.complete();
  }

  buildTables() {
    if (!this.isJSONNotEmpty(this.report.money) && !this.isJSONNotEmpty(this.report.resources) && !this.isJSONNotEmpty(this.report.ships)) {
      this.isEmpty = true;
    }
    if (this.isJSONNotEmpty(this.report.ships)) {
      for (let key in this.report.ships) {
        let value = this.report.ships[key];
        let ship = this.knownShips.find((shipKey) => {
          return shipKey.id === key
        });
        this.report.ships[key] = { 'value': value, 'name': ship.name }
      }
    }
  }

  context(tooltip, context) {
    tooltip.close();
    if (this.tooltipContext) {
      this.tooltipContext.unsubscribe();
    }
    this.tooltipContext = this.timerTooltip
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        tooltip.open(context);
      });
  }

  shipName(ship) {
    return ship.name;
  }

  onMouseLeave(tooltip) {
    tooltip.close();
    if (this.tooltipContext) {
      this.tooltipContext.unsubscribe();
    }
  }

  isJSONNotEmpty(json) {
    for (let prop in json) {
      return true;
    }
    return false;
  }

  unitDetailModal;
  squadronDetails(squad) {
    let squadron = JSON.parse(JSON.stringify(squad));
    if (!this.unitDetailModal) {
      this.unitDetailModal = this.modalService.open(SquadronReportComponent, {
        windowClass: "report-SquadronReport backModalImg",
        ariaLabelledBy: "report-SquadronReport",
        scrollable: true,
        size: "lg",
        backdrop: true,
      });
    }
    /*if (this.report.attackInitialFleet[squadron.fleetId]) {
      if (!this.report.attackFinalFleet[squadron.fleetId] || !this.report.attackFinalFleet[squadron.fleetId]['squadrons'][squadron.id]) {
        squadron.amount = 0;
      }
    } else {
      if (!this.report.defenseFinalFleet[squadron.fleetId] || !this.report.defenseFinalFleet[squadron.fleetId]['squadrons'][squadron.id]) {
        squadron.amount = 0;
      }

    }
    this.unitDetailModal.componentInstance.addSquadron(squadron);
    this.unitDetailModal.hidden.subscribe(() => {
      this.unitDetailModal = null;
    });/** */
  }

  close(reason, source) {
    if (source.shiftKey) {
      this.modalService.dismissAll()
    } else {
      this.modal.dismiss(reason)
    }
  }
}
