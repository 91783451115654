import { Component, Input, OnDestroy, AfterViewInit, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { ResourceCumul } from "src/app/models/base/resources/ResourceCumul";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { Squadron } from "src/app/models/Squadron";
import { BaseService } from "src/app/services/bases/base.service";
import { FleetService } from "src/app/services/fleets/fleet.service";
import { UtilsService } from "src/app/services/Utils/utils.service";

@Component({
  selector: "app-fleet-storage",
  templateUrl: "./fleet-storage.component.html",
  styleUrls: ["./fleet-storage.component.scss"],
})
export class FleetStorageComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  public selectedFleet: Fleet;
  //ressources dans la base
  public resources: Map<string, ResourceCumul> = new Map<string, ResourceCumul>();
  //valeur Max de stockage dans la flotte
  public maxStorage = 0;
  public storage: Map<string, number> = new Map<string, number>();
  public initialStorage: Map<string, number> = new Map<string, number>();
  public selectedShips: Map<string, boolean> = new Map<string, boolean>();

  private readonly destroy$ = new Subject();

  constructor(
    public readonly modal: NgbActiveModal,
    public readonly baseService: BaseService,
    private readonly fleetService: FleetService,
    public readonly utilsService: UtilsService,
  ) {
    this.baseService.resourcesUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedResources) => {
        if (updatedResources.size > 0) {
          if (this.resources.size > 0) {
            for (let entry of updatedResources.entries()) {
              this.resources.get(entry[0]).clientAmount = entry[1].clientAmount;
            }
          } else {
            this.resources = updatedResources;
            this.resources.forEach((resource) => {
              resource['locked'] = false;
            })
          }
          this.refreshStorageRatio();
        }
      });
  }


  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }


  ngOnInit() {
    for (let key of this.resources.keys()) {
      this.storage.set(key, 0);
    }
    for (let id in this.selectedFleet.squadrons) {
      this.maxStorage += this.selectedFleet.squadrons[id].cumulatedCapacity;
      let storage = this.selectedFleet.squadrons[id].storage;
      this.selectedShips.set(this.selectedFleet.squadrons[id].name, true);
      for (let key in storage) {
        this.storage.set(key, +(storage[key] + this.storage.get(key)));
      }
    }
    this.initialStorage = new Map(this.storage);
  }

  refreshStorage() {
    for (let key of this.resources.keys()) {
      this.storage.set(key, 0);
    }
    this.maxStorage = 0;
    for (let id in this.selectedFleet.squadrons) {
      let squadron = this.selectedFleet.squadrons[id]
      if (document.getElementById(squadron.name)['checked'] == true) {
        this.maxStorage += squadron.cumulatedCapacity;
        let storage = squadron.storage;
        for (let key in storage) {
          this.storage.set(key, +(storage[key] + this.storage.get(key)));
        }
      }
    }
    this.initialStorage = new Map(this.storage);
  }

  ngAfterViewInit() {
    let inputs = document.getElementsByClassName('form-range progress-bar');
    for (let key = 0; key < inputs.length; key++) {
      inputs.item(key)['value'] = this.storage.get(inputs.item(key).id)
      inputs.item(key)['style']['background'] = 'linear-gradient(to right, transparent 0%, transparent ' + inputs.item(key)['value'] * (100.0 / inputs.item(key)['max'])
        + '%, rgba(0,0,0, 0.8) '
        + inputs.item(key)['value'] * (100.0 / inputs.item(key)['max'])
        + '%, rgba(0,0,0, 0.8) 100%)';
    }
  }

  isJSONNotEmpty(json) {
    for (let prop in json) {
      return true;
    }
    return false;
  }

  refreshStorageRatio() {
    for (let key of this.storage.keys()) {
      document.getElementById('hold_' + key)['value'] = this.storage.get(key);
      if (this.storage.get(key) > 0) {
        document.getElementById('hold_' + key).style['background'] = 'linear-gradient(to right, transparent 0%, transparent ' + this.storage.get(key) * (100.0 / this.min(key))
          + '%, rgba(0,0,0, 0.8) '
          + this.storage.get(key) * (100.0 / this.min(key))
          + '%, rgba(0,0,0, 0.8) 100%)';
      } else {
        document.getElementById('hold_' + key).style['background'] = 'linear-gradient(to right, transparent 0%, transparent 0%, rgba(0,0,0, 0.8) 0%, rgba(0,0,0, 0.8) 100%)';
      }

    }
  }

  submit() {
    // la map qui sera envoyée au Serv (<SquadronId, <ResourceName, ValueToAdd>>)
    let storageMap: Map<string, Map<string, Map<string, number>>> = new Map();

    // ce qu'il reste à stocker d'aorès ce que demande l'utilisateur et ce qui est déjà en stock
    let leftToStore = new Map(this.storage);
    for (let resource of leftToStore) {
      leftToStore.set(resource[0], +('' + (resource[1] - this.initialStorage.get(resource[0]))));
      if (leftToStore.get(resource[0]) == 0) {
        leftToStore.delete(resource[0])
      }
    }
    let selectedShips = document.getElementsByClassName('form-check-input');
    for (let key = 0; key < selectedShips.length; key++) {
      let selectedShip = selectedShips.item(key)
      if (selectedShip['checked'] == true) {
        let filteredSquadrons = [];
        for (let id in this.selectedFleet.squadrons) {
          let squadron = this.selectedFleet.squadrons[id];
          if (squadron.name === selectedShip.id) {
            filteredSquadrons.push(squadron.techId);
          }
        }
        for (let squadronId in filteredSquadrons) {
          let squadron: Squadron = this.selectedFleet.squadrons[filteredSquadrons[squadronId]];
          let storage = 0;
          if (this.isJSONNotEmpty(squadron.storage)) {
            for (let resource in squadron.storage) {
              storage += +squadron.storage[resource];
            }
          }
          let storageMax = squadron['cumulatedCapacity'];
          if (storage < storageMax) {
            for (let key of leftToStore.keys()) {
              if (!this.isJSONNotEmpty(storageMap[filteredSquadrons[squadronId]])) {
                storageMap[filteredSquadrons[squadronId]] = { "ressources": {} };
              }
              if ((storageMax - storage) < +leftToStore.get(key)) {
                storageMap[filteredSquadrons[squadronId]]["ressources"][key] = (storageMax - storage);
              } else {
                storageMap[filteredSquadrons[squadronId]]["ressources"][key] = +leftToStore.get(key);
              }
              leftToStore.set(key, +(leftToStore.get(key) - storageMap[filteredSquadrons[squadronId]]["ressources"][key]));
              if (leftToStore.get(key) == 0) {
                leftToStore.delete(key)
              }
              storage += storageMap[filteredSquadrons[squadronId]]["ressources"][key];
            }
          }
        }
      }
    }

    this.fleetService.loadStorageSquadrons(
      this.baseService.getCurrentBaseId(),
      this.selectedFleet.id,
      storageMap
    ).subscribe((val) => {
      this.modal.close();
      this.baseService.updateBase();
    });
  }

  emptyStorage() {
    let storageMap: Map<string, Map<string, Map<string, number>>> = new Map();

    let selectedShips = document.getElementsByClassName('form-check-input');
    for (let key = 0; key < selectedShips.length; key++) {
      let selectedShip = selectedShips.item(key)
      if (selectedShip['checked'] == true) {
        let filteredSquadrons = [];
        for (let id in this.selectedFleet.squadrons) {
          let squadron = this.selectedFleet.squadrons[id];
          if (squadron.name === selectedShip.id) {
            filteredSquadrons.push(squadron.techId);
          }
        }
        for (let squadronId in filteredSquadrons) {
          let squadron: Squadron = this.selectedFleet.squadrons[filteredSquadrons[squadronId]];
          for (let key in squadron.storage) {
            if (squadron.storage[key] > 0) {
              if (!storageMap[filteredSquadrons[squadronId]]) {
                storageMap[filteredSquadrons[squadronId]] = { "ressources": {} };
              }

              storageMap[filteredSquadrons[squadronId]]["ressources"][key] = squadron.storage[key];
            }
          }
        }
      }
    }

    this.fleetService.unloadStorageSquadrons(
      this.baseService.getCurrentBaseId(),
      this.selectedFleet.id,
      storageMap
    ).subscribe(val => {
      this.fleetService.getFleetById(this.selectedFleet.id).subscribe((fleet) => {
        this.selectedFleet = fleet;
        this.refreshStorage();
        this.baseService.updateBase();
      })
    });
  }

  min(key) {
    return Math.min(this.resources.get(key).clientAmount + this.initialStorage.get(key), this.maxStorage);
  }

  lockResource(event) {
    let resource = event.target.getAttribute('resource');
    this.resources.get(resource)['locked'] = !this.resources.get(resource)['locked'];
  }

  onChange(event) {
    let resource = event.target.getAttribute('resource');
    if (this.resources.get(resource)['locked']) {
      event.target.value = this.storage.get(resource);
      return;
    }
    if (isNaN(event.target.value) || this.initialStorage.get(resource) >= event.target.value) {
      event.target.value = this.initialStorage.get(resource);
    }
    let maxValue = this.maxStorage;
    let totalResource: number = 0;
    for (let key of this.initialStorage.keys()) {
      if (key !== resource) {
        if (!this.resources.get(key)['locked']) {
          maxValue -= this.initialStorage.get(key);
        } else {
          maxValue -= +this.storage.get(key);
        }
        totalResource += +this.storage.get(key);
      }
    }
    event.target.value = Math.min(maxValue, event.target.value)
    totalResource += +event.target.value;


    if (totalResource > this.maxStorage) {
      let toRemove = totalResource - this.maxStorage;
      for (let key of this.initialStorage.keys()) {
        if (key !== resource && toRemove > 0) {
          if (!this.resources.get(key)['locked']) {
            if (this.initialStorage.get(key) < this.storage.get(key)) {
              if ((this.storage.get(key) - toRemove) > (this.initialStorage.get(key))) {
                this.storage.set(key, +(this.storage.get(key) - toRemove));
                toRemove = 0;
              } else {
                toRemove -= (this.storage.get(key) - this.initialStorage.get(key));
                this.storage.set(key, +this.initialStorage.get(key));
              }
            }
          }
        }
      }
    }
    if (this.storage.has(resource)) {
      this.storage.set(resource, +event.target.value);
    }

    this.refreshStorageRatio();
  }

  onClickOutput(event, resourceName) {
    if (event.target.parentElement.getElementsByTagName('input').length == 0) {
      let input = document.createElement('input')

      input.setAttribute('type', 'text')
      input.setAttribute('size', '2');
      input.setAttribute('value', this.storage.get(resourceName) + '');
      input.setAttribute('resource', resourceName);
      input.addEventListener('change', (event) => this.onExitOutput(event, resourceName));
      input.addEventListener('blur', (event) => this.onExitOutput(event, resourceName));
      event.target.parentElement.append(input);
      event.target.classList.add('hidden')
      input.focus();
      input.select();
    }
  }

  onExitOutput(event, resource) {
    event.target.resource = resource;
    let percent = (event.target.value + '').split('%')
    if (percent.length > 1) {
      event.target.value = this.maxStorage / (100 / +percent[0]);
    }
    let minMax = document.getElementById('hold_' + resource);
    event.target.value = Math.min(event.target.value, +minMax.getAttribute('max'))
    event.target.value = Math.max(event.target.value, +minMax.getAttribute('min'))
    this.onChange(event)
    event.target.removeAllListeners();
    event.target.parentElement.getElementsByTagName('label')[1].classList.remove('hidden')
    event.target.parentElement.removeChild(event.target)
  }

}
