<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Squadron-detailComponent|Squadron details">Détails d'unité(s)
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div class="modal-body">
  <div *ngIf="units[0]">
    <table class="table table-striped table-dark table-hover ship-Detail">
      <thead>
        <th style="border:0px">
        </th>
        <th *ngFor="let unit of units">
          <div class="button">
            <button (click)="remove(unit)" aria-label="Close" class="btn btn-close" type="reset"></button>
          </div>
          <div class="d-flex justify-content-center flex-column">
            <img alt="{{ unit.name }}" class="shipImage" src="{{ '/assets/img/ships/' + unit.name + '.png' }}"
              title="{{ unit.name }}" />
            <label>
              {{unit.name}}
            </label>
            <!--            <label> {{unit.description}} </label>-->
          </div>
        </th>
      </thead>
      <tbody>
        <tr class="justify-content-around p-2">
          <th colspan="1000" class="underline">En Combat :</th>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Armure</td>
          <td *ngFor="let unit of units">{{unit.armor || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Bouclier</td>
          <td *ngFor="let unit of units">{{unit.shield || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Stockage</td>
          <td *ngFor="let unit of units">{{unit.capacity || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Dégats (par tir)</td>
          <td *ngFor="let unit of units">{{unit.damage || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Nb de tir (par tour)</td>
          <td *ngFor="let unit of units">{{unit.shoots || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Points de vie</td>
          <td *ngFor="let unit of units">{{unit.hp || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Portée</td>
          <td *ngFor="let unit of units">{{unit.range || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Dimension</td>
          <td *ngFor="let unit of units">{{unit.size || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Vitesse</td>
          <td *ngFor="let unit of units">{{(unit.speed || 0)/10}} par tour</td>
        </tr>


        <tr class="justify-content-around p-2">
          <th colspan="1000" class="underline">Hors Combat :</th>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Consommation</td>
          <td *ngFor="let unit of units">{{unit.consummation || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Consommation statique</td>
          <td *ngFor="let unit of units">{{unit.staticConsummation || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Vitesse sur la carte</td>
          <td *ngFor="let unit of units">{{unit.speed || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Capacité de stockage de carburant</td>
          <td *ngFor="let unit of units">{{unit.fuelTank || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Coût</td>
          <td *ngFor="let unit of units">
            <div class="d-flex flex-wrap justify-content-center p-1 resource">
              <div *ngFor="let amount of unit.cost | keyvalue">
                <div class="p-1 d-flex flex-column">
                  <img class="resourceImg" src="/assets/img/resources/{{ amount.key }}.png" />
                  {{ amount.value }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
