<div *ngIf="selectedFleet" class="modal-header d-flex flex-row justify-content-around">
  <div class="d-flex flex-fill">
    <h4 class="modal-title me-3" id="modal-basic-title" i18n="PlanetComponent|Title: fleet moving">
      Gestion de la soute de la flotte '{{selectedFleet.name}}''
    </h4>
  </div>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>

<div class="storageFleet modal-body" *ngIf="isJSONNotEmpty(selectedFleet)">
  <div class="d-flex justify-content-around flex-wrap">
    <div *ngFor="let ship of selectedShips | keyvalue" class="form-check form-switch">
      <label class="form-check-label" [for]="ship.key">
        <input class="form-check-input" (click)="refreshStorage()" type="checkbox" role="switch" [id]="ship.key"
          [checked]=ship.value>
        {{ship.key}}</label>
    </div>
  </div>

  <div class="mt-3 text-center maxStorage" [title]=maxStorage *ngIf="maxStorage">
    Capacité la soute : {{utilsService.nFormatter(maxStorage)}}
  </div>

  <div *ngFor="let resource of resources | keyvalue" class="col-md resource-item flex-column">
    <label class="d-flex flex-row align-items-center justify-content-between">
      <label class="resource-value">{{resource.key}}</label>
      <img alt={{resource.key}} class="resourceImg d-flex" src="/assets/img/resources/{{resource.key}}.png"
        title={{resource.key}} />
      <i type="button" class="btn btn-secondary bx"
        [ngClass]="resources.get(resource.key)['locked']? 'bxs-lock': 'bx-lock-open'" [attr.resource]=resource.key
        (click)="lockResource($event)"></i>
      <label class="resource-amount" (click)="onClickOutput($event, resource.key)">
        {{utilsService.nFormatter(storage.get(resource.key))}}
      </label>
    </label>
    <input type="range" class="form-range progress-bar" [ngClass]="'bg-'+utilsService.resourceColour.get(resource.key)"
      min="0" [value]=storage.get(resource.key) [max]=min(resource.key) [id]="'hold_'+resource.key"
      [attr.resource]=resource.key name="resource" (input)=onChange($event)>
  </div>

  <div class="d-flex justify-content-around flex-wrap">
    <input type="button" class="btn btn-primary" (click)="submit()" value="Valider">
    <input type="button" class="btn btn-info" (click)="emptyStorage()" value="Vider la soute">
  </div>
</div>
