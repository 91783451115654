<div *ngIf="!missions" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="missions" class="modal-header d-flex flex-row justify-content-around">
  <div class="d-flex flex-fill">
    <h4 class="modal-title me-3" id="modal-basic-title" i18n="PlanetComponent|Title: fleet moving">
      Missions d'un espion
    </h4>
  </div>
  <div *ngIf="agent.position" class="d-flex flex-fill">
    <h4 i18n="PlanetComponent|Starting position">
      Position :<br />
      <!-- <ng-container *ngIf="base">
        {{ base.name }}
      </ng-container> -->
      ({{ agent.position.coordinates }})
    </h4>
  </div>

  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div (keyup.enter)="onSubmit()" class="modal-body">
  <div class="d-flex flex-row">
    <form>
      <select *ngIf="missions" (change)="onChange($event.target['value'])" class="form-control" id="baseName">
        <option *ngFor="let mission of missions" [selected]="mission.id === selectedMission.id" [value]="mission.id">
          {{ mission.name }}
        </option>
      </select>
      <div *ngIf="selectedMission">
        {{selectedMission.description}}
      </div>

      <div *ngIf="selectedMission">
        durée de la mission :
        <input *ngIf="!selectedMission.duration" type="number" (change)="onChangeDuration($event.target['value'])">
        <label>
          {{selectedMission.duration}}
        </label>
      </div>

      <div *ngIf="selectedMission">
        difficulté de la mission :
        <label>
          {{selectedMission.difficulty}}
        </label>
      </div>
    </form>
  </div>
  <div class="d-flex flex-row mt-3 justify-content-around">
    <button class="btn btn-outline-success" ngbAutofocus (click)="onSubmit()">Envoyer en mission</button>
    <button class="btn btn-outline-danger" (click)="modal.dismiss('Cross click')">Annuler</button>
  </div>
</div>
