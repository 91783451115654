import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { Base } from 'src/app/models/base/Base';
import { Research } from 'src/app/models/research/Research';
import { BaseService } from 'src/app/services/bases/base.service';
import { UsersService } from 'src/app/services/Users/user.service';
import { User } from 'src/app/models';
import { EffectRef } from "src/app/models/Effect/EffectRef";
import { EffectService } from "src/app/services/effect/effect.service";
import { EffectDetailComponent } from "../../effects/effect-detail/effect-detail.component";

@Component({
  selector: "app-research-detail",
  templateUrl: "./research-detail.component.html",
  styleUrls: ["./research-detail.component.scss"],
})
export class ResearchDetailComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject();

  @Input()
  public research: Research;

  @Input()
  public currentLvl: number;
  public base: Base;
  public user: User;

  hasCurrentEffects;

  constructor(
    private readonly modalService: NgbModal,
    private readonly effectService: EffectService,
    public readonly modal: NgbActiveModal,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
  ) {
    //Récup de la base et des ressources
    baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
        }
      });

    //Récup de l'owner
    userService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.user = user;
        }
      });
  }

  ngOnInit() {
    if (this.currentLvl != null) {
      this.hasCurrentEffects = this.isJSONNotEmpty(this.research.levels[this.currentLvl].effects) ||
        this.isJSONNotEmpty(this.research.levels[this.currentLvl].cumulatedProductionMultiplier) ||
        this.isJSONNotEmpty(this.research.levels[this.currentLvl].cumulatedStorageMultiplier);
    } else {
      this.hasCurrentEffects = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  isJSONNotEmpty(json) {
    for (let prop in json) {
      return true;
    }
    return false;
  }

  getEffects(effectCode: string): EffectRef {
    return this.effectService.getEffects(effectCode);
  }

  clickOnEffect(effect) {
    const modal = this.modalService.open(EffectDetailComponent, {
      ariaLabelledBy: "effectDetail",
      scrollable: true,
      size: "xl",
      windowClass: "effectDetail backModalImg",
      backdrop: true,
    });

    modal.componentInstance.effect = {code:effect.key, libelle: effect.key};
    modal.componentInstance.isFromUser = !this.effectService.getEffects(effect.value.code).local;
  }
}
