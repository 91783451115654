<div class="modal-header">
  <div *ngIf="report" class="d-flex flex-row align-items-center">
    <h4 class="modal-title me-3" id="modal-basic-title">
      {{ report.libelle }}

      - {{ report.id }}
    </h4>
  </div>
  <button (click)="close('Cross click', $event)" aria-label="Close" class="btn btn-close" type="button">
  </button>
</div>

<ng-template #found>
  <div class="text-center">
    vous avez trouvé :
  </div>
</ng-template>

<div *ngIf="!report" class="modal-body loading"></div>
<div *ngIf="report" class="modal-body d-flex flex-column report">
  <div *ngIf="isEmpty; else found" class="text-center">
    vous n'avez rien trouvé
  </div>
  <div *ngIf="isJSONNotEmpty(report.money)" class="mx-auto d-flex flex-row">
    <div *ngFor=" let money of report.money | keyvalue">
      <i class='my-auto mx-2 bx bxs-bank'></i>
      <div class="text-center">
        {{money.value}}
      </div>
    </div>
  </div>
  <div *ngIf="isJSONNotEmpty(report.resources)" class="mx-auto d-flex flex-row">
    <div *ngFor=" let resource of report.resources | keyvalue">
      <img alt={{resource.key}} class="my-auto mx-2 resourceImg d-flex" src="/assets/img/resources/{{resource.key}}.png"
        title={{resource.key}} />
      <div class="text-center">
        {{resource.value}}
      </div>
    </div>
  </div>
  <div *ngIf="isJSONNotEmpty(report.ships)" class="mx-auto d-flex flex-row">
    <div *ngFor=" let ship of report.ships | keyvalue">
      <img alt={{shipName(ship.value)}} class="my-auto mx-2 resourceImg d-flex" src="/assets/img/ships/{{ship.value['name']}}.png" title={{shipName(ship.value)}} />
      <div class="text-center">
        {{ship.value["value"]}}
      </div>
    </div>
  </div>
</div>
