// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//import {KeycloakConfig} from 'keycloak-angular';
export const kcConfig = {
  url: "https://keycloak.needaname.fr/auth/",
  realm: "need-a-name",
  clientId: "need-a-name",
  //credentials: {secret: '71205132-649c-4007-84a9-2ce1d3d80aa0'},
};

export const environment = {
  production: false,
  keycloakConfig: kcConfig,
  apiEndpoint: "http://localhost:8080/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
