import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { Leaderboard } from 'src/app/models/leaderboard/Leaderboard';
import { LeaderboardScore } from 'src/app/models/leaderboard/leaderboardScore';
import { LeaderboardService } from 'src/app/services/Leaderboard/leaderboard.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  leaderboards: Leaderboard[] = [];
  scores: LeaderboardScore[] = [];
  selectedLeaderboard: Leaderboard;
  constructor(
    public readonly modal: NgbActiveModal,
    public readonly leaderboardService: LeaderboardService,
  ) { }


  ngOnInit(): void {
    this.leaderboardService.leaderboardsUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((leaderboards) => {
        if (this.leaderboards.length == 0 && leaderboards) {
          for (let leaderboard of leaderboards) {
            leaderboard['selected'] = false;
          }
        }
        if (leaderboards && leaderboards.length > 0) {
          this.leaderboards = leaderboards;
          this.selectedLeaderboard = leaderboards.filter((leaderboard) => {
            return leaderboard.name === 'FOUILLES';
          })[0];
          if (!this.selectedLeaderboard)
            this.selectedLeaderboard = leaderboards[0];
          leaderboards[leaderboards.indexOf(this.selectedLeaderboard)]['selected'] = true;
          this.leaderboardService.getScorebyLeaderboardId(this.selectedLeaderboard.id).subscribe((scores) => {
            this.scores = scores;
            this.sortByNumber('score');
          })
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  onChange(selectedLeaderboardId) {
    this.selectedLeaderboard = this.leaderboards.filter((leaderboard) => {
      return leaderboard.id === selectedLeaderboardId;
    })[0];
    this.leaderboards[this.leaderboards.indexOf(this.selectedLeaderboard)]['selected'] = true;
    this.leaderboardService.getScorebyLeaderboardId(this.selectedLeaderboard.id).subscribe((scores) => {
      this.scores = scores;
      document.getElementById('score').getElementsByTagName('i')[0].classList.remove('bx-sort-down')
      document.getElementById('score').getElementsByTagName('i')[0].classList.remove('bx-sort-down')
      this.sortByNumber('score');
    })

  }

  sortByNumber(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.scores = this.scores.sort((a, b) => {
      return (parseInt(b[orderType], 16) - parseInt(a[orderType], 16)) * order;
    })
  }

  showOrder(orderType) {
    let ths = document.getElementsByTagName('app-leaderboard')[0].getElementsByTagName('th');
    let order = document.getElementById(orderType).getElementsByTagName('i')[0].classList.contains('bx-sort-down');
    for (let i = 0; i < ths.length; i++) {
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-down')
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-up')
    }
    if (order) {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-up')
    } else {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-down')
    }
    return order;
  }

  sortByText(orderType) {
    let order = this.showOrder(orderType) ? 1 : -1;
    this.scores = this.scores.sort((a, b) => {
      return (a[orderType].toLocaleLowerCase() > b[orderType].toLocaleLowerCase() ? 1 : -1) * order;
    })
  }

}
