import { OwnerRef } from "../owner/OwnerRef";

export class AllianceRef extends OwnerRef {
  description: string;
  imageLocation: string;
  leaderId: string;
  leaderName: string;
  mainBaseId: string;
  shortName: string;
}
