<table *ngIf="hiredStaff || hireableStaff"
  class="table table-striped table-hover table-dark planet-table custom-bottom">
  <tbody>
    <tr>
      <td>
        <input class="inputSearch" type="search" (input)="onSearch($event.target['value'])" placeholder="Recherche...">
      </td>
      <td colspan="10">
        <button *ngIf="hireableStaff" class="btn btn-outline-warning" (click)="modalHireStaff(HireNewStaff)">Engager du
          personnel</button>
      </td>
      <td>
      </td>
      <td>
      </td>
    </tr>
    <tr>
      <th id="name" (click)="sortByText('name')">
        <i class='bx'></i>
        Agent
      </th>
      <th id="state" (click)="sortByText('state')">
        <i class='bx'></i>
        État
      </th>
      <th id="lvl" (click)="sortByNumber('lvl')">
        <i class='bx'></i>
        Niveau
      </th>
      <th id="position">
        <i class='bx'></i>
        Position
      </th>
      <th id="characteristiclevels">
        <i class='bx'></i>
        Charactéristiques (niveau)
      </th>
      <th id="characteristicxp">
        <i class='bx'></i>
        Charactéristiques (XP)
      </th>
      <th>
        <i class='bx d-none'></i>
      </th>
    </tr>
    <tr *ngFor="let staff of hiredStaff" class="justify-content-around p-2 openUserProfile">
      <td class="align-middle">
        {{staff.name}}
      </td>
      <td class="align-middle">
        <div i18n="FleetsComponent|Status of the fleet">{{utilsService.translateConstants(staff.state)}}</div>
        <div i18n="FleetsComponent|Consumption of the fleet" *ngIf="staff.missionEnd">Temps restant : {{ +this.timerMap.get(staff.id) | timer:'UTC'}} s</div>
      </td>
      <td class="align-middle">
        {{staff.lvl}}
      </td>
      <td class="align-middle">
        <div *ngIf="staff.position">
          <div>planète: {{staff.position.planetId}}</div>
          <div>coordonnées: {{staff.position.coordinates}}</div>
        </div>
      </td>
      <td class="align-middle">
        <div *ngFor="let characteristiclevel of staff.characteristiclevels | keyvalue">
          {{utilsService.translateConstants(characteristiclevel.key)}} : {{characteristiclevel.value}}
        </div>
      </td>
      <td class="align-middle">
        <div *ngFor="let characteristicxp of staff.characteristicxp | keyvalue">
          {{utilsService.translateConstants(characteristicxp.key)}} : {{characteristicxp.value}}
        </div>
        <div>
          xp général : {{staff.xp}}
        </div>
      </td>
      <td class="align-middle">
        <button *ngIf="staff.position && !staff.missionId" class="btn btn-outline-warning"
          (click)="moveHiredStaff(staff)">Déplacer le personnel</button>
        <button *ngIf="staff.position && !staff.missionId" class="btn btn-outline-warning" (click)="launchMission(staff)">lancer une
          mission</button>
      </td>
    </tr>
  </tbody>
</table>


<ng-template #NameNewStaff let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDisable|Title" id="confirmDisable">Nommer le personnel</h4>
    <button (click)="utilsService.closeModalByEvent($event)" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <form>
      <textarea id="NamedNewStaff" name="NamedNewStaff" placeholder="Nom de l'agent..."></textarea>
    </form>
    <button class="btn btn-outline-success" (click)="hireNamedStaff($event)">Engager</button>
  </div>
</ng-template>


<ng-template #HireNewStaff let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDisable|Title" id="confirmDisable">Engager du personnel</h4>
    <button (click)="utilsService.closeModalByEvent($event)" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <table *ngIf="hireableStaff" class="table table-striped table-hover table-dark planet-table custom-bottom">
      <tbody>
        <tr>
          <th id="type" (click)="sortByText('type')">
            <i class='bx'></i>
            Type de Personnel
          </th>
          <th id="defaultCharacteristics">
            <i class='bx'></i>
            Compétences
          </th>
          <th id="unitPrice">
            <i class='bx'></i>
            Prix unitaire
          </th>
          <th></th>
        </tr>
        <tr *ngFor="let staff of hireableStaff" class="justify-content-around p-2 openUserProfile">
          <td class="align-middle">
            {{staff.name}}
          </td>
          <td class="align-middle">
            <div *ngFor="let characteristic of staff.defaultCharacteristics | keyvalue">
              {{utilsService.translateConstants(characteristic.key)}} : {{characteristic.value}}<br>
            </div>
          </td>
          <td class="align-middle">
            <label *ngFor="let amount of staff.recruitPrice | keyvalue">
              <label [ngbTooltip]="amount.value+''" #tooltip="ngbTooltip" container="body" class="p-1 d-flex flex-column">
                <img class="resourceImg" src="/assets/img/resources/{{ amount.key }}.png" />
                {{ utilsService.nFormatter(amount.value) }}
              </label>
            </label>
          </td>
          <td class="align-middle">
            <button class="btn btn-outline-success" (click)="hireNewStaff(staff, NameNewStaff)">Engager</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
