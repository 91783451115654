<div *ngIf="base || user" class="modal-body d-flex flex-column">
  <table class="table table-hover table-dark effect">
    <tbody *ngIf="base.effects">
      <tr class="justify-content-around p-2">
        <th class="underline">Effets provenant de la base </th>
        <th>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr *ngFor="let effect of base.effects | keyvalue" class="justify-content-around p-2" (click)="clickOnEffect(effect, false)">
        <td>{{getEffects(effect.key).libelle }}</td>
        <td class="text-center">{{ effect.value }}</td>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible">test</td>
      </tr>
    </tbody>
    <tbody *ngIf="user.effects">
      <tr class="justify-content-around p-2">
        <th class="underline">Effets provenant du joueur </th>
        <th>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr *ngFor="let effect of user.effects | keyvalue" class="justify-content-around p-2" (click)="clickOnEffect(effect, true)">
        <td>{{getEffects(effect.key).libelle }}</td>
        <td class="text-center">{{ effect.value }}</td>
      </tr>
    </tbody>
  </table>
</div>
