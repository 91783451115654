import { KeyValue } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil, timer } from "rxjs";
import { SquadronReportComponent } from "src/app/main/message-box/report/squadron-report/squadron-report.component";
import { ShowProfileComponent } from "src/app/main/users/profile/show-profile/show-profile.component";
import { Alert } from "src/app/models/Alert";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { FightReport } from "src/app/models/reports/FightReport";
import { FightRound } from "src/app/models/reports/FightRound";
import { Squadron } from "src/app/models/Squadron";
import { AlertService } from "src/app/services/alert/alert.service";
import { BaseService } from "src/app/services/bases/base.service";
import { OptionsService } from "src/app/services/options/options.service";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { ReportService } from "src/app/services/reports/report.service";
import { MoveReport } from "src/app/models/reports/MoveReport";
import { FleetService } from "src/app/services/fleets/fleet.service";

@Component({
  selector: "app-move-detail",
  templateUrl: "./move-detail.component.html",
  styleUrls: ["./move-detail.component.scss"],
})
export class MoveDetailComponent implements OnInit, OnDestroy {
  public report: MoveReport;
  instances: NgbModalRef[];

  @Input()
  public getReport;
  private readonly destroy$ = new Subject();
  userFleets: Fleet[] = [];
  fleetName = "";
  constructor(
    public readonly modalService: NgbModal,
    private readonly optionsService: OptionsService,
    private readonly reportService: ReportService,
    private readonly baseService: BaseService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    public readonly utilsService: UtilsService,
    private readonly fleetService: FleetService,
  ) {
    let destroy$ = new Subject();
    this.modalService.activeInstances
      .pipe(takeUntil(destroy$))
      .subscribe((instances) => {
        this.instances = instances
      })

    this.fleetService.fleetUpdate$
      .pipe(takeUntil(destroy$))
      .subscribe((fleets) => {
        this.userFleets = fleets || [];
      });
  }

  ngOnInit() {
    if (this.getReport) {
      this.getReport.subscribe((report) => {
        report.date = new Date(Date.parse(report.date + ''));
        this.report = report;
        for (let fleet of this.userFleets) {
          if (fleet.id === report.fleetId) {
            this.fleetName = fleet.name;
          }
        }
      });
    }
  }

  close(reason, event) {
    if (event.shiftKey) {
      this.modalService.dismissAll()
    } else {
      this.utilsService.closeModalByEvent(event);
    }
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }


  //Pour que le keyvalue soit dans le bon ordre
  originalOrder = (
    a: KeyValue<number, FightRound>,
    b: KeyValue<number, FightRound>
  ): number => {
    return 0;
  };

  isJSONNotEmpty(json) {
    for (let prop in json) {
      return true;
    }
    return false;
  }
}
