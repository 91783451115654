<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">Options</h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>

<div class="modal-body">
  <div class="settings">
    <div class="d-flex justify-content-around">
      <button type="button" id="disconnect" (click)="logout()" class="btn btn-danger" i18n="OptionComponent|Disconnect">
        Se déconnecter</button>
      <button *ngIf="debug" type="button" id="debugMenu" (click)="openDebug()" class="btn btn-outline-warning"
        i18n="OptionComponent|openDebug"> menu de Debug</button>
      <button *ngIf="debug" type="button" id="debugMenu" (click)="askForNotifications()" class="btn btn-outline-warning"
        i18n="OptionComponent|openDebug">recevoir des Notifications (en test!!)</button>
    </div>
    <table class="mt-5">
      <tr *ngFor="let option of optionsHtml">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id={{option[0]}} [checked]="options[option[0]]"
            (click)="setOption(option[0], $event.target['checked'])" #{{option[0]}} i18n="OptionComponent|{{option[0]}}">
          <label class="form-check-label" for="{{option[0]}}">{{option[1]}}</label>
        </div>
      </tr>
    </table>
  </div>
</div>
