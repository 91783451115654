import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, of, Subject, throwError } from "rxjs";
import { Base } from "src/app/models/base/Base";
import { BaseRef } from "src/app/models/base/BaseRef";
import { ResourceCumul } from "src/app/models/base/resources/ResourceCumul";
import { UtilsService } from "../Utils/utils.service";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class BaseService implements OnDestroy {
  private apiUrl = environment.apiEndpoint;
  private base = new BehaviorSubject<Base>(null);
  public baseUpdate$ = this.base.asObservable();

  private baseNeedUpdate = false;
  private baseLockUpdate = false;

  private resources = new BehaviorSubject<Map<string, ResourceCumul>>(new Map<string, ResourceCumul>());
  public resourcesUpdate$ = this.resources.asObservable();
  private resourcesTimestamp = Date.now();

  private readonly destroy$ = new Subject();

  constructor(
    private readonly http: HttpClient,
  ) {
    setInterval((val) => {
      this.resourceCounter();
      if (this.getCurrentBaseId()) {
        //refresh Base
        if (this.baseNeedUpdate == true && !this.baseLockUpdate) {
          this.baseLockUpdate = true;
          this.getBaseById(this.getCurrentBaseId()).subscribe((base) => {
            this.baseLockUpdate = false;
            this.baseNeedUpdate = false;
          });
        }
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }


  private resourceCounter() {
    if (this.resources && this.resources.value.size > 0) {
      let elapsedTime = Date.now() - this.resourcesTimestamp;
      let resources = this.resources.value;
      for (let res of resources.keys()) {
        let resource = resources.get(res);
        if (resource.clientAmount < resource.storage) {
          resource.clientAmount = Math.floor(resource.amount + ((resource.production - resource.taxes - resource.consumption) / 3600000.0 * elapsedTime));
          resource.clientAmount = Math.min(resource.clientAmount, resource.storage);
        }
        resource.clientAmount = Math.max(resource.clientAmount, 0);
        resources.set(res, resource);
      }
      this.resources.next(resources);
    }
  }

  public getCurrentBaseId() {
    if (this.base.value) {
      return this.base.value.id;
    } else {
      return localStorage.getItem('currentBase');
    }
  }

  public getCurrentBase() {
    return this.base.value;
  }

  public getCurrentBaseRessources(): Map<string, ResourceCumul> {
    if (this.resources) {
      return this.resources.value;
    }
  }

  public updateBase(baseId?: string) {
    if (!baseId) {
      this.baseNeedUpdate = true;
    } else {
      this.getBaseById(baseId).subscribe((base) => {

      });
    }
  }

  public getBases(): Observable<BaseRef[]> {
    return this.http.get<BaseRef[]>(this.apiUrl + `bases`);
  }

  public getBaseById(baseId: string): Observable<Base> {
    return this.http.get<Base>(this.apiUrl + `bases/${baseId}`).pipe(map((base) => {
      this.base.next(base);
      this.resourcesTimestamp = Date.now();

      let resources = new Map<string, ResourceCumul>();
      for (let res in base.ressources) {
        base.ressources[res].clientAmount = base.ressources[res].amount;
        resources.set(res, base.ressources[res])
      }
      this.resources.next(resources);
      return base;
    })
    ).pipe(
      catchError(err => {
        this.base.error(err);
        return throwError(() => err);
      })
    );
  }

  public getRefBaseById(baseId: string): Observable<BaseRef> {
    return of(
      Object.assign(new BaseRef(), {
        id: "toto",
        name: "Base de test",
        type: "test",
        ownerId: "Mamat",
        ownerName: "Mamat",
      })
    );
  }

  public buildNew(
    baseId: string,
    emplacement: number,
    level: number,
    buildingId: string
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `bases/${baseId}`,
      {
        emplacement: emplacement,
        level: level,
        buildId: buildingId,
      }
    );
  }

  public destroyBuilding(baseId: string, slot: number): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `bases/${baseId}/d`,
      +slot
    );
  }

  public cancel(baseId: string, emplacement: number): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `bases/${baseId}/c`,
      +emplacement
    );
  }

  public enableBuilt(baseId: string, emplacement: number): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `bases/${baseId}/enable`,
      +emplacement
    );
  }

  public disableBuilt(baseId: string, emplacement: number): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `bases/${baseId}/disable`,
      +emplacement
    );
  }

  public renameBase(baseId: string, name: string): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `bases/${baseId}/rename`,
      name
    );
  }

  public repairBuilding(baseId: string, slot: number): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `bases/${baseId}/repair/${slot}`,
      +slot
    );
  }
}
