import { Component, OnDestroy } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { Alert } from "src/app/models/Alert";
import { AllianceRef } from "src/app/models/Alliance/AllianceRef";
import { AlertService } from "src/app/services/alert/alert.service";
import { AllianceService } from "src/app/services/Alliance/alliance.service";
import { BaseService } from "src/app/services/bases/base.service";
import { MarketService } from "src/app/services/Market/market.service";
import { OptionsService } from "src/app/services/options/options.service";
import { ShipService } from "src/app/services/ships/ship.service";
import { UsersService } from "src/app/services/Users/user.service";

@Component({
  selector: "app-new-offer",
  templateUrl: "./createAlliance.component.html",
  styleUrls: ["./createAlliance.component.scss"],
})
export class CreateAllianceComponent implements OnDestroy {
  private readonly destroy$ = new Subject();
  private debug: boolean = false;
  public allianceRef: AllianceRef = new AllianceRef();

  constructor(
    private readonly modalService: NgbModal,
    private readonly marketService: MarketService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    private readonly optionsService: OptionsService,
    private readonly shipService: ShipService,
    private readonly alertService: AlertService,
    public readonly modal: NgbActiveModal,
    private readonly allianceService: AllianceService,
  ) {
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  change(value, source) {
    this.allianceRef[source] = value;
  }

  confirmNewAlliance() {
    this.allianceService.createNewAlliance(this.userService.currentUserId(), this.allianceRef).subscribe(() => {
      console.error('alliance created')
      let alert = new Alert();
      alert.type = "info";
      alert.msg = `votre alliance nommé ${this.allianceRef.name} à bien été créée`;
      alert.timeout = 10000;
      this.modal.close();
    });
  }
}
