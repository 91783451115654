import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take, timer } from 'rxjs';
import { User } from 'src/app/models';
import { GlobalPosition } from 'src/app/models/GlobalPosition';
import { AllianceService } from '../Alliance/alliance.service';
import { BaseService } from '../bases/base.service';
import { BuildingsService } from '../Buildings/buildings.service';
import { FleetService } from '../fleets/fleet.service';
import { StaffService } from '../Staff/staff.service';
import { UsersService } from '../Users/user.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private translatedConstants: Map<string, string>;
  public readonly timer = timer(0, 1000);
  private instances: NgbModalRef[];
  private baseTimestamp = Date.now();
  private userTimestamp = Date.now();
  private fleetTimestamp = Date.now();
  private allianceTimestamp = Date.now();
  private knownAgents = Date.now();
  private user: User;
  public resourceColour: Map<string, string>;

  constructor(
    private readonly modalService: NgbModal,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    private readonly fleetService: FleetService,
    private readonly buildingsService: BuildingsService,
    private readonly staffService: StaffService,
    private readonly allianceService: AllianceService,
  ) {
    this.initTranslations();

    this.userService.userUpdate$
      .subscribe((user) => {
        this.user = user;
      });

    this.modalService.activeInstances
      .subscribe((instances) => {
        this.instances = instances
      })

    this.timer.subscribe((val) => {
      this.baseService.baseUpdate$
        .pipe(take(1))
        .subscribe((base) => {
          if (base) {
            let ms = Date.now();
            let baseNeedUpdate = false;
            let userNeedUpdate = false;
            for (let [slotKey, slot] of Object.entries(base.slots)) {
              if (slot && slot.construction) {
                ms = Date.parse(slot.construction + '') - Date.now();
                if (this.needUpdatesFromTimer(ms, val, this.baseTimestamp)) {
                  baseNeedUpdate = true;
                  this.buildingsService.buildingsMap.forEach((building) => {
                    if (building.id === slot.built.building.id) {
                      for (let key in building.levels[0].effects) {
                        let effect = building.levels[0].effects[key];
                        if (effect.target === "OWNER") {
                          userNeedUpdate = true;
                        }
                      }
                    }
                  })
                }
              }
            }
            if (baseNeedUpdate) {
              this.baseTimestamp = Date.now();
              this.baseService.updateBase();
            }
            if (userNeedUpdate) {
              this.userService.updateUser();
              this.userTimestamp = Date.now()
            }
          }
        })

      this.allianceService.allianceUpdate$
        .pipe(take(1))
        .subscribe({
          next: (alliance) => {
            if (alliance) {
              let ms = Date.now();
              let needUpdate = false;
              /*for (let [researchId, timer] of Object.entries(alliance.pendingResearch)) {
                ms = Date.parse(timer + '') - Date.now();
                needUpdate ||= this.needUpdatesFromTimer(ms, val, this.allianceTimestamp)
              }
              if (needUpdate) {
                this.allianceService.updateAlliance();
                this.allianceTimestamp = Date.now()
              }/** */
            }
          },
          error: (err) => { }
        })

      this.userService.userUpdate$
        .pipe(take(1))
        .subscribe({
          next: (user) => {
            if (user) {
              let ms = Date.now();
              let needUpdate = false;
              for (let [researchId, timer] of Object.entries(user.pendingResearch)) {
                ms = Date.parse(timer + 'Z') - Date.now();
                needUpdate ||= this.needUpdatesFromTimer(ms, val, this.userTimestamp)
              }
              if (needUpdate) {
                this.userService.updateUser();
                this.userTimestamp = Date.now()
              }
            }
          },
          error: (err) => { }
        })

      this.fleetService.fleetUpdate$
        .pipe(take(1))
        .subscribe((fleets) => {
          for (let key in fleets) {
            let fleet = fleets[key];
            if (fleet.travelTime) {
              let ms = Date.parse(fleet.travelTime + '') - Date.now();
              let needUpdate = this.needUpdatesFromTimer(ms, val, this.fleetTimestamp)
              if (needUpdate) {
                this.fleetService.updateFleets();
                this.fleetTimestamp = Date.now();
              }
            }
          }
        })

      this.staffService.knownAgentsUpdate$
        .pipe(take(1))
        .subscribe((agents) => {
          for (let key in agents) {
            let agent = agents[key];
            if (agent.missionEnd) {
              let ms = Date.parse(agent.missionEnd + '') - Date.now();
              let needUpdate = this.needUpdatesFromTimer(ms, val, this.knownAgents)
              if (needUpdate) {
                this.staffService.updateAgents();
                this.knownAgents = Date.now();
              }
            }
          }
        })
    })
    this.resourceColour = new Map<string, string>();
    this.resourceColour.set("metal", "dark");
    this.resourceColour.set("cristal", "primary");
    this.resourceColour.set("organic", "success");
    this.resourceColour.set("energy", "warning");
  }

  floor(number: number) {
    return ~~(number);
  }

  private initTranslations() {

    this.translatedConstants = new Map<string, string>();
    this.translatedConstants.set('ATTACK', 'Attaque');
    this.translatedConstants.set('SUPPORT', 'Support');
    this.translatedConstants.set('MOVE', 'Déplacement');
    this.translatedConstants.set('DELIVERY', 'Livraison');
    this.translatedConstants.set('BUILD_BASE', 'Nouvelle base');
    this.translatedConstants.set('BUILD_BASE_ALLIANCE', 'Nouvelle base alliance');
    this.translatedConstants.set('INACTIVE', 'Inactive');
    this.translatedConstants.set('STANDBY', 'En veille');
    this.translatedConstants.set('PATROLLING', 'Patrouiller');
    this.translatedConstants.set('MOVING', 'En déplacement');
    this.translatedConstants.set('OUT_OF_GAS', 'Panne de carburant');
    this.translatedConstants.set('TRANSITING', 'En transition');
    this.translatedConstants.set('FIGHTING', 'En combat');
    this.translatedConstants.set('TRANSITING', 'En transition');
    this.translatedConstants.set('DEFAULT', 'Par défaut');
    this.translatedConstants.set('RUSH', 'Se ruer');
    this.translatedConstants.set('ESCAPE', 'Fuir');
    this.translatedConstants.set('CHASE', 'Poursuivre');
    this.translatedConstants.set('CLOSER_RANDOM', 'Le plus proche');
    this.translatedConstants.set('BASE', 'Base');
    this.translatedConstants.set('SPY', 'Espionner');
    this.translatedConstants.set('INFILTRATE', 'Infiltrer');
    this.translatedConstants.set('ARCHITECTURE', 'Connaissances en erchitecture');
    this.translatedConstants.set('BASIC_SCIENCE', 'Connaissances en recherches');
    this.translatedConstants.set('MILITARY_EXPERT', 'Connaissances militaires');
    this.translatedConstants.set('OVERSIGHT', 'Surveillance');
    this.translatedConstants.set('COUNTER_SPYING', 'contre espionnage');
    this.translatedConstants.set('INFILTRATED', 'Infiltré');

    this.translatedConstants.set('BUILD', 'Construction');
    this.translatedConstants.set('RESEARCH', 'Recherche');
    this.translatedConstants.set('BUILD_SHIP', 'Production');
    this.translatedConstants.set('SEARCH', 'Fouille');
    this.translatedConstants.set('AGENT_RECRUIT', 'Recrutement');
    this.translatedConstants.set('FLEET_STATUS', 'Status');
    this.translatedConstants.set('AGENT_MOVE', 'Déplacement');
    this.translatedConstants.set('ACTIVE', 'En mission');
    this.translatedConstants.set('SPOTTING', 'Repérage');
    this.translatedConstants.set('AGENT_MISSION', 'Mission');


    this.translatedConstants.set('Owner', 'Propriétaire');
    this.translatedConstants.set('Leader', 'Chef');
    this.translatedConstants.set('Manager', 'Gestionnaire');
    this.translatedConstants.set('Member', 'Membre');
    this.translatedConstants.set('Newcommer', 'Nouvel arrivant');
  }

  public calculateDistance(pos1: GlobalPosition, pos2: GlobalPosition): number {
    if (pos1 && pos2) {

      let xa = +pos1.coordinates.split(";")[0];
      let ya = +pos1.coordinates.split(";")[1];
      let xb = +pos2.coordinates.split(";")[0];
      let yb = +pos2.coordinates.split(";")[1];
      return Math.round(
        1000 * Math.sqrt(Math.pow(xb - xa, 2) + Math.pow(yb - ya, 2))
      );
    }
    return 0;
  }

  public handleResource(resource, actualAmount?) {
    return "" + this.nFormatter(resource[actualAmount] || resource.clientAmount) + " / " + this.nFormatter(resource.storage) + " " + (resource.production > resource.consumption ? "+" : "") + this.nFormatter(resource.production - resource.consumption) + "/h ";
  }

  public nFormatter(num) {
    let sign = num >= 0 ? "" : "-";
    num = Math.abs(num);
    let si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value * 2) {
        break;
      }
    }
    let digits = Math.floor((num / si[i].value) * 10);
    let tmp = "";
    if (num > si[i].value * 10 || num < 1) {
      tmp = ~~(digits / 10) + "";
    } else {
      tmp = digits / 10.0 + "";
    }
    return sign + tmp.replace(rx, "$1") + si[i].symbol;
  }

  public translateConstants(constantName: string): string {
    return this.translatedConstants.get(constantName) || constantName;
  }

  public closeModalByEvent(source) {
    if (this.instances) {
      this.instances.forEach(instance => {
        let nativeElement = instance['_contentRef'].nodes[0][0]
        let selector = '.' + nativeElement.classList.value.split(' ').join('.')
        if (source.target.closest('.modal').querySelector(selector) === nativeElement) {
          instance.dismiss();
        }
      })
    }
  }

  needUpdatesFromTimer(ms, val, timestamp?): boolean {
    if (ms < 0) { // fix server lag
      if (ms > -5000) { // if event if behind for less than 5 sec => refresh each second
        return true;
      } else if (ms > -60000) {// if event if behind for less than 60 sec => refresh every 10 seconds
        if ((val % 10) === 0) {
          return true;
        }
      } else {  // if event if behind for less than 60 sec => refresh every minutes
        if ((val % 60) === 0) {
          return true;
        }
      }
    }
    if (timestamp && (timestamp + 300000) < Date.now()) { //fix chromium perf where after 5 minutes, timer works only once per minutes
      return true;
    }
    return false;
  }

  originalOrder = (
    a: KeyValue<any, any>,
    b: KeyValue<any, any>
  ): number => {
    return 0;
  };
}
