<div *ngIf="!selectedFleet" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="selectedFleet" class="modal-header d-flex flex-row justify-content-around">
  <div class="d-flex flex-fill">
    <h4 class="modal-title me-3" id="modal-basic-title" i18n="PlanetComponent|Title: fleet moving">
      Déplacement d'une flotte
    </h4>
    <h4 class="modal-title text-danger" *ngIf="!selectedFleet.position" i18n="PlanetComponent|Starting position">
      Position de départ Inconnue => contactez un admin !
    </h4>
  </div>
  <div *ngIf="selectedFleet.position" class="d-flex flex-fill">
    <h4 i18n="PlanetComponent|Starting position">
      Position de départ : <br />
      ({{ selectedFleet.position.coordinates }})
    </h4>
  </div>

  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div *ngIf="fleetMove" (keyup.enter)="sendFleet()" class="modal-body d-flex flex-row">
  <form #form="ngForm" (ngSubmit)="sendFleet()" [formGroup]="frmTravel" novalidate>
    <div>
      <!--Choix de flotte-->
      <label for="fleet" i18n="PlanetComponent|Fleet">Flotte: </label>
      <select (change)="onChangeFleet($event.target['value'])" formControlName="fleet">
        <option *ngFor="let o of fleets" [value]="o.id">
          {{ o.name }}({{ o.power }})
        </option>
      </select>
      <!--Choix de cible-->
      <label for="objective" i18n="PlanetComponent|Goal">Objectif: </label>
      <select formControlName="objective" (change)="onChangeObjective($event.target['value'])">
        <option *ngFor="let o of objectives" [value]="o">
          {{ utilsService.translateConstants(o) }}
        </option>
      </select>
    </div>
    <div>
      <label i18n="PlanetComponent|">Cible :</label>
      <input (change)="onChangeX($event.target, 'selectedTarget')" class="form-control-distance" formControlName="targetX" id="targetCoordsX"
        [max]="planet.size" min="0" placeholder="x" type="number" />
      <input (change)="onChangeY($event.target, 'selectedTarget')" class="form-control-distance" formControlName="targetY" id="targetCoordsY"
        [max]="planet.size" min="0" placeholder="y" type="number" />
      <button (click)="openTargetSelector('selectedTarget')" type="button" class="btn btn-outline-info"
        i18n="PlanetComponent|Button cancel">
        ...
      </button>
    </div>
    <div *ngIf="frmTravel.value.objective === 'ATTACK' || frmTravel.value.objective === 'DELIVERY'">
      <label i18n="PlanetComponent|">Position de retour :</label>
      <input (change)="onChangeX($event.target, 'returnTarget')" class="form-control-distance" formControlName="returnX" id="returnCoordsX"
        [max]="planet.size" min="0" placeholder="x" type="number" />
      <input (change)="onChangeY($event.target, 'returnTarget')" class="form-control-distance" formControlName="returnY" id="returnCoordsX"
        [max]="planet.size" min="0" placeholder="y" type="number" />
      <button (click)="openTargetSelector('returnTarget')" type="button" class="btn btn-outline-info"
        i18n="PlanetComponent|Button cancel">
        ...
      </button>
    </div>
    <!--Detail flotte-->
    <div>
      <label i18n="PlanetComponent|Power">Puissance : {{ selectedFleet.power }}</label>
      <label i18n="PlanetComponent|Power">Nb vaisseaux : {{ selectedFleet.amount }}</label>
      <label i18n="PlanetComponent|Power">Vitesse : {{ selectedFleet.speed }}</label>
      <label i18n="PlanetComponent|Power">Consommation : {{ selectedFleet.consumption }}/h</label>
    </div>

    <div>
      <label i18n="PlanetComponent|Range">Distance : {{ distance }}</label>
      <label i18n="PlanetComponent|Travel time"> Temps(s) : {{ travelTime*1000 | timer: "UTC" }}</label>
    </div>
    <div>
      <label i18n="PlanetComponent|consumption">Consommation : {{ consumption }}/{{ selectedFleet.currentFuel }}</label>
      <img alt="Energie" class="resourceImg" src="/assets/img/resources/energy.png" />
      ({{ autonomy*1000| timer: "UTC" }})

    </div>
    <label *ngIf="frmTravel.value.objective === 'ATTACK'" class="mx-auto d-table"><u>Ratio de pillage</u></label>
    <div *ngIf="frmTravel.value.objective === 'ATTACK'" class="d-flex flex-warp">
      <div *ngFor="let resource of resources | keyvalue" class="col-md resource-item flex-column">
        <label class="d-flex flex-row ">
          <img alt={{resource.key}} class="resourceImg d-flex" src="/assets/img/resources/{{resource.key}}.png"
            title={{resource.key}} />
          <label class="resource-value">{{resource.key}}</label>
          <label [attr.resource]=resource.key
            (click)="onClickOutput($event, resource)">{{fleetMove.pillageRate[resource.key]}}%</label>
        </label>
        <input type="range" class="form-range progress-bar progress-bar-striped"
          [ngClass]="'bg-'+utilsService.resourceColour.get(resource.key)" min="0"
          [value]=fleetMove.pillageRate[resource.key] max="100" [id]=resource.key [attr.resource]=resource.key
          name="resource" (input)=onChange($event)>
      </div>
    </div>
    <button (click)="modal.dismiss('Cancel click')" type="reset" class="btn btn-danger"
      i18n="PlanetComponent|Button cancel">
      Annuler
    </button>
    <button class="btn btn-success" type="button" (click)="sendFleet()" ngbAutoFocus
      i18n="PlanetComponent|Button accept">
      Go !
    </button>
  </form>
</div>
