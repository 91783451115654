import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { UnitDetailComponent } from "src/app/main/fleets/unit-detail/unit-detail.component";
import { User } from "src/app/models";
import { Base } from "src/app/models/base/Base";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { FleetRef } from "src/app/models/Fleet/FleetRef";
import { Ship } from "src/app/models/Ship";
import { Squadron } from "src/app/models/Squadron";
import { AlertService } from "src/app/services/alert/alert.service";
import { BaseService } from "src/app/services/bases/base.service";
import { FleetService } from "src/app/services/fleets/fleet.service";
import { ShipService } from "src/app/services/ships/ship.service";
import { RefuelComponent } from "src/app/main/fleets/refuel/refuel.component";
import { AddNewSquadronComponent } from "./edit-squadron/add-new-squadron/add-new-squadron.component";
import { AddToSquadronComponent } from "./edit-squadron/add-to-squadron/add-to-squadron.component";
import { EditSquadronComponent } from "./edit-squadron/edit-squadron/edit-squadron.component";
import { RemoveSquadronComponent } from "./edit-squadron/remove-squadron/remove-squadron.component";
import { SplitSquadronComponent } from "./edit-squadron/split-squadron/split-squadron.component";
import { Alert } from "src/app/models/Alert";
import { UtilsService } from "src/app/services/Utils/utils.service";

@Component({
  selector: "app-formation-detail",
  templateUrl: "./formation-detail.component.html",
  styleUrls: ["./formation-detail.component.scss"],
})
export class FormationDetailComponent implements OnInit {


  @Input()
  public getMainFleet: Observable<Fleet>;

  @Input()
  public getCurrentUnits;

  @Input()
  public formationMaxLines: number;

  @Input()
  public isTurrets: boolean;

  @Input()
  public user: User;

  mainFleet: Fleet;
  public selectedFleetBase: Base;
  unitsRef: Ship[] = [];
  public secondaryFleet: Fleet;

  public localFleets: FleetRef[];

  public selectedFleet: Fleet;
  public sourceFleet: Fleet;

  selectedUnit: Ship;
  sourceSquadron: Squadron;
  isNameUpdating: boolean;
  isNameUpdatingSecondary: boolean;
  isStatusUpdating: boolean;
  isStatusUpdatingSecondary: boolean;
  isFuelUpdating: boolean;
  isFuelUpdatingSecondary: boolean;
  canRefuel: boolean;
  editedStatus: string;
  editedFuel: string;

  readonly status: string[] = ["INACTIVE", "STANDBY", "PATROLLING"];
  readonly fuelCodes: string[] = ["energy", "organic"];
  readonly modalType = {
    CLICK_PLUS: 0, // click on + button
    BASE_PLUS: 1, // drop ship from Base to +
    BASE_SQUADRON: 2, // drop ship from Base to Squadron
    SQUADRON_BASE: 3, // drop ship from Squadron to Base
    SQUADRON_PLUS: 4, // drop ship from squadron to +
    SQUADRON_SQUADRON: 5, // drop ship from Squadron to another Squadron
    CLICK_MINUS: 6, // click on - button on Base
  };
  constructor(
    private readonly modalService: NgbModal,
    private readonly shipService: ShipService,
    private readonly fleetService: FleetService,
    private readonly baseService: BaseService,
    private readonly alertService: AlertService,
    public readonly utilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.canRefuel = false;
    this.getMainFleet.subscribe((fleet) => {
      this.mainFleet  = fleet;
      if (this.mainFleet.currentBaseId) {
        if (this.user && Object.keys(this.user.baseListId).includes(this.mainFleet.currentBaseId)) {
          this.getCurrentUnits = this.shipService[this.getCurrentUnits](fleet.currentBaseId);
          this.loadBase();
        }
      }
      if(!this.isTurrets) {
        this.loadSecondaryFleet();
      }
    });
  }

  public onChangeFleet(value): void {
    this.fleetService.getFleetById(value).subscribe((fleet) => {
      this.mainFleet = fleet;
    });
  }

  public loadSecondaryFleet(): void {
    this.fleetService.getFleetsByFleetPos(this.mainFleet.id).subscribe((fleets) => {
      let localFleets: FleetRef[] = [];
      fleets.forEach((fleet) => {
        if ((fleet.type == "DEFAULT") && (fleet.id !== this.mainFleet.id) &&(fleet.ownerId === this.mainFleet.ownerId)) {
          localFleets.push(fleet);
        }
      });
      if (localFleets.length > 0) {
        this.localFleets = localFleets;
        this.onChangeFleetSecondary(localFleets[0].id);
      }
    });
  }

  public onChangeFleetSecondary(value): void {
    this.fleetService.getFleetById(value).subscribe((fleet) => {
      this.secondaryFleet = fleet;
      this.canRefuel = true;
    });
  }

  loadBase() {
    this.baseService
      .getBaseById(this.mainFleet.currentBaseId)
      .subscribe((base) => {
        this.selectedFleetBase = base;
        //modal.componentInstance.formationMaxLines = this.selectedFleetBase.effects['FLEET_MAX_LINE'] || 1;
        this.refreshUnit();
        if(!this.isTurrets) {
          this.canRefuel = true;
        }
      });
  }

  refreshUnit() {
    this.getCurrentUnits.subscribe((units) => {
      this.unitsRef = units;
    });
  }

  reloadFleets() {
    this.fleetService.getFleetById(this.mainFleet.id).subscribe((fleet) => {
      this.mainFleet = fleet;
    });
    if (this.secondaryFleet) {
      this.fleetService.getFleetById(this.secondaryFleet.id).subscribe((fleet) => {
        this.secondaryFleet = fleet;
      });
    }
  }

  selectUnitToMove(event, unit) {
    event.target.classList.remove("dragHover");
    //event.dataTransfer.setData('text','test');
    this.selectedUnit = unit;
    this.sourceSquadron = null;
  }

  selectSquadToMove(squad) {
    this.sourceSquadron = squad;
    this.sourceFleet = this.selectedFleet;
    this.selectedUnit = null;
  }

  removeSquad(event) {
    event.preventDefault();
    event.target.classList.remove("dragHover");
    this.selectedFleet = (this.selectedFleet || this.mainFleet);
    if (Object.keys(this.selectedFleet.squadrons).length > 0) {
      if (!this.selectedUnit) {
        let modal = this.modalService.open(RemoveSquadronComponent, {
          ariaLabelledBy: "fleet-battleGroup",
          scrollable: true,
          size: "sm",
          windowClass: "fleet-remove-squadron backModalImg",
          backdrop: true,
        });
        modal.componentInstance.selectedFleet = this.selectedFleet;
        modal.componentInstance.availableShips = this.selectedFleet.squadrons;
        modal.componentInstance.selectedShip = this.sourceSquadron;

        modal.result.catch((reason) => {
          if (reason && reason.msg)
            this.alertService.addAlert(reason);
        });
        modal.hidden.subscribe((reason) => {
          this.selectedUnit = null;
          this.sourceSquadron = null;
          this.reloadFleets();
          this.refreshUnit();
        });
      }
    } else {
      let alert = new Alert();
      alert.type = "warning";
      alert.msg = "on ne peut pas enlever un escadron quand il n'y en a pas";
      alert.timeout = 10000;
      this.alertService.addAlert(alert);
    }
  }

  addSquad(event, selectedZone, fleet: Fleet) {
    event.preventDefault();
    event.target.classList.remove("dragHover");
    let modal = this.modalService.open(AddNewSquadronComponent, {
      ariaLabelledBy: "fleet-battleGroup",
      scrollable: true,
      size: "sm",
      windowClass: "fleet-add-squadron backModalImg",
      backdrop: true,
    });
    modal.componentInstance.selectedFleet = fleet;
    modal.componentInstance.selectedZone = selectedZone;

    if (!this.sourceSquadron && this.unitsRef.length > 0) {
      modal.componentInstance.selectedShip = this.selectedUnit;
      modal.componentInstance.selectedFleetBase = this.selectedFleetBase;
      modal.componentInstance.availableShips = this.unitsRef;
    } else {
      if(!this.sourceSquadron) {
        if(!this.sourceFleet || !this.sourceFleet.squadrons[0]) {
          let alert = new Alert();
          alert.type = "warning";
          alert.msg = "on ne peut pas ajouter un escadron quand il n'y a pas de vaisseaux disponibles";
          alert.timeout = 10000;
          this.alertService.addAlert(alert);
          modal.dismiss();
        }
      }
      modal.componentInstance.availableShips = this.sourceFleet.squadrons;
      modal.componentInstance.selectedShip = this.sourceSquadron;
      modal.componentInstance.sourceSquadron = this.sourceSquadron;
      modal.componentInstance.sourceFleetId = this.sourceFleet.id;
    }
    modal.result.catch((reason) => {
      if (reason && reason.msg)
        this.alertService.addAlert(reason);
    });
    modal.hidden.subscribe((reason) => {
      this.selectedUnit = null;
      this.sourceSquadron = null;
      this.reloadFleets();
      this.refreshUnit();
    });
  }

  dragOnSquad(event, selectedZone: number, squadId: string, fleet: Fleet) {
    event.preventDefault();
    event.target.classList.remove("dragHover");
    let targetSquadron = fleet.squadrons[squadId];

    if (this.selectedUnit) {   //Transfert depuis la base
      if (targetSquadron) {//base => squadron
        if (targetSquadron.shipId == this.selectedUnit.id) {
          this.sourceSquadron = new Squadron();
          let modal = this.modalService.open(AddToSquadronComponent, {
            ariaLabelledBy: "modal-basic-title",
            scrollable: true,
            size: "sm",
            windowClass: "fleet-edit-squadron backModalImg",
            backdrop: true,
          });
          modal.componentInstance.selectedFleet = fleet;
          modal.componentInstance.availableShips = [this.selectedUnit];
          modal.componentInstance.sourceSquadron = this.sourceSquadron;
          modal.componentInstance.targetSquadron = targetSquadron;

          modal.result.then(
            (result) => {
              this.reloadFleets();
              this.refreshUnit();
            },
            () => { }
          );
          modal.hidden.subscribe((reason) => {
            this.selectedUnit = null;
            this.sourceSquadron = null;
          });
        } else { // si les 2 vaisseaux sont différents, on génère une alerte, et on quitte.
          let alert = new Alert();
          alert.type = "warning";
          alert.msg = "on ne peut pas mettre 2 unités différentes dans le même escardon";
          alert.timeout = 10000;
          this.alertService.addAlert(alert);
          return;
        }
      }
    } else if (this.sourceSquadron) {      //Transfert depuis un escadron
      if (targetSquadron) { // déplacement d'un squadron vers un autre squadron
        if (targetSquadron.techId == this.sourceSquadron.techId) {
          return;
        }
        if (targetSquadron.shipId == this.sourceSquadron.shipId) {
          let modal = this.modalService.open(SplitSquadronComponent, {
            ariaLabelledBy: "modal-basic-title",
            scrollable: true,
            size: "sm",
            windowClass: "fleet-edit-squadron backModalImg",
            backdrop: true,
          });
          modal.componentInstance.selectedFleet = fleet;
          modal.componentInstance.sourceFleetId = this.sourceFleet.id;
          modal.componentInstance.sourceSquadron = this.sourceSquadron;
          modal.componentInstance.targetSquadron = targetSquadron;
          modal.componentInstance.availableShips = this.sourceFleet.squadrons;
          modal.componentInstance.selectedShip = this.sourceSquadron;

          modal.result.then(
            (result) => {
              this.reloadFleets();
            },
            () => { }
          );
          modal.hidden.subscribe((reason) => {
            this.selectedUnit = null;
            this.sourceSquadron = null;
          });
        } else { // si les 2 vaisseaux sont différents, on génère une alerte, et on quitte.
          let alert = new Alert();
          alert.type = "warning";
          alert.msg = "on ne peut pas mettre 2 vaisseaux différents dans le même escardon";
          alert.timeout = 10000;
          this.alertService.addAlert(alert);
          return;
        }
      }
    }
  }

  editGroup(squadron: Squadron) {
    let modal = this.modalService.open(EditSquadronComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "sm",
      windowClass: "fleet-edit-squadron backModalImg",
      backdrop: true,
    });
    modal.componentInstance.selectedFleet = this.selectedFleet;
    modal.componentInstance.availableShips = [squadron];
    modal.componentInstance.targetSquadron = squadron;

    modal.result.then(
      (result) => {
        this.reloadFleets();
      },
      () => { }
    );
    modal.hidden.subscribe((reason) => {
      this.selectedUnit = null;
      this.sourceSquadron = null;
    });
  }

  dragenter(event) {
    if (event.target.classList)
      event.target.classList.add("dragHover");
  }

  dragleave(event) {
    if (event.target.classList)
      event.target.classList.remove("dragHover");
  }

  editFleetName() {
    this.fleetService
      .changeName(this.selectedFleet.name, this.selectedFleet.id)
      .subscribe(() => {
        this.isNameUpdating = false;
        this.isNameUpdatingSecondary = false;
      });
  }

  selectFleet(fleet: Fleet) {
    this.selectedFleet = fleet;
  }

  editFleetStatus(event) {
    if (this.editedStatus !== this.selectedFleet.status) {
      this.fleetService
        .changeStatus(this.editedStatus, this.selectedFleet.id)
        .subscribe(() => {
          this.isStatusUpdating = false;
          this.isStatusUpdatingSecondary = false;
          this.selectedFleet.status = "TRANSITING ";
          this.utilsService.closeModalByEvent(event);
        });
    } else {
      this.isStatusUpdating = false;
      this.isStatusUpdatingSecondary = false;
      this.editedStatus = this.selectedFleet.status;
    }
  }

  editFleetFuel(event) {
    if (this.editedFuel !== this.selectedFleet.fuelCode) {
      this.fleetService
        .changeFuel(this.selectedFleet.id,this.editedFuel)
        .subscribe(() => {
          this.isFuelUpdating = false;
          this.isFuelUpdatingSecondary = false;
          this.selectedFleet.fuelCode = this.editedFuel;
          this.utilsService.closeModalByEvent(event);
        });
    } else {
      this.isFuelUpdating = false;
      this.isFuelUpdatingSecondary = false;
      this.editedFuel = this.selectedFleet.fuelCode;
    }
  }

  unitDetailModal;
  unitDetails(unit: Ship) {
    if (!this.unitDetailModal) {
      this.unitDetailModal = this.modalService.open(UnitDetailComponent, {
        ariaLabelledBy: "ShipDetail",
        scrollable: true,
        size: "lg",
        windowClass: "fleet-ShipDetail backModalImg",
        backdrop: false,
      });
    }
    this.unitDetailModal.componentInstance.addUnit(unit);
    this.unitDetailModal.hidden.subscribe(() => {
      this.unitDetailModal = null;
    });
  }

  cleanAfterDrag() {
    this.selectedUnit = null;
    this.sourceSquadron = null;
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  refuel(fleetType){
    let modal = this.modalService.open(RefuelComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "sm",
      windowClass: "fleet-refuel backModalImg",
      backdrop: true,
    });
    if(fleetType === 'mainFleet') {
    modal.componentInstance.fleet = this.mainFleet;
    modal.componentInstance.sourceFleet = this.secondaryFleet;
  } else {
    modal.componentInstance.fleet = this.secondaryFleet;
    modal.componentInstance.sourceFleet = this.mainFleet;
  }
    modal.componentInstance.base = this.selectedFleetBase;

    modal.result.then(
      (result) => {
        this.reloadFleets();
        if (this.mainFleet.currentBaseId) {
          this.baseService.updateBase();
        }
      },
      () => {}
    );
    modal.hidden.subscribe((reason) => {
      this.selectedUnit = null;
      this.sourceSquadron = null;
    });
  }
}
