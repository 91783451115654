import { Component, OnDestroy } from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { Alert } from "src/app/models/Alert";
import { ResourceCumul } from "src/app/models/base/resources/ResourceCumul";
import { Market } from "src/app/models/Market/Market";
import { Offer } from "src/app/models/Market/Offer";
import { Ship } from "src/app/models/Ship";
import { AlertService } from "src/app/services/alert/alert.service";
import { BaseService } from "src/app/services/bases/base.service";
import { MarketService } from "src/app/services/Market/market.service";
import { OptionsService } from "src/app/services/options/options.service";
import { ShipService } from "src/app/services/ships/ship.service";
import { UsersService } from "src/app/services/Users/user.service";

@Component({
  selector: "app-new-offer",
  templateUrl: "./createOffer.component.html",
  styleUrls: ["./createOffer.component.scss"],
})
export class CreateOfferComponent implements OnDestroy {
  private readonly destroy$ = new Subject();
  private debug: boolean = false;
  public markets: Market[];
  public types = ['SHIP', 'RESOURCE'];
  public offer: Offer = new Offer();
  resources: Map<string, ResourceCumul> = new Map<string, ResourceCumul>();
  units: Ship[] = [];
  maxAmount: number = 0;


  constructor(
    private readonly modalService: NgbModal,
    private readonly marketService: MarketService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    private readonly optionsService: OptionsService,
    private readonly shipService: ShipService,
    private readonly alertService: AlertService,
    public readonly modal: NgbActiveModal,
  ) {
    this.offer.baseId = this.baseService.getCurrentBaseId();
    this.offer.ownerId = this.userService.currentUserId();
    baseService.resourcesUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedResources) => {
        if (updatedResources.size > 0) {
          this.resources = updatedResources;
          if (this.offer.type === 'RESOURCE') {
            for (let resource of updatedResources) {
              if (resource[0] === this.offer.productId) {
                this.maxAmount = resource[1].clientAmount;
              }
            }
          }
        }
      });
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  createOffer() {
    this.offer.marketId = document.getElementById('marketSelector')['value'];
    let alert = new Alert();
    alert.type = "danger";
    alert.msg = "";
    alert.timeout = 10000;
    if (!this.offer.type) {
      alert.msg = 'il faut sélectionner une ressource à vendre'
      this.alertService.addAlert(alert);
    } else if (this.offer.amount < 1 || this.offer.amount > this.maxAmount) {
      alert.msg = "La quantité de l'offre ne correspond pas aux quantités disponibles"
      this.alertService.addAlert(alert);
    } else if (this.offer.unitPrice < 1) {
      alert.msg = "le prix unitaire minimum est de 1"
      this.alertService.addAlert(alert);
    } else {
      this.marketService.createOffer(this.offer).subscribe((offers) => {
        this.modal.dismiss();
      })
    }
  }
  confirmCancelModal: NgbModalRef;
  openUnitChoice(modal) {
    this.units = [];
    if (!this.confirmCancelModal) {
      this.confirmCancelModal = this.modalService
        .open(modal, {
          ariaLabelledBy: "open_unitChoice",
          scrollable: true,
          windowClass: "open_unitChoice",
        });
      this.confirmCancelModal.hidden.subscribe(() => {
        this.confirmCancelModal = null;
      });
    }
    if (this.offer.sell) {
      this.shipService.getBuiltTurrets(this.offer.baseId).subscribe((units) => {
        for (let unit of units) {
          this.units.push(unit);
        }
      })
      this.shipService.getBuiltShips(this.offer.baseId).subscribe((units) => {
        for (let unit of units) {
          this.units.push(unit);
        }
      })
    } else {
      this.shipService.knownShipsUpdate$.subscribe((units) => {
        for (let unit of units) {
          this.units.push(unit);
        }
      })
    }
  }
  confirmChoice(unit) {
    if (unit.id) {
      this.offer.type = 'SHIP';
      this.offer.productId = unit.id;
      this.offer.productName = unit.name;
      this.maxAmount = unit.amount;
    } else if (unit.value.clientAmount) {
      this.offer.type = 'RESOURCE';
      this.offer.productId = unit.key;
      this.offer.productName = unit.key;
      this.maxAmount = unit.value.clientAmount;

    } else {
      this.offer.type = '';
      this.offer.productId = '';
      this.offer.productName = '';
      this.maxAmount = 0;
    }
    this.confirmCancelModal.dismiss();
    document.getElementsByClassName('open_unitChoice')[0].classList.add('hidden');
  }

  checkMinMax(event) {
    if (event.target.max) {
      event.target.value = Math.min(event.target.value, event.target.max)
    }
    event.target.value = Math.max(event.target.value, event.target.min)
    this.offer[event.target.id] = +event.target.value;
  }
}
