import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { MainComponent } from "src/app/main//main.component";
import { AuthenticationService } from "src/app/services/Authentication/authentication.service";
import { UsersCreateComponent } from "src/app/main//users/users-create/users-create.component";
import { StartupComponent } from "src/app/main//startup/startup.component";
import { FightDetailComponent } from "src/app/main/message-box/report/fight-detail/fight-detail.component";
import { SpyReportComponent } from "src/app/main/message-box/report/spy-report/spy-report.component";
import { TechnoTreeComponent } from "src/app/main//technoTree/technoTree.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "main",
    component: MainComponent,
    children: MainComponent.routes,
    canActivate: [AuthenticationService],
    title: "Need a Name",
    data: {
      roles: ["user"]
    }
  },
  {
    path: "users/create",
    component: UsersCreateComponent,
    canActivate: [AuthenticationService],
    title: "Création du joueur",
    data: {
      roles: ["user"]
    }
  },
  {
    path: "login",
    component: StartupComponent,
    title: "Need a Name",
    data: {
      //roles: ["user"]
    }
  },
  {
    path: "fight/:id",
    component: FightDetailComponent
  },
  {
    path: "spy/:id",
    component: SpyReportComponent
  },
  {
    path: "technologytree",
    component: TechnoTreeComponent,
    title: "Arbre Technologique",
    data: {
      roles: ["user"]
    }
  },
  // otherwise redirect to home
  { path: "**", redirectTo: "login" },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
