import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalPosition } from 'src/app/models/GlobalPosition';
import { Planet } from 'src/app/models/Planet/Planet';
import { Agent } from 'src/app/models/Staff/Agent';
import { EventService } from 'src/app/services/event/event.service';
import { PlanetsService } from 'src/app/services/Planets/planets.service';
import { StaffService } from 'src/app/services/Staff/staff.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';
import { TargetSelectorComponent } from '../../targetSelector/TargetSelector.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-agent-move',
  templateUrl: './agent-move.component.html',
  styleUrls: ['./agent-move.component.scss']
})
export class AgentMoveComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();

  agent: Agent;
  objectives = [
    "MOVE",
    "INFILTRATE"
  ];
  selectedTarget: GlobalPosition;
  public frmTravel: FormGroup;
  public planet: Planet;


  constructor(
    private readonly staffService: StaffService,
    private readonly fb: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly planetService: PlanetsService,
    public readonly modal: NgbActiveModal,
    public readonly utilsService: UtilsService,
    public readonly eventService: EventService,
  ) {
    this.planetService.planetUpdate$
    .pipe(takeUntil(this.destroy$))
    .subscribe((planet) => {
      if (planet) {
        this.planet = planet;
      }
    })
  }

  ngOnInit(): void {
    this.selectedTarget = new GlobalPosition();
    this.selectedTarget.coordinates = this.agent.position.coordinates;
    this.selectedTarget.planetId = this.agent.position.planetId;
    let x: number;
    let y: number;
    if (this.selectedTarget) {
      x = +this.selectedTarget.coordinates.split(";")[0];
      y = +this.selectedTarget.coordinates.split(";")[1];
    }
    this.frmTravel = this.fb.group({
      objective: ["INFILTRATE", Validators.required],
      targetX: [x, Validators.required],
      targetY: [y, Validators.required],
    });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  public onChangeX(event, target): void {
    event.value = +event.value;
    event.value = Math.max(event.min, event.value)
    event.value = Math.min(event.max, event.value)
    this.selectedTarget.coordinates = event.value + ";" + this.selectedTarget.coordinates.split(";")[1];
    this.frmTravel.controls.targetX.setValue(event.value);

  }

  public onChangeY(event, target): void {
    event.value = +event.value;
    event.value = Math.max(event.min, event.value)
    event.value = Math.min(event.max, event.value)
    this.selectedTarget.coordinates = this.selectedTarget.coordinates.split(";")[0] + ";" + event.value;
    this.frmTravel.controls.targetY.setValue(event.value);
  }

  openTargetSelector(target) {
    let targetSelector = this.modalService.open(TargetSelectorComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "base_openBuilding backModalImg",
    });

    targetSelector.componentInstance.objectToMove = this.agent;
    targetSelector.closed.subscribe((reason) => {
      if (reason && reason["coordinates"]) {
        let coords = reason["coordinates"].split(";")
        this.selectedTarget.planetId = reason["planetId"];
        this.selectedTarget.coordinates = reason["coordinates"];
        this.frmTravel.controls.targetX.setValue(coords[0]);
        this.frmTravel.controls.targetY.setValue(coords[1]);
      }
    })
  }



  onSubmit() {
    this.staffService.moveAgent(this.agent.id, this.frmTravel.value.objective, this.selectedTarget).subscribe((event) => {
      this.eventService.updatePlayerEventNeeded();
      this.modal.dismiss('complete');
    });
  }
}
