import { Component, OnDestroy, OnInit } from "@angular/core";
import { Routes } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
// import { MultiPolygon } from '@turf/helpers/dist/js/lib/geojson.d';
import union from "@turf/union";
import Stats from 'node_modules/three/examples//jsm/libs/stats.module.js';
import * as Controls from 'node_modules/three/examples/jsm/controls/OrbitControls';
import * as GUI from 'node_modules/three/examples/jsm/libs/lil-gui.module.min.js';
import * as Renderer from 'node_modules/three/examples/jsm/renderers/CSS2DRenderer';
import { Subject, take, takeUntil } from "rxjs";
import { ShowProfileComponent } from "src/app/main/users/profile/show-profile/show-profile.component";
import { Alert } from "src/app/models/Alert";
import { Base } from "src/app/models/base/Base";
import { BaseRef } from "src/app/models/base/BaseRef";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { GlobalPosition } from "src/app/models/GlobalPosition";
import { Planet } from "src/app/models/Planet/Planet";
import { Tile } from "src/app/models/Planet/Tile";
import { AlertService } from "src/app/services/alert/alert.service";
import { BaseService } from "src/app/services/bases/base.service";
import { EventService } from "src/app/services/event/event.service";
import { FleetService } from "src/app/services/fleets/fleet.service";
import { OptionsService } from "src/app/services/options/options.service";
import { PlanetsService } from "src/app/services/Planets/planets.service";
import { RummageService } from "src/app/services/Rummage/rummage.service";
import { UsersService } from "src/app/services/Users/user.service";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { TimerPipe } from "src/app/timer.pipe";
import * as THREE from 'three';
import ThreeGlobe from 'three-globe';
import { FleetTravelComponent } from "../fleets/fleets/fleet-travel/fleet-travel.component";


@Component({
  selector: "app-planets",
  templateUrl: "./planets.component.html",
  styleUrls: ["./planets.component.scss"],
})
export class PlanetsComponent implements OnInit, OnDestroy {
  static routes: Routes = [{ path: "", component: PlanetsComponent }];
  private readonly destroy$ = new Subject();
  // public planet3D: boolean = false;
  public debug: boolean = false;
  renderers: THREE.Renderer[];
  selectedBase: BaseRef;
  currentBase: Base;
  closeResult: string;
  hasClickDrag: boolean = false;
  filters = [
    { id: "USER_", value: "Joueurs", selected: false }, // player
    { id: "GOV_FREE", value: "bases libres", selected: false }, // free bases
    { id: "GOV_MAIN", value: "Capitales", selected: false }, // capital base
    { id: "NPC_", value: "PNJs", selected: false }, // NPC
    { id: "CAPTURABLE", value: "Capturables", selected: false }, // NPC
    { id: "BUILDING", value: "En construction", selected: false }, // NPC
    { id: "ALLY", value: "Bases alliances", selected: false }, // NPC
    { id: "", value: "Toutes", selected: true }, // all
  ];
  baseTypes: Map<string, object> = new Map<string, object>();
  tooltipHTML;
  Globes: ThreeGlobe[];

  tilesData: Map<string, { lng: number, lat: number, altitude: number, material: THREE.Material, position: GlobalPosition, }[]>
    = new Map<string, { lng: number, lat: number, altitude: number, material: THREE.Material, position: GlobalPosition, }[]>;

  //fleets: Fleet[];
  // fleetsData = [];
  camera: THREE.PerspectiveCamera;
  tbControls: Controls.OrbitControls;
  load3DObjects = {};
  readonly COLORS = ['gray', 'red', 'green', 'blue'];

  readonly texture = new THREE.TextureLoader().load('/assets/img/base.png', (event) => { event.anisotropy = 0; })
  // readonly materials: THREE.Material[] = this.COLORS.map((color, index) => new THREE.MeshLambertMaterial({ color, opacity: (index == 0 ? 0.01 : 0.4), transparent: true }));
  readonly materials: THREE.Material[] = [];
  tiles: Planet["tiles"];
  planets: Planet[];
  mapLink: Map<string, GlobalPosition> = new Map<string, GlobalPosition>();
  stats = new Stats();

  constructor(
    private readonly modalService: NgbModal,
    private readonly planetService: PlanetsService,
    private readonly baseService: BaseService,
    private readonly fleetService: FleetService,
    private readonly optionsService: OptionsService,
    private readonly alertService: AlertService,
    private readonly userService: UsersService,
    private readonly rummageService: RummageService,
    private readonly eventService: EventService,
    private readonly timerPipe: TimerPipe,
    private readonly utilsService: UtilsService,
  ) { }

  gui: GUI.GUI;
  testGUI(event) {
    if (event.property) {
      if (event.value) {
        event.controller.parent.children.forEach((child) => {

          if (!child.property) {
            child.object[child.property] = (false)
            child.updateDisplay()
          }
        })
      }
    } else {
      if (event.value) {
        event.controller.parent.children.forEach((child) => {
          if (child.property) {
            child.object[child.property] = (false)
            child.updateDisplay()
          }
        })
      }
    }
    this.filter3D(event.controller.parent)
  }

  ngOnInit() {

    let filters = this.filters;
    this.gui = new GUI.GUI({ container: document.getElementById('sub-content') });
    let folder = this.gui.addFolder('Filter les bases');
    for (let filter of filters) {
      let tmp = {};
      tmp[filter.id] = filter.selected;
      folder.add(tmp, filter.id).name(filter.value);
    }
    this.gui.open();
    this.gui.onChange((event) => this.testGUI(event));
    this.gui.$title.textContent = 'Controles';
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === 'true';
          if (this.debug) {
            document.getElementById('globeViz').appendChild(this.stats.dom);
          } else {
            document.getElementById('globeViz').removeChild(this.stats.dom)
          }
        }
      });
    this.debug = this.optionsService.getOption('debug');
    if (this.debug) {
      document.getElementById('globeViz').appendChild(this.stats.dom);
    }
    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.currentBase = base;
        }
      })
    this.planetService.universeUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((planets) => {
        if (planets) {
          if (this.planets) {
            planets.forEach((planet) => {
              let globalPlanet = this.planets.filter((plan) => plan.id === planet.id)
              if (globalPlanet.length == 0) {
                this.planets.push(planet);
              }
            })
          } else {
            this.planets = planets;
          }
          if (!this.Globes) {
            this.buildPlanet3D();
          }
        }
      })
    this.planetService.planetUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((planet) => {
        if (planet) {
          if (this.Globes) {
            let globe = this.Globes.filter((globe) => globe['planetId'] === planet.id)
            globe[0]['size'] = planet.size;
          }
          let planetRef = this.planets.filter((planetRef) => planetRef.id === planet.id)
          if (planetRef.length > 0) {
            for (let property in planet) {
              planetRef[0][property] = planet[property]
            }
          }
        }
      })
    if (this.currentBase) {
      this.planetService.getPlanetById(this.currentBase.id);
    }
    /*this.fleetService.getAllFleets().subscribe((fleets) => {
      if (fleets.length > 0) {
        this.fleets = fleets
      }
    })/** */
    this.tooltipHTML = document.getElementById('tooltip');
    this.tooltipHTML.querySelector('#text').addEventListener('click', (event) => this.tooltipClick(event));
  }

  private spiral(index): number[][] {
    let table = []
    let x = 0, dx = 0, y = 0
    let dy = -1;
    let max = Math.sqrt(index);
    table.push([x, y])
    for (let i of Array(index)) {
      if (x == y || (x < 0 && x == -y) || (x > 0 && x == 1 - y)) {
        let dxx = dx;
        dx = -dy;
        dy = dxx;
      }
      x += dx;
      y += dy;
      table.push([x, y])
    }
    return table;
  }

  ngOnDestroy() {
    // also this.destroy$.subscribe(destroy$); l238
    this.destroy$.next("");
    this.destroy$.complete();
    if (this.Globes)
      this.Globes.every((globe => globe.clear()));
    if (this.gui)
      this.gui.destroy()
    if (this.tbControls)
      this.tbControls.dispose()
  }
  findType(object, type) {
    object.children.forEach((child) => {
      if (child.type === type) {
      }
      this.findType(child, type);
    });
  }
  littleFireMixer;
  moonMassLabel = [];
  buildPlanet3D() {
    /* let loader = new GLTFLoader.GLTFLoader();
     loader.load('/assets/models/spaceships/StarSparrow/StarSparrow.gltf', (gltf) => {
       this.load3DObjects['spaceships'] = gltf
       this.load3DObjects['spaceships'].scene.children[0].scale.copy(new THREE.Vector3(0.2, 0.2, 0.2))
       //  this.load3DObjects['base'].scene.children[0].material.emissive.setRGB({ r: 255, b: 255, g: 255 });
     });
     loader.load('/assets/models/fire/littlefire.gltf', (gltf) => {
       this.load3DObjects['littlefire'] = gltf
       this.littlefireMixer = new THREE.AnimationMixer(gltf.scene);
     });
     loader.load('/assets/models/base_test/BusGameMap.gltf', (gltf) => {
       this.load3DObjects['base'] = gltf
       this.load3DObjects['base'].scene.children[0].scale.copy(new THREE.Vector3(0.0001, 0.0001, 0.0001))
     });/** */
    let self = this;

    this.baseTypes = new Map<string, object>();
    this.baseTypes.set('UNKNOWN', ['white']);
    this.baseTypes.set('GOV_MAIN', ['red']);
    this.baseTypes.set("USER_SECONDARY", ['brown']);
    this.baseTypes.set("USER_MAIN", ['blue']);
    this.baseTypes.set("BUILDING", ['yellow']);
    this.baseTypes.set("CAPTURABLE", ['green']);
    this.baseTypes.set("GOV_FREE", ['magenta']);
    this.baseTypes.set("NPC_AUTOMATIC", ['aqua']);
    this.baseTypes.set("NPC_MAIN", ['chartreuse']);
    this.baseTypes.set("ALLY_MAIN", ['coral']);

    for (let baseType of this.baseTypes.keys()) {
      let baseMaterial = new THREE.MeshBasicMaterial({ map: this.texture, transparent: true, opacity: (1), reflectivity: 1, color: this.baseTypes.get(baseType)[0] })
      this.baseTypes.set(baseType, [this.materials.length]);
      this.materials.push(baseMaterial)
    }


    this.moonMassLabel.push({
      lat: 0,
      lng: 0,
      color: 'white',
      ownerName: 'zam',
      ownerId: 'zam',
      name: 'zam"s base',
      position: '50;50',
      type: "USER_MAIN"
    })

    this.Globes = [];
    let planetFolder = this.gui.addFolder('Planètes');
    let guiElement = {};
    guiElement['Ma base'] = function () {
      self.findMyBase();
    }
    this.gui.add(guiElement, 'Ma base')
    guiElement = {};
    guiElement['Reset de l\'historique des fouilles'] = function () {
      self.cleanRummageHistory();
    }
    this.gui.add(guiElement, 'Reset de l\'historique des fouilles')
    let matrixPosition = this.spiral(this.planets.length);
    for (let index in this.planets) {
      let planet = this.planets[index];
      let globe = new ThreeGlobe({ 'animateIn': true })
        //  .showGraticules(true)
        .showAtmosphere(true)
        .atmosphereColor(planet.atmoColor)
        .atmosphereAltitude(0.3)

        .globeImageUrl('/assets/img/planets/' + planet.name + '.png')
        //   .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')

        .tilesTransitionDuration(0)
        .tileAltitude(d => d['altitude'] || 0.0045)
        .tileMaterial('material')
      globe.position.x = 500 * matrixPosition[+index][0];
      globe.position.z = 500 * matrixPosition[+index][1];
      this.Globes.push(globe);
      this.Globes[index]['planetId'] = planet.id;
      this.Globes[index]['size'] = planet.size;
      this.tilesData.set(planet.id, []);
      //GUI: un bouton pour chaque planète
      let guiElement = {};
      guiElement[planet.name] = function () {
        self.initRenderPlanet(globe.id, globe.position);
      }
      planetFolder.add(guiElement, planet.name)
    }

    // Setup renderers
    let renderers = [new THREE.WebGLRenderer(), new Renderer.CSS2DRenderer()];

    renderers.forEach((r, idx) => {
      r.setSize(window.innerWidth, window.innerHeight);
      document.getElementById('globeViz').appendChild(r.domElement);
    });

    renderers[0].domElement.style.position = 'absolute';


    // Setup scene
    let scene = new THREE.Scene();
    let center = new THREE.Object3D();
    //center.visible = false
    this.Globes.every(Globe => center.add(Globe));
    scene.add(center);
    scene.add(new THREE.AmbientLight(0xffffff));
    let light = new THREE.DirectionalLight(0xffffff, 0.5);
    scene.add(light.clone());
    light.position.set(0, -1, 0);
    scene.add(light.clone());
    light.position.set(1, 0, 0);
    scene.add(light.clone());
    light.position.set(-1, 0, 0);
    scene.add(light.clone());
    light.position.set(0, 0, 1);
    scene.add(light.clone());
    light.position.set(0, 0, -1);
    scene.add(light.clone());
    // Setup camera
    this.camera = new THREE.PerspectiveCamera();
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.camera.position.y = 1500;
    this.camera.far = 10000;
    //let camera = this.camera;
    // Add camera controls
    this.tbControls = new Controls.OrbitControls(this.camera, renderers[0].domElement);
    // let tbControls = this.tbControls;
    this.tbControls.minDistance = 101;
    this.tbControls.rotateSpeed = 0.4;
    this.tbControls.zoomSpeed = 0.8;
    // tbControls.enableRotate = false;
    this.tbControls.noPan = true;
    // tbControls.enablePan = false;

    // Update pov when camera moves
    this.Globes.every(globe => globe.setPointOfView(self.camera.position, globe.position));
    this.tbControls.addEventListener('change', () => {
      this.Globes.every(globe => globe.setPointOfView(self.camera.position, globe.position));
      this.hasClickDrag = true;
    }, { passive: true });
    this.tbControls.addEventListener('start', () => {
    }, { passive: true });
    this.tbControls.addEventListener('end', () => {
    }, { passive: true });


    // Kick-off renderers
    (function animate() { // IIFE
      // Frame cycle
      self.tbControls.update();

      renderers.forEach(r => r.render(scene, self.camera));
      self.stats.update();
      requestAnimationFrame(animate)
    }
    )();

    // add event for resize
    window.addEventListener('resize', onWindowResize, { passive: true });

    function onWindowResize(event) {
      renderers.forEach((r, idx) => {
        r.setSize(window.innerWidth, window.innerHeight);
      });
      self.camera.aspect = window.innerWidth / window.innerHeight;
      self.camera.updateProjectionMatrix();
    }

    // handle removal of the resize event when destroying the component
    this.destroy$.subscribe(destroy$);

    function destroy$() {
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('resize', onWindowResize, true);
    }

    document.getElementById('globeViz').addEventListener('mousemove', (event) => this.onMouseUpdate(event), true);

    for (let i = 0; i < document.getElementById('globeViz').children.length; i++) {
      let child = document.getElementById('globeViz').children.item(i);
      if (child['dataset']['engine'] && child['dataset']['engine'].startsWith('three.js')) {
        child.addEventListener('click', (event) => this.interractWithPlanet(event), { passive: true });
        child.addEventListener('mousedown', () => {
          this.hasClickDrag = false;
        }, { passive: true });
        break;
      }
    }
  }

  interractWithPlanet(event) {
    if (!this.hasClickDrag) {
      if (event.ctrlKey) {
        let potentialIntersects = []
        this.Globes.forEach((globe) => {
          potentialIntersects.push(globe.children[0].children[0].children[0])
        });
        let intersects = this.intersectObjects(potentialIntersects, false);
        intersects.every((intersect) => {
          if (intersect.object && intersect.object["__globeObjType"] === "globe") {
            this.initRenderPlanet(intersect.object.parent.parent.parent.id, intersect.object.parent.parent.parent.position)
            return false;
          }
          return true;
        })
      } else if (event.shiftKey) {
        let potentialIntersects = []
        this.Globes.forEach((globe) => {
          potentialIntersects.push(globe.children[0].children[0].children[0])
        });
        let globalPosition: GlobalPosition = new GlobalPosition();
        let intersectsPlanet = this.intersectObjects(potentialIntersects, false);
        intersectsPlanet.forEach((intersectPlanet) => {
          let globe: ThreeGlobe = this.getGlobeFromChildren(intersectPlanet.object) as ThreeGlobe;
          globalPosition.coordinates = this.toPosition(globe.toGeoCoords(intersectPlanet.point.sub(globe.position)), globe);
          globalPosition.planetId = globe['planetId']
        });
        if (globalPosition.planetId) {
          this.rummage(globalPosition);
          this.onMouseMove(event)
        }
      } else {
        this.render();
      }
    }
  }

  deg2Rad(deg) {
    return deg * Math.PI / 180;
  };

  findMyBase() {
    if (this.currentBase) {
      this.Globes.forEach(globe => {
        if (globe['planetId'] === this.currentBase.position.planetId) {
          if (!globe.children[0].children[10].children[0]) {
            this.initRenderPlanet(globe.id, globe.position)
          }
          this.tilesData.get(globe['planetId']).every((tile) => {
            let position = this.toPosition(tile, globe);
            if (position === this.currentBase.position.coordinates) {
              this.tbControls.reset();
              let xyz = globe.getCoords(tile.lat, tile.lng, 0.4);
              this.camera.position.setFromEuler(new THREE.Euler(xyz.x + globe.position.x, xyz.y + globe.position.y, xyz.z + globe.position.z));
            }
            return true;
          })
        }
      });
    } else {
      setTimeout(() => {
        this.findMyBase();
      }, 100);
    }
  }

  cleanRummageHistory() {
    for (let globe of this.Globes) {
      let features = []
      let polygon = {
        type: 'Polygon',
        coordinates: []
      }
      features.push({ 'geometry': polygon, type: 'Feature', color: "rgba(200, 0, 0, 0.7)", altitude: 0.0042 });
      features[0].geometry.coordinates.push([[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]);
      features[0]['target'] = features[0].geometry;
      globe.polygonsData(features);
    }
    this.rummageService.resetRummageHistory()
  }

  initRenderPlanet(globeId, position) {
    this.tbControls.target0 = position.clone();
    this.tbControls.position0 = position.clone();
    this.tbControls.reset();
    this.camera.translateZ(500);
    //let globeId = intersect.object.parent.parent.parent.id;
    this.Globes.forEach(globe => {
      globe.children[0].children[10].children = [];
      if (globe.id === globeId) {
        this.prepTilesFor3D(globe)
        let planet = this.planets.filter((planet) => planet.id === globe['planetId']);
        if (planet[0] && !planet[0].tiles) {
          setTimeout(() => {
            this.initRenderPlanet(globeId, position)
          }, 250);
          return;
        }
        let datas = [];
        let key;
        /*for (key in this.fleetsData) {
          datas.push(this.fleetsData[key])
        }/** */
        let tilesData = this.tilesData.get(globe['planetId']);
        for (key in tilesData) {
          datas.push(tilesData[key])
        }

        //TODO get rummage history
        let features = []
        let rummageHistory = this.rummageService.rummageHistory[globe['planetId']] || [];
        let polygon = {
          type: 'MultiPolygon',
          coordinates: []
        }

        features.push({ 'geometry': polygon, type: 'Feature', color: "rgba(200, 0, 0, 0.7)", altitude: 0.0042 });
        features[0].geometry.coordinates = [];

        if (rummageHistory.length > 0) {
          for (let keys of rummageHistory) {
            let targets = []
            keys.forEach((target) => {
              targets.push({ coordinates: target });
            })
            features[0].geometry.coordinates.push((this.coordsToPolygon(targets, globe)));
          }
          let simplified = union(features[0].geometry, features[0].geometry)
          simplified.geometry = this.splitPolygonGeometry(simplified.geometry);
          if (simplified.geometry.type === 'Polygon') {
            simplified.geometry.coordinates.forEach((polygon) => {
              if (polygon[0][0] !== polygon[1][0])
                polygon.reverse()
            });
          } else {
            simplified.geometry.coordinates.forEach((polygons) => {
              polygons.forEach((polygon) => {
                if (polygon[0][0] !== polygon[1][0])
                  polygon.reverse()
              });
            });
          }
          features[0].geometry.type = simplified.geometry.type;
          features[0].geometry.coordinates = simplified.geometry.coordinates;
        } else {
          features[0].geometry.coordinates.push([[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]]);
          features[0].geometry.type = 'Polygon';
        }


        features[0]['target'] = features[0].geometry;
        globe.tilesData(this.tilesData.get(globe['planetId']))
          //.customLayerData(datas)
          .customThreeObject(d => {
            let object = this.load3DObjects['spaceships'].scene.children[0].clone()
            if (d['fleet']) {
              object = this.load3DObjects['spaceships'].scene.children[0].clone()
              object.type = 'fleet'
              //   }
            } else {
              object = (this.load3DObjects['base'].scene.children[0].clone())
              object.type = 'base'
            }
            return object
          })

          .customThreeObjectUpdate((obj, d) => {
            Object.assign(obj.position, globe.getCoords(d['lat'], d['lng'], d['alt'] + obj['__data'].alt));
            var rotLng = this.deg2Rad(d['lng']);
            var rotLat = this.deg2Rad(-d['lat'] + 90);
            obj.setRotationFromEuler(new THREE.Euler(rotLat, rotLng, -obj.rotation.z, 'YXZ'));
            //  Object.assign(obj.rotation, globe.getCoords(d['lat'], d['lng'], d['alt']));
          })
          .htmlElementsData(this.moonMassLabel)
          .htmlTransitionDuration(0)
          .htmlElement(d => {
            let el = this.tooltipHTML;
            el.style.color = d['color'];
            for (let key in d) {
              el[key] = d[key]
            }
            setTimeout(() => {
              register()
            }, 500);
            return el;
          })
          .polygonsData(features);
        let register = function () {
          let onAfterRender = function (renderer, scene, camera, geometry, material, group) {
            if (renderer.domElement.firstChild.style.transform) {
              renderer.domElement.firstChild.style.transform = renderer.domElement.firstChild.style.transform.split(', -50%)').join(', -100%)');
            }
          };
          if (globe.children[0].children[10].children)
            globe.children[0].children[10].children[0].onAfterRender = onAfterRender
        }
      } else {
        globe.tilesData([]);
        globe.children[0].children[7].children.splice(0, globe.children[0].children[7].children.length);

        globe.pathsData([])
        globe.children[0].children[10].children.splice(0, globe.children[0].children[10].children.length);
      }
    })
    document.getElementById('tooltip').classList.add('d-none')
  }


  render() {
    // update the picking ray with the camera and pointer position
    let potentialIntersects = []
    this.Globes.forEach((globe) => {
      potentialIntersects.push(globe.children[0].children[0].children[0])
    });
    let intersects = this.intersectObjects(potentialIntersects, false);
    if (intersects.length > 0) {
      let intersect = intersects.filter(object => object.object.type === 'Mesh')[0];
      let globe: ThreeGlobe = this.getGlobeFromChildren(intersect.object.parent) as ThreeGlobe;
      if (globe.children[0].children[10].children.length > 0) {
        let globalPosition = new GlobalPosition();
        globalPosition.planetId = globe['planetId'];
        globalPosition.coordinates = this.toPosition(globe.toGeoCoords(intersect.point.sub(globe.position)), globe);
        let position = this.mapLink.get('position')
        if (position && position.planetId) {
          globalPosition.coordinates = globalPosition.coordinates.split(';').map((value) => Math.min(+value, globe['size'])).join(';')
        }
        let tooltip: HTMLElement = document.querySelector('#tooltip');
        let positionArray = globalPosition.coordinates.split(';').map((value) => +value, globe['size'])
        for (let coord of positionArray) {
          if (coord < 0 || coord > globe['size']) {
            tooltip.classList.add('d-none');
            return;
          }
        }
        tooltip['datas'] = JSON.stringify(globalPosition)
        if (!position || !position.planetId) {
          let tile = this.tiles[globalPosition.coordinates];
          tooltip.querySelector('#text')['datas'] = null;
          if (tile && tile.base) {
            tooltip.querySelector('#text')['innerText'] = tile.base.name + '\n ' + tile.base.ownerName + '' + '\n';
            tooltip.querySelector('#text')['datas'] = JSON.stringify({ 'globalPosition': globalPosition, 'ownerId': tile.base.ownerId });
            tooltip.querySelector('#title')['innerText'] = '(' + globalPosition.coordinates + ')';
          } else {
            tooltip.querySelector('#text')['innerText'] = '';
            tooltip.querySelector('#title')['innerText'] = '(' + globalPosition.coordinates + ')';
          }
          tooltip.querySelector('#rummage')['innerText'] = 'sélectionner la zone de fouille';
          tooltip.querySelector('#fight').classList.remove('d-none')
          /*for (let colored of this.coloredMap) {
            globe.children[0].children[7].children[colored[0]]['material'] = this.materials[colored[1]];
            this.coloredMap.delete(colored[0]);
          }/** */
        } else {
          if (position.planetId) {
            let startPosition = this.mapLink.get('startPosition');
            let index = 0;
            this.targets.every((target, i) => {
              if (target.coordinates === startPosition.coordinates) {
                index = i;
                return false;
              }
              return true;
            })
            globalPosition.coordinates = this.targets[this.targets.length - index - 1].coordinates;
            this.mapLink.set('endPosition', globalPosition);
            tooltip.querySelector('#title')['innerText'] = '(' + globalPosition.coordinates + ')';
            tooltip.querySelector('#rummage')['innerText'] = 'valider la fouille de la zone sélectionnée';
            tooltip.querySelector('#fight').classList.add('d-none');


            let distanceToBase = this.utilsService.calculateDistance(this.currentBase.position, globalPosition);

            let effet = 100 + this.currentBase.effects['SEARCH_SPEED'];
            let rummageTimeNum = (distanceToBase + Math.sqrt(this.targets.length) * 1000) / (effet * 1.0);
            let rummageTime = this.timerPipe.transform(rummageTimeNum * 1000, "UTC");
            tooltip.querySelector('#text')['innerText'] = `Temps total estimé : ${rummageTime}`;
          }
        }
        if (!position) {
          this.mapLink.set('position', new GlobalPosition());
          setTimeout(() => {
            let position = this.mapLink.get('position')
            if (position && !position.planetId) {
              this.mapLink.set('position', null);
            }
          }, 5000)
        }
        let coords = this.fromPosition(globalPosition.coordinates, globe)
        globe.htmlLat(coords.lat)
        globe.htmlLng(coords.lng)
        tooltip.classList.remove('d-none');
        tooltip.querySelector('#rummage').classList.remove('d-none')
      }
    }
  }


  toPosition(geoCoords, globe: ThreeGlobe): string {
    let size = globe['size'] + 1;
    let GRID_SIZE = [size, size];
    let tileWidth = 360 / GRID_SIZE[0];
    let tileHeight = 150 / GRID_SIZE[1];
    let lng = (180 + geoCoords.lng + tileWidth / 2)

    let lngIdx = lng / tileWidth;
    if (lng >= 360) {
      lngIdx = (lng - 360) / tileWidth;
    }
    let latIdx = (75 + geoCoords.lat) / tileHeight;
    return (Math.round(latIdx) + ";" + Math.floor(lngIdx))
  }

  fromPosition(position: string, globe: ThreeGlobe, removehalf?: boolean): { lat: number, lng: number } {
    let positionArray = position.split(';').map((value) => {
      if (removehalf)
        return +value - 0.5
      else
        return +value
    });
    let size = globe['size'] + 1;
    let GRID_SIZE = [size, size];
    let tileWidth = 360 / GRID_SIZE[0];
    let tileHeight = 150 / GRID_SIZE[1];
    let lng = -180 + positionArray[1] * tileWidth;
    let lat = -75 + positionArray[0] * tileHeight;
    return { lat: lat, lng: lng }
  }

  prepTilesFor3D(globe: ThreeGlobe) {

    let planets = this.planets.filter((planet) => planet.id === globe['planetId']);
    if (planets.length == 1 && planets[0].tiles) {
      let TILE_MARGIN = 0.10; // degrees
      this.tiles = JSON.parse(JSON.stringify(planets[0].tiles));
      let size = planets[0].size + 1;
      let GRID_SIZE = [size, size];
      let tileWidth = 360 / GRID_SIZE[0];
      let tileHeight = 150 / GRID_SIZE[1];
      let tilesData = this.tilesData.get(globe['planetId']);
      tilesData.splice(0, tilesData.length);
      // this.fleetsData.splice(0, this.fleetsData.length);

      let tile = planets[0].tiles[0];
      let positionArray = [0, 0];
      let tilePosition = 0;
      for (let position in planets[0].tiles) {
        tile = planets[0].tiles[position];
        if (tile.base) {
          positionArray = position.split(';').map((value) => +value);
          
          // Charger l'image en fonction du nom de la base
          let imageUrl = ( (tile.base.image) ? `/assets/img/bases/${tile.base.image}.png` : null)
                        || (tile.base.type ? `/assets/img/bases/${tile.base.type}.png` : null) 
                        || "/assets/img/bases/base.png";
      
          let texture = new THREE.TextureLoader().load(imageUrl);

          let tileData = {
            lng: -180 + positionArray[1] * tileWidth,
            lat: -75 + positionArray[0] * tileHeight,
            altitude: 0.005,
            material: new THREE.MeshBasicMaterial({ map: texture, transparent: true, opacity: 1 }),
            position: { coordinates: position, planetId: planets[0].id },
          };

          
          
          /*
          Old
          let tileData = {
            lng: -180 + positionArray[1] * tileWidth,
            lat: -75 + positionArray[0] * tileHeight,
            altitude: 0.005,
            material: this.materials[0],
            position: { coordinates: position, planetId: planets[0].id },

          }*/
          /*
          if (this.baseTypes.get(tile.base.type)) {
            tileData.material = this.materials[this.baseTypes.get(tile.base.type)[0]]

          }*/
          tilesData.push(tileData);

        }
      }

      /*for (let fleet of this.fleets) {
        //let fleet = this.fleets[id];
        if (fleet.position) {
          positionArray = fleet.position.coordinates.split(';').map((value) => +value);
          let fleetsData = {
            lng: -180 + positionArray[1] * tileWidth,
            lat: -75 + positionArray[0] * tileHeight,
            alt: 0.005,
            position: positionArray[0] + ";" + positionArray[1],
            fleet: fleet,

          }
          this.fleetsData.push(fleetsData);
        }
      }/** */
      /*let pointData =
      [...Array(GRID_SIZE[0]).keys()].map((lngIdx) => {
        let lng =  -180 + lngIdx * tileWidth;
        let lat = -75;
        let alt = 0

        //pointData.push([[lat, lng, alt], [-lat, lng, alt]]);
        return [[lat, lng, alt], [-lat, lng, alt]];
      });

       /* [...Array(GRID_SIZE[1]).keys()].forEach(latIdx =>
          pointData.push({
            lng:,
            lat: ,
            let lat = -75 + latIdx * tileHeight;
          }

      );/** */
      // Gen paths
      let gData = [...Array(GRID_SIZE[0]).keys()].map((lngIdx) => {
        let lat = 75 - (0.5 * tileHeight);
        let lng = -180 + (lngIdx - 0.5) * tileWidth;
        let alt = 0;
        return [[lat, lng, alt], ...[...Array(1).keys()].map(() => {
          lat = -75 - (0.5 * tileHeight);
          return [lat, lng, alt];
        })];
      });
      let gData2 = [...Array(GRID_SIZE[1] + 1).keys()].map((lngIdx) => {
        let lat = -75 + (lngIdx - 0.5) * tileHeight;
        let lng = 0;
        let alt = 0;
        return [[lat, lng, alt], ...[...Array(3).keys()].map((index) => {
          lng = (index + 1) * 120;
          return [lat, lng, alt];
        })];
      });
      gData2.forEach((data) => {
        gData.push(data)
      })

      globe
        // .globeImageUrl('//unpkg.com/three-globe/example/img/earth-dark.jpg')
        // .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
        .tileWidth(tileWidth - TILE_MARGIN)
        .tileHeight(tileHeight - TILE_MARGIN)
        .pathsData(gData)
        //  .pathDashLength(0.01)
        // .pathDashGap(0.004)
        .pathColor(() => ['rgba(100,100,100,0.5)', 'rgba(100,100,100,0.5)'])
        .pathTransitionDuration(0)

        .polygonsData([]) // reset l'historique des fouilles
        // .polygonCapColor(() => 'rgba(0, 200, 0, 0.7)')
        .polygonAltitude((polygon) => { if (polygon) return polygon['altitude'] })
        .polygonCapColor((polygon) => { if (polygon) return polygon['color'] })
        .polygonSideColor((polygon) => { if (polygon) return polygon['color'] })
        .polygonsTransitionDuration(0)
      // .polygonCapCurvatureResolution(0.15)
      // .polygonStrokeColor(() => '#111');/** */

      /*
            globe.pathPoints(pointData)
            .pathStroke('10px')/** */

    } else {
      this.planetService.getPlanetById(globe['planetId']).subscribe((planet) => {



        let planetRef = this.planets.filter((planetRef) => planetRef.id === planet.id)
        if (planetRef.length > 0) {
          globe['size'] = planet.size;
          //this.tiles = JSON.parse(JSON.stringify(planet.tiles));
          for (let property in planet) {
            planetRef[0][property] = planet[property];
          }
        }
      })
    }
  }

  onKeydown(event) {
    console.warn(event)

  }

  dismiss(reason?) {
    let tooltip = document.querySelector('#tooltip');
    tooltip.classList.add('d-none');
    if (this.mapLink.get('position')) {
      let planetId = JSON.parse(tooltip['datas']).planetId;
      let globe = this.Globes.filter((globe) => globe['planetId'] === planetId)[0];
      let polygonsData = globe.polygonsData();
      polygonsData.forEach((polygon, index) => {
        if (polygon['altitude'] == 0.01) {
          delete polygonsData[index];
        }
      })
      globe.polygonsData(polygonsData.filter((value) => { return value }));
      this.mapLink.set('position', null)
      this.mapLink.set('startPosition', null)
    }
  }


  public pointer = new THREE.Vector2();

  getGlobeFromChildren(children) {
    if (children.parent['planetId']) {
      return children.parent
    } else {
      return this.getGlobeFromChildren(children.parent)
    }
  }

  onMouseUpdate(event) {
    this.setPointerFromEvent(event);
    if (event.buttons) {
      this.hasClickDrag = true;
    } else {
      if (!this.mapLink.get('position')) {
        let potentialIntersects = []
        this.Globes.forEach((globe) => {
          potentialIntersects.push(globe.children[0].children[0].children[0])
        });
        let intersectsPlanet = this.intersectObjects(potentialIntersects, false);
        intersectsPlanet.forEach((intersectPlanet) => {
          let intersects = this.intersectObjects(intersectPlanet.object.parent.parent.children[7].children, false);
          intersects.forEach((intersect) => {
            if (intersect.object['__data'] && intersect.object['__data'].position) {
              let globe: ThreeGlobe = this.getGlobeFromChildren(intersect.object.parent) as ThreeGlobe;
              let tile = this.planets[0].tiles[intersect.object['__data'].position.coordinates];
              if (!(intersect.object['__data'].altitude < 0) && intersect.object['__globeObjType'] === 'tile' && tile && tile.base) {
                let coords = this.fromPosition(intersect.object['__data'].position.coordinates, globe)
                globe.htmlLat(coords.lat)
                globe.htmlLng(coords.lng)
                let tooltip = document.getElementById('tooltip');
                tooltip.classList.remove('d-none');
                tooltip.querySelector('#title')['innerText'] = '(' + intersect.object['__data'].position.coordinates + ')';
                tooltip.querySelector('#text')['datas'] = JSON.stringify({ 'globalPosition': intersect.object['__data'].position, 'ownerId': tile.base.ownerId });
                tooltip.querySelector('#text')['innerText'] = tile.base.name + '\n ' + tile.base.ownerName + '' + '\n';
                tooltip.querySelector('#fight').classList.add('d-none')
                tooltip.querySelector('#rummage').classList.add('d-none')
              }
            }
          });
        });
      }
    }
  }

  setPointerFromEvent(event: any) {
    this.pointer.x = ((event.clientX - document.getElementsByClassName('menu_box')[0].clientWidth) / (event.target.clientWidth)) * 2 - 1;
    this.pointer.y = -(event.clientY / event.target.clientHeight) * 2 + 1;
  }

  openBase(content, baseId: string) {
    this.baseService.getRefBaseById(baseId).subscribe((base) => {
      this.selectedBase = base;
      let baseModal = this.modalService
        .open(content, {
          ariaLabelledBy: "planet-base",
          scrollable: true,
          windowClass: "planet-base backModalImg",
        });
      baseModal.result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => { }
      );
      baseModal.hidden.subscribe(() => { });
    });
  }

  filter3D(parent) {
    let currentCursor = JSON.parse(JSON.stringify(this.pointer));
    this.pointer.x = 0.5;
    this.pointer.y = 0;
    this.Globes.forEach((globe) => {
      let tile: Tile;
      let tilesData = globe.tilesData() as { position: GlobalPosition, altitude: number }[];
      let planet = this.planets.filter((planet) => planet.id === globe['planetId'])[0];
      if (planet && tilesData.length > 0) {
        for (let position in planet.tiles) {
          tile = planet.tiles[position];
          if (tile.base) {
            let filtred = parent.children.filter((child) => tile.base.type.startsWith(child.property) && child.getValue());
            let base = tilesData.filter((tile) => tile.position.coordinates === position);
            if (base.length > 0) {
              if (filtred.length > 0) {
                delete base[0].altitude;
              } else {
                base[0].altitude = -0.1;
              }
            }
          }
        }
        globe.tilesData(this.tilesData.get(globe['planetId']));
      }
    });
    this.pointer.x = currentCursor.x;
    this.pointer.y = currentCursor.y;
  }

  clickTest(event) {
    console.log(event);
  }

  coordsToPolygon(coords: GlobalPosition[], globe) {
    let lngSorted = coords.sort((a, b) => {
      return +a.coordinates.split(';')[1] - +b.coordinates.split(';')[1];
    })
    let latSorted = coords.sort((a, b) => {
      return +a.coordinates.split(';')[0] - +b.coordinates.split(';')[0];
    })


    let start = this.fromPosition(+latSorted[0].coordinates.split(';')[0] + ";" + +lngSorted[0].coordinates.split(';')[1], globe, true);
    let upperRight = this.fromPosition(+latSorted[0].coordinates.split(';')[0] + ";" + (+lngSorted[lngSorted.length - 1].coordinates.split(';')[1] + 1), globe, true);
    let bottomRight = this.fromPosition(+latSorted[latSorted.length - 1].coordinates.split(';')[0] + 1 + ";" + (+lngSorted[lngSorted.length - 1].coordinates.split(';')[1] + 1), globe, true);
    let bottomLeft = this.fromPosition(+latSorted[latSorted.length - 1].coordinates.split(';')[0] + 1 + ";" + +lngSorted[0].coordinates.split(';')[1], globe, true);
    //)
    /*let polygon = [
      [start.lng, start.lat],
      [bottomLeft.lng, bottomLeft.lat],
      [bottomRight.lng, bottomRight.lat],
      [upperRight.lng, upperRight.lat],
      [start.lng, start.lat],
    ]/** */
    let polygon = []
    polygon.push([start.lng, start.lat])

    let orders = [
      [start, bottomLeft],
      [bottomLeft, bottomRight],
      [bottomRight, upperRight],
      [upperRight, start],

    ]
    for (let order of orders) {
      let diff = this.numberOfPoints(order[1], order[0], 2.0);
      for (let i = 1; i < (diff + 1); i++) {
        let idiff = Math.min(i / (1.0 * diff), 1)
        polygon.push([order[0].lng + (order[1].lng - order[0].lng) * idiff, order[0].lat + (order[1].lat - order[0].lat) * idiff])
      }
    }/** */
    return [polygon];
  }

  splitPolygonGeometry(polygon) {
    if (polygon.type) {
      if (polygon.type === "MultiPolygon") {
        let coord = []
        for (let coords in polygon.coordinates) {
          coord.push(this.splitPolygonGeometry(polygon.coordinates[coords]))
        }
        polygon.coordinates = coord;
      } else {
        polygon.coordinates = this.splitPolygonGeometry(polygon.coordinates)
      }
    } else {
      let coordinates = []
      for (let obj in polygon[0]) {
        let key = +obj;
        if (polygon[0][key + 1]) {
          let before = { lat: polygon[0][key][1], lng: polygon[0][key][0] };
          let after = { lat: polygon[0][key + 1][1], lng: polygon[0][key + 1][0] };
          let diff = this.numberOfPoints(before, after, 1);
          for (let i = 1; i < (diff + 1); i++) {
            let idiff = Math.min(i / (1.0 * diff), 1)
            coordinates.push([before.lng + (after.lng - before.lng) * idiff, before.lat + (after.lat - before.lat) * idiff])
          }
        }
      }
      polygon[0] = coordinates;
    }
    return polygon;
  }

  numberOfPoints(before, after, resolution): number {
    let distance = Math.round(
      Math.sqrt(Math.pow(before.lat - after.lat, 2) + Math.pow(before.lng - after.lng, 2))
    ) || 0;
    if (distance / resolution >= 1) {
      return distance / resolution;
    }
    return 1;
  }

  rummage(globalPosition?: GlobalPosition) {
    let data = globalPosition || JSON.parse(document.querySelector('#tooltip')['datas'])
    let globe = this.Globes.filter((globe) => globe['planetId'] === data.planetId)[0];
    let position = this.mapLink.get('position')
    if (!position || !position.planetId) {
      this.mapLink.set('startPosition', data);
      //let size = globe['size'] + 1;
      //let GRID_SIZE = [size, size];
      //let tileWidth = 360 / GRID_SIZE[0];
      //let tileHeight = 150 / GRID_SIZE[1];

      let features = globe.polygonsData();
      if (features.length > 1) {
        features[1]['geometry']['coordinates'] = this.coordsToPolygon([data], globe);
        features[1]['altitude'] = 0.01;
      } else {

        features.push({ 'geometry': { 'coordinates': this.coordsToPolygon([data], globe), type: 'Polygon' }, type: 'Feature', color: "rgba(0, 200, 0, 0.7)", altitude: 0.01 });
      }
      globe.polygonsData(features)

      this.mapLink.set('position', data);
      this.mapLink.set('endPosition', null);
      //  renderers[0].domElement.addEventListener('mousemove', throttle(mouseMoveColors, 150), { passive: true });
      //TODO: debounce l'event pour éviter les perf
      for (let i = 0; i < document.getElementById('globeViz').children.length; i++) {
        let child = document.getElementById('globeViz').children.item(i);
        if (child['dataset']['engine'] && child['dataset']['engine'].startsWith('three.js')) {
          child.addEventListener('mousemove', (event) => this.onMouseMove(event), true);
          // let mousemove = fromEvent(child, 'mousemove');
          // mousemove
          // .pipe(
          //   throttle(val => val, 500, {leading: true})
          // )
          // .subscribe((event) => this.onMouseMove(event));
          break;
        }
      }
    } else {
      let data = this.mapLink.get('position');

      this.mapLink.set('position', data);
      this.mapLink.set('endPosition', null);
      this.mapLink.set('position', null);
      for (let i = 0; i < document.getElementById('globeViz').children.length; i++) {
        let child = document.getElementById('globeViz').children.item(i);
        if (child['dataset']['engine'] && child['dataset']['engine'].startsWith('three.js')) {
          child.removeAllListeners('mousemove');
          break;
        }
      }
      this.rummageService.rummageByCoords(this.baseService.getCurrentBaseId(), this.targets).subscribe({
        next: (test) => {
          let features = globe.polygonsData();

          if (features.length > 1) {
            let first = JSON.parse(JSON.stringify(features[0]))
            let last = JSON.parse(JSON.stringify(features[1] || features[0]))
            let simplified = union(first['target'], last['geometry']);
            simplified.geometry = this.splitPolygonGeometry(simplified.geometry);
            features[0]['target'] = simplified.geometry;
            if (simplified.geometry.type === 'Polygon') {
              simplified.geometry.coordinates.forEach((polygon) => {
                if (polygon[0][0] !== polygon[1][0])
                  polygon.reverse()
              });
            } else {
              simplified.geometry.coordinates.push(last['geometry'].coordinates);
              simplified.geometry.coordinates.forEach((polygons) => {
                polygons.forEach((polygon) => {
                  if (polygon[0][0] !== polygon[1][0])
                    polygon.reverse()
                });
              });

            }
            features[0]['geometry'] = simplified.geometry;
            features[0]['properties'] = simplified.properties;

          } else {
            features[0]['color'] = "rgba(200, 0, 0, 0.7)";
            features[0]['altitude'] = 0.0045;
            features[0]['targets'] = [this.targets]
            features[0]['target'] = features[0]['geometry']
          }
          features[1]['altitude'] = -0.1;
          globe.polygonsData(features);
          let position = data.coordinates.split(';').map((value) => +value);
          let coords = position[1] * (this.planets[0].size + 1) + position[0];
          this.targets.forEach((target) => {
            position = target.coordinates.split(';');
            coords = position[1] * (this.planets[0].size + 1) + position[0];
          })
          this.mapLink.set('startPosition', null);
          this.eventService.updatePlayerEventNeeded();
          let alert = new Alert();
          alert.type = "success";
          alert.msg = "une fouille de " + this.targets.length + " case(s) a bien été envoyé vers " + this.targets[0].coordinates;
          alert.timeout = 2000;
          this.alertService.addAlert(alert);
        },
        error: () => {
          let features = globe.polygonsData();
          if (features.length > 1) {
            features[1]['altitude'] = -0.1;
          } else {
            features[0]['altitude'] = -0.1;
          }
          globe.polygonsData(features);

        }
      })/** */
    }
    document.querySelector('#tooltip').classList.add('d-none');
  }

  generateGetBoundingClientRect(x = 0, y = 0) {
    let dom: DOMRect = new DOMRect();
    dom.width = 0;
    dom.height = 0;
    dom.y = y - 4;
    dom.x = x - 3;
    return () => dom;
  }

  private targets = [];
  private targetsLength = 0;
  //  private coloredMap = new Map<number, number>();
  private raycaster = new THREE.Raycaster();
  private onMouseMove(event) {
    this.setPointerFromEvent(event);
    if (!this.mapLink.get('endPosition')) {
      let position = this.mapLink.get('startPosition');
      let startPosition = position.coordinates.split(';').map((value) => +value);
      //let obj = (this.Globes[0].children[0].children[7].children[startPosition[1] * (this.planets[0].size + 1) + startPosition[0]])
      let globe: ThreeGlobe = this.Globes.filter((globe) => globe['planetId'] === position.planetId)[0];
      let potentialIntersects = []
      this.Globes.forEach((globe) => {
        potentialIntersects.push(globe.children[0].children[0].children[0])
      });
      let intersects = this.intersectObjects(potentialIntersects, false);
      let endPosition = startPosition;
      this.targets = [];
      let diffX = 0;
      let diffY = 0;
      let intersect = intersects.filter(object => object.object.type === 'Mesh')[0];
      if (intersect) {
        let rummageMax = this.currentBase.effects['SEARCH_AREA']
        endPosition = this.toPosition(globe.toGeoCoords(intersect.point.sub(globe.position)), globe).split(';').map((value) => Math.min(+value, globe['size']));
        diffX = (endPosition[0] - startPosition[0])
        diffY = (endPosition[1] - startPosition[1])
        if (Math.abs(diffX) >= rummageMax) {
          diffX = (rummageMax - 1) * ((diffX) / Math.abs(diffX));
        }
        if (Math.abs(diffY) >= rummageMax) {
          diffY = (rummageMax - 1) * ((diffY) / Math.abs(diffY));
        }
        let tagret = JSON.parse(JSON.stringify(this.currentBase.position));
        tagret.coordinates = startPosition.join(';');
        let workCoords = tagret.coordinates.split(";");
        let signX = diffX > 0 ? 1 : -1;
        let signY = diffY > 0 ? 1 : -1;
        diffX = Math.abs(diffX);
        diffY = Math.abs(diffY);
        for (let i = 0; i <= diffX; i++) {
          workCoords[0] = startPosition[0] + (i * signX) + '';
          for (let iJ = 0; iJ <= diffY; iJ++) {
            workCoords[1] = startPosition[1] + iJ * signY + '';
            tagret.coordinates = workCoords.join(';');
            this.targets.push(JSON.parse(JSON.stringify(tagret)));
          }
        }
        if (this.targets.length < 1) {
          this.targets = [JSON.parse(JSON.stringify(tagret))];
        }
        if (this.targetsLength != this.targets.length) {
          this.targetsLength = this.targets.length
          let features = globe.polygonsData();
          let coordinates = this.coordsToPolygon(this.targets, globe);
          if (JSON.stringify(coordinates) !== JSON.stringify(features[1]['geometry'].coordinates)) {
            features[1]['geometry'].coordinates = this.coordsToPolygon(this.targets, globe);
            globe.polygonsData(features)
          }
        }

      }
    }
  }

  attack() {
    let datas = JSON.parse(document.querySelector('#tooltip')['datas'])

    this.fleetService.fleetUpdate$
      .pipe(take(1))
      .subscribe((fleets) => {

        let usableFleets: Fleet[] = [];
        fleets.forEach((fleet) => {
          if (fleet.status != 'MOVING' && fleet.status != 'FIGHTING' && fleet.status != 'TRANSITING' && fleet.amount > 0) {
            usableFleets.push(fleet);
          }

        });
        if (usableFleets && usableFleets[0]) {
          this.fleetService.getFleetById(usableFleets[0].id).subscribe((fleet) => {
            let modal = this.modalService.open(FleetTravelComponent, {
              ariaLabelledBy: "modal-basic-title",
              scrollable: true,
              size: "xl",
              windowClass: "fleet-travel backModalImg",
              backdrop: false,
            });
            modal.componentInstance.fleets = usableFleets;
            modal.componentInstance.selectedTarget = datas;
            modal.componentInstance.selectedFleet = fleet;
            modal.componentInstance.recalculateDistance();
          });
        } else {
          let alert = new Alert();
          alert.type = "info";
          alert.msg = "il n'y a pas de flottes utilisable actuellement";
          alert.timeout = 10000;
          this.alertService.addAlert(alert);
        }
      });
  }

  openUserProfile(ownerId) {
    let showProfileComponent = this.modalService.open(ShowProfileComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "base_openBuilding backModalImg",
    });
    showProfileComponent.componentInstance.ownerId = ownerId;
  }

  tooltipClick(event) {
    let tooltip = document.getElementById('tooltip');
    let tooltipDatas = JSON.parse(tooltip.querySelector('#text')['datas']);
    if (tooltipDatas && tooltipDatas.ownerId) {
      let showProfileComponent = this.modalService.open(ShowProfileComponent, {
        ariaLabelledBy: "modal-show-profile",
        scrollable: true,
        size: "xl",
        windowClass: "showProfile backModalImg",
      });
      showProfileComponent.componentInstance.ownerId = tooltipDatas.ownerId;
    }
  }

  intersectObjects(targets: THREE.Object3D<THREE.Event>[], recursive: boolean): THREE.Intersection<THREE.Object3D<THREE.Event>>[] {
    this.raycaster.setFromCamera(this.pointer, this.camera);
    return this.raycaster.intersectObjects(targets, recursive);;
  }
}
