import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Research } from 'src/app/models/research/Research';
import { UsersService } from "../../services/Users/user.service";
import { BaseService } from "../bases/base.service";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ResearchService {
  private apiUrl = environment.apiEndpoint;
  private research = new BehaviorSubject<Research[]>(null);
  public researchUpdate$ = this.research.asObservable();
  updateError = false;

  constructor(
    private readonly http: HttpClient,
    private readonly usersService: UsersService,
    private readonly baseService: BaseService,
  ) {
    baseService.baseUpdate$.subscribe((base) => {
      if (base) {
        if (!this.research.value) {
          this.updateError = false;
          this.updateResearch(base.id);
        }
      }
    })
  }


  private updateResearch(ownerId: string) {
    this.getKnownResearch(ownerId).subscribe({
      next: (research) => {
        this.research.next(research)
      },
      error: (err) => {
        if (err.error.error === "Forbidden" && ! this.updateError) {
          this.updateError = true;
          this.updateResearch(this.baseService.getCurrentBaseId());
        }
      }
    });
  }

  private getKnownResearch(ownerId: string): Observable<Research[]> {
    return this.http.get<Research[]>(this.apiUrl + `researchs/owner/${ownerId}/known`);
  }

  public launchResearch(ownerId: string, baseId: string, researchId: string): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `researchs/base/${baseId}/launch`,
      researchId
    );
  }
}
