import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TemplateSquadronComponent } from "src/app/main/fleets/fleets/formation/edit-squadron/template-squadron.component";
import { FleetService } from "src/app/services/fleets/fleet.service";


@Component({
  selector: "app-battle-group",
  templateUrl: "../template-squadron.component.html",
  styleUrls: ["../template-squadron.component.scss"],
})
export class AddToSquadronComponent extends TemplateSquadronComponent implements OnInit {

  constructor(
    protected readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal, // needed in HTML
    protected readonly fleetService: FleetService,
  ) {
    super(fb, modal, fleetService);
  }

  ngOnInit() {
    if(!this.selectedShip) {
      this.selectedShip = this.availableShips[0];
      this.doSelectShip = false;
    }
    super.ngOnInit();

    this.doAmount = true;
    this.doTactic = false;
    this.title = "Ajouter à un escadron"
  }

  public onSubmit(): boolean {
    // Vérifier la validité du formulaire
    if (this.frmShip.valid && this.selectedFleet) {
      this.fleetService.transferShipToFleet(
          this.selectedFleet.id,
          this.targetSquadron.techId,
          this.selectedShip.id,
          this.amount.value
        )
        .subscribe(() => {
          this.modal.close();
      });
    } else {
      //TODO : afficher le pourquoi de ca fonctionne pas
    }
    return false;
  }
}
