import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from 'src/app/models/Alert';
import { Agent } from 'src/app/models/Staff/Agent';
import { AgentMission } from 'src/app/models/Staff/AgentMission';
import { AlertService } from 'src/app/services/alert/alert.service';
import { BaseService } from 'src/app/services/bases/base.service';
import { StaffService } from 'src/app/services/Staff/staff.service';
import { UsersService } from 'src/app/services/Users/user.service';

@Component({
  selector: 'app-spy-mission',
  templateUrl: './spy-mission.component.html',
  styleUrls: ['./spy-mission.component.scss']
})
export class SpyMissionComponent implements OnInit {
  agent: Agent;
  missions: AgentMission[];
  selectedMission: AgentMission;
  duration: number;

  constructor(
    private readonly staffService: StaffService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    private readonly modalService: NgbModal,
    public readonly modal: NgbActiveModal,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit(): void {
    console.warn()
    this.staffService.getAvailableMissionByAgentId(this.agent.typeId).subscribe((missions) => {
      if (missions) {
        this.missions = missions;
        this.selectedMission = this.missions[0];
      }
    })
  }

  onChange(missionId) {
    this.selectedMission = this.missions.filter((mission) => mission.id === missionId)[0];
    this.duration = 0;

  }

  onChangeDuration(duration) {
    this.duration = duration;
  }

  onSubmit() {
    if (!this.selectedMission.duration) {
      if (this.duration > 0) {
        this.selectedMission.duration = this.duration
      } else {

        let alert = new Alert();
        alert.type = "danger";
        alert.msg = "vous n'avez pas sélectionné de durée de la mission";
        alert.timeout = 10000;
        this.alertService.addAlert(alert);
        return;
      }
    }
    this.staffService.launchMission(this.agent.id, this.selectedMission).subscribe((result) => {
      this.modal.dismiss();
    })
  }

}
