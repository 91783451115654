import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Subject, take, takeUntil } from "rxjs";
import { User } from "src/app/models";
import { Event } from "src/app/models/Event";
import { EventService } from "src/app/services/event/event.service";
import { FleetService } from "src/app/services/fleets/fleet.service";
import { NotificationsService } from "src/app/services/Notifications/notifications.service";
import { UsersService } from "src/app/services/Users/user.service";
import { UtilsService } from "src/app/services/Utils/utils.service";

@Component({
  selector: "app-event-list",
  templateUrl: "./event-list.component.html",
  styleUrls: ["./event-list.component.scss"],
})
export class EventListComponent implements OnInit, OnDestroy {
  //public allEvent: Event[] = [];
  public baseEvents: Event[] = [];
  public playerEvents: Event[] = [];
  public hostileEvents: Event[] = [];
  public userId: string;
  public timerMap: Map<string, string>;
  private readonly destroy$ = new Subject();
  private eventTab;
  private sendedNotifications = [];

  order = {
    orderType: "resolution",
    order: 1
  }
  constructor(
    private readonly eventService: EventService,
    private readonly userService: UsersService,
    private readonly fleetService: FleetService,
    private readonly notificationService: NotificationsService,
    public readonly utilsService: UtilsService,
  ) {
    eventService.baseEventUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((events) => {
        if (!this.baseEvents)
          setTimeout(() => this.orderByText(this.order), 0)
        this.baseEvents = events
        this.orderByText(this.order)
        this.isEventDanger()
      });
    eventService.playerEventUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((events) => {
        if (!this.playerEvents)
          setTimeout(() => this.orderByText(this.order), 0)
        this.playerEvents = events;
        this.orderByText(this.order)
        this.isEventDanger()
      });
  }

  ngOnInit() {
    this.eventTimer();
    this.eventTab = document.getElementsByClassName("event-tab")[0];
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  //Temporaire : quand la gestion des event sera faite, y aura plus besoin de refresh hors event
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.orderByText(this.order)
    if (changeEvent.nextId === 1) {
      this.eventService.updateBaseEventNeeded();
    } else if (changeEvent.nextId === 2) {
      this.eventService.updatePlayerEventNeeded();
    } else if (changeEvent.nextId === 3) {
      this.eventService.updatePlayerEventNeeded();
    }
  }

  private updateTimestamp = Date.now();
  eventTimer() {
    this.timerMap = new Map();
    this.utilsService.timer
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        document.getElementById("sub-content").style.setProperty('--eventTabHeight', (this.eventTab.offsetHeight) + "px");
        if (document.getElementById('alert-danger'))
          document.getElementById('alert-danger').classList.toggle('alert-danger');
        let needUpdate = false;
        if (this.baseEvents) {
          for (let event of this.baseEvents) {
            let then = Date.parse(event.resolution + '');
            let ms = then - Date.now();
            this.timerMap.set(event.id, ms + '');

            if (ms < 0) { // fix server lag
              if (ms > -5000) { // if event if behind for less than 5 sec => refresh each second
                needUpdate = true;
              } else if (ms > -60000) {// if event if behind for less than 60 sec => refresh every 10 seconds
                if ((val % 10) === 0) {
                  needUpdate = true;
                }
              } else {  // if event if behind for less than 60 sec => refresh every minutes
                if ((val % 60) === 0) {
                  needUpdate = true;
                }
              }
              if ((this.updateTimestamp + 300000) < Date.now()) { //fix chromium perf where after 5 minutes, timer works only once per minutes
                needUpdate = true;
              }
            }
          }
          if (needUpdate) {
            this.eventService.updateBaseEventNeeded();
          }
        }
        if (this.playerEvents) {
          let userNeedUpdate = false;
          needUpdate = false;
          for (let event of this.playerEvents) {
            let then = Date.parse(event.resolution + '');
            let ms = then - Date.now();
            this.timerMap.set(event.id, ms + '');
            if (ms < 0) { // fix server lag
              if (ms > -3000) { // if event if behind for less than 5 sec => refresh each second
                needUpdate = true;
                if (event.type.includes('SEARCH')) {
                  //   userNeedUpdate = true;
                }
              } else if (ms > -60000) {// if event if behind for less than 60 sec => refresh every 10 seconds
                if ((val % 10) === 0) {
                  needUpdate = true;
                }
              } else {  // if event if behind for less than 60 sec => refresh every minutes
                if ((val % 60) === 0) {
                  needUpdate = true;
                }
              }
              if ((this.updateTimestamp + 300000) < Date.now()) { //fix chromium perf where after 5 minutes, timer works only once per minutes
                needUpdate = true;
              }
            }
          }
          if (userNeedUpdate) {
            this.userService.updateUser();
          }
          if (needUpdate) {
            this.eventService.updatePlayerEventNeeded();
            this.updateTimestamp = Date.now()
          }
        }
      });
  }


  cancelMove(moveEventId) {
    this.eventService.cancelTravelByEventId(moveEventId).subscribe(() => {

    });
  }

  showOrder(orderType) {
    let ths = document.getElementsByTagName('app-event-list')[0].getElementsByTagName('th');
    let order = document.getElementsByClassName(orderType)[0].getElementsByTagName('i')[0].classList.contains('bx-sort-up');
    for (let i = 0; i < ths.length; i++) {
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-down')
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-up')
    }
    for (let key in document.getElementsByClassName(orderType)) {
      let element = document.getElementsByClassName(orderType).item(+key);
      if (element) {
        if (order) {
          element.getElementsByTagName('i')[0].classList.add('bx-sort-down')
        } else {
          element.getElementsByTagName('i')[0].classList.add('bx-sort-up')
        }
      }
    }
    this.sortByText(orderType, (order ? -1 : 1));
  }

  orderByText(order) {
    let ths = document.getElementsByTagName('app-event-list')[0].getElementsByTagName('th');
    for (let i = 0; i < ths.length; i++) {
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-down')
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-up')
      if (ths[i].classList.contains(order.orderType)) {
        ths[i].getElementsByTagName('i')[0].classList.add('bx-sort-up')
      }
    }
    this.sortByText(order.orderType, order.order);
  }


  sortByText(orderType, order) {
    if (this.baseEvents) {
      this.baseEvents = this.baseEvents.sort((a, b) => {
        return (a[orderType].toLocaleLowerCase() > b[orderType].toLocaleLowerCase() ? 1 : -1) * order;
      })
    }
    if (this.playerEvents) {
      this.playerEvents = this.playerEvents.sort((a, b) => {
        return (a[orderType].toLocaleLowerCase() > b[orderType].toLocaleLowerCase() ? 1 : -1) * order;
      })
    }
    if (this.hostileEvents) {
      this.hostileEvents = this.hostileEvents.sort((a, b) => {
        return (a[orderType].toLocaleLowerCase() > b[orderType].toLocaleLowerCase() ? 1 : -1) * order;
      })
    }
    this.order.order = order;
    this.order.orderType = orderType;
  }

  isEventDanger() {
    this.hostileEvents = []
    let events = []
    this.baseEvents.forEach((event) => {
      events.push(event);
    })
    this.playerEvents.forEach((event) => {
      events.push(event);
    })

    events.forEach((event) => {
      if (event.type === 'MOVE') {
        this.fleetService.fleetUpdate$
          .pipe(take(1))
          .subscribe((fleets) => {
            if (fleets) {
              for (let fleet of fleets) {
                if (event.description.includes(fleet.name)) {
                  event.hostile = false;
                  return;
                }
              }
            }
          })
      }

      if (event.hostile) {
        if (this.hostileEvents.filter((hostileEvent) => event.id === hostileEvent.id).length < 1) {
          this.hostileEvents.push(event);
          if (!this.sendedNotifications.includes(event.id)) {
            this.sendedNotifications.push(event.id);
            this.notificationService.sendNotification(event.description)
          }
        }
      }
    })
  }
}
