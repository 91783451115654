import { KeyValue } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil, timer } from "rxjs";
import { SquadronReportComponent } from "src/app/main/message-box/report/squadron-report/squadron-report.component";
import { FightRound } from "src/app/models/reports/FightRound";
import { InviteReport } from "src/app/models/reports/InviteReport";
import { Squadron } from "src/app/models/Squadron";
import { AllianceService } from "src/app/services/Alliance/alliance.service";
import { BaseService } from "src/app/services/bases/base.service";
import { OptionsService } from "src/app/services/options/options.service";
import { ReportService } from "src/app/services/reports/report.service";
import { UsersService } from "src/app/services/Users/user.service";

@Component({
  selector: "app-invite-detail",
  templateUrl: "./invite-detail.component.html",
  styleUrls: ["./invite-detail.component.scss"],
})
export class InviteDetailComponent implements OnInit, OnDestroy {
  public report: InviteReport;

  @Input()
  public getReport;

  public squadrons: Map<string, Squadron> = new Map<string, Squadron>();
  timerTooltip = timer(500);
  tooltipContext;

  private readonly destroy$ = new Subject();
  private debug: boolean = false;

  constructor(
    private readonly modalService: NgbModal,
    public readonly modal: NgbActiveModal,
    private readonly baseService: BaseService,
    private readonly optionsService: OptionsService,
    private readonly allianceService: AllianceService,
    private readonly reportService: ReportService,
    private readonly usersService: UsersService,
  ) { }

  ngOnInit() {
    this.getReport.subscribe((report) => {
      report.date = new Date(Date.parse(report.date + ''));
      this.report = report;
      //  setTimeout(() => this.buildTables(), 0);
    });
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });

  }

  ngOnDestroy() {
    if (this.tooltipContext) {
      this.tooltipContext.unsubscribe();
    }
    this.destroy$.next("");
    this.destroy$.complete();
  }

  destroyInvit() {
    this.reportService.deleteReportById(this.report.uuid).subscribe();
  }

  accept() {
    this.allianceService.acceptInvite(this.report.allianceId, this.usersService.currentUserId()).subscribe((debug) => {
      this.usersService.updateUser();
      this.destroyInvit();
      this.modal.dismiss();
    })
  }

  decline() {
    this.reportService.deleteReportById(this.report.id).subscribe();
    this.allianceService.declineInvite(this.report.allianceId, this.usersService.currentUserId()).subscribe((debug) => {
      this.destroyInvit();
      this.modal.dismiss();
    })
  }
}
