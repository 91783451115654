import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Leaderboard } from "src/app/models/leaderboard/Leaderboard";
import { LeaderboardScore } from "src/app/models/leaderboard/leaderboardScore";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class LeaderboardService {
  private leaderboards = new BehaviorSubject<Leaderboard[]>(null);
  public leaderboardsUpdate$ = this.leaderboards.asObservable();
  private apiUrl = environment.apiEndpoint;

  constructor(
    private readonly http: HttpClient,
  ) {
    this.getLeaderboards().subscribe((leaderboards)=> {
      this.leaderboards.next(leaderboards);
    })
  }

  public getLeaderboards(): Observable<Leaderboard[]> {
    return this.http.get<Leaderboard[]>(this.apiUrl + `leaderboard`);
  }

  public getLeaderboardsByType(type: string): Observable<Leaderboard> {
    return this.http.get<Leaderboard>(this.apiUrl + `leaderboard/type/${type}`);
  }

  public getScorebyLeaderboardId(leaderboardId: string): Observable<LeaderboardScore[]> {
    return this.http.get<LeaderboardScore[]>(this.apiUrl + `leaderboard/score/${leaderboardId}`);
  }

  public getScorebyownerId(leaderboardId: string, ownerId: string): Observable<LeaderboardScore[]> {
    return this.http.get<LeaderboardScore[]>(this.apiUrl + `leaderboard/score/${leaderboardId}/owner/${ownerId}`);
  }

  public getScorePaginated(leaderboardId: string, start: number, end: number): Observable<LeaderboardScore[]> {
    return this.http.get<LeaderboardScore[]>(this.apiUrl + `leaderboard/score/${leaderboardId}/start/${start}/end/${end}`);
  }
}
