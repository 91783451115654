<!-- Menu Sidebar Proto Debut-->
<div class="menu_box" *ngIf="hasLoggedIn">
  <div class="sidebar">
    <div class="logo-details">
      <i class='bx bx-menu-alt-left'></i>
      <div class="logo_name">Menu</div>
      <i class='bx bx-menu' id="btn"></i>
    </div>
    <b class="nav-list">
      <li class="alwaysOn">
        <i class='bx bx-search' (click)="$event.target['parentElement'].children[1].focus()"></i>
        <input class="inputSearch" type="search" (input)="onSearch($event.target['value'])" placeholder="Search...">
        <span class="tooltip">Rechercher</span>
      </li>
      <li class="alwaysOn">
        <select *ngIf="currentBase" (change)="onChange($event.target['value'])" class="form-control" id="baseName">
          <option *ngFor="let base of bases" [selected]="base.id === currentBase.id" [value]="base.id">
            {{ base.name }}
          </option>
        </select>
      </li>
      <li *ngFor="let menu of menuService.menu" [ngStyle]="{'--menuColor':menu.color}" [id]=menu.redirect disabled=true>
        <a (click)="redirect(menu.redirect)" [ngbTooltip]="checkTool(menu.description, menu.disabled)" placement="right" container="body" #tooltip="ngbTooltip">
          <i class='bx links-icon' [class]="menu.icon"></i>
          <span class="links_name">{{menu.name}}</span>
          <sup *ngIf="menu.redirect === 'messages' && newReports!==0">{{ newReports }}</sup>
          <sub *ngIf="menu.redirect === 'messages' && newThreads!==0">{{ newThreads }}</sub>
          <sup *ngIf="menu.redirect === 'quests' && availableQuests.length>0">{{ availableQuests.length }}</sup>
        </a>
      </li>
    </b>
  </div>
</div>
<!-- Menu Sidebar Proto Fin-->

<div class="custom-top " *ngIf="hasLoggedIn">
  <nav class="navbar navbar-expand-lg navbar-dark container-fluid">
    <a class="navbar-brand" href="#">Need A Name</a>
    <button (click)="isCollapsed = !isCollapsed" class="navbar-toggler navbar-toggler-right" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isCollapsed" class="collapse navbar-collapse">
      <div class="container d-block justify-content-center">
        <app-resource-bar class="row"></app-resource-bar>
      </div>
      <br />

    </div>
  </nav>
</div>
<div class="alerts">
  <ul *ngFor="let value of alerts | keyvalue">
    <table (mouseenter)="value.value.focus = true" (mouseleave)="value.value.focus = false"
      (click)="openAlerts(value.key)" class="fade show alert alert-{{value.value.type}}">
      <tbody>
        <tr>
          <td [innerText]="value.value.msg"></td>
          <td>
            <button type="button" class="btn btn-close" aria-label="Close" (click)="removeAlert(value.key)"></button>
          </td>
        </tr>
      </tbody>
    </table>
  </ul>
</div>

<div class="container-fluid " id="content" *ngIf="hasLoggedIn">
  <div id="sub-content">
    <router-outlet></router-outlet>
  </div>
  <app-event-list></app-event-list>
</div>
