import { Component, ElementRef } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Building } from "src/app/models/Building/Building";

@Component({
  selector: "app-building-details",
  templateUrl: "./building-details.component.html",
  styleUrls: ["./building-details.component.scss"],
})
export class BuildingDetailsComponent {
  building: Building;
  constructor(
    public readonly modal: NgbActiveModal,
    private el: ElementRef,
  ) { }

  public addBuilding(building) {
    this.building = building;
    this.el.nativeElement.closest('.modal').focus();
  }
}
