import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { Ship } from "src/app/models/Ship";
import { UsersService } from "src/app/services/Users/user.service";
import { BaseService } from "src/app/services/bases/base.service";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class ShipService {
  private apiUrl = environment.apiEndpoint;
  /*public shipRef:Map<string,Ship>;

  public getShipRefById(id:string){
    if(this.shipRef[id]){
      return this.shipRef[id];
    }else{
      this.updateShipRef();
    }
    return this.shipRef[id];
  }

  private updateShipRef(){
    this.getShipRef(localStorage.getItem('currentBase')).subscribe(ships=> {
      this.shipRef = ships;
    });
  }*/

  private knownShips = new BehaviorSubject<Ship[]>(null);
  public knownShipsUpdate$ = this.knownShips.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly usersService: UsersService,
    private readonly baseService: BaseService,
  ) {
    if (localStorage.getItem("user")) {
      this.updateShips(JSON.parse(localStorage.getItem("user")).id);
    }
  }

  private updateShips(ownerId: string) {
    this.getKnownShips(ownerId).subscribe({
      next: (ships) => {
        this.knownShips.next(ships)
      },
      error: (err) => {
        if (err.error.error === "Forbidden") {
          this.updateShips(this.usersService.currentUserId());
        }
      }
    });
  }

  private getKnownShips(ownerId: string): Observable<Ship[]> {
    return this.http.get<Ship[]>(this.apiUrl + `ships/owner/${ownerId}/known`);
  }

  public getBuildableShips(baseId?: string): Observable<Ship[]> {
    return this.http.get<Ship[]>(this.apiUrl + `ships/base/${baseId || this.baseService.getCurrentBaseId()}`);
  }

  public getBuildableTurrets(baseId?: string): Observable<Ship[]> {
    return this.http.get<Ship[]>(this.apiUrl + `turrets/base/${baseId || this.baseService.getCurrentBaseId()}`);
  }

  public getBuiltTurrets(baseId: string): Observable<Ship[]> {
    return this.http.get<Ship[]>(this.apiUrl + `turrets/base/${baseId}/built`
    );
  }

  public getBuiltShips(baseId: string): Observable<Ship[]> {
    return this.http.get<Ship[]>(this.apiUrl + `ships/base/${baseId}/built`);
  }

  public buildShips(baseId: string, ship: Ship): Observable<string> {
    return this.http.post<string>(
      this.apiUrl + `ships/owner/${this.usersService.currentUserId()}/base/${baseId}/ships?id=${ship.id}&amount=${ship.buildQuantity}`,
      null
    );
  }

  public getShipRef(baseId: string): Observable<Map<string, Ship>> {
    return this.http.get<Map<string, Ship>>(this.apiUrl + `ships/base/${baseId}/refs`);
  }
}
