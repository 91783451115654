import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceDetail } from "src/app/models/base/resources/ResourceDetail";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private apiUrl = environment.apiEndpoint;

  constructor(
    private readonly http: HttpClient,
  ) { }

  public getResourceDetail(ressourceKey: string, baseId: string): Observable<ResourceDetail> {
    return this.http.get<ResourceDetail>(this.apiUrl + `ressources/detail/${ressourceKey}/base/${baseId}`);
  }
}
