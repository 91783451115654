export class Offer {
  amount: number;
  baseId: string;
  id: string;
  marketId: string;
  ownerId: string;
  ownerName: string;
  productId: string;
  productName: string;
  sell: boolean;
  type: string;
  unitPrice: number;
}
