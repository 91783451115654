<div class="d-flex test2 p-2 custom-bottom">
  <div class="fleets flex-column">
    <ul #nav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="active" class="nav-tabs" ngbNav>
      <li [ngbNavItem]="1">
        <a ngbNavLink i18n="FleetsComponent|Tab for local fleets">Locales (joueur)</a>
        <ng-template ngbNavContent>
          <table class="table table-striped table-dark table-hover fleetTable">
            <tbody>
              <tr *ngIf="turrets" class="turrets justify-content-around p-2">

                <td>{{ turrets.name }}</td>
                <td *ngIf="debug">id: {{ turrets.id }}</td>
                <td>
                  <div i18n="FleetsComponent|Number of turrets of the fleet">Nombre de tourelles : {{ turrets.amount }}</div>
                  <div i18n="FleetsComponent|NTotal power of the fleet">Puissance : {{ turrets.power }}</div>
                </td>
                <td>
                  <div i18n="FleetsComponent|Status of the fleet">Statut : {{ utilsService.translateConstants(turrets.status) }}</div>
                </td>
                <td *ngIf="turrets.status === 'TRANSITING'" >
                  <div i18n="FleetsComponent|Travel time">Temps restant : {{ +this.timerMap.get(turrets.id) | timer:'UTC' }}</div>
                </td>
                <td [ngStyle]="{'display':(turrets.status !== 'FIGHTING' && turrets.status !== 'TRANSITING' && turrets.status !== 'MOVING' && !turrets.destination && !turrets.travelTime)?'table-cell':'none'}">
                  <button
                    (click)="openTurrets(turrets)"
                    class="btn btn-primary"
                    i18n="FleetsComponent|Manage turrets"
                  >
                    Gérer
                  </button>
                </td>
                <td>

                </td>
              </tr>
              <tr
                *ngFor="let fleet of localFleets"
                class="justify-content-around p-2"
              >
                <td>{{ fleet.name }}</td>
                <td *ngIf="debug">id: {{ fleet.id }}</td>
                <td>
                  <div i18n="FleetsComponent|Number of ships of the fleet">Nombre de vaisseaux : {{ fleet.amount }}</div>
                  <div i18n="FleetsComponent|NTotal power of the fleet">Puissance : {{ fleet.power }}</div>
                </td>
                <td>
                  <!--<div>
                    Position : {{fleet.position.coordinates}}
                </div>-->
                  <div i18n="FleetsComponent|Status of the fleet">Statut : {{ utilsService.translateConstants(fleet.status) }}</div>
                  <div i18n="FleetsComponent|Consumption of the fleet">Conso : {{ fleet.instantConsumption }}</div>
                </td>
                <td *ngIf="fleet.status === 'TRANSITING'" >
                  <div i18n="FleetsComponent|Travel time">Temps restant : {{ +this.timerMap.get(fleet.id) | timer:'UTC'}}</div>
                </td>
                <td [ngStyle]="{'display':(fleet.status !== 'FIGHTING' && fleet.status !== 'TRANSITING' && fleet.status !== 'MOVING' && !fleet.destination && !fleet.travelTime)?'table-cell':'none'}">
                  <button
                    (click)="openFleet(fleet)"
                    class="btn btn-primary"
                    i18n="FleetsComponent|Manage fleet"
                  >
                    Gérer
                  </button>
                  <button
                    (click)="moveFleet(fleet)"
                    [ngStyle]="{'visibility':(fleet.amount > 0)?'visible':'hidden'}"
                    class="btn btn-primary"
                    i18n="FleetsComponent|Move fleet"
                  >
                    Déplacer
                  </button>
                  <button
                  (click)="quickRefuel(fleet)"
                  class="btn btn-primary"
                  [ngStyle]="{'visibility': (fleet.status !== 'MOVING' && fleet.status !== 'FIGHTING' && fleet.status !== 'TRANSITING' && fleet.amount > 0)?'visible':'hidden'}"
                  i18n="FleetsComponent|Refuel"
                  >
                    Carburant
                  </button>
                  <button
                    (click)="storageSquadrons(fleet)"
                    class="btn btn-primary"
                    [ngStyle]="{'visibility': (fleet.status !== 'MOVING' && fleet.status !== 'FIGHTING' && fleet.status !== 'TRANSITING' && fleet.amount > 0)?'visible':'hidden'}"
                    i18n="FleetsComponent|Refuel"
                  >
                    Soute
                  </button>
                </td>
                <td>
                  <div *ngIf="fleet" i18n="FleetsComponent|Consumption of the fleet">Stock : {{ fleet.currentFuel }} /  {{ fleet.cumulatedFuelTank }}</div>
                  <div *ngIf="fleet.fuelTime || fleet.fuelTime===0" i18n="FleetsComponent|Autonomy of the fleet">Autonomie restante : {{ (fleet.fuelTime)*1000 +1 |timer:'UTC'}}</div>
                </td>
              </tr>
              <tr [ngStyle]="{'display':(!localFleets)?'':'none'}" class="loading">
              </tr>
            </tbody>
          </table>
          <div class="justify-content-center addFleet">
            <button (click)="addFleet()" class="btn btn-primary">+</button>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink i18n="FleetsComponent|Tab for the player fleets">Joueur</a>
        <ng-template ngbNavContent>
          <table class="table table-striped table-dark table-hover fleetTable">
            <tbody>
              <tr
                *ngFor="let fleet of userFleets"
                class="justify-content-around p-2"
              >
                <td>{{ fleet.name }}</td>
                <td *ngIf="debug">id: {{ fleet.id }}</td>
                <td>
                  <div i18n="FleetsComponent|Number of ships of the fleet">Nombre de vaisseaux : {{ fleet.amount }}</div>
                  <div i18n="FleetsComponent|NTotal power of the fleet">Puissance : {{ fleet.power }}</div>
                </td>
                <td>
                  <div *ngIf="fleet.position" i18n="FleetsComponent|Coordinates where the fleet is">
                    Position : {{ fleet.position.coordinates }}
                  </div>
                  <div i18n="FleetsComponent|Status of the fleet">Statut : {{ utilsService.translateConstants(fleet.status) }}</div>
                </td>
                <td>
                  <div *ngIf="fleet.destination" i18n="FleetsComponent|destination of the fleet">
                    Destination : {{ fleet.destination.coordinates }}
                  </div>
                  <div [ngStyle]="{'visibility': (fleet.travelTime)?'visible':'hidden'}" i18n="FleetsComponent|Time left before reaching the destination">Temps restant : {{ +timerMap.get(fleet.id) |timer:'UTC'}}</div>
                </td>
                <td>
                  <div *ngIf="fleet.instantConsumption" i18n="FleetsComponent|Consumption of the fleet">Conso : {{ fleet.instantConsumption }}</div>
                  <div *ngIf="fleet.fuelTime || fleet.fuelTime===0" i18n="FleetsComponent|Autonomy of the fleet">Autonomie restante : {{ (fleet.fuelTime)*1000 +1 |timer:'UTC'}}</div>
                </td>

                <td [ngStyle]="{'display':(fleet.status !== 'FIGHTING' && fleet.status !== 'TRANSITING')?'table-cell':'none'}">
                  <button
                    *ngIf="fleet.status !== 'MOVING' && !fleet.destination && !fleet.travelTime"
                    (click)="openFleet(fleet)"
                    class="btn btn-primary"
                    i18n="FleetsComponent|Manage fleet"
                  >
                    Gérer
                  </button>
                  <button
                    (click)="storageSquadrons(fleet)"
                    class="btn btn-primary"
                    [ngStyle]="{'visibility': (fleet.status !== 'MOVING' && fleet.status !== 'FIGHTING' && fleet.status !== 'TRANSITING' && fleet.amount > 0 && fleet.currentBaseId)?'visible':'hidden'}"
                    i18n="FleetsComponent|Refuel"
                  >
                    Soute
                  </button>
                  <button *ngIf="fleet.status !== 'OUT_OF_GAS' && fleet.status !== 'MOVING' && !fleet.destination && !fleet.travelTime"
                    (click)="moveFleet(fleet)"
                    [ngStyle]="{'visibility':(fleet.amount > 0)?'visible':'hidden'}"
                    class="btn btn-primary"
                    i18n="FleetsComponent|Move fleet"
                  >
                    Déplacer
                  </button>
                  <button
                    *ngIf="fleet.status === 'MOVING' || fleet.destination || fleet.travelTime"
                    (click)="cancel(fleet.id)"
                    class="btn btn-primary"
                    i18n="FleetsComponent|Move fleet"
                  >
                    Annuler
                  </button>
                  <!--<button
                  (click)="quickRefuel(fleet)"
                  class="btn btn-primary"
                  [ngStyle]="{'visibility': (fleet.status != 'MOVING' && fleet.status != 'FIGHTING' && fleet.status != 'TRANSITING' && fleet.amount > 0)?'visible':'hidden'}"
                  i18n="FleetsComponent|Refuel"
                  >
                    Plein
                  </button>
                  <button
                  (click)="quickUnloadFuel(fleet)"
                  class="btn btn-primary"
                  [ngStyle]="{'visibility': (fleet.status != 'MOVING' && fleet.status != 'FIGHTING' && fleet.status != 'TRANSITING' && fleet.amount > 0)?'visible':'hidden'}"
                  i18n="FleetsComponent|Refuel"
                  >
                    Vidange
                  </button>-->
                </td>
              </tr>
              <tr [ngStyle]="{'display':(!userFleets)?'':'none'}" class="loading">
              </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink i18n="FleetsComponent|every fleets on the base not owned by the base itself">Locales (autres)</a>
        <ng-template ngbNavContent>
          <table class="table table-striped table-dark table-hover fleetTable">
            <tbody>
              <tr
                *ngFor="let fleet of otherFleets"
                class="justify-content-around p-2"
              >
                <td class="openUserProfile" (click)="openUserProfile(fleet.ownerId)">
                  <div>
                    {{ fleet.name }}
                  </div>
                  <div i18n="FleetsComponent|owner of the fleet">Proprio : {{ fleet.ownerName }}</div>
                </td>
                <td *ngIf="debug">id: {{ fleet.id }}</td>
                <td>
                </td>
                <td>
                  <div i18n="FleetsComponent|Status of the fleet">Statut : {{ utilsService.translateConstants(fleet.status) }}</div>
                </td>
                <td>
                  <!--<button class="btn btn-primary" (click)="openFleet(fleetDetailModal, fleet)">Gérer</button>-->
                </td>
              </tr>
              <tr [ngStyle]="{'display':(!otherFleets)?'':'none'}" class="loading">
              </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
      <li>
        <input class="inputSearch" type="search" (input)="onSearch($event.target['value'])" placeholder="Search...">
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
