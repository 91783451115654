import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { User } from "src/app/models";
import { ResourceCumul } from "src/app/models/base/resources/ResourceCumul";
import { BaseService } from "src/app/services/bases/base.service";
import { UsersService } from "src/app/services/Users/user.service";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { ResourceDetailComponent } from "./resource-detail/resource-detail.component";

@Component({
  selector: "app-resource-bar",
  templateUrl: "./resource-bar.component.html",
  styleUrls: ["./resource-bar.component.scss"],
})
export class ResourceBarComponent implements OnDestroy, OnInit {
  resources: Map<string, ResourceCumul> = new Map<string, ResourceCumul>();
  private readonly destroy$ = new Subject();
  user: User;
  private resourcesBar;

  constructor(
    private readonly modalService: NgbModal,
    public readonly baseService: BaseService,
    private readonly usersService: UsersService,
    public readonly utilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.resourcesBar = document.getElementsByClassName("custom-top")[0];
    // timer toutes les secondes qui met à jour les ressources approximativement
    this.baseService.resourcesUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedResources) => {
        if (updatedResources.size > 0) {
          this.resources = updatedResources;
        }
        document.getElementById("sub-content").style.setProperty('--resourcesBarHeight', (this.resourcesBar.offsetHeight) + "px");
      });
    this.usersService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user && user.id) {
          this.user = user;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  openDetail(resourceCode) {
    const modal = this.modalService.open(ResourceDetailComponent, {
      ariaLabelledBy: "resourceDetail",
      scrollable: true,
      size: "xl",
      windowClass: "resourceDetail backModalImg",
      backdrop: true,
    });

    modal.componentInstance.resourceCode = resourceCode;
  }
}
