export class Squadron {
  accuracy: number;
  amount: number;
  armor: number;
  capacity: number;
  consumption: number;
  currentHp: number;
  damage: number;
  fireRate: number;
  fleetId: string;
  formationRound: number;
  hp: number;
  line: number;
  moveTactic: string;
  name: string;
  power: number;
  range: number;
  reloadTime: number;
  restraint: number;
  shield: number;
  shipId: string;
  shootingTactic: string;
  size: number;
  speed: number;
  staticconsumption: number;
  storage: Map<string, number>;
  techId: string;
  travelRange: number;
}
