import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceDetail } from 'src/app/models/base/resources/ResourceDetail';
import { ResourceService } from 'src/app/services/resource/resource.service';

@Component({
  selector: 'app-resource-detail',
  templateUrl: './resource-detail.component.html',
  styleUrls: ['./resource-detail.component.scss']
})
export class ResourceDetailComponent implements OnInit {
  @Input()
  public resourceCode: string;

  public resourceDetail: ResourceDetail;

  constructor(
    public readonly modal: NgbActiveModal,
    private readonly resourceService: ResourceService
  ) { }

  ngOnInit(): void {
    this.resourceService.getResourceDetail(this.resourceCode,localStorage.getItem("currentBase")).subscribe((resources) => {
      this.resourceDetail = resources;
    });
  }

  public hasKeys (object) {
    for(let prop in object) {
      return true;
    }
    return false;
  }

}
