<div  *ngIf="targets.length === 0">
  <label class="d-flex mx-auto my-auto loading"></label>
</div>
<div (keyup.enter)="sendFleet()" *ngIf="targets.length > 0 && planet" class="modal-body d-flex flex-column">
  <div class="mx-auto mb-2 d-flex flex-column">
    <div class="mx-auto mb-2 d-flex flex-row">
      <label i18n="PlanetComponent|">Cible :</label>
      <input (change)="onChangeX($event.target)" class="form-control-distance" id="formationRoundX" [max]="planet.size" min="0"
        [value]='targets[0].coordinates.split(";")[0]' placeholder="x" type="number" />
      <input (change)="onChangeY($event.target)" class="form-control-distance" id="formationRoundY" [max]="planet.size" min="0"
        [value]='targets[0].coordinates.split(";")[1]' placeholder="y" type="number" />
    </div>
    <div class="mx-auto mb-2 d-flex flex-row">
      <label i18n="PlanetComponent|">Taille de la zone :</label>
      <input (change)="onChangeSize($event.target['value'])" class="form-control-distance" id="formationRoundY"
        [max]="base.effects['SEARCH_AREA']" min="1" [value]='size' placeholder="y" type="number" />
    </div>
  </div>

  <div class="mx-auto mb-2 d-flex flex-column flex-wrap">
    target
    <div *ngFor="let target of targets">
      {{target.coordinates}}
    </div>
  </div>
  <label class="mx-auto mb-2" i18n="PlanetComponent|Range">Distance : {{ distance }}</label>
  <label class="mx-auto mb-2" i18n="PlanetComponent|Range">Temps de fouille estimé : {{ rummageTime *1000 | timer: "UTC" }}</label>
  <!-- <label class="mx-auto mb-2" i18n="PlanetComponent|Travel time"> Temps(s) : {{ travelTime}}</label> -->

  <div class="d-flex justify-content-around">
    <button (click)="modal.dismiss('Cancel click')" type="reset" class="btn btn-danger"
      i18n="PlanetComponent|Button cancel">
      Annuler
    </button>
    <button class="btn btn-success" type="button" (click)="sendFleet()" ngbAutoFocus
      i18n="PlanetComponent|Button accept">
      Go !
    </button>
  </div>
</div>
