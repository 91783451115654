import { Component, OnDestroy, OnInit } from "@angular/core";
import { ChildrenOutletContexts } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { BaseService } from "src/app/services/bases/base.service";
import { MarketService } from "src/app/services/Market/market.service";
import { PlanetsService } from "src/app/services/Planets/planets.service";
import { UsersService } from "src/app/services/Users/user.service";
@Component({
  selector: "app-debug",
  templateUrl: "./debug.component.html",
  styleUrls: ["./debug.component.scss"],
})
export class DebugComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  data;
  keys: string[] = [];
  private outletInstance;
  public sourceChoices: string[] = [];

  constructor(
    private readonly modalService: NgbModal,
    private readonly marketService: MarketService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    public readonly modal: NgbActiveModal,
    private readonly router: ChildrenOutletContexts,
    private readonly planetService: PlanetsService,
  ) { }

  ngOnInit() {
    if(!this.data) {
      this.sourceChoices.push('Base')
  //    this.sourceChoices.push('Vaisseaux')
      this.sourceChoices.push('Joueur')
      this.sourceChoices.push('Planète')
      this.sourceChoices.push('Contexte')
    }
  //
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  private convertDatas(elm?) {
    let key = '';
    this.keys = [];
    for (key in this.outletInstance) {
      if (!key.startsWith('_'))
        this.keys.push(key);
    }
    //   util.format();
      this.data = this.outletInstance[elm || key];
    console.error(this.data)
    console.error(this.data.constructor.name)
    if ((this.data.constructor.name !== 'Map' && this.data.constructor.name !== 'Array'))
      this.data = Object.assign({}, this.data);
    console.warn(this.data)

    /* if (this.data.constructor.name === 'Map') {
       this.data = Array.from(this.data.entries() || this.data);
       console.warn(this.data.constructor.name)
     }/** */

    setTimeout(() => {
      try {
        this.data = JSON.stringify(this.data, replacer, 2);
      } catch (exception_var) {
        console.error(exception_var)
        this.data = [];
      }/** */
    })
    function replacer(key, value) {
      if (value) {
        if (!key || (!key.startsWith('_') && !key.startsWith('destroy$') && !key.startsWith('http') && !key.endsWith('Service'))) {
          if (value.constructor.name === 'Map') {
            value = Array.from(value.entries() || value);
          } else if (value.constructor.name === 'AsyncAction') {
            return '';
          } else {
            try {
              JSON.stringify(value);
            } catch {
              for (let keys in value) {
                value[keys] = replacer(keys, value[keys]);
              }
            }
          }
          return value;
        } else {
          return '';
        }
      } else {
        return value;
      }
    }
  }

  public stringify(data) {
    console.error(data);
    return JSON.stringify(data, null, 2);
  }

  onChangeKey(elm) {
    this.destroy$.next("");
    this.convertDatas(elm)
  }

  onChange(elm) {
    this.keys = [];
    this.destroy$.next("");
    console.error(elm);
    if (elm === 'Joueur') {
      this.userService.userUpdate$
        .pipe(takeUntil(this.destroy$))
        .subscribe((user) => this.data = this.stringify(user))
    } else if (elm === 'Planète') {
      this.planetService.planetUpdate$
        .pipe(takeUntil(this.destroy$))
        .subscribe((planet) => this.data = this.stringify(planet))
    } else if (elm === 'Base') {
      this.baseService.baseUpdate$
        .pipe(takeUntil(this.destroy$))
        .subscribe((base) => this.data = this.stringify(base))
    } else if (elm === 'Contexte') {
      this.outletInstance = this.router.getContext('primary').children.getContext('primary').outlet['activated'].instance;
      this.convertDatas();
      // ng.getComponent('');
    }


  }

}
