<div class="startup">

  <div class="container-fluid bg-dark text-white position-relative remove-padding">
    <img src="/assets/img/startup/fond.png" class="img-fluid" style="object-fit: cover; height: 300px; width: 100%;" alt="Game Screenshot">
    <div class="position-absolute top-50 start-50 translate-middle">
      <h1 style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6)">NeedaName Project</h1>
      <p class="lead">Bienvenue sur la plateforme de test du projet</p>
      <div class="prototopbar" *ngIf="byPassTime || (timeBeforeRelease <= 0 && timeBeforeEnd >= 0)">
        <button (click)="doLogin()" class="btn btn-primary">
          Se connecter
        </button>
        <button (click)="doRegister()" class="btn btn-primary">
          S'enregistrer
        </button>
      </div>
    </div>
  </div>
  <div>
    <h3 style="font-size: 16px">
      Le jeu tel que présenté lors des tests est encore bien loin de son état final. C'est une version de test, probablement (même si on espère pas) pleine de bugs. <br>
      Nous espérons donc que vous serez compréhensif, et que cette version inachevée ne vous dégoutera pas du jeu.
      Merci en tout cas de votre participation aux tests, qui nous est d'une grande aide.
    </h3>
    <div id="TimerTest" class="prototopbar underline" *ngIf="timeBeforeEnd < 0">
      <a href="http://needaname.fr">Le test technique est terminé. Le prochain test n'est pas encore planifié. En attendant, retrouvez nous sur Discord !</a>
    </div>
    <div id="TimerTest" class="prototopbar underline" *ngIf="timeBeforeRelease > 0">
      <a href="http://needaname.fr">Le prochain test débutera le {{releaseDate | date: 'full'}}. Les inscriptions sont fermée d'ici là. En attendant, retrouvez nous sur Discord ! <br>
      Temps restant avant le prochain Test : {{timeBeforeRelease | timer: 'UTC'}}</a>
    </div>
    <div id="TimerTest" style="text-align: center" *ngIf="(timeBeforeRelease <= 0 && timeBeforeEnd >= 0)">
      <a href="http://needaname.fr" target="_blank">Le test est en court, vous pouvez vous connecter ! Pour plus d'info, retrouvez nous sur Discord</a><br>
    </div>
  </div>
  <div class="container my-5">
    <hr>
    <div class="row">
      <div class="col-md-4 mb-4">
        <div class="card feature-card">
          <img src="/assets/img/startup/base.png" style="object-fit: cover;" class="card-img-top" alt="Building image">
          <div class="card-body">
            <h5 class="card-title">Gérez vos bases</h5>
            <p class="card-text">Construisez et améliorez vos batiments, effectuez des recherches et installez de nouvelles bases pour monter en puissance !</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="card feature-card">
          <img src="/assets/img/startup/fleet.png" class="card-img-top" alt="Feature Image">
          <div class="card-body">
            <h5 class="card-title">Interagissez avec vos voisins</h5>
            <p class="card-text">Créez des flottes de vaisseaux pour fouiller les planètes, détruisez des PNJ, commercez ou pillez les autres joueurs</p>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="card feature-card">
          <img src="/assets/img/startup/govs.png" class="card-img-top" alt="Feature Image">
          <div class="card-body">
            <h5 class="card-title">Rejoignez un gouvernement</h5>
            <p class="card-text">Choisissez un gouvernement et combattez pour lui ! Elisez vos leaders, ou devenez en un, et parcourez l'univers aux cotés de vos concitoyens </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="bg-dark text-white py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p>&copy; 2023 NeedaName. All rights reserved. <br>
            Most images were generated by Midjourney</p>
        </div>
        <div class="col-md-6 text-md-end">
          <a href="http://needaname.fr " target="_blank" class="text-white">Legal Mention</a>
        </div>
      </div>
    </div>
  </footer>
</div>