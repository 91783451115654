<div class="modal-header">
  <div *ngIf="messages" class="d-flex flex-row align-items-center">
    <h4 class="modal-title me-3" id="modal-basic-title">
      {{ messageThread.name }} du {{ messageThread.initialMessageDate | date: 'fullDate': '' : 'fr-FR' }} à {{
      messageThread.initialMessageDate | date: 'mediumTime': ''
      : 'fr-FR' }}
    </h4>
  </div>
  <button (click)="close('Cross click', $event)" aria-label="Close" class="btn btn-close" type="button">
  </button>
</div>

<div *ngIf="!messages" class="modal-body loading"></div>
<div *ngIf="messages" class="modal-body d-flex flex-column report">
  <div *ngFor="let message of messages">
    {{message.date}}
  </div>
</div>
