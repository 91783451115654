<div class="modal-header">
  <h4 class="modal-title" id="confirmDestroy" i18n="confirmDestroy|Title">Menu de debug de certaines données</h4>
  <div *ngIf="sourceChoices.length > 0">
    <label for="sourceChoices" i18n="Battle-groupComponent|Move tactic">Source: </label>
    <select (change)="onChange($event.target['value'])">
      <option *ngFor="let o of sourceChoices" [selected]="sourceChoices[0] === o">
        {{ o }}
      </option>
    </select>
  </div>
  <div *ngIf="keys.length > 0">
    <label for="keys" i18n="Battle-groupComponent|Move tactic">clé: </label>
    <select (change)="onChangeKey($event.target['value'])">
      <option *ngFor="let o of keys" [selected]="keys[0] === o">
        {{ o }}
      </option>
    </select>
  </div>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button">
  </button>
</div>
<div class="modal-body">
  <pre *ngIf="data">
      <code>
{{ data }}
      </code>
    </pre>
</div>
