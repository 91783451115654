import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MessageThread } from 'src/app/models/message/MessageThread';
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Message } from 'src/app/models/message/Message';
import { environment } from "src/environments/environment";
import { UsersService } from '../Users/user.service';


@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private apiUrl = environment.apiEndpoint;

  constructor(
    private readonly http: HttpClient,
    private readonly usersService: UsersService,
  ) {
    usersService.userUpdate$.subscribe(user => {
      if (user) {
        this.numberOfThreads.next(user.newMessagesAmount || 0);
      }
    })
  }

  private numberOfThreads = new Subject<number>();
  public numberOfThreadsAnnounced$ = this.numberOfThreads.asObservable();


  public getThreads(): Observable<MessageThread[]> {
    return this.http.get<MessageThread[]>(this.apiUrl + `messages`
    ).pipe(
      map(messages => {
        this.numberOfThreads.next(messages.length);
        return messages;
      }),
    );
  }

  public getThreadsLu(): Observable<MessageThread[]> {
    return this.http.get<MessageThread[]>(this.apiUrl + `messages/lu`);
  }

  public getThreadsPaginated(
    start: number,
    end: number
  ): Observable<MessageThread[]> {
    return this.http.get<MessageThread[]>(this.apiUrl + `messages/start/${start}/end/${end}`);
  }


  public getMessagesInThread(threadId: string): Observable<Message[]> {
    return this.http.get<Message[]>(this.apiUrl + `messages/${threadId}`);
  }

  public sendMessageInThread(threadId: string, message: string): Observable<string> {
    return this.http.put<string>(this.apiUrl + `messages/${threadId}`,
      message)
  }

  public createThread(thread: MessageThread): Observable<string> {
    return this.http.post<string>(this.apiUrl + `messages`,
      thread)
  }
}
