<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">
    Liste des alliances
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>

<div class="modal-body">
  <table *ngIf="alliances" class="table table-striped table-hover table-dark planet-table custom-bottom">
    <tbody>
      <tr>
        <td>
          <input class="inputSearch" type="search" (input)="onSearch($event.target['value'])" placeholder="Recherche...">
        </td>
        <td>
        </td>
      </tr>
      <tr>
        <th id="name" (click)="sortByText('name')">
          <i class='bx'></i>
          Nom
        </th>
        <th id="leaderName" (click)="sortByText('leaderName')">
          <i class='bx'></i>
          Chef
        </th>
      </tr>
      <tr *ngFor="let alliance of alliances" (click)="openAlliance(alliance)"
        class="justify-content-around p-2 openUserProfile">
        <td class="align-middle">
          {{alliance.name}}
        </td>
        <td class="align-middle">
          {{alliance.leaderName}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
