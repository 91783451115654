import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Base } from 'src/app/models/base/Base';
import { GlobalPosition } from 'src/app/models/GlobalPosition';
import { Planet } from 'src/app/models/Planet/Planet';
import { Tile } from 'src/app/models/Planet/Tile';
import { BaseService } from 'src/app/services/bases/base.service';
import { OptionsService } from 'src/app/services/options/options.service';
import { PlanetsService } from 'src/app/services/Planets/planets.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';

@Component({
  selector: 'app-target-selector',
  templateUrl: './TargetSelector.component.html',
  styleUrls: ['./TargetSelector.component.scss']
})
export class TargetSelectorComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  public universe: Planet[];
  public planet: Planet;
  currentBase: Base;
  tiles: Tile[];
  objectToMove;
  debug: boolean = false;

  filters = [
    { id: "USER_", value: "Joueurs", selected: true }, // player
    //  { id: "GOV_FREE", value: "bases libres", selected: false }, // free bases
    { id: "GOV_MAIN", value: "Capitales", selected: false }, // capital base
    { id: "NPC_", value: "PNJs", selected: false }, // NPC
    { id: "CAPTURABLE", value: "Capturables", selected: false }, // NPC
    { id: "BUILDING", value: "En construction", selected: false }, // NPC
    { id: "", value: "Toutes", selected: false }, // all
  ];

  constructor(
    private readonly planetService: PlanetsService,
    private readonly baseService: BaseService,
    private readonly utilsService: UtilsService,
    public readonly modal: NgbActiveModal,
    private readonly optionsService: OptionsService,
  ) { }

  ngOnInit(): void {
    this.planetService.universeUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((universe) => {
        if (!this.universe) {
          this.universe = universe
        } else {

        }
      });
    this.planetService.planetUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((planet) => {
        if (planet) {
          this.planet = planet;
          this.onFilter('USER_');
        } else {
          this.planetService.getPlanetById(this.baseService.getCurrentBase().position.planetId).subscribe()
        }
      })
    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        this.currentBase = base;
        this.onChangePlanet(base.position.planetId);
      });
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
    this.planetService.markForPlanetUpdate(this.currentBase.position.planetId);
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  onFilter(changes) {
    let tiles = JSON.parse(JSON.stringify(this.planet.tiles));
    this.tiles = [];
    for (let key in tiles) {
      let tile = tiles[key];
      tile['coordinates'] = key;
      this.tiles.push(tile)
    }
    this.filter(changes);
  }

  filter(value) {
    for (let filter in this.filters) {
      this.filters[filter].selected = (this.filters[filter].id === value);
    }
    let tiles: Tile[] = JSON.parse(JSON.stringify(this.tiles));
    this.tiles = [];
    for (let tile of tiles) {
      if (tile.base && tile.base.type.startsWith(value)) {
        this.tiles.push(tile);
      }
    }
    if (this.objectToMove) {
      for (let tile of this.tiles) {
        let tilePos = new GlobalPosition();
        tilePos.coordinates = tile['coordinates'];
        tile['distance'] = this.utilsService.calculateDistance(this.objectToMove.position, tilePos);
        if (this.objectToMove.speed) {
          tile['travelTime'] = tile['distance'] / this.objectToMove.speed;
        }
      }

    }
  }

  sortByNumber(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.tiles = this.tiles.sort((a, b) => {
      return (parseInt(b[orderType], 16) - parseInt(a[orderType], 16)) * order;
    })
  }



  showOrder(orderType) {
    let ths = document.getElementsByTagName('app-target-selector')[0].getElementsByTagName('th');
    let order = document.getElementById(orderType).getElementsByTagName('i')[0].classList.contains('bx-sort-down');
    for (let i = 0; i < ths.length; i++) {
      if (ths[i].getElementsByTagName('i').length > 0) {
        ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-down')
        ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-up')
      }
    }
    if (order) {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-up')
    } else {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-down')
    }
    return order;
  }

  sortByText(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.tiles = this.tiles.sort((a, b) => {
      return (a[orderType].toLocaleLowerCase() > b[orderType].toLocaleLowerCase() ? 1 : -1) * order;
    })
  }


  onSearch(value) {
    value = value.toLocaleLowerCase();
    let filterId = "";
    for (let filter in this.filters) {
      if (this.filters[filter].selected) {
        filterId = this.filters[filter].id
        break;
      }
    }
    this.onFilter(filterId);
    let tiles = JSON.parse(JSON.stringify(this.tiles))
    this.tiles = [];
    let base;
    for (let tile of tiles) {
      base = tile.base;
      for (let key1 in base) {
        if ((base[key1] + '').toLocaleLowerCase().includes(value)) {
          this.tiles.push(tile);
          break;
        }
      }
      if (tile.coordinates.includes(value)) {
        this.tiles.push(tile);
      }
    }
  }

  select(tile) {
    let position = new GlobalPosition()
    position.planetId = this.planet.id;
    position.coordinates = tile;
    this.modal.close(position)
  }

  onChangePlanet(planetId) {
    if (this.universe) {
      let planet = this.universe.find((planets) => planets.id === planetId);
      if (planet && planet.tiles) {
        this.planet = planet;
        this.onFilter('USER_');
      }
      this.planetService.markForPlanetUpdate(planetId);
    }
  }
}
