<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Squadron-detailComponent|Squadron details">Détails d'unité(s)
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div class="modal-body">
  <div *ngIf="squadrons[0]">
    <table class="table table-striped table-hover table-dark ship-Detail">
      <thead>
        <th style="border:0px">
        </th>
        <th *ngFor="let squadron of squadrons">
          <div class="button">
            <button (click)="remove(squadron)" aria-label="Close" class="btn btn-close" type="reset"></button>
          </div>
          <div class="d-flex justify-content-center flex-column">
            <img alt="{{ squadron.name }}" class="shipImage"
              src="{{ '/assets/img/ships/' + squadron.name + '.png' }}" title="{{ squadron.name }}" />
            <label>
              {{squadron.name}}
            </label>
            <label>
              ({{squadron.fleetName}})
            </label>
            <br>
            <!--            <label> {{squadron.description}} </label>-->
          </div>
        </th>
      </thead>
      <tbody>
        <tr class="justify-content-around p-2">
          <th colspan="1000" class="underline">Stats différentielles</th>
        </tr>
        <tr class="justify-content-around p-2" >
          <td>Quantité restante: </td>
          <td *ngFor="let squadron of squadrons">
            {{squadron.amount || 0}}
          </td>
        </tr>
        <tr class="justify-content-around p-2" >
          <td>Stock de ressources pillées </td>
          <td *ngFor="let squadron of squadrons">
            <div class="d-flex flex-warp align-self-center p-1">
              <div *ngFor="let amount of squadron.storage | keyvalue">
                <div class="p-1 d-flex flex-column">
                  <img class="resourceImg" src="/assets/img/resources/{{ amount.key }}.png" />
                  {{ amount.value }}
                </div>
              </div>
            </div>
          </td>

        </tr>
        <tr class="justify-content-around p-2">
          <th colspan="1000"  class="underline">Stats du vaisseau</th>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Armure</td>
          <td *ngFor="let squadron of squadrons">{{squadron.armor || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Tour de bombardement (?)</td>
          <td *ngFor="let squadron of squadrons">{{squadron.bombardTurn || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Consommation</td>
          <td *ngFor="let squadron of squadrons">{{squadron.consumption || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Dégats (par tir)</td>
          <td *ngFor="let squadron of squadrons">{{squadron.damage || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Nb de tour de formation</td>
          <td *ngFor="let squadron of squadrons">{{squadron.formationRound || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Points de Vie</td>
          <td *ngFor="let squadron of squadrons">{{squadron.hp || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Portée</td>
          <td *ngFor="let squadron of squadrons">{{squadron.range || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Bouclier</td>
          <td *ngFor="let squadron of squadrons">{{squadron.shield || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>tirs par tour</td>
          <td *ngFor="let squadron of squadrons">{{squadron.shoots || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Vitesse</td>
          <td *ngFor="let squadron of squadrons">{{squadron.speed || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Capacité de stockage</td>
          <td *ngFor="let squadron of squadrons">{{squadron.storageCapacity || 0}}</td>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Tactiques</td>
          <td *ngFor="let squadron of squadrons">déplacement: {{squadron.moveTactic || "DEFAULT"}}<br/>tir: {{squadron.shootingTactic || "CLOSER_RANDOM"}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
