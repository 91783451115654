<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Squadron-detailComponent|Squadron details">Détails du Batiment:
    {{building.name}}
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div class="modal-body">
  <div *ngIf="building.id">
    <div class="d-flex flex-column text">
      <img alt="{{ building.name }}" class="shipImage"
        src="{{ '/assets/img/buildings/' + building.name + '.png' }}" title="{{ building.name }}" />
      <label>
        {{building.name}}
      </label>
      <label>
        {{building.description}}
      </label>
    </div>
    <br>
    <div class="buildingReq">
    </div>
    <br>
    <div class="buildingDetails">
      <div *ngFor="
                let resource of building.levels[0].production | keyvalue
              " i18n="BaseComponent|Display de production value of the building">
        Production de {{ resource.key }} : {{ resource.value }}/h
      </div>
      <div *ngFor="let storage of building.levels[0].storage | keyvalue"
        i18n="BaseComponent|Display the storage value of the building">
        Stockage de {{ storage.key }} : {{ storage.value }}
      </div>
      <div *ngFor="let effects of building.levels[0].effects | keyvalue">
        {{ effects.value.libelle }} : {{ effects.value.amount }}
      </div>
      <div *ngFor="let effects of building.levels[0].productionMultiplier | keyvalue"
        i18n="BaseComponent|cumulatedProductionMultiplier">
        Multiplicateur de production de {{ effects.key }} : {{ effects.value }} %
      </div>
      <div *ngFor="let effects of building.levels[0].storageMultiplier | keyvalue"
        i18n="BaseComponent|cumulatedStorageMultiplier">
        Multiplicateur de stockage de {{ effects.key }} : {{ effects.value }} %
      </div>
    </div>
  </div>
</div>
