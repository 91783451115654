<ng-template #Agents let-staff="staff">
  <div>
    {{ staff.name }}
  </div>
</ng-template>

<ng-template #Researchs let-researchId="researchId" let-researchLvl="researchLvl">
  <td class="">{{ getResearchFromId(researchId).name }}
  </td>
  <td class="">{{ researchLvl + 1 }}
  </td>
</ng-template>

<ng-template #Buildings let-base="base">
  <div class="d-flex justify-content-around flex-wrap">
    <div *ngFor="let slot of base.slots | keyvalue: utilsService.originalOrder" class="card slots transparent_bg">
      <img *ngIf="slot.value.built" alt="Image du batiment pour ce slot" class="buildingImg"
        [ngClass]="!slot.value.built.active ? 'grayscale': ''" title="{{ slot.value.built.building.name }}"
        src="{{ '/assets/img/buildings/' + slot.value.built.building.name +'.png'}}" />

      <img *ngIf="!slot.value.built" alt="Image du slot non construit" class="lockedSlotImg"
        src="{{ '/assets/img/slots/' + slot.value.type + '.png' }}" />

      <div class="card-body">
        <h5 *ngIf="!slot.value.built" class="card-title" i18n="BaseComponent|Building Location (when empty)">
          Emplacement {{ slot.key }}
        </h5>
        <h5 *ngIf="slot.value.built" class="card-title invisible my-1"
          i18n="BaseComponent|Building Location (when populated)">
          {{ slot.value.built.building.name }}
        </h5>
        <h5 *ngIf="slot.value.built" class="card-title" i18n="BaseComponent|Building Location (when populated)">
          niveau {{ slot.value.built.level + 1 }}
        </h5>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #squadronId let-before="before">
  <div class="battleGroup">
    <img (click)="squadronDetails(before)" alt="{{ before.name }}" class="shipImage"
      src="{{'/assets/img/ships/' + before.name + '.png'}}" title="{{ before.name }}" />
    x{{ before.amount }}
    <br>
  </div>
</ng-template>
<ng-template #Fleet let-fleet="fleet">
  <div class="fleets my-1">
    <div class="defense">
      <div class="d-flex flex-column justify-content-center text-center tooltip-shipId fleet">
        <div class="fleetName openUserProfile mt-3">
          {{ fleet.name }} - {{ fleet.ownerName }}
        </div>
        <div class="battleFlow d-flex flex-fill justify-content-center defense">
          <div *ngFor="let line of numSequence(fleet.formationMaxLines || 1); let i = index"
            class="battleLine d-flex flex-fill m-3 flex-column justify-content-center">
            <div *ngIf="i !== 0" class="d-flex flex-fill justify-content-center" i18n="Fleet-detailComponent|n line">
              {{ i + 1 }}ème ligne
            </div>
            <div *ngIf="i === 0" class="d-flex flex-fill justify-content-center"
              i18n="Fleet-detailComponent|first line">
              1ère ligne
            </div>
            <div>
              <div *ngFor="let group of fleet.squadrons | keyvalue; let indexOfelement = index">
                <div *ngIf="group.value['line'] === i">
                  <ng-container [ngTemplateOutlet]="squadronId" [ngTemplateOutletContext]="{before:group.value}">
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Resources>
  <div class="resource d-flex flex-wrap">

    <div *ngFor="let resource of report.ressources | keyvalue" class="col-md resource-item flex-column dropdown">
      <label class="resource-value d-flex flex-fill">{{ utilsService.handleResource(resource.value, "amount") }}</label>
      <label class="d-flex flex-fill">
        <img alt={{resource.key}} class="resourceImg d-flex" src="/assets/img/resources/{{resource.key}}.png"
          title={{resource.key}} />
        <ngb-progressbar [max]=resource.value.storage [striped]="true" [value]=resource.value.amount
          [ngClass]="utilsService.resourceColour.get(resource.key)"
          type={{utilsService.resourceColour.get(resource.key)}} class="resource-progressbar d-flex flex-fill">
        </ngb-progressbar>
      </label>
      <div class="dropdown-content resource-content" [ngClass]="utilsService.resourceColour.get(resource.key)">
        <label class="d-flex flex-fill flex-column" style="left:0;">
          <label class="d-flex flex-fill">Production horaire : {{resource.value.production -
            resource.value.consumption}}</label>
          <label class="d-flex flex-fill">Stockage actuel : {{ utilsService.floor(resource.value.amount) }}</label>
          <label class="d-flex flex-fill">Stockage maximum : {{ resource.value.storage }}</label>
        </label>
      </div>
    </div>
  </div>
</ng-template>

<div class="d-flex flex-row justify-content-between modal-header">
  <div *ngIf="report" class="d-flex justify-content-between">
    <h4 class="modal-title me-3" id="modal-basic-title">
      {{ report.libelle }} - {{ report.date | date: 'long' }}
    </h4>
  </div>
  <div *ngIf="getReport" class="d-flex flex-row">
    <button (click)="shareReport()" aria-label="Close" class="btn bx bx-share-alt" type="button"></button>
    <button (click)="close('Cross click', $event)" aria-label="Close" class="btn btn-close" type="button"></button>
  </div>
</div>

<div *ngIf="!report" class="modal-body loading"></div>
<div *ngIf="report" class="modal-body d-flex flex-column report" [ngClass]="{'my-3': !getReport}">
  <div>
    <div *ngIf="getReport">
      {{report.missionReport}}
    </div>
    <ng-container *ngFor="let agent of report.agents" [ngTemplateOutlet]="Agents"
      [ngTemplateOutletContext]="{staff: agent}">
    </ng-container>
    <ng-container *ngIf="report.baseSlotMap" [ngTemplateOutlet]="Buildings"
      [ngTemplateOutletContext]="{base: {slots:report.baseSlotMap}}">
    </ng-container>

    <table *ngIf="report.researchs" class="table table-striped table-hover table-dark test">
      <tbody>
        <tr class="">
          <th class="">
            nom de la recherche
          </th>
          <th class="">
            niveau
          </th>
        </tr>
        <tr class="" *ngFor="let research of report.researchs | keyvalue">
          <ng-container [ngTemplateOutlet]="Researchs"
            [ngTemplateOutletContext]="{researchId: research.key, researchLvl: research.value}">
          </ng-container>
        </tr>
      </tbody>
    </table>
    <ng-container *ngIf="report.ressources" [ngTemplateOutlet]="Resources">
    </ng-container>
  </div>

  <ng-container *ngFor="let fleet of report.fleets" [ngTemplateOutlet]="Fleet"
    [ngTemplateOutletContext]="{fleet:fleet}">
  </ng-container>
</div>
