import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OptionsService {
  constructor() {
    this.init();
  }

  public init() {
    this.options = JSON.parse(localStorage.getItem("options")) || {};
    for (let option in this.options) {
      this.subjectOptions.next(JSON.parse('{\"' + option + '\":\"' + this.options[option] + '\"}'));
    }
  }

  private subjectOptions = new Subject<JSON>();

  public optionsAnnounced$ = this.subjectOptions.asObservable();

  private options;

  public getOptions() {
    return this.options;
  }
  public getOption(key) {
    if (!(key in this.options)) {
      this.setOption(key, false);
    };
    return this.options[key];
  }
  public setOption(key, value) {
    this.options[key] = value;
    localStorage.setItem("options", JSON.stringify(this.options));
    let send = {};
    send[key] = value;
    this.subjectOptions.next(JSON.parse('{\"' + key + '\":\"' + value + '\"}'));
  }
}
