<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Squadron-detailComponent|Squadron details">
    répondre à une offre
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>

<div class="modal-body">
  <div class="d-flex mx-auto flex-column">
    <div class="mx-auto text-center">
      offre: {{offer.productName}}
    </div>
    <img *ngIf="offer.type === 'RESOURCE'" alt="{{ offer.productName }}" class="shipImage mx-auto mb-1" src="{{
      '/assets/img/resources/' + offer.productName + '.png'
    }}" title="{{offer.productName}}" />

    <img *ngIf="offer.type === 'SHIP'" alt="{{ offer.productName }}" class="mx-auto shipImage mb-1" src="{{
      '/assets/img/ships/' + offer.productName + '.png'
    }}" title="{{ offer.productName }}" />

    <input (change)="refreshamount($event.target['value'])" class="form-control-distance mx-auto" id="amountFulfilled"
      [max]="offer.amount" min="0" [value]='amountFulfilled' placeholder="quantité" type="number" />

    <div class="mx-auto">
      prix unitaire: {{offer.unitPrice}}
    </div>
    <div class="mx-auto">
      prix total: {{offer.unitPrice * amountFulfilled}}
    </div>
  </div>
  <div class="d-flex justify-content-center">
    <div>
      <button (click)="fulfillOffer()" class="btn btn-outline-success" i18n="confirmDestroy|Confirm">
        {{offer.sell ? 'Acheter à ' : 'Vendre à '}}{{offer.ownerName}}
      </button>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-outline-danger" i18n="confirmDestroy|Cancel" (click)="modal.dismiss('Cancel click')">
        Annuler
      </button>
    </div>
  </div>
</div>
