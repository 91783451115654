import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { Quest } from 'src/app/models/Quest/Quest';
import { QuestStep } from 'src/app/models/Quest/QuestStep';
import { UsersService } from '../Users/user.service';
import { UtilsService } from '../Utils/utils.service';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class QuestsService {
  private apiUrl = environment.apiEndpoint;
  private availableQuests = new BehaviorSubject<Quest[]>(null);
  public availableQuestsAnnounced$ = this.availableQuests.asObservable();

  private activeQuests = new BehaviorSubject<Quest[]>(null);
  public activeQuestsAnnounced$ = this.activeQuests.asObservable();

  private finishedQuests = new BehaviorSubject<Quest[]>(null);
  public finishedQuestsAnnounced$ = this.finishedQuests.asObservable();

  availableQuestsNeedUpdate: boolean = false;
  activeQuestsNeedUpdate: boolean = false;


  constructor(
    private readonly http: HttpClient,
    private readonly userService: UsersService,
    public readonly utilsService: UtilsService,
  ) {
    userService.userUpdate$.subscribe((user) => {
      if (user) {
        this.getAvailableQuests(user.id).subscribe()
        this.getActiveQuests(user.id).subscribe()
      }
    })


    this.utilsService.timer.subscribe((val) => {
      if (this.availableQuestsNeedUpdate) {
        this.getAvailableQuests(this.userService.currentUserId()).subscribe(() => {

        });
        this.availableQuestsNeedUpdate = false;
      }
      if (this.activeQuestsNeedUpdate) {
        this.getActiveQuests(this.userService.currentUserId()).subscribe(() => {

        });
        this.activeQuestsNeedUpdate = false;
      }

    });
  }

  refreshAvailableQuests() {
    this.availableQuestsNeedUpdate = true;
  }

  refreshActiveQuests() {
    this.activeQuestsNeedUpdate = true;
  }

  public getAvailableQuests(ownerId): Observable<Quest[]> {
    return this.http.get<Quest[]>(this.apiUrl + `quests/owner/${ownerId}/available`).pipe(map((quest) => {
      this.availableQuests.next(quest);
      return quest;
    })
    ).pipe(
      catchError(err => {
        this.availableQuests.error(err);
        return throwError(() => err);
      })
    );
  }

  public getActiveQuests(ownerId): Observable<Quest[]> {
    return this.http.get<Quest[]>(this.apiUrl + `quests/owner/${ownerId}/active`).pipe(map((quest) => {
      this.activeQuests.next(quest);
      return quest;
    })
    ).pipe(
      catchError(err => {
        this.activeQuests.error(err);
        return throwError(() => err);
      })
    );
  }

  public getFinishedQuests(ownerId): Observable<Quest[]> {
    return this.http.get<Quest[]>(this.apiUrl + `quests/owner/${ownerId}/finished`).pipe(map((quest) => {
      this.finishedQuests.next(quest);
      return quest;
    })
    ).pipe(
      catchError(err => {
        this.finishedQuests.error(err);
        return throwError(() => err);
      })
    );
  }

  public abandonQuest(ownerId, questId): Observable<string> {
    return this.http.get<string>(this.apiUrl + `quests/owner/${ownerId}/quest/${questId}/abandon`);
  }

  public finishQuest(ownerId, questId): Observable<string> {
    return this.http.get<string>(this.apiUrl + `quests/owner/${ownerId}/quest/${questId}/finish`);
  }

  public acceptQuest(ownerId, questId): Observable<string> {
    return this.http.get<string>(this.apiUrl + `quests/owner/${ownerId}/quest/${questId}/accept`);
  }

  public refuseQuest(ownerId, questId): Observable<string> {
    return this.http.get<string>(this.apiUrl + `quests/owner/${ownerId}/quest/${questId}/refuse`);
  }

}
