import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { Base } from "src/app/models/base/Base";
import { Building } from "src/app/models/Building/Building";
import { BaseService } from "src/app/services/bases/base.service";
import { EventService } from "src/app/services/event/event.service";
import { EffectDetailComponent } from "../../effects/effect-detail/effect-detail.component";
import { ResourceDetailComponent } from "../../resource-bar/resource-detail/resource-detail.component";

@Component({
  templateUrl: "./buildable.component.html",
  styleUrls: ["./buildable.component.scss"],
})
export class BuildableComponent implements OnInit, OnDestroy {

  @Input()
  base: Base;

  @Input()
  selectedSlot: number = -1;

  @Input()
  buildings: Building[];
  resources;
  private readonly destroy$ = new Subject();

  constructor(
    private readonly modalService: NgbModal,
    private readonly baseService: BaseService,
    private readonly eventService: EventService,
    public readonly modal: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
        }
      });

    this.baseService.resourcesUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedResources) => {
        if (updatedResources.size > 0) {
          this.resources = updatedResources;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  build(building: Building) {
    this.baseService
      .buildNew(this.base.id, this.selectedSlot, 0, building.id)
      .subscribe((base) => {
        this.baseService.updateBase();
      this.eventService.updateBaseEventNeeded();
        this.modalService.dismissAll();
      });
  }

  clickOnEffect(effect) {
    console.error({effectCode:effect.value});
    let modal = this.modalService.open(EffectDetailComponent, {
      ariaLabelledBy: "effectDetail",
      scrollable: true,
      size: "xl",
      windowClass: "effectDetail backModalImg",
      backdrop: true,
    });

    modal.componentInstance.effect = effect.value;
  }

  clickOnResource(resource) {
    console.error({resource:resource});
    let modal = this.modalService.open(ResourceDetailComponent, {
      ariaLabelledBy: "resourceDetail",
      scrollable: true,
      size: "xl",
      windowClass: "resourceDetail backModalImg",
      backdrop: true,
    });

    modal.componentInstance.resourceCode = resource.key
  }
}
