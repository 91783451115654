import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Event } from "src/app/models/Event";
import { BaseService } from "src/app/services/bases/base.service";
import { UsersService } from 'src/app/services/Users/user.service';
import { UtilsService } from "../Utils/utils.service";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class EventService {
  private apiUrl = environment.apiEndpoint;
  private baseEvent = new Subject<Event[]>();
  private playerEvent = new Subject<Event[]>();


  baseEventUpdate$ = this.baseEvent.asObservable();
  playerEventUpdate$ = this.playerEvent.asObservable();

  private playerEventsNeedUpdate = true;
  private playerEventsLockUpdate = false;

  private baseEventsNeedUpdate = true;
  private baseEventsLockUpdate = false;

  constructor(
    private readonly http: HttpClient,
    private readonly userService: UsersService,
    private readonly baseService: BaseService,
    private readonly utilsService: UtilsService,
  ) {
    this.utilsService.timer.subscribe((val) => {
      if (this.baseService.getCurrentBaseId()) {
        //refresh Player event tab
        if (this.playerEventsNeedUpdate === true && !this.playerEventsLockUpdate) {
          this.playerEventsLockUpdate = true;
          if (this.userService.currentUserId()) {
            this.getPlayerEvent(this.userService.currentUserId()).subscribe({
              next: (events) => {
                this.playerEvent.next(events);
                this.playerEventsNeedUpdate = false;
                this.playerEventsLockUpdate = false;
              },
              error: (error) => {
                this.playerEventsLockUpdate = false;
              }
            });
          }
        }

        // refresh Base event tab
        if (this.baseEventsNeedUpdate === true && !this.baseEventsLockUpdate) {
          this.baseEventsLockUpdate = true;
          this.getBaseEvent(this.baseService.getCurrentBaseId()).subscribe({
            next: (events) => {
              this.baseEvent.next(events);
              this.baseEventsNeedUpdate = false;
              this.baseEventsLockUpdate = false;
            },
            error: (error) => {
              this.baseEventsLockUpdate = true;
            }
          });
        }
      }
    });
  }

  public forceUpdates() {

    this.baseEventsLockUpdate = false;
    this.baseEventsNeedUpdate = true;
    this.playerEventsLockUpdate = false;
    this.playerEventsNeedUpdate = true;
  }

  public updateBaseEventNeeded() {
    this.baseEventsNeedUpdate = true;

  }

  public updatePlayerEventNeeded() {
    this.playerEventsNeedUpdate = true;

  }

  public getBasePlayerEvent(
    baseId: string,
    playerId: string
  ): Observable<Event[]> {
    return this.http.get<Event[]>(this.apiUrl + `events/base/${baseId}/player/${playerId}`);
  }

  public getPlayerEvent(playerId: string): Observable<Event[]> {
    return this.http.get<Event[]>(this.apiUrl + `events/player/${playerId}`);
  }

  public getBaseEvent(baseId: string): Observable<Event[]> {
    return this.http.get<Event[]>(this.apiUrl + `events/base/${baseId}`);
  }

  //Permet d'annuler un déplacement
  public cancelTravelByEventId(
    eventId: string
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/moveEvent/${eventId}/cancelMove`,
      null
    );
  }
}
