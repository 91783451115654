import { Component, OnDestroy, OnInit } from "@angular/core";
import { Routes } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { Market } from "src/app/models/Market/Market";
import { Offer } from "src/app/models/Market/Offer";
import { BaseService } from "src/app/services/bases/base.service";
import { MarketService } from "src/app/services/Market/market.service";
import { UsersService } from "src/app/services/Users/user.service";
import { CreateOfferComponent } from "./createOffer/createOffer.component";
import { FulfillOfferComponent } from "./fulfillOffer/fulfillOffer.component";

@Component({
  selector: "app-market",
  templateUrl: "./market.component.html",
  styleUrls: ["./market.component.scss"],
})
export class MarketComponent implements OnInit, OnDestroy {
  static routes: Routes = [{ path: "", component: MarketComponent }];
  private readonly destroy$ = new Subject();
  private markets: Market[];
  public types = ['SHIP', 'RESOURCE'];
  isSell: boolean = true;

  filters = [
    { id: "SHIP", value: "Vaisseaux", selected: false }, // player
    { id: "RESOURCE", value: "Ressources", selected: false }, // capital base
    { id: "", value: "Toutes", selected: true }, // all
  ];
  offers: Offer[] = [];
  originalOffers: Offer[] = [];
  currentUserId = "";

  constructor(
    private readonly modalService: NgbModal,
    private readonly marketService: MarketService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
  ) { }

  ngOnInit() {
    this.marketService.getByBase(this.baseService.getCurrentBaseId()).subscribe((markets) => {
      this.markets = markets;
      this.refreshOffers();
    })
    this.userService.userUpdate$
    .pipe(takeUntil(this.destroy$))
    .subscribe((user) => {
      if (user) {
        this.currentUserId = user.id;
      }
    })
  }

  refreshOffers() {
    this.offers = [];
    this.originalOffers = [];
    for (let market of this.markets) {
      this.marketService.getMarketOffers(market.id, this.isSell).subscribe((offers) => {
        for (let offer of offers) {
          this.offers.push(offer)
          this.originalOffers.push(offer)
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  sortByNumber(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.offers = this.offers.sort((a, b) => {
      return (parseInt(b[orderType], 16) - parseInt(a[orderType], 16)) * order;
    })
  }



  showOrder(orderType) {
    let ths = document.getElementsByTagName('app-market')[0].getElementsByTagName('th');
    let order = document.getElementById(orderType).getElementsByTagName('i')[0].classList.contains('bx-sort-down');
    for (let i = 0; i < ths.length; i++) {
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-down')
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-up')
    }
    if (order) {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-up')
    } else {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-down')
    }
    return order;
  }

  sortByText(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.offers = this.offers.sort((a, b) => {
      return (a[orderType].toLocaleLowerCase() > b[orderType].toLocaleLowerCase() ? 1 : -1) * order;
    })
  }

  createOffer() {
    let createOffer = this.modalService.open(CreateOfferComponent, {
      ariaLabelledBy: "createOfferComponent",
      scrollable: true,
      windowClass: "createOfferComponent backModalImg ",
    });
    createOffer.componentInstance.markets = this.markets;
    createOffer.componentInstance.offer.sell = this.isSell;
    createOffer.dismissed.subscribe(() => {
      this.refreshOffers();
    })
  }

  fulfillOffer(offer: Offer, amount?: number) {
    let fullfilOffer = this.modalService.open(FulfillOfferComponent, {
      ariaLabelledBy: "fullfilOfferComponent",
      scrollable: true,
      windowClass: "fullfilOfferComponent backModalImg ",
    });
    fullfilOffer.componentInstance.offer = offer;
    fullfilOffer.dismissed.subscribe(() => {
      this.refreshOffers();
      this.baseService.updateBase();
      this.userService.updateUser();
    });
  }

  filter(value) {
    for (let filter in this.filters) {
      this.filters[filter].selected = (this.filters[filter].id === value);
    }
    if (!value) {
      this.offers = this.originalOffers;
    } else {
      this.offers = this.originalOffers.filter((offer) => {
        if (offer.type === value) {
          return true;
        }
        return false;
      });
    }
  }

  onSearch(value) {
    let filterId = "";
    for (let filter in this.filters) {
      if (this.filters[filter].selected) {
        filterId = this.filters[filter].id
      }
    }
    this.offers = this.originalOffers.filter((offer) => {
      if (offer.type.includes(filterId)) {
        for (let key in offer) {
          if ((offer[key] + '').toLocaleLowerCase().includes(value)) {
            return true;
          }
        }
      }
      return false;
    });
  }


  onChange(changes) {
    this.filter(changes.target.value);
  }
}
