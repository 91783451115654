<div *ngIf="!report && getReport" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="close('Cross click', $event)" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="report" [ngClass]="!getReport ? 'modal-body': ''"
  class="d-flex flex-wrap justify-content-between modal-header">
  <h4 class="modal-title me-3" id="modal-basic-title">
    {{ report.libelle }} du {{ report.date | date: 'fullDate': '' : 'fr-FR' }} à {{ report.date | date: 'mediumTime': ''
    : 'fr-FR' }}
  </h4>

  <div class="form-check form-switch border border-success rounded border-5">
    <input class="form-check-input" type="checkbox" id="debugRounds" (change)="flipdebugRounds()" [checked]="debug">
    <label class="form-check-label" for="debugRounds">afficher tous les tours du combat</label>
  </div>
  <div *ngIf="getReport">
    <button (click)="shareReport()" aria-label="Close" class="btn bx bx-share-alt" type="button"></button>
    <button (click)="close('Cross click', $event)" aria-label="Close" class="btn btn-close" type="button"></button>
  </div>
</div>

<!-- <div *ngIf="report && !getReport" class=" ">
  <h4 class="modal-title me-3" id="modal-basic-title">
    {{ report.libelle }} du {{ report.date | date: 'full': '' : 'fr-FR' }} - {{ report.id }}
  </h4>

  <div class="form-check form-switch border border-success rounded border-5">
    <input class="form-check-input" type="checkbox" id="debugRounds" (change)="flipdebugRounds()">
    <label class="form-check-label" for="debugRounds">afficher tous les tours du combat</label>
  </div>
</div> -->

<ng-template #BaseOwner let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDisable|Title" id="confirmDisable">Information sur le lieu du combat</h4>
    <button (click)="close('Cross click', $event)" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <label>
      ID de la base: {{ report.baseId }} <br>
      Nom de la base: {{ report.baseName }} <br>
    </label>
  </div>
</ng-template>

<div *ngIf="report" class="modal-body d-flex flex-column report" [ngClass]="{'my-3': !getReport}">
  <h5 class="me-3">
    Ayant eu lieu sur la base de
    <label class="openUserProfile" (click)="openUserProfile(report.baseOwnerId)">{{ report.baseOwnerName }} </label>
    en
    <label data-toggle="modal"
      (click)="modalService.open(BaseOwner,{backdrop: true, scrollable: true, windowClass: 'base-owner backModalImg'})">
      {{ report.position.coordinates }}
    </label>
  </h5>
  <div #squadron class="squadron">
    <ng-template #squadronId let-before="before" let-after="after">
      <div class="battleGroup" [ngClass]="{'destroyedSquadron': !after}">
        <img (click)="squadronDetails(after || before)" alt="{{ before.name }}" class="shipImage"
          src="{{'/assets/img/ships/' + before.name + '.png'}}" title="{{ before.name }}" />
        x{{ after ? after.amount : 0 }}
        <span *ngIf="!after || (after && before.amount !== after.amount)">
          (-{{(before.amount || 0) - (after ? after.amount : 0)}})
        </span>
        <br>
      </div>
    </ng-template>
  </div>
  <div #tooltips class="tooltips">
    <ng-template #shipId let-fleet="fleet" let-finalFleet="finalFleet" let-isAttack="isAttack">
      <div [ngClass]="isAttack? 'attack': 'defense'">
        <div [ngClass]="{'destroyedFleet': !finalFleet}"
          class="d-flex flex-column justify-content-center text-center tooltip-shipId fleet">
          <div class="fleetName openUserProfile mt-3" (click)="openUserProfile(fleet.ownerId)">
            {{ fleet.name }} - {{ fleet.ownerName }}
          </div>
          <div class="battleFlow d-flex flex-fill justify-content-center">
            <div *ngFor="let line of numSequence(fleet.formationMaxLines || 1); let i = index"
              class="battleLine d-flex flex-fill m-3 flex-column justify-content-center">
              <div *ngIf="i !== 0" class="d-flex flex-fill justify-content-center" i18n="Fleet-detailComponent|n line">
                {{ i + 1 }}ème ligne
              </div>
              <div *ngIf="i === 0" class="d-flex flex-fill justify-content-center"
                i18n="Fleet-detailComponent|first line">
                1ère ligne
              </div>
              <div>
                <div *ngFor="let group of fleet.squadrons | keyvalue; let indexOfelement = index">
                  <div *ngIf="group.value['line'] === i" ngbTooltip="{{group.key}}" #tooltip="ngbTooltip"
                    (mouseleave)="onMouseLeave(tooltip)" triggers="manual" (mouseenter)="context(tooltip, group.key)"
                    container="body">
                    <ng-container [ngTemplateOutlet]="squadronId"
                      [ngTemplateOutletContext]="{before:group.value, after: (finalFleet ? finalFleet.squadrons[group.key+''] : null)}">
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="d-flex flex-row">
    <div class="d-flex flex-column flex-fill">
      <div class="d-flex justify-content-center">Attaque</div>
      <ng-container *ngFor="let fleet of report.attackInitialFleet | keyvalue" [ngTemplateOutlet]="shipId"
        [ngTemplateOutletContext]="{fleet: report.attackInitialFleet[fleet.key], finalFleet: report.attackFinalFleet[fleet.key], isAttack: true}">
      </ng-container>
    </div>

    <div class="d-flex flex-column flex-fill">
      <div class="d-flex justify-content-center">Defense</div>
      <ng-container *ngFor="let fleet of report.defenseInitialFleet | keyvalue" [ngTemplateOutlet]="shipId"
        [ngTemplateOutletContext]="{fleet: report.defenseInitialFleet[fleet.key], finalFleet: report.defenseFinalFleet[fleet.key], isAttack: false}">
      </ng-container>
    </div>
  </div>
  <hr />
  <!-- <button (click)="openFight3DComponent()">
    ouvrir le visuel
  </button> -->
  <div *ngFor="let round of report.rounds | keyvalue: originalOrder"
    [ngStyle]="{'display':(debug ||isJSONNotEmpty(round.value.attackPillage) || isJSONNotEmpty(round.value.attackShoot) || isJSONNotEmpty(round.value.defenseShoot) || isJSONNotEmpty(round.value.bombardShoot))?'':'none'}"
    class="round">
    <b>Round {{ round.key }}</b>
    <br />
    <b>Déplacements</b>
    <br />
    <!--    1. join, 2. mouvement attaque, 3. mouvements def-->
    <a *ngIf="isJSONNotEmpty(round.value.attackMove)">
      Attaque
      <div *ngFor="let fleet of round.value.attackMove | keyvalue">
        <div class='ms-3'>
          Déplacement de la flotte '
          <a [ngbTooltip]=shipId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
            (mouseenter)="context(tooltip, {fleet: report.attackInitialFleet[fleet.key], finalFleet: report.attackFinalFleet[fleet.key], isAttack: true})"
            container="body" tooltipClass="customTooltip">
            {{ report.attackInitialFleet[fleet.key].name }}
          </a>
          ' vers la position : {{ fleet.value.fleetNewPos }}
          <div class='ms-4' *ngFor="let squadron of fleet.value.newSquadPos | keyvalue">
            Déplacement de l'escadron '
            <a [ngbTooltip]=squadronId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
              (mouseenter)="context(tooltip, {before: report.attackInitialFleet[fleet.key].squadrons[squadron.key], after: (report.attackFinalFleet[fleet.key] ? report.attackFinalFleet[fleet.key].squadrons[squadron.key] : null)})"
              container="body" tooltipClass="customTooltip black">
              {{ squadron.key }}
            </a>
            ' vers la position : {{ squadron.value }}
          </div>
        </div>
      </div>
    </a>
    <a *ngIf="isJSONNotEmpty(round.value.defenseMove)">
      Defense
      <div *ngFor="let fleet of round.value.defenseMove | keyvalue">
        <div class='ms-3'>
          Déplacement de la flotte '
          <a [ngbTooltip]=shipId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
            (mouseenter)="context(tooltip, {fleet: report.defenseInitialFleet[fleet.key], finalFleet: report.defenseFinalFleet[fleet.key], isAttack: false})"
            container="body" tooltipClass="customTooltip">
            {{ report.defenseInitialFleet[fleet.key].name }}
          </a>
          ' vers la position : {{ fleet.value.fleetNewPos }}
          <div class='ms-4' *ngFor="let squadron of fleet.value.newSquadPos | keyvalue">
            Déplacement de l'escadron '
            <a [ngbTooltip]=squadronId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
              (mouseenter)="context(tooltip, {before: report.defenseInitialFleet[fleet.key].squadrons[squadron.key], after: (report.defenseFinalFleet[fleet.key] ? report.defenseFinalFleet[fleet.key].squadrons[squadron.key] : null)})"
              container="body" tooltipClass="customTooltip black">
              {{ squadron.key }}
            </a>
            ' vers la position : {{ squadron.value }}
          </div>
        </div>
      </div>
    </a>
    <b *ngIf="(isJSONNotEmpty(round.value.attackShoot) && isJSONNotEmpty(round.value.defenseShoot))">
      <br>
      Tirs
      <br>
    </b>
    <a *ngIf="isJSONNotEmpty(round.value.attackShoot)">
      Attaque
      <div *ngFor="let fleet of round.value.attackShoot | keyvalue">
        <div class='ms-3'>
          La flotte '
          <a [ngbTooltip]=shipId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
            (mouseenter)="context(tooltip, {fleet: report.attackInitialFleet[fleet.key], finalFleet: report.attackFinalFleet[fleet.key], isAttack: true})"
            container="body" tooltipClass="customTooltip">
            {{ report.attackInitialFleet[fleet.key].name }}
          </a>' :

          <div *ngFor="let shoots of fleet.value.squadronShoots | keyvalue">
            <div class='ms-3'>
              L'escadron '
              <a [ngbTooltip]=squadronId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
                (mouseenter)="context(tooltip, {before: report.attackInitialFleet[fleet.key].squadrons[shoots.key], after: (report.attackFinalFleet[fleet.key] ? report.attackFinalFleet[fleet.key].squadrons[shoots.key] : null)})"
                container="body" tooltipClass="customTooltip black">
                {{ shoots.key }}
              </a>
              ' tire :
              <br>
              <div class='ms-4' *ngFor="let squadronShot of shoots.value.squadronHits | keyvalue">
                {{ squadronShot.value.shoot }} fois pour un total de {{ squadronShot.value.damage }} ({{
                squadronShot.value.mitigated }} sont absorbés) sur '
                <!--            <a [ngbTooltip]=squadronId #tooltip="ngbTooltip"
             (mouseleave)="onMouseLeave(tooltip)"
            triggers="manual"
            (mouseenter)="context(tooltip, {before: report.attackInitialFleet[squadronShot.key].squadrons[squadronShot.key], after: (report.attackFinalFleet[squadronShot.key] ? report.attackFinalFleet[squadronShot.key].squadrons[squadronShot.key] : null)})" container="body" tooltipClass="customTooltip black">-->
                {{ squadronShot.key }}
                <!--            </a>-->
                '. {{ squadronShot.value.destroyedShips }} sont détruit
                <a *ngIf="squadronShot.value.restraint">
                  , et {{ squadronShot.value.restraint }} sont retenus
                </a>
                .
                <a *ngIf="squadronShot.value.destroyed">
                  L'escadron ennemi est détruit.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
    <a *ngIf="isJSONNotEmpty(round.value.defenseShoot)">
      Défense
      <div *ngFor="let fleet of round.value.defenseShoot | keyvalue">
        <div class='ms-3'>
          La flotte '
          <a [ngbTooltip]=shipId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
            (mouseenter)="context(tooltip, {fleet: report.defenseInitialFleet[fleet.key], finalFleet: report.defenseFinalFleet[fleet.key], isAttack: false})"
            container="body" tooltipClass="customTooltip">
            {{ report.defenseInitialFleet[fleet.key].name }}
          </a>' :

          <div *ngFor="let shoots of fleet.value.squadronShoots | keyvalue">
            <div class='ms-3'>
              L'escadron '
              <a [ngbTooltip]=squadronId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
                (mouseenter)="context(tooltip, {before: report.defenseInitialFleet[fleet.key] ? report.defenseInitialFleet[fleet.key].squadrons[shoots.key]:null, after: (report.defenseFinalFleet[fleet.key] ? report.defenseFinalFleet[fleet.key].squadrons[shoots.key] : null)})"
                container="body" tooltipClass="customTooltip black">
                {{ shoots.key }}
              </a>
              ' tire :
              <br>
              <div class='ms-4' *ngFor="let squadronShot of shoots.value.squadronHits | keyvalue">
                {{ squadronShot.value.shoot }} fois pour un total de {{ squadronShot.value.damage }} ({{
                squadronShot.value.mitigated }} sont absorbés) sur '
                <!--            <a [ngbTooltip]=squadronId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual" (mouseenter)="context(tooltip, {before: report.defenseInitialFleet[squadronShot.key].squadrons[squadronShot.key], after: (report.defenseFinalFleet[squadronShot.key] ? report.attackFinalFleet[squadronShot.key].squadrons[squadronShot.key] : null)})" container="body" tooltipClass="customTooltip black">-->
                {{ squadronShot.key }}
                <!--            </a>-->
                '. {{ squadronShot.value.destroyedShips }} sont détruit
                <a *ngIf="squadronShot.value.restraint">
                  , et {{ squadronShot.value.restraint }} sont retenus
                </a>
                .
                <a *ngIf="squadronShot.value.destroyed">
                  L'escadron ennemi est détruit.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
    <b *ngIf="isJSONNotEmpty(round.value.attackPillage)">
      <br>
      Pillage
    </b>
    <a *ngIf="isJSONNotEmpty(round.value.attackPillage)">
      <div *ngFor="let fleet of round.value.attackPillage | keyvalue">
        <div class='ms-3'>
          La flotte '
          <a [ngbTooltip]=shipId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
            (mouseenter)="context(tooltip, {fleet: report.attackInitialFleet[fleet.key], finalFleet: report.attackFinalFleet[fleet.key], isAttack: true})"
            container="body" tooltipClass="customTooltip">
            {{ report.attackInitialFleet[fleet.key].name }}
          </a>' pille pour un total de :
          <label *ngFor="let amount of fleet.value.pilled | keyvalue">
            <label [ngbTooltip]="amount.value+''" #tooltip="ngbTooltip" container="body" class="p-1 d-flex flex-column">
              <img class="resourceImg" src="/assets/img/resources/{{ amount.key }}.png" />
              {{ utilsService.nFormatter(amount.value) }}
            </label>
          </label>

          <div *ngFor="let shoots of fleet.value.squadronPillage | keyvalue">
            <div class='ms-3'>
              L'escadron '
              <a [ngbTooltip]=squadronId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
                (mouseenter)="context(tooltip, {before: report.attackInitialFleet[fleet.key].squadrons[shoots.key], after: (report.attackFinalFleet[fleet.key] ? report.attackFinalFleet[fleet.key].squadrons[shoots.key] : null)})"
                container="body" tooltipClass="customTooltip black">
                {{ shoots.key }}
              </a>pille pour un total de :
              <label *ngFor="let amount of fleet.value.squadronPillage[shoots.key] | keyvalue">
                <label [ngbTooltip]="amount.value+''" #tooltip="ngbTooltip" container="body"
                  class="p-1 d-flex flex-column">
                  <img class="resourceImg" src="/assets/img/resources/{{ amount.key }}.png" />
                  {{ utilsService.nFormatter(amount.value) }}
                </label>
              </label>
            </div>
          </div>
        </div>
      </div>
    </a>
    <b *ngIf="isJSONNotEmpty(round.value.bombardShoot)">
      <br>
      Bombardement
    </b>
    <a *ngIf="isJSONNotEmpty(round.value.bombardShoot)">
      <div *ngFor="let fleet of round.value.bombardShoot | keyvalue">
        <div class='ms-3'>
          La flotte '
          <a [ngbTooltip]=shipId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
            (mouseenter)="context(tooltip, {fleet: report.attackInitialFleet[fleet.key], finalFleet: report.attackFinalFleet[fleet.key], isAttack: false})"
            container="body" tooltipClass="customTooltip">
            {{ report.attackInitialFleet[fleet.key].name }}
          </a>' :

          <div *ngFor="let shoots of fleet.value.squadronShoots | keyvalue">
            <div class='ms-3'>
              L'escadron '
              <a [ngbTooltip]=squadronId #tooltip="ngbTooltip" (mouseleave)="onMouseLeave(tooltip)" triggers="manual"
                (mouseenter)="context(tooltip, {before: report.attackInitialFleet[fleet.key].squadrons[shoots.key], after: (report.attackFinalFleet[fleet.key] ? report.attackFinalFleet[fleet.key].squadrons[shoots.key] : null)})"
                container="body" tooltipClass="customTooltip black">
                {{ shoots.key }}
              </a>
              ' tire :
              <br>
              <div class='ms-4' *ngFor="let squadronShot of shoots.value.squadronHits | keyvalue">
                {{ squadronShot.value.shoot }} fois pour un total de {{ squadronShot.value.damage }} ({{
                squadronShot.value.mitigated }} sont absorbés) sur '
                <!--            <a [ngbTooltip]=squadronId #tooltip="ngbTooltip"
             (mouseleave)="onMouseLeave(tooltip)"
            triggers="manual"
            (mouseenter)="context(tooltip, {before: report.attackInitialFleet[squadronShot.key].squadrons[squadronShot.key], after: (report.attackFinalFleet[squadronShot.key] ? report.attackFinalFleet[squadronShot.key].squadrons[squadronShot.key] : null)})" container="body" tooltipClass="customTooltip black">-->
                {{ squadronShot.key }}
                <!--            </a>-->
                '. {{ squadronShot.value.destroyedShips }} sont détruit
                <a *ngIf="squadronShot.value.restraint">
                  , et {{ squadronShot.value.restraint }} sont retenus
                </a>
                .
                <a *ngIf="squadronShot.value['destroyed']">
                  L'escadron ennemi est détruit.
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<div *ngIf="report && !getReport" class="d-flex flex-fill vh-100">
</div>
