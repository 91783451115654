import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import mermaid from "Mermaid";
import { Subject, takeUntil } from "rxjs";
import { BuildingDetailsComponent } from "src/app/main/bases/buildingDetails/building-details.component";
import { UnitDetailComponent } from "src/app/main/fleets/unit-detail/unit-detail.component";
import { Alert } from 'src/app/models/Alert';
import { CommonRef } from 'src/app/models/CommonRef';
import { AlertService } from 'src/app/services/alert/alert.service';
import { BuildingsService } from "src/app/services/Buildings/buildings.service";
import { OptionsService } from 'src/app/services/options/options.service';
import { ResearchService } from "src/app/services/research/research.service";
import { ShipService } from "src/app/services/ships/ship.service";
import { UsersService } from 'src/app/services/Users/user.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';

@Component({
  selector: 'app-techno-tree',
  templateUrl: './technoTree.component.html',
  styleUrls: ['./technoTree.component.scss']
})
export class TechnoTreeComponent implements OnInit, OnDestroy {
  private playerId: string;
  private readonly destroy$ = new Subject();

  private elements: Map<string, CommonRef> = new Map<string, CommonRef>();
  private colors = { "building": "#A44043", "researchElement": "#9370DB", "ship": "#277BC6" };
  private currentLevels: Map<string, number>;

  public isRendered = false;
  filterType = "";
  filterName = "";

  constructor(
    private readonly buildingsService: BuildingsService,
    private readonly shipService: ShipService,
    private readonly researchService: ResearchService,
    private readonly modalService: NgbModal,
    private readonly optionService: OptionsService,
    private readonly usersService: UsersService,
    private readonly alertService: AlertService,
    public readonly utilsService: UtilsService,
    private readonly router: Router,
  ) {
    this.currentLevels = new Map<string, number>();
    this.usersService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          for (let key in user.researchMap) {
            this.currentLevels.set(key, +user.researchMap[key] + 1);
          }
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  filter = ["", "building", "ship", "researchElement"]
  onChange(event) {
    this.isRendered = false;
    this.filterType = this.filter[event.nextId - 1];
    this.ngOnInit();
  }

  onSearch(value: string) {
    this.isRendered = false;
    this.filterName = value.trim();
    this.ngOnInit();
  }

  addPaths(id) {
    let element = this.elements.get(id);
    if (!(element.type.includes(this.filterType)))
      return;
    let reqs = new Map<string, number>();
    let tempReqs = {};
    if (element.type === "building" && element.id !== this.HQId) {
      element['levels'][0].buildingReq[this.HQId] = element['levels'][0].buildingReq[this.HQId] || 0;
    } else if (element.type === "researchElement") {
      element['levels'][0].buildingReq[this.LaboratoryId] = element['levels'][0].buildingReq[this.LaboratoryId] || 0;
    }
    if (element['levels']) {
      tempReqs = {
        ...element['levels'][0].researchReq,
        ...element['levels'][0].buildingReq
      }
    } else {
      tempReqs = {
        ...element['researchReq'],
        ...element['buildingReq']
      }
    }
    if (element.name.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase())) {
      for (let temp in tempReqs) {
        reqs.set(temp, tempReqs[temp]);
      }
    } else {
      for (let temp in tempReqs) {
        if (this.elements.get(temp).name.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase())) {
          reqs.set(temp, tempReqs[temp]);
        }
      }
    }
    for (let req of reqs.keys()) {
      if (this.filterType === "ship" && this.elements.get(req).type === "researchElement") {
        document.getElementsByClassName('mermaidTest')[0].innerHTML = req + "[" + this.elements.get(req).name + "] ---->|" + (reqs.get(req) + 1) + "| " + element.id + "(" + element.name + ")\n"
      } else {
        document.getElementsByClassName('mermaidTest')[0].innerHTML = req + "[" + this.elements.get(req).name + "] -->|" + (reqs.get(req) + 1) + "| " + element.id + "(" + element.name + ")\n"
      }
      let text = document.getElementsByClassName('mermaidTest')[0].innerHTML;
      let split = document.getElementsByClassName('mermaid')[0].innerHTML.split(text);
      if (split.length < 2) {
        document.getElementsByClassName('mermaid')[0].innerHTML += text;
      }

      //style
      document.getElementsByClassName('mermaidTest')[0].innerHTML = "style " + req + " fill:" + this.colors[this.elements.get(req).type] + ",stroke:" + this.colors[this.elements.get(req).type] + ",stroke-width:2px,color:#fff,stroke-dasharray: 5 5\n"
      text = document.getElementsByClassName('mermaidTest')[0].innerHTML;
      split = document.getElementsByClassName('mermaid')[0].innerHTML.split(text);
      if (split.length < 2) {
        document.getElementsByClassName('mermaid')[0].innerHTML += text;
      }

      document.getElementsByClassName('mermaidTest')[0].innerHTML = "style " + element.id + " fill:" + this.colors[element.type] + ",stroke:" + this.colors[element.type] + ",stroke-width:2px,color:#fff,stroke-dasharray: 5 5\n"
      text = document.getElementsByClassName('mermaidTest')[0].innerHTML;
      split = document.getElementsByClassName('mermaid')[0].innerHTML.split(text);
      if (split.length < 2) {
        document.getElementsByClassName('mermaid')[0].innerHTML += text;
      }
      this.addPaths(req);
    }
  }

  ngOnInit(): void {
    if (document.getElementsByClassName('mermaid')[0].attributes.getNamedItem('data-processed')) {
      document.getElementsByClassName('mermaid')[0].attributes.removeNamedItem('data-processed')
    }
    document.getElementsByClassName('mermaid')[0].innerHTML = 'graph ' +
      (this.optionService.getOption('heightWiseTechTree') ? 'LR' : 'TD') + ' \n';
    this.destroy$.next("");
    setTimeout(() => { this.getKnownBuildings() }, 0);
  }

  getKnownBuildings() {
    let HQLvl = -1;
    if (this.buildingsService.buildingsMap.size > 0) {
      for (let key of this.buildingsService.buildingsMap.keys()) {
        if (this.buildingsService.buildingsMap.get(key).name === "HQ") {
          HQLvl = this.buildingsService.buildingsMap.get(key)["currentLevel"];
          if(window.location.pathname === '/technologytree') {
            HQLvl = 0
          }
          break;
        }
      }
    }
    let buildingsMap = this.buildingsService.buildingsMap;
    for (let key of buildingsMap.keys()) {
      if (buildingsMap.get(key)["currentLevel"] == 0) {
        this.currentLevels.set(key, 1);
      } else {
        this.currentLevels.set(key, (+buildingsMap.get(key)["currentLevel"] || -1) + 1);
      }
    }
    if (HQLvl > -1) {
      this.getKnownResearch();
    } else {
      setTimeout(() => { this.getKnownBuildings() }, 1000);
    }
  }

  getKnownResearch() {
    this.researchService.researchUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((researchElements) => {
        if (researchElements) {
          this.getKnownShips(JSON.parse(JSON.stringify(researchElements)));
        }
      });
  }

  HQId: string;
  LaboratoryId: string;
  getKnownShips(researchElements) {
    this.shipService.knownShipsUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ships) => {
        if (ships) {
          for (let key of this.buildingsService.buildingsMap.keys()) {
            let building = this.buildingsService.buildingsMap.get(key);
            if (building.name === "HQ") {
              this.HQId = building.id;
            } else if (building.name === "Laboratoire") {
              this.LaboratoryId = building.id;
            }
            building.type = "building";
            this.elements.set(building.id, building);
          }

          for (let researchElement of researchElements) {
            researchElement.type = "researchElement";
            this.elements.set(researchElement.id, researchElement);
          }

          for (let ship of ships) {
            ship.type = "ship";
            this.elements.set(ship.id, ship);
          }

          let elements = new Map<string, CommonRef>()
          this.elements.forEach((element, key) => {
            if (element.name.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase())) {
              elements.set(key, element);
            }
          })
          if (elements.size > 0) {
            for (let id of this.elements.keys()) {
              let element = this.elements.get(id);
              if (element.id) {
                this.addPaths(element.id);
              }
            }

            // format strokes :
            let strokes = document.getElementsByClassName('mermaid')[0].innerHTML.split('--&gt;')
            for (let key in strokes) {
              let lines = strokes[key].split('\n');
              let id = lines[lines.length - 1].split('[')[0];
              if (id) {
                let lvl = +strokes[(+key) + 1].split('|')[1];
                if ((this.currentLevels.get(id) || -1) >= lvl) {
                  document.getElementsByClassName('mermaid')[0].innerHTML += `linkStyle ${key} stroke:chartreuse,stroke-width:2px;\n`
                } else {
                  document.getElementsByClassName('mermaid')[0].innerHTML += `linkStyle ${key} stroke:salmon,stroke-width:2px;\n`
                }
              }
            }
            mermaid.initialize({
              startOnLoad: false,
              deterministicIds: true,
              flowchart: {
                curve: 'linear',
                useMaxWidth: false,
              }
            });
            mermaid.run().finally(() => {
              let paths = document.getElementsByClassName('mermaid')[0].getElementsByTagName('path');
              let path = paths.item(0);
              let mouseDown = function (event) {
                for (let idx = 0; idx < paths.length; idx++) {
                  paths.item(idx).style.strokeOpacity = '0.35';
                }
                event.target['style'].strokeOpacity = '1';
              };
              let mouseUp = function () {
                for (let idx = 0; idx < paths.length; idx++) {
                  paths.item(idx).style.strokeOpacity = '1';
                }
              }
              for (let i = 0; i < paths.length; i++) {
                path = paths.item(i);
                path.addEventListener('mousedown', (event) => {
                  mouseDown(event);
                }, { passive: true });

                path.addEventListener('touchstart', (event) => {
                  mouseDown(event);
                }, { passive: true });
              }

              document.addEventListener('mouseup', (event) => {
                mouseUp();
              }, { passive: true });

              document.addEventListener('touchend', () => {
                mouseUp();
              }, { passive: true });
              this.isRendered = true;
              let nodes = document.getElementsByClassName('node');
              let keyNumber = 0;
              let node = nodes.item(0);
              for (let key in nodes) {
                keyNumber = +key;
                if (keyNumber) {
                  node = nodes.item(keyNumber);
                  node.addEventListener(
                    "click",
                    (event) => { this.clickEvent(event); },
                    { passive: true }
                  );
                }
              };
            })
          } else {
            document.getElementsByClassName('mermaid')[0]['innerText'] = 'Aucuns éléments trouvés';
            this.isRendered = true;
          }
        }
      });
  }


  unitDetailModal;
  buildingDetailModal;
  clickEvent(event) {
    let target = event.target;
    if (event.target.nextSibling) {
      target = event.target.nextSibling.firstChild.firstChild.firstChild;
    }
    let node;
    for (let id of this.elements.keys()) {
      let element = this.elements.get(id);
      if (element.name === target.innerText) {
        node = element;
        break;
      }
    }
    if (node.type === "ship") {
      if (!this.unitDetailModal) {
        this.unitDetailModal = this.modalService.open(UnitDetailComponent, {
          ariaLabelledBy: "ShipDetail",
          scrollable: true,
          size: "lg",
          windowClass: "fleet-ShipDetail backModalImg",
          backdrop: false,
        });
      }
      this.unitDetailModal.componentInstance.addUnit(node);
      this.unitDetailModal.hidden.subscribe(() => {
        this.unitDetailModal = null;
      });
    } else if (node.type === "building") {
      if (!this.buildingDetailModal) {
        this.buildingDetailModal = this.modalService.open(BuildingDetailsComponent, {
          ariaLabelledBy: "BuildingDetail",
          scrollable: true,
          size: "lg",
          windowClass: "BuildingDetail backModalImg",
          backdrop: true,
        });
      }
      this.buildingDetailModal.componentInstance.addBuilding(node);
      this.buildingDetailModal.hidden.subscribe(() => {
        this.buildingDetailModal = null;
      });
    }
  }

  shareTechnologyTree() {

    let alert = new Alert();
    let result = navigator.clipboard.writeText(window.location.origin + `/technologytree`);
    result.then((reason) => {
      alert.type = "success";
      alert.msg =
        `Le lien vers le rapport de combat a bien été copié
          il ne peut pas encore etre partagé à d'autres mais le lien pourrai vous être utile`;
      alert.timeout = 2000;
      this.alertService.addAlert(alert);/** */

    })
      .catch((error) => {
        console.error(error)
        if (error) {
          alert['datas'] = { error: error };
        }
        alert.type = "danger";
        alert.msg = "Le lien vers le rapport de combat n'a pas pu être copié";
        alert.timeout = 10000;
        this.alertService.addAlert(alert);/** */
      })
  }
}
