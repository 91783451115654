import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { ShowAllianceComponent } from "src/app/main/alliance/showAlliance/showAlliance.component";
import { GovernmentRef } from "src/app/models";
import { Alliance } from "src/app/models/Alliance/Alliance";
import { UserProfile } from "src/app/models/user/UserProfile";
import { AllianceService } from "src/app/services/Alliance/alliance.service";
import { BaseService } from "src/app/services/bases/base.service";
import { GovernmentService } from "src/app/services/Government/government.service";
import { UsersService } from 'src/app/services/Users/user.service';

@Component({
  selector: "app-show-profile",
  templateUrl: "./show-profile.component.html",
  styleUrls: ["./show-profile.component.scss"],
})
export class ShowProfileComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject();

  ownerId: string;
  profile: UserProfile;

  selectedGovernment: GovernmentRef;
  constructor(
    private readonly router: Router,
    private readonly baseService: BaseService,
    private readonly governmentService: GovernmentService,
    private readonly userService: UsersService,
    private readonly modalService: NgbModal,
    public readonly modal: NgbActiveModal,
    public readonly allianceService: AllianceService,
  ) {
  }

  ngOnInit() {
    this.userService.getProfileByUserId(this.ownerId).subscribe((profile) => {
      this.profile = profile;
      if (profile.governmentId) {
        this.governmentService.getGovernmentRef(this.profile.governmentId).subscribe((government) => {
          this.selectedGovernment = government;
        });
      }
    });
  }

  showAlliance() {
    this.allianceService.getAllianceRefById(this.profile.allianceId).subscribe((alliance) => {
      let showAllianceComponent = this.modalService.open(ShowAllianceComponent, {
        ariaLabelledBy: "modal-show-alliance",
        scrollable: true,
        size: "xl",
        windowClass: "showAlliance backModalImg",
      });
      showAllianceComponent.componentInstance.allianceRef = alliance;
    });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }
}
