import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { Ship } from "src/app/models/Ship";
import { Squadron } from "src/app/models/Squadron";
import { FleetService } from "src/app/services/fleets/fleet.service";

@Component({
  selector: "app-battle-group",
  templateUrl: "./template-squadron.component.html",
  styleUrls: ["./template-squadron.component.scss"],
})
export class TemplateSquadronComponent implements OnInit {
  @Input()
  public selectedFleet: Fleet;

  @Input()
  public sourceFleetId: string;

  @Input()
  public selectedZone: number;

  @Input()
  public selectedShip: Ship;

  @Input()
  public availableShips: Ship[];

  @Input()
  public sourceSquadron: Squadron;

  @Input()
  public targetSquadron: Squadron;

  @Input()
  public modalType: number;

  @Input()
  readonly staticModalType;

  public doSelectShip: boolean = false;
  public doTactic: boolean = false;
  public doAmount: boolean = true;
  public title= "Affectation des vaisseaux";

  readonly moveTactics: string[] = ["DEFAULT", "RUSH", "ESCAPE", "CHASE"];;
  readonly shootingTactics: string[] = ["CLOSER_RANDOM"];

  public frmShip: FormGroup;
  get amount() {
    return this.frmShip.get("amount");
  }
  get moveTactic() {
    return this.frmShip.get("moveTactic");
  }
  get shootingTactic() {
    return this.frmShip.get("shootingTactic");
  }
  get formationRound() {
    return this.frmShip.get("formationRound");
  }

  constructor(
    protected readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal, // needed in HTML
    protected readonly fleetService: FleetService,
  ) {}

  ngOnInit() {
    this.doTactic = !this.targetSquadron;

    this.frmShip = this.fb.group({
      availableShips: [this.availableShips.indexOf(this.selectedShip), Validators.required],
      amount: [
        this.selectedShip.amount,
        this.selectedShip ? Validators.required : null,
      ],
      moveTactic: [this.selectedShip['moveTactic'] || "DEFAULT", Validators.required],
      shootingTactic: [this.selectedShip['shootingTactic'] || "CLOSER_RANDOM", Validators.required],
      formationRound: [this.selectedShip['formationRound'] || 0, Validators.required],
    });
  }

  onSelectedShip (index) {
    this.selectedShip = this.availableShips[index];
    this.frmShip.patchValue({amount: this.selectedShip.amount});
  }

  public onSubmit(): boolean {
    return false;
  }

  isJSONNotEmpty(json) {
    for (let prop in json) {
      return true;
    }
    return false;
  }
}
