import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Menu } from 'src/app/models/Menu/Menu';
import { MenuService } from 'src/app/services/Menu/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss', "../style.css"]
})
export class MenuComponent implements OnInit {

  private elements;
  private draggedMenu;

  constructor(
    public readonly modal: NgbActiveModal,
    public readonly menuService: MenuService,
  ) { }

  ngOnInit(): void {
    document.getElementsByClassName('customizeMenu')[0].getElementsByClassName('modal-content')[0].addEventListener('scroll', () => this.onScroll(), { passive: true });
    document.getElementsByClassName('customizeMenu')[0].getElementsByClassName('modal-content')[0].addEventListener('touchmove', () => this.onScroll(), { passive: true });
  }



  cleanAfterDrag() {
    for (let element of this.elements) {
      element.classList.remove('margin-bottom')
      element.classList.remove('margin-top')
    }
  }

  selectMenuToMove(event, menu) {
    this.elements = event.target.parentElement.children;
    this.draggedMenu = menu.redirect;
  }

  drop(event) {
    let element = document.getElementsByClassName('margin-bottom')[0] || document.getElementsByClassName('margin-top')[0]
    let isTop = document.getElementsByClassName('margin-top')[0]
    if (!element) {
      console.error('pas de move')
    } else {
      let insertName = element.getElementsByClassName('links_name')[0]['innerText']
      let dragged: Menu;
      let indexInsert = -1;
      let indexDragged = -1;
      this.menuService.menu.filter((menu, index) => {
        if (menu.redirect === this.draggedMenu) {
          dragged = menu;
          indexDragged = index;
        } else {
          if (menu.name === insertName) {
            indexInsert = index;
          }
        }
        return false;
      })
      if (!dragged) {
        dragged = JSON.parse(JSON.stringify(this.menuService.knownMenu.get(this.draggedMenu)))
      }
      if (indexInsert >= 0) {
        if (!isTop) {
          indexInsert += 1
        }
        this.menuService.menu.splice(indexInsert, 0, dragged);
        if (indexDragged >= 0) {
          if (indexInsert > indexDragged) {
            this.menuService.menu.splice(indexDragged, 1);
          } else {
            this.menuService.menu.splice(indexDragged + 1, 1);
          }
        }
      }
      this.saveMenu();
    }
  }

  remove(event) {
    let indexDragged = -1;
    this.menuService.menu.filter((menu, index) => {
      if (menu.redirect === this.draggedMenu && menu.name !== 'Modifier') {
        indexDragged = index;
      }
      return false;
    })
    if (indexDragged >= 0) {
      this.menuService.menu.splice(indexDragged, 1);
      this.saveMenu();
    }
  }

  drag(event) {
    let viewportOffset = this.elements[0].getBoundingClientRect();
    // these are relative to the viewport, i.e. the window
    for (let element of this.elements) {
      viewportOffset = element.getBoundingClientRect();
      // these are relative to the viewport, i.e. the window
      if (event.x >= viewportOffset.left) {
        if (event.x <= viewportOffset.right) {
          element.classList.remove('margin-bottom')
          element.classList.remove('margin-top')
          if (event.y >= viewportOffset.top) {
            if (event.y <= viewportOffset.bottom) {
              if (event.y < (viewportOffset.top + viewportOffset.bottom) / 2) {
                console.log("top")
                element.classList.add('margin-top')
                element.classList.remove('margin-bottom')
              } else {
                console.log("bottom")
                element.classList.add('margin-bottom')
                element.classList.remove('margin-top')
              }
            }
          }
        }
      }
    }
  }

  dragover(event) {
    console.warn(event);
    for (let element of this.elements) {
      element.classList.remove('margin-bottom')
      element.classList.remove('margin-top')
    }
    let element = event.target.closest(".currentMenu");
    let viewportOffset = element.getBoundingClientRect();
    if (element.classList.contains('margin-bottom')) {
      viewportOffset.top += 25;
    } else {
      if (element.classList.contains('margin-top')) {
        //  viewportOffset.top += 25;
      }
    }
    console.error(viewportOffset.y);
    if (event.x >= viewportOffset.left) {
      if (event.x <= viewportOffset.right) {
        element.classList.remove('margin-bottom')
        element.classList.remove('margin-top')
        if (event.y < (viewportOffset.top + (viewportOffset.height / 2))) {
          console.log("top")
          element.classList.add('margin-top')
          element.classList.remove('margin-bottom')
        } else {
          console.log("bottom")
          element.classList.add('margin-bottom')
          element.classList.remove('margin-top')
        }
      }
    }
  }



  selectMenuToAdd(event, menu) {
    this.elements = event.srcElement.offsetParent.getElementsByClassName('nav-list')[0].children;
    this.draggedMenu = menu.key;
  }

  saveMenu() {
    this.menuService.saveMenu();
  }
  onColorPickerChange(menu, event) {
    menu.color = event;
  }

  onScroll() {
    //ColorPickerModule
    let colorPicker = document.getElementsByTagName('color-picker')[0];
    if (colorPicker && colorPicker.children.length > 0) {
      this.saveMenu()
      colorPicker.remove();
    }
  }

}
