import { Component, OnDestroy } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { User } from "src/app/models";
import { Alliance } from "src/app/models/Alliance/Alliance";
import { AllianceRef } from "src/app/models/Alliance/AllianceRef";
import { AlertService } from "src/app/services/alert/alert.service";
import { AllianceService } from "src/app/services/Alliance/alliance.service";
import { BaseService } from "src/app/services/bases/base.service";
import { MarketService } from "src/app/services/Market/market.service";
import { OptionsService } from "src/app/services/options/options.service";
import { ShipService } from "src/app/services/ships/ship.service";
import { UsersService } from "src/app/services/Users/user.service";
import { ShowProfileComponent } from "../../users/profile/show-profile/show-profile.component";

@Component({
  selector: "app-show-alliance",
  templateUrl: "./showAlliance.component.html",
  styleUrls: ["./showAlliance.component.scss"],
})
export class ShowAllianceComponent implements OnDestroy {
  private readonly destroy$ = new Subject();
  private debug: boolean = false;
  public allianceRef: AllianceRef;
  public currentUser: User;

  constructor(
    private readonly modalService: NgbModal,
    private readonly marketService: MarketService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    private readonly optionsService: OptionsService,
    private readonly shipService: ShipService,
    private readonly alertService: AlertService,
    public readonly modal: NgbActiveModal,
    private readonly allianceService: AllianceService,
  ) {
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
    this.userService.userUpdate$
    .pipe(takeUntil(this.destroy$))
    .subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  change(value, source) {
    this.allianceRef[source] = value;
  }

  openUserProfile(ownerId) {
    let showProfileComponent = this.modalService.open(ShowProfileComponent, {
      ariaLabelledBy: "modal-show-profile",
      scrollable: true,
      size: "xl",
      windowClass: "showProfile backModalImg",
    });
    showProfileComponent.componentInstance.ownerId = ownerId;
  }

  join() {
    this.allianceService.acceptInvite(this.allianceRef.id, this.currentUser.id).subscribe(() => {
      this.modal.dismiss();
    });
  }
}
