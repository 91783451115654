<div class="modal-header">
  <h4 class="modal-title me-3" id="modal-basic-title">
    Livre de Quêtes
  </h4>
  <button (click)="closeWindow('Cross click', $event)" aria-label="Close" class="btn btn-close" type="button">
  </button>
</div>

<div class="modal-body">
  <div id="questsGroup">
    <div *ngFor="let quest of availableQuests" class="questList available">
      <div class="quest" [ngClass]="{'selected': selectedQuest && selectedQuest.id === quest.id}" (click)=openQuest(quest)>
        <i class='bx bx-play'></i>
        <span> {{ quest.name }}</span>
      </div>
    </div>
    <div class="border-top">
      <div *ngFor="let quest of activeQuests" class="questList active">
        <div class="quest" [ngClass]="{'selected': selectedQuest && selectedQuest.id === quest.id}" (click)=openQuest(quest)>
          <i class='bx bxs-book'></i>
          <span> {{ quest.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedQuest" id="questDetails" class="border-top">
    <div class="d-flex justify-content-around">
      <label class="my-auto">
        Détails de la quête
      </label>
      <button (click)="abandonQuest(selectedQuest)" *ngIf="selectedQuest.isRejectable && selectedQuest.userId"
        class="btn btn-danger">abandonner</button>
      <button (click)="refuseQuest(selectedQuest)" *ngIf="selectedQuest.isRejectable && !selectedQuest.userId"
        class="btn btn-danger">refuser</button>
      <button (click)="acceptQuest(selectedQuest)" *ngIf="!selectedQuest.userId" class="btn btn-success">accepter</button>
      <button (click)="finishQuest(selectedQuest)" *ngIf="selectedQuest.userId" class="btn btn-success">Valider</button>
    </div>
    <div class="d-flex justify-content-around">
      <pre>
{{selectedQuest.description}}
      </pre>
    </div>

    <div>
      <label class="underline d-flex justify-content-around">Objectifs</label>
      <div *ngFor="let objective of selectedQuest.passiveObjectives">
        <pre>
{{objective.description}}
      </pre>
      </div>
      <div *ngFor="let objective of selectedQuest.activesObjectives">
        <pre>
{{objective.description}}
      </pre>
        <label *ngFor="let objectives of objective.objectives | keyvalue">
          {{objectives.value}}
        </label>
      </div>
    </div>
    <div class="mx-4">
      <label class="underline d-flex justify-content-around">Récompenses</label>
      <div *ngFor="let reward of selectedQuest.questRewards">
        <li *ngIf="reward.type === 'RESOURCE'">
          <label [ngbTooltip]="reward.entityId+''" #tooltip="ngbTooltip" container="body"
            class="p-1">
            <img class="resourceImg" src="/assets/img/resources/{{ reward.entityId }}.png" />
            {{ utilsService.nFormatter(reward.value) }}
          </label>
        </li>
        <li *ngIf="reward.type === 'QUEST'">

          une nouvelle quête !
        </li>
      </div>
    </div>
  </div>
</div>
