<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Squadron-detailComponent|Squadron details">Arbre Technologique
  </h4>
  <ul #nav="ngbNav" class="nav-tabs" (navChange)="onChange($event)" ngbNav>
    <li [ngbNavItem]="1">
      <a i18n="BaseComponent|General tab for the building" ngbNavLink>Tous</a>
    </li>
    <li [ngbNavItem]="2">
      <a i18n="BaseComponent|General tab for the building" ngbNavLink>Batiments</a>
    </li>
    <li [ngbNavItem]="3">
      <a i18n="BaseComponent|General tab for the building" ngbNavLink>Vaisseaux</a>
    </li>
    <li [ngbNavItem]="4">
      <a i18n="BaseComponent|General tab for the building" ngbNavLink>Recherches</a>
    </li>
  </ul>
  <input class="inputSearch" type="search" (change)="onSearch($event.target['value'])" placeholder="Recherche...">
  <div>
    <button (click)="shareTechnologyTree()" aria-label="Close" class="btn bx bx-share-alt" type="button"></button>
    <button (click)="utilsService.closeModalByEvent($event)" aria-label="Close" class="btn btn-close" type="reset"></button>
  </div>
</div>
<div class="modal-body">
  <label *ngIf="isRendered === false" class="modal-body loading"></label>
  <div [ngClass]="isRendered === false ? 'hidden': ''" class="mermaid"></div>
  <div class="mermaidTest"></div>
</div>
