<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">
    Création d'une alliance
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>

<div class="modal-body">
  <div class="d-flex flex-column">
    <div class="profile">
      <div class="allianceName">
        Nom de l'alliance
        <form>
          <textarea (input)="change($event.target['value'], 'name')" id="allianceNameForm"
            name="allianceNameForm">{{allianceRef.name}}</textarea>
        </form>
      </div>
      <div class="allianceShortName">
        Nom court de l'alliance
        <form>
          <textarea (input)="change($event.target['value'], 'shortName')" id="allianceShortNameForm"
            name="allianceShortNameForm">{{allianceRef.shortName}}</textarea>
        </form>
      </div>
      <div class="allianceDescription">
        Description
        <form>
          <textarea (input)="change($event.target['value'], 'description')" id="allianceDescriptionForm"
            name="allianceDescriptionForm">{{allianceRef.description}}</textarea>
        </form>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="d-flex justify-content-center">
        <button (click)="confirmNewAlliance()" class="btn btn-outline-success" i18n="confirmDestroy|Confirm">
          Créer l'alliance
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button (click)="modal.dismiss('Cancel click')" class="btn btn-outline-danger" i18n="confirmDestroy|Cancel">
          Annuler
        </button>
      </div>
    </div>

  </div>
