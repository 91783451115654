import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { ElementScrollPercentage } from 'src/app/element-scroll-percentage';
import { User } from 'src/app/models';
import { ReportRef } from 'src/app/models/reports/ReportRef';
import { UsersService } from 'src/app/services/Users/user.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';
import { MessageService } from 'src/app/services/message/message.service';
import { OptionsService } from 'src/app/services/options/options.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { ShipService } from 'src/app/services/ships/ship.service';
import { FightDetailComponent } from './fight-detail/fight-detail.component';
import { RummageDetailComponent } from './rummage-detail/rummage-detail.component';
import { InviteDetailComponent } from './invite-detail/invite-detail.component';
import { SpyReportComponent } from './spy-report/spy-report.component';
import { MoveDetailComponent } from './move-detail/move-detail.component';

@Component({
  selector: 'app-report-box',
  templateUrl: './report-box.component.html',
  styleUrls: ['./report-box.component.scss']
})
export class ReportBoxComponent implements OnInit, OnDestroy {
  private loaded: number;
  private inLoading: boolean = false;
  public inError: boolean = false;
  public reloading: boolean = false;
  private scrollEvent;
  reports: ReportRef[];
  rawReports: ReportRef[];
  user: User;
  newReport: number;
  showLu: boolean = false;
  deleteUnreadReport: boolean;
  private readonly destroy$ = new Subject();
  type: string = '';

  filters = [
    { id: "", value: "Tous", selected: true },
    { id: "INVITE", value: "invitations", selected: false },
    { id: "FIGHT", value: "Combats", selected: false },
    { id: "SEARCH", value: "Fouille", selected: false },
    { id: "SPY", value: "Espionnage", selected: false },
    { id: "MOVE", value: "Déplacements", selected: false },
  ];

  constructor(
    private readonly modalService: NgbModal,
    private readonly reportService: ReportService,
    private readonly optionsService: OptionsService,
    private readonly elementScrollPercentage: ElementScrollPercentage,
    private readonly elRef: ElementRef,
    private readonly usersService: UsersService,
    private readonly shipService: ShipService,
    public readonly utilsService: UtilsService,
    public readonly messageService: MessageService,
  ) { }


  ngOnInit() {
    //  this.user = JSON.parse(localStorage.getItem("user"));
    this.usersService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (!this.rawReports) {
          this.inError = true;
          this.reloadReport();
          this.countNew();
          this.showLu = false;
        }
      })
    //TODO : add messages
    if (!this.optionsService.getOption('deleteUnreadReport')) {
      this.deleteUnreadReport = true
    } else {
      this.deleteUnreadReport = false;
    }
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["deleteUnreadReport"]) {
          this.deleteUnreadReport = option["deleteUnreadReport"] === "false";
        }
      });
    this.scrollEvent = this.elementScrollPercentage.getScrollAsStream(this.elRef.nativeElement.parentElement)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (percent: number): void => {
          if (percent > 80) {
            if (!this.inLoading && this.showLu) {
              if (this.loaded % 20 == 0) {
                this.inLoading = true;
                this.reportService.getReportPaginated(this.loaded, this.loaded + 19).subscribe({
                  next: (reports) => {
                    if (reports && reports.length > 0) {
                      for (let report in reports) {
                        reports[report].date = new Date(Date.parse(reports[report].date + ''));
                      }
                      this.rawReports = this.rawReports.concat(reports);
                      this.loaded += reports.length;
                      this.inLoading = false;
                      this.filterReports()
                    } else {

                    }
                  },
                  error: (err) => {
                    this.inError = true;
                  }
                });
              } else {
                this.inError = true;
              }
            } else {
              this.inError = true;
            }
          } else {
            if (this.inError) {
              this.inError = true;
              this.inLoading = false;
            }
          }
        });
  }

  ngOnDestroy() {
    //this.usersService.updateUser()
    this.reportService.getReport().subscribe(() => { });
    this.destroy$.next("");
    this.destroy$.complete();
  }

  countNew() {
    this.newReport = 0;
    if (this.rawReports)
      this.newReport = this.rawReports.length;
    /*for (let [key, value] of this.user.reports) {
      if(value){
        this.newReport = this.newReport+1;
      }
    }*/
  }

  openReport(selectedReport: ReportRef): NgbModalRef {
    //Open Modal
    let modal: NgbModalRef;
    if (selectedReport.type === 'FIGHT') {
      modal = this.modalService.open(FightDetailComponent, {
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
        size: "xl",
        windowClass: "report-detail backModalImg",
      });
      modal.componentInstance.getReport = this.reportService.getFightReportByUuid(selectedReport.uuid)
    } else if (selectedReport.type === "SEARCH") {
      modal = this.modalService.open(RummageDetailComponent, {
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
        size: "xl",
        windowClass: "report-detail backModalImg",
        backdrop: false,

      });
      modal.componentInstance.getReport = this.reportService.getRummageReportByUuid(selectedReport.uuid)
    } else if (selectedReport.type === "INVITE") {
      modal = this.modalService.open(InviteDetailComponent, {
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
        size: "xl",
        windowClass: "report-detail backModalImg",
        backdrop: false,
      });
      modal.componentInstance.getReport = this.reportService.getInviteReportByUuid(selectedReport.uuid);
    } else if (selectedReport.type === "SPY") {
      modal = this.modalService.open(SpyReportComponent, {
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
        size: "xl",
        windowClass: "report-detail backModalImg",
        backdrop: false,
      });
      modal.componentInstance.getReport = this.reportService.getSpyReportByUuid(selectedReport.uuid);
    } else if (selectedReport.type === "MOVE") {
      modal = this.modalService.open(MoveDetailComponent, {
        ariaLabelledBy: "modal-basic-title",
        scrollable: true,
        size: "xl",
        windowClass: "report-detail backModalImg",
        backdrop: false,
      });
      modal.componentInstance.getReport = this.reportService.getMoveReportByUuid(selectedReport.uuid);
    }
    if (modal) {
      modal.dismissed.subscribe((reason) => {
        this.reloadReport();
      })
    } else {

    }
    return modal;
  }

  deleteReport(selectedReport: ReportRef) {
    this.reportService
      .deleteReportById(selectedReport.uuid)
      .subscribe((report) => {
        this.reloadReport();
      });
  }

  reloadReport() {
    if (!this.reloading) {

      this.reloading = true; // to add the loading animation
      this.inError = false;
      this.rawReports = [];
      this.reports = [];
      if (this.showLu) {
        this.reportService.getReportPaginated(0, 19).subscribe((reports) => {
          for (let report in reports) {
            reports[report].date = new Date(Date.parse(reports[report].date + ''));
          }
          this.rawReports = reports;
          this.loaded = reports.length;
          if (reports.length != 20) {
            this.inError = true;
          }
          this.filterReports();
          this.reloading = false;
        });
      } else {
        this.reportService.getReport().subscribe((reports) => {
          for (let report in reports) {
            reports[report].date = new Date(Date.parse(reports[report].date + ''));
          }
          this.rawReports = reports;
          this.countNew();
          this.filterReports();
          this.inError = true; // to remove the loading animation
          this.reloading = false;
        });
      }
    }
  }

  filterReports(event?) {
    let filter = '';
    if (event) {
      this.filters.forEach((filter) => {
        filter.selected = filter.id === event.value;
      })
      filter = event.value;
    } else {
      filter = this.filters.filter((tmp) => {
        return tmp.selected;
      })[0].id;
    }
    this.reports = this.rawReports.filter((report) => {
      return report.type.includes(filter);
    })
  }

  translateReportType(type): string {
    return (this.filters.find((filter) => filter.id === type) || { value: this.utilsService.translateConstants(type) }).value;
  }

  readAllReports() {
    this.reportService.readAllReports(this.usersService.currentUserId()).subscribe()
  }

  openAllReports() {
    if (this.reports[0]) {
      this.openReport(this.reports[0]).shown.subscribe(() => {
        this.reportService
          .deleteReportById(this.reports[0].uuid)
          .subscribe();
        this.reports.splice(0, 1);
        setTimeout(() => { this.openAllReports() }, 100);
      })
    } else {
      this.reloadReport;
    }
  }
}
