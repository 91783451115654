<div class="alerts">
  <ul *ngFor="let value of alerts | keyvalue">
    <table (mouseenter)="value.value.focus = true" (mouseleave)="value.value.focus = false"
      class="fade show alert alert-{{value.value.type}}">
      <tbody>
        <tr>
          <td [innerText]="value.value.msg"></td>
          <td>
            <button type="button" class="btn btn-close" aria-label="Close" (click)="removeAlert(value.key)"></button>
          </td>
        </tr>
      </tbody>
    </table>
  </ul>
</div>
<form #form="ngForm" (ngSubmit)="onSubmit()" [formGroup]="frmUser" class="d-flex flex-column createForm" novalidate>
  <div class="test">
    <div class="d-flex flex-wrap justify-content-center">
      <div class="col-md-6 justify-content-center ">
        <div class="form-group user-create">
          <label for="pseudo">Pseudo</label>
          <input id="pseudo" class="form-control " formControlName="pseudo" type="text" />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea id="description" class="form-control" formControlName="description" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="government">Choisissez un gouvernement</label>
          <div class="govList justify-content-center" id="government">
            <div class="btn-group-vertical justify-content-center m-1">
              <button type="button" [disabled]="government.freeBaseCount === 0" (click)="selectGovernment(government)"
                *ngFor="let government of governments" [ngClass]="{ govSelected: selectedGovernment === government }"
                class="govElement ">
                {{ government.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="justify-content-center mt-4">
        <div *ngIf="selectedGovernment" class="govDescription">
          <img *ngIf="selectedGovernment.shortName" alt="Card image cap" class="govImage" src="{{
            '/assets/img/govs/' + selectedGovernment.shortName + '.png'
          }}" />
          <h3>{{ selectedGovernment.name }}</h3>
          <p>{{ selectedGovernment.description }}</p>
          <p>Places libres : {{ selectedGovernment.freeBaseCount }}</p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-primary" type="submit">Valider</button>
    </div>
  </div>
</form>
