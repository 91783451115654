<div *ngIf="!agent" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="agent" class="modal-header d-flex flex-row justify-content-around">
  <div class="d-flex flex-fill">
    <h4 class="modal-title me-3" id="modal-basic-title" i18n="PlanetComponent|Title: fleet moving">
      Déplacement d'un espion
    </h4>
  </div>
  <div *ngIf="agent.position" class="d-flex flex-fill">
    <h4 i18n="PlanetComponent|Starting position">
      Position de départ : <br />
      <!-- <ng-container *ngIf="base">
        {{ base.name }}
      </ng-container> -->
      ({{ agent.position.coordinates }})
    </h4>
  </div>

  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div (keyup.enter)="onSubmit()" class="modal-body">
  <div class="d-flex flex-row">
    <form #form="ngForm" (ngSubmit)="onSubmit()" [formGroup]="frmTravel" novalidate>
      <div>
        <!--Choix de cible-->
        <label for="objective" i18n="PlanetComponent|Goal">Objectif: </label>
        <select formControlName="objective">
          <option *ngFor="let o of objectives" [value]="o">
            {{ utilsService.translateConstants(o) }}
          </option>
        </select>
      </div>
      <div>
        <label i18n="PlanetComponent|">Cible :</label>
        <input (change)="onChangeX($event.target, 'selectedTarget')" class="form-control-distance"
          formControlName="targetX" id="targetCoordsX" [max]="planet.size" min="0" placeholder="x" type="number" />
        <input (change)="onChangeY($event.target, 'selectedTarget')" class="form-control-distance"
          formControlName="targetY" id="targetCoordsY" [max]="planet.size" min="0" placeholder="y" type="number" />
        <button (click)="openTargetSelector('selectedTarget')" type="button" class="btn btn-outline-info"
          i18n="PlanetComponent|Button cancel">
          ...
        </button>
      </div>
    </form>
  </div>
  <div class="d-flex flex-row">
    <button class="btn btn-outline-success" ngbAutofocus (click)="onSubmit()">déplacer l'agent</button>
    <button class="btn btn-outline-danger" (click)="modal.dismiss('Cross click')">Annuler</button>
  </div>
</div>
