import { Component, OnDestroy, OnInit } from "@angular/core";
import { Routes } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { User } from "src/app/models";
import { Alert } from "src/app/models/Alert";
import { Alliance } from "src/app/models/Alliance/Alliance";
import { UserProfile } from "src/app/models/user/UserProfile";
import { AlertService } from "src/app/services/alert/alert.service";
import { AllianceService } from "src/app/services/Alliance/alliance.service";
import { OptionsService } from "src/app/services/options/options.service";
import { UsersService } from "src/app/services/Users/user.service";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { ProfilePictureSelectorComponent } from "../users/profile/profile-picture-selector/profile-picture-selector.component";
import { ShowProfileComponent } from "../users/profile/show-profile/show-profile.component";
import { CreateAllianceComponent } from "./createAlliance/createAlliance.component";
import { ListAlliancesComponent } from "./list-alliances/list-alliances.component";

@Component({
  selector: "app-alliance",
  templateUrl: "./alliance.component.html",
  styleUrls: ["./alliance.component.scss"],
})
export class AllianceComponent implements OnInit, OnDestroy {
  static routes: Routes = [{ path: "", component: AllianceComponent }];
  private readonly destroy$ = new Subject();
  private debug: boolean = false;
  public currentUser: User;
  public currentUserRole: number = 1000;
  public currentAlliance: Alliance;
  public users: UserProfile[];
  public usersFiltered: UserProfile[];
  descriptionTextLines = [];
  private roleEdits = [];
  public libelleRole: string[];

  constructor(
    private readonly modalService: NgbModal,
    private readonly userService: UsersService,
    private readonly allianceService: AllianceService,
    private readonly optionsService: OptionsService,
    private readonly alertService: AlertService,
    public readonly utilsService: UtilsService,
  ) {

  }

  ngOnInit() {
    this.allianceService.allianceUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (currentAlliance) => {
          console.warn(currentAlliance)
          if (currentAlliance) {
            currentAlliance.libelleRole.push('Expulser')
            this.currentAlliance = currentAlliance;
            this.currentUserRole = currentAlliance.memberRoles[this.currentUser.id];
            this.descriptionTextLines = currentAlliance.description.split('\n');
            setTimeout(() => this.calcHeighDescription(), 100);
          }
        }
        ,
        error: (err) => {
          this.userService.updateUser();
        }
      });

    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === "true";
        }
      });
    this.userService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user) {
          this.currentUser = user;
          if (user.allianceId) {
            this.refreshCurrentAlliance();
          } else {
            this.currentAlliance = null;
          }
        }
      })
    this.debug = this.optionsService.getOption('debug');
  }

  refreshCurrentAlliance() {
    this.allianceService.updateAlliance();
  }

  calcHeighDescription() {
    let target = document.getElementById('allianceDescriptionForm');
    if (target) {
      this.descriptionTextLines = target['value'].split('\n');
      let height = document.getElementById('allianceDescriptionLabel').offsetHeight
      height = Math.max(height, 45);
      if (height > 0) {
        target.style.setProperty('--allianceDescription', height + 5 + "px");
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  openUserProfile(ownerId) {
    let showProfileComponent = this.modalService.open(ShowProfileComponent, {
      ariaLabelledBy: "modal-show-profile",
      scrollable: true,
      size: "xl",
      windowClass: "showProfile backModalImg",
    });
    showProfileComponent.componentInstance.ownerId = ownerId;
  }

  createNewAlliance() {
    let createAllianceComponent = this.modalService.open(CreateAllianceComponent, {
      ariaLabelledBy: "modal-create-alliance",
      scrollable: true,
      size: "xl",
      windowClass: "createAlliance backModalImg",
    });

    createAllianceComponent.hidden.subscribe(() => {
      this.userService.updateUser();
    })
  }

  saveAllianceDescription(elementId) {
    let description = document.getElementById(elementId)['value'];
    this.allianceService.modifyAllianceDescription(this.currentAlliance.id, description).subscribe((success) => {
      this.refreshCurrentAlliance();
    })
  }

  joinAlliance() {
    let listAllianceComponent = this.modalService.open(ListAlliancesComponent, {
      ariaLabelledBy: "modal-list-alliance",
      scrollable: true,
      size: "xl",
      windowClass: "listAlliance backModalImg",
    });

    listAllianceComponent.hidden.subscribe(() => {
      this.userService.updateUser();
    })
  }

  invite(modal) {
    this.modalService.open(modal, {
      ariaLabelledBy: "modal-select-users",
      scrollable: true,
      size: "xl",
      windowClass: "selectUsers backModalImg",
    });
    this.userService.getProfiles().subscribe((users) => {
      this.users = users;
      this.usersFiltered = JSON.parse(JSON.stringify(users));
    })
  }

  showRequests(modal) {
    this.modalService.open(modal, {
      ariaLabelledBy: "modal-select-users",
      scrollable: true,
      size: "xl",
      windowClass: "selectUsers backModalImg",
    });
    let requested = [];

    for (let key in this.currentAlliance.pendingMembers) {
      if (!this.currentAlliance.pendingMembers[key]) {
        requested.push(key);
      }
    }

    this.userService.getProfiles().subscribe((users) => {
      this.users = users.filter((user) => {
        if (requested.filter((requestedId) => requestedId === user.id).length > 0) {
          return true
        }
      });

      this.usersFiltered = JSON.parse(JSON.stringify(this.users));
    })
  }

  hasRequest(): boolean {
    for (let key in this.currentAlliance.pendingMembers) {
      if (!this.currentAlliance.pendingMembers[key]) {
        return true;
      }
    }
    return false;
  }

  changeRoles(modal) {
    this.modalService.open(modal, {
      ariaLabelledBy: "modal-change-roles",
      scrollable: true,
      size: "xl",
      windowClass: "changeRoles backModalImg",
    });
    let usersFiltered = []
    for (let key in this.currentAlliance.members) {
      usersFiltered.push({ id: key, name: this.currentAlliance.members[key], role: this.currentAlliance.memberRoles[key] })
    }
    this.usersFiltered = usersFiltered;
    this.libelleRole = JSON.parse(JSON.stringify(this.currentAlliance.libelleRole));
  }


  filterUserName(userName) {
    this.usersFiltered = JSON.parse(JSON.stringify(this.users)).filter((user) => user['name'].toLowerCase().includes(userName.toLowerCase()));
  }

  select(userName) {
    let user = this.users.filter((user) => {
      return user.name === userName;
    });
    if (user[0]) {
      user[0]['selected'] = !user[0]['selected'];
    }

    user = this.usersFiltered.filter((user) => {
      return user.name === userName;
    });
    if (user[0]) {
      user[0]['selected'] = !user[0]['selected'];
    }
  }

  invitePlayers(target) {
    let invitedPlayers = [];
    this.users.forEach((user) => {
      if (user['selected'] === true) {
        invitedPlayers.push(user);
      }
      user['selected'] = false;
    });
    for (let player of invitedPlayers) {
      this.allianceService.invitePlayer(this.currentAlliance.id, player.id).subscribe(() => {
        let alert = new Alert();
        alert.type = "info";
        alert.msg = `l'invitation au joueur ${player.name} a bien été envoyée`;
        alert.timeout = 1000;
        this.alertService.addAlert(alert)
      });
    }
    target.parentElement.parentElement.parentElement.parentElement.remove()
    let backdrop = document.getElementsByClassName('modal-backdrop')
    if (backdrop.length > 0) {
      document.getElementsByClassName('modal-backdrop')[backdrop.length - 1].remove();
    }
  }

  descriptionChange(target) {
    this.descriptionTextLines = target.value.split('\n');
    setTimeout(() => this.calcHeighDescription(), 100);
  }

  leave(alliance) {
    this.allianceService.leaveAlliance(alliance.id, this.userService.currentUserId()).subscribe(() => {
      this.userService.updateUser();
    });
  }

  changeImg() {
    let modal = this.modalService.open(ProfilePictureSelectorComponent, {
      ariaLabelledBy: "profile-picture-selector.",
      scrollable: true,
      size: "sm",
      windowClass: "profile-picture-selector. backModalImg",
      backdrop: true,
    });
    modal.closed.subscribe((result) => {
      if (result) {
        this.allianceService.modifyAllianceImage(this.currentAlliance.id, result).subscribe((success) => {
          this.refreshCurrentAlliance();
        })
      }
    });
  }

  confirmNewRoles(target) {
    let users = target.closest('.modal-body').getElementsByClassName('roleChoice');
    for (let user of users) {
      let userdatas = user.parentElement.parentElement.firstChild
      let newRole = user.selectedOptions[0].value;
      let edit = { promote: true, user: userdatas.id };
      if (newRole < +userdatas.slot) {
        edit.promote = true;
        for (; newRole != +userdatas.slot; newRole++) {
          this.roleEdits.push(edit);
        }
      } else {
        if (newRole > +userdatas.slot) {
          edit.promote = false;
          for (; newRole != +userdatas.slot; newRole--) {
            this.roleEdits.push(edit);
          }
        }
      }
    }
    target.parentElement.parentElement.parentElement.parentElement.remove()
    let backdrop = document.getElementsByClassName('modal-backdrop')
    if (backdrop.length > 0) {
      document.getElementsByClassName('modal-backdrop')[backdrop.length - 1].remove();
    }
    setTimeout(() => this.pushRoleChanges(), 50);
  }

  pushRoleChanges() {
    if (this.roleEdits.length > 0) {
      let edit = this.roleEdits[0];
      this.roleEdits.splice(0, 1);
      if (edit['promote']) {
        this.allianceService.promotePlayer(this.currentAlliance.id, edit['user']).subscribe(() => {
          setTimeout(() => this.pushRoleChanges(), 50);
        })
      } else {
        this.allianceService.demotePlayer(this.currentAlliance.id, edit['user']).subscribe(() => {
          setTimeout(() => this.pushRoleChanges(), 50);
        })
      }
    } else {
      this.refreshCurrentAlliance();
    }
  }

  roleRename(text, roleId) {
    this.libelleRole[roleId] = text;
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  saveRoleName(target) {
    if (this.libelleRole.length > 0) {
      if (this.libelleRole[0] !== this.currentAlliance.libelleRole[this.currentAlliance.libelleRole.length - this.libelleRole.length]) {
        this.allianceService.editRoleName(this.currentAlliance.id, this.currentAlliance.libelleRole.length - (this.libelleRole.length), this.libelleRole[0]).subscribe(() => {
          setTimeout(() => this.saveRoleName(target), 50);
        })
      } else {
        setTimeout(() => this.saveRoleName(target), 50);
      }
      this.libelleRole.splice(0, 1);
    } else {
      this.utilsService.closeModalByEvent(target)
      this.refreshCurrentAlliance();
    }
  }
}


