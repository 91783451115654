<div *ngIf="!resourceDetail" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>
<div *ngIf="resourceDetail" class="modal-header">
  <h4 class="modal-title me-3 ">
    {{ resourceCode }}
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset" ngbAutofocus>
  </button>
</div>

<div *ngIf="resourceDetail" class="modal-body d-flex flex-column">
  <table class="table table-hover table-dark resource-Detail">
    <tbody>
      <tr class="justify-content-around p-2">
        <th class="underline">Production </th>
        <th>
          <div class="p-1 d-flex flex-column">
            <img class="resourceImg" src="/assets/img/resources/{{ resourceCode }}.png" />
          </div>
        </th>
      </tr>
      <tr *ngFor="let production of resourceDetail.productions | keyvalue" class="justify-content-around p-2">
        <td>{{ production.value.libelle }}</td>
        <td class="text-center">{{ production.value.amount }}</td>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr class="justify-content-around p-2 text-center">
        <td>Total production</td>
        <td>{{resourceDetail.totalProduction}}</td>
      </tr>
      <tr class="justify-content-around p-2">
        <td colspan="2" class="invisible">hidden</td>
      </tr>


      <tr class="justify-content-around p-2">
        <th>Multiplicateurs</th>
      </tr>
      <tr class="justify-content-around p-2">
        <td>Production</td>
        <td class="text-center">100 %</td>
      </tr>
      <tr *ngFor="let multiplier of resourceDetail.multipliers | keyvalue" class="justify-content-around p-2">
        <td>{{ multiplier.value.libelle }} </td>
        <td class="text-center">+ {{ multiplier.value.amount }}%</td>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr class="justify-content-around p-2 text-center">
        <td>Total multiplicateurs</td>
        <td>{{resourceDetail.totalMultiplier}}%</td>
      </tr>
      <tr class="justify-content-around p-2">
        <td colspan="2" class="invisible">hidden</td>
      </tr>


      <tr class="justify-content-around p-2">
        <th>Dépenses </th>
      </tr>
      <tr *ngFor="let consumption of resourceDetail.buildingConsumptions | keyvalue" class="justify-content-around p-2">
        <td>{{ consumption.value.libelle }}</td>
        <td>{{ consumption.value.amount }}</td>
      </tr>
      <tr *ngFor="let consumption of resourceDetail.fleetConsumptions | keyvalue" class="justify-content-around p-2">
        <td>{{ consumption.value.libelle }}</td>
        <td>{{ consumption.value.amount }}</td>
      </tr>
      <tr *ngFor="let consumption of resourceDetail.externalConsumptions | keyvalue" class="justify-content-around p-2">
        <td>{{ consumption.value.libelle }}</td>
        <td>{{ consumption.value.amount }}</td>
      </tr>
      <tr *ngIf="hasKeys(resourceDetail.taxes)" class="justify-content-around p-2">
        <th colspan="2" class="underline">Taxes</th>
      </tr>
      <tr *ngFor="let taxe of resourceDetail.taxes | keyvalue" class="justify-content-around p-2">
        <td>{{ taxe.value.libelle }}</td>
        <td>{{ taxe.value.amount }}</td>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>

      <tr class="justify-content-around p-2 text-center">
        <td>Consommation totale</td>
        <td>{{resourceDetail.totalConsumption}}</td>
      </tr>
      <tr class="justify-content-around p-2 text-center">
        <td>Taxes totales</td>
        <td>{{resourceDetail.totalTaxe}}</td>
      </tr>
      <tr class="justify-content-around p-2">
        <td colspan="2" class="invisible">hidden</td>
      </tr>



      <tr class="justify-content-around p-2 text-center">
        <td class="underline">Production brute</td>
        <td>{{resourceDetail.calculatedProduction}}</td>
      </tr>
      <tr class="justify-content-around p-2 text-center">
        <td class="underline">Production nette</td>
        <td>{{resourceDetail.calculatedProduction - resourceDetail.totalTaxe - resourceDetail.totalConsumption}}</td>
      </tr>
      <tr class="justify-content-around p-2">
        <td colspan="2" class="invisible">hidden</td>
      </tr>
      <tr class="justify-content-around p-2">
        <th colspan="2" class="underline">Stockage </th>
      </tr>
      <tr *ngFor="let storage of resourceDetail.storages | keyvalue" class="justify-content-around p-2">
        <td>{{ storage.value.libelle }}</td>
        <td class="text-center">{{ storage.value.amount }}</td>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr class="justify-content-around p-2 text-center">
        <td>Stockage total</td>
        <td>{{resourceDetail.totalStorage}}</td>
      </tr>
      <tr class="justify-content-around p-2">
        <td colspan="2" class="invisible">hidden</td>
      </tr>
      <tr class="justify-content-around p-2">
        <th>Multiplicateurs</th>
      </tr>
      <tr class="justify-content-around p-2">
        <td>Stockage</td>
        <td class="text-center">100 %</td>
      </tr>
      <tr *ngFor="let storageMultiplier of resourceDetail.storageMultipliers | keyvalue"
        class="justify-content-around p-2">
        <td>{{ storageMultiplier.value.libelle }}</td>
        <td class="text-center">+ {{ storageMultiplier.value.amount }}%</td>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr class="justify-content-around p-2 text-center">
        <td>Total multiplicateurs</td>
        <td>{{resourceDetail.totalStorageMultiplier}}%</td>
      </tr>
      <tr class="p-2">
        <td colspan="2" class="invisible"></td>
      </tr>
      <tr class="justify-content-around p-2 text-center">
        <td class="underline">Stockage net</td>
        <td>{{resourceDetail.calculatedStorage}}</td>
      </tr>
    </tbody>
  </table>
</div>
