import { Component, OnDestroy, OnInit } from "@angular/core";
import { Routes } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { Alert } from "src/app/models/Alert";
import { Base } from "src/app/models/base/Base";
import { GlobalPosition } from "src/app/models/GlobalPosition";
import { Planet } from "src/app/models/Planet/Planet";
import { AlertService } from "src/app/services/alert/alert.service";
import { BaseService } from "src/app/services/bases/base.service";
import { EventService } from "src/app/services/event/event.service";
import { PlanetsService } from "src/app/services/Planets/planets.service";
import { RummageService } from "src/app/services/Rummage/rummage.service";
import { UtilsService } from "src/app/services/Utils/utils.service";

@Component({
  selector: "app-rummage",
  templateUrl: "./rummage.component.html",
  styleUrls: ["./rummage.component.scss"],
})
export class RummageComponent implements OnInit, OnDestroy {
  static routes: Routes = [{ path: "", component: RummageComponent }];
  private readonly destroy$ = new Subject();
  public base: Base;
  public targets: GlobalPosition[] = [];
  public distance: number;
  public size: number = 1;
  planet: Planet;
  rummageTime = 0;



  constructor(
    private readonly rummageService: RummageService,
    private readonly baseService: BaseService,
    private readonly eventService: EventService,
    public readonly modal: NgbActiveModal,
    private readonly alertService: AlertService,
    private readonly planetService: PlanetsService,
    private readonly utilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
          if (this.targets.length == 0) {
            this.targets.push(JSON.parse(JSON.stringify(base.position)));
            this.size = +base.effects['SEARCH_AREA'];
            this.recalculateDistance();
          }
        }
      });
    this.planetService.planetUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((planet) => {
        if (planet) {
          this.planet = planet;
          this.recalculateDistance();
        }
      });
  }

  isJSONNotEmpty(json) {
    for (let prop in json) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  public onChangeX(event): void {
    event.value = +event.value;
    this.targets[0].coordinates = event.value + ";" + this.targets[0].coordinates.split(";")[1];
    this.recalculateDistance();
  }

  public onChangeY(event): void {
    event.value = +event.value;
    this.targets[0].coordinates = this.targets[0].coordinates.split(";")[0] + ";" + event.value;
    this.recalculateDistance();
  }

  public onChangeSize(size) {
    this.size = +size;
    this.recalculateSize();
  }

  public recalculateSize() {
    if (!this.planet || !this.base) {
      return;
    }
    let tagret = JSON.parse(JSON.stringify(this.targets[0]));
    let initCoords = tagret.coordinates.split(";");
    let workCoords = this.targets[0].coordinates.split(";");
    let limits = [1, 1];
    for (let key in initCoords) {
      limits[key] = Math.min(this.size, ((this.planet.size + 1) - +initCoords[key]));
    }
    if (this.size > 1) {
      this.targets = [];
      for (let i = 0; i < limits[0]; i++) {
        workCoords[0] = +initCoords[0] + i + '';
        for (let iJ = 0; iJ < limits[1]; iJ++) {
          workCoords[1] = +initCoords[1] + iJ + '';
          tagret.coordinates = workCoords.join(';');
          this.targets.push(JSON.parse(JSON.stringify(tagret)));
        }
      }
      if (this.targets.length < 1) {
        this.targets = [JSON.parse(JSON.stringify(tagret))];
      }
    } else {
      this.targets = [JSON.parse(JSON.stringify(this.targets[0]))];
    }
    this.recalculateTime();
  }

  public recalculateDistance() {
    if (this.targets[0].coordinates && this.base.position.coordinates) {
      this.distance = this.utilsService.calculateDistance(this.targets[0], this.base.position);
      // this.travelTime = Math.round(this.distance / (15 + this.base.effects['SEARCH_SPEED']));
      // this.optimalFuel = (this.consumption * 2) + this.selectedFleet.consumption;     //2* la conso du trajer +1h sur place
      this.recalculateSize();
    } else {
      this.distance = 0;
    }
  }

  recalculateTime() {
    let effet = 100 + (this.base.effects['SEARCH_SPEED'] || 0);
    this.rummageTime = (this.distance + this.size * 1000) / (effet * 1.0);
  }



  public sendFleet() {
    let rummage = this.rummageService.rummageByCoords(this.base.id, this.targets)
    rummage.subscribe((test) => {
      this.eventService.updatePlayerEventNeeded();
      let alert = new Alert();
      alert.type = "success";
      alert.msg = "une fouille a bien été envoyé vers " + this.targets[0].coordinates;
      alert.timeout = 2000;
      this.alertService.addAlert(alert);
    })
    // rummage.subscribe((test) => {
    // })
  }
}
