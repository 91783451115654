<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Research-detailComponent|Research details">Détails de la
    recherche
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div class="modal-body">
  <div *ngIf="research">
    <table class="table table-striped table-hover table-dark ship-Detail">
      <thead>
        <th style="border:0px">
        </th>
        <th colspan="1000">
          <div class="d-flex justify-content-center flex-column">
            <img alt="Recherche" class="researchImg" src="{{'/assets/img/research/' +research.name +'.png'}}" />
            <label>
              {{research.name}}
            </label>
            <br>
            <label>
              {{research.description}}
            </label>
            <!--            <label> {{squadron.description}} </label>-->
          </div>
        </th>
      </thead>
      <tbody *ngIf="research.levels[currentLvl+1 || 0]">
        <tr class="justify-content-around p-2">
          <th colspan="1000" class="underline">Stats différentielles</th>
        </tr>
        <tr class="justify-content-around p-2">
          <td>Coût du niveau suivant</td>
          <td colspan="4">
            <div class="d-flex flex-wrap justify-content-center p-1 resource">
              <div *ngFor="let amount of research.levels[currentLvl+1 || 0].cost | keyvalue">
                <div class="p-1 d-flex flex-column">
                  <img class="resourceImg" src="/assets/img/resources/{{ amount.key }}.png" />
                  {{ amount.value }}
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="currentLvl != null && hasCurrentEffects && research.levels[currentLvl+1]">
        <tr *ngFor="let effect of research.levels[currentLvl+1].effects | keyvalue" class="justify-content-around p-2" (click)="clickOnEffect(effect)">
          <td>{{ getEffects(effect.value.code).libelle }}<br>au niveau suivant</td>
          <td>+{{effect.value.amount}}</td>
        </tr>
        <tr *ngIf="isJSONNotEmpty(research.levels[currentLvl+1].productionMultiplier)" class="justify-content-around p-2">
          <td>Multiplicateur additionnel de production<br>au niveau suivant</td>
          <td *ngFor="let effect of research.levels[currentLvl+1].productionMultiplier | keyvalue">
            <div class="p-1 d-flex flex-column">
              <img class="resourceImg" src="/assets/img/resources/{{ effect.key }}.png" />
              {{ effect.value }} %
            </div>
          </td>
        </tr>
        <tr *ngIf="isJSONNotEmpty(research.levels[currentLvl+1].storageMultiplier)" class="justify-content-around p-2">
          <td>Multiplicateur additionnel de stockage<br>au niveau suivant</td>
          <td *ngFor="let effect of research.levels[currentLvl+1].storageMultiplier | keyvalue">
            <div class="p-1 d-flex flex-column">
              <img class="resourceImg" src="/assets/img/resources/{{ effect.key }}.png" />
              {{ effect.value }} %
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="currentLvl != null && hasCurrentEffects">
        <tr class="justify-content-around p-2">
          <th colspan="1000" class="underline">Effets actuels de la recherche<br>(hors déblocage de l'arbre
            Technologique)</th>
        </tr>
        <tr *ngFor="let effect of research.levels[currentLvl].effects | keyvalue" class="justify-content-around p-2" (click)="clickOnEffect(effect)">
          <td>{{ getEffects(effect.value.code).libelle }}</td>
          <td *ngIf="user">
            {{ user.effects[effect.value.code] }}
          </td>
        </tr>
        <tr *ngIf="isJSONNotEmpty(research.levels[currentLvl].cumulatedProductionMultiplier)"
          class="justify-content-around p-2">
          <td>Multiplicateur cumulé de production</td>
          <td *ngFor="let effect of research.levels[currentLvl].cumulatedProductionMultiplier | keyvalue">
            <div class="p-1 d-flex flex-column">
              <img class="resourceImg" src="/assets/img/resources/{{ effect.key }}.png" />
              {{ effect.value }} %
            </div>
          </td>
        </tr>
        <tr *ngIf="isJSONNotEmpty(research.levels[currentLvl].cumulatedStorageMultiplier)"
          class="justify-content-around p-2">
          <td>Multiplicateur cumulé de stockage</td>
          <td *ngFor="let effect of research.levels[currentLvl].cumulatedStorageMultiplier | keyvalue">
            <div class="p-1 d-flex flex-column">
              <img class="resourceImg" src="/assets/img/resources/{{ effect.key }}.png" />
              {{ effect.value }} %
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="(currentLvl == null)">
        <tr class="justify-content-around p-2">
          <th colspan="1000" class="underline">Effets de la recherche débloquée <br>(hors déblocage de l'arbre
            Technologique)</th>
        </tr>
        <tr *ngFor="let effect of research.levels[0].effects | keyvalue" class="justify-content-around p-2" (click)="clickOnEffect(effect)">
          <td>{{ getEffects(effect.value.code).libelle }}</td>
          <td>{{effect.value.amount}}</td>
        </tr>
        <tr *ngIf="isJSONNotEmpty(research.levels[0].productionMultiplier)" class="justify-content-around p-2">
          <td>Multiplicateur de production (en %)</td>
          <td *ngFor="let effect of research.levels[0].productionMultiplier | keyvalue">
            <div class="p-1 d-flex flex-column">
              <img class="resourceImg" src="/assets/img/resources/{{ effect.key }}.png" />
              {{ effect.value }} %
            </div>
          </td>
        </tr>
        <tr *ngIf="isJSONNotEmpty(research.levels[0].storageMultiplier)" class="justify-content-around p-2">
          <td>Multiplicateur de stockage</td>
          <td *ngFor="let effect of research.levels[0].storageMultiplier | keyvalue">
            <div class="p-1 d-flex flex-column">
              <img class="resourceImg" src="/assets/img/resources/{{ effect.key }}.png" />
              {{ effect.value }} %
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="currentLvl != null && !hasCurrentEffects">
        <tr class="justify-content-around p-2">
          <td colspan="1000">Cette recherche n'a pas d'effets additionnels<br>(autres que de débloquer l'arbre
            Technologique)</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
