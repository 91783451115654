import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TemplateSquadronComponent } from "src/app/main/fleets/fleets/formation/edit-squadron/template-squadron.component";
import { FleetService } from "src/app/services/fleets/fleet.service";


@Component({
  selector: "app-battle-group",
  templateUrl: "../template-squadron.component.html",
  styleUrls: ["../template-squadron.component.scss"],
})
export class AddNewSquadronComponent extends TemplateSquadronComponent implements OnInit {

  constructor(
    protected readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal, // needed in HTML
    protected readonly fleetService: FleetService,
  ) {
    super(fb, modal, fleetService);
  }

  ngOnInit() {
    let availableShips = this.availableShips;
    this.availableShips = [];
    for(let shipId in availableShips) {
      this.availableShips.push(availableShips[shipId]);
    }
    if(!this.selectedShip) {
      this.selectedShip = this.availableShips[0];
      this.doSelectShip = true;
    }
    super.ngOnInit();

    this.doTactic = true;
    this.doAmount = true;
    this.title = "Ajout d'un escadron"
  }

  public onSubmit(): boolean {
    // Vérifier la validité du formulaire
    if (this.frmShip.valid && this.selectedFleet) {
      if(this.sourceFleetId){
        this.fleetService.createBattleGroupFromGroup(
          this.selectedFleet.id,
          this.moveTactic.value,
          this.shootingTactic.value,
          this.formationRound.value,
          this.selectedZone,
          this.amount.value,
          this.sourceFleetId,
          this.sourceSquadron.techId
        ).subscribe(() => {
          this.modal.close();
        });
      }else{
        this.fleetService.createBattleGroup(
          this.selectedFleet.id,
          this.moveTactic.value,
          this.shootingTactic.value,
          this.formationRound.value,
          this.selectedZone,
          this.amount.value,
          this.selectedShip.id
        ).subscribe(() => {
          this.modal.close();
        });
      }
    } else {
      //TODO : afficher le pourquoi de ca fonctionne pas
    }
    return false;
  }
}
