<div *ngIf="!markets" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Squadron-detailComponent|Squadron details">
    Créer une offre
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>
<div class="modal-body">
  <table>
    <tbody>
      <tr class="justify-content-around p-2">
        <td>
          Marché
        </td>
        <td>
          <select id="marketSelector">
            <option *ngFor="let market of markets" [value]="market.id">
              {{ market.name }}
            </option>
          </select>
        </td>
      </tr>
      <tr class="justify-content-around p-2">
        <td>
          Type d'offre
        </td>
        <td>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="SwitchNewOfferIsSell" [(ngModel)]="offer.sell">
            <label class="form-check-label" for="SwitchNewOfferIsSell"> {{offer.sell ? "Vente" : "Achat"}}</label>
          </div>
        </td>
      </tr>
      <tr (click)="openUnitChoice(unitChoice)" class="justify-content-around p-2">
        <td>
          Choix de la ressource
        </td>
        <td>
          <label>
            <button class="btn btn-outline-warning"></button>
            {{offer.productName}}
          </label>
        </td>
      </tr>
      <tr *ngIf="offer.productName" class="justify-content-around p-2">
        <td>
          Quantité
        </td>
        <td *ngIf="offer.sell" class="justify-content-around p-2">
          <input (change)=checkMinMax($event) type="number" id="amount" min="0" [max]="(maxAmount || 0)"
            [(ngModel)]="offer.amount">
          <label>
            (disponibles : {{maxAmount || 0}})
          </label>
        </td>
        <td *ngIf="!offer.sell" class="p-2">
          <input (change)=checkMinMax($event) type="number" id="amount" min="1" [(ngModel)]="offer.amount">
        </td>
      </tr>
      <tr *ngIf="offer.productName" class="justify-content-around p-2">
        <td>
          Prix par unité
        </td>
        <td class="p-2">
          <input type="number" id="unitPrice" min="1" [(ngModel)]="offer.unitPrice">
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex justify-content-center">
    <div>
      <button (click)="createOffer()" class="btn btn-outline-success" i18n="confirmDestroy|Confirm">
        Confirmer l'offre'
      </button>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-outline-danger" i18n="confirmDestroy|Cancel" (click)="modal.dismiss('Cancel click')">
        Annuler
      </button>
    </div>
  </div>
</div>

<ng-template #unitChoice let-unitChoice>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDestroy|Title" id="confirmDestroy">Choix de la ressource</h4>
    <button (click)="unitChoice.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body d-flex justify-content-around flex-wrap">
    <div *ngFor="let unit of units" (click)="confirmChoice(unit)" class="card mx-2 mb-3 slots transparent_bg">

      <img alt="{{ unit.name }}" class="shipImage" src="{{
        '/assets/img/ships/' + unit.name + '.png'
      }}" title="{{ unit.name }}" />
    </div>
    <div *ngFor="let resource of resources | keyvalue" (click)="confirmChoice(resource)"
      class="card mx-2 mb-3 slots transparent_bg">

      <img alt="{{ resource.key }}" class="shipImage" src="{{
        '/assets/img/resources/' + resource.key + '.png'
      }}" title="{{resource.key}}" />
    </div>
  </div>
</ng-template>
