<ng-template #confirmDestroyModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDestroy|Title" id="confirmDestroy">Confirmer la Destruction</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <div class="danger">Rappel : la fonctionnalité de destruction n'est pas pleinement implémentée et n'est présente
      qu'a des fins de debug, utilisez là a vos risques et péril
    </div>
    <br><br><br>
    <div class="d-flex justify-content-center">
      <div>
        <button (click)="confirmDestroy()" class="btn btn-outline-danger" i18n="confirmDestroy|Confirm">
          Confirmer la Destruction
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button (click)="modal.dismiss('Cancel click')" class="btn btn-outline-warning" i18n="confirmDestroy|Cancel">
          Annuler
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmDisableModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDisable|Title" id="confirmDisable">Confirmer la Désactivation</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <div class="danger">Désactiver ce batiment stopera toute construction, production ou recherche qui y est lié.</div>
    <br><br><br>
    <div class="d-flex justify-content-center">
      <div>
        <button (click)="confirmDisable()" class="btn btn-outline-danger" i18n="confirmDisable|Confirm">
          Confirmer la Désactivation
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button (click)="modal.dismiss('Cancel click')" class="btn btn-outline-warning" i18n="confirmDisable|Cancel">
          Annuler
        </button>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="!selectedBuilding" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="selectedBuilding" class="modal-header">
  <h4 *ngIf="selectedSlot && base.slots[selectedSlot].built" class="modal-title" id="buildingModal">
    {{ base.slots[selectedSlot].built.building.name }}
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button">
  </button>
</div>
<div *ngIf="(selectedSlot !== -1) && selectedBuilt && selectedBuilding" class="modal-body">
  <ul #nav="ngbNav" class="flex-fill" ngbNav (navChange)="change($event)">
    <li [ngbNavItem]="1">
      <a i18n="BaseComponent|General tab for the building" ngbNavLink>Général</a>
      <ng-template ngbNavContent>
        <div>
          <div>
            <img alt="Card image cap" class="buildingImage" src="{{
                '/assets/img/buildings/' +
                  selectedBuilt.building.name +
                  '.png'
              }}" />
          </div>
          <div>
            {{ selectedBuilt.hp }} /
            {{ selectedBuilding.levels[selectedBuilt.level].maxHP }}
          </div>
          <div>Level {{ selectedBuilt.level + 1 }}</div>
        </div>
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex justify-content-around">
            <ol>
              <li class="py-1" *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]"
                [ngClass]="selectedBuilt.active === true ? '': 'hidden'">
                <button (click)="upgrade()" class="btn btn-outline-primary buildingButton"
                  [disabled]="!isRequirementsFulfilled" i18n="BaseComponent|Upgrade the building">
                  Améliorer
                </button>
              </li>
              <li class="py-1" *ngIf="selectedBuilt.active">
                <button (click)="disableBuilding(confirmDisableModal)" class="btn btn-outline-warning"
                  i18n="BaseComponent|Disable the building">
                  Désactiver
                </button>
              </li>
              <li class="py-1" *ngIf="!selectedBuilt.active">
                <button (click)="enableBuilding()" class="btn btn-outline-warning"
                  i18n="BaseComponent|Enable the building">
                  Activer
                </button>
              </li>
              <li class="py-1">
                <button (click)="destroyBuilding(confirmDestroyModal)" class="btn btn-outline-danger"
                  i18n="BaseComponent|Destroy the building">
                  Détruire
                </button>
              </li>
            </ol>
            <ol class="d-flex flex-warp align-self-center p-1" *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">
              <li *ngFor="let buildingCost of selectedBuilding.levels[selectedBuilt.level + 1].buildingCost | keyvalue">
                <div [ngClass]="{
                    insufficientResource:
                      buildingCost.value > resources.get(buildingCost.key).clientAmount
                  }" class="p-1 d-flex flex-column">
                  <img class="resourceImg" src="/assets/img/resources/{{
                      buildingCost.key
                    }}.png" />
                  {{ buildingCost.value }}
                </div>
              </li>
            </ol>
            <div *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">
              {{
              selectedBuilding.levels[selectedBuilt.level + 1]
              .buildingTime * 1000 / (1 + ((base.effects['BUILD_SPEED'] || 0) /100.0))| timer: "UTC"
              }} s
            </div>
            <ul *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">
              <li *ngFor="
                    let requestedBuilding of selectedBuilding.levels[selectedBuilt.level + 1].buildingReq | keyvalue
                  " i18n="BaseComponent|Building required for the upgrade">
                <label [ngClass]="{ insufficientResource: !isBuildingsRequirements }"
                  *ngIf="buildingsService.buildingsMap.has(requestedBuilding.key)">
                  Prerequis: {{ buildingsService.buildingsMap.get(requestedBuilding.key).name }} : lvl
                  {{ requestedBuilding.value + 1 }}
                </label>
              </li>
              <li *ngFor="let requestedResearchElement of requestedResearch | keyvalue"
                [ngClass]="{ insufficientResource: !isResearchRequirements }"
                i18n="BaseComponent|Research required for the upgrade">
                Prerequis: {{ requestedResearchElement.key.name }} : lvl
                {{ requestedResearchElement.value + 1 }}
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div>
            {{ selectedBuilt.building.description }}
          </div>
          <table class="newTable">
            <tr class="underline">
              <th></th>
              <th i18n="BaseComponent|General information">Actuel</th>
              <th *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]" i18n="BaseComponent|General information N+1">
                au
                niveau suivant
              </th>
              <th i18n="BaseComponent|For all base">Total</th>
            </tr>
            <tr *ngFor="let effect of selectedBuilding.levels[selectedBuilt.level].cumulatedConsumption | keyvalue" (click)=clickOnResource(effect)>
              <td i18n="BaseComponent|cumulatedConsumption">Consommation de {{ effect.key }}</td>
              <td>{{ utilsService.nFormatter(effect.value) }}</td>
              <td *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">{{
                utilsService.nFormatter(selectedBuilding.levels[selectedBuilt.level
                +1].cumulatedConsumption[effect.key]) }}
              </td>
              <td>{{ utilsService.nFormatter(resources.get(effect.key).consumption) }}</td>
            </tr>
            <tr *ngFor="let effect of selectedBuilding.levels[selectedBuilt.level].cumulatedEffects | keyvalue" (click)=clickOnEffect(effect)>
              <td i18n="BaseComponent|cumulatedEffects">{{ getEffects(effect.value.code).libelle }}</td>
              <td>{{ effect.value.amount }}</td>
              <td *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">
                {{selectedBuilding.levels[selectedBuilt.level
                +1].cumulatedEffects[effect.key].amount}}
              </td>
              <td *ngIf="user">
                {{ base.effects[effect.key] }}
              </td>
            </tr>
            <tr *ngFor="let effect of ((selectedBuilding.levels[+selectedBuilt.level + 1] || {}).cumulatedEffects) | keyvalue" (click)=clickOnEffect(effect)>
              <td i18n="BaseComponent|cumulatedEffects"
                *ngIf="!selectedBuilding.levels[selectedBuilt.level].cumulatedEffects[effect.key]">
                {{ getEffects(effect.value.code).libelle }}
              </td>
              <td *ngIf="!selectedBuilding.levels[selectedBuilt.level].cumulatedEffects[effect.key]">
                0
              </td>
              <td
                *ngIf="!selectedBuilding.levels[selectedBuilt.level].cumulatedEffects[effect.key]">
                {{selectedBuilding.levels[selectedBuilt.level
                +1].cumulatedEffects[effect.key].amount}}
              </td>
              <td *ngIf="!selectedBuilding.levels[selectedBuilt.level].cumulatedEffects[effect.key]">
                0
              </td>
            </tr>
            <tr *ngFor="let effect of selectedBuilding.levels[selectedBuilt.level].cumulatedProduction | keyvalue" (click)=clickOnResource(effect)>
              <td i18n="BaseComponent|cumulatedProduction">Production de {{ effect.key }}</td>
              <td>{{ utilsService.nFormatter(effect.value) }}</td>
              <td *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">{{
                utilsService.nFormatter(selectedBuilding.levels[selectedBuilt.level
                +1].cumulatedProduction[effect.key]) }}
              </td>
              <td>{{ utilsService.nFormatter(resources.get(effect.key).production -
                resources.get(effect.key).consumption)
                }}
              </td>
            </tr>
            <tr
              *ngFor="let effect of selectedBuilding.levels[selectedBuilt.level].cumulatedProductionMultiplier | keyvalue" (click)=clickOnResource(effect)>
              <td i18n="BaseComponent|cumulatedProductionMultiplier">Multiplicateur de production de {{ effect.key }}</td>
              <td>{{ effect.value }} %</td>
              <td *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">
                {{selectedBuilding.levels[selectedBuilt.level
                +1].cumulatedProductionMultiplier[effect.key]}} %
              </td>
              <td></td>
            </tr>
            <tr *ngFor="let effect of selectedBuilding.levels[selectedBuilt.level].cumulatedStorage | keyvalue" (click)=clickOnResource(effect)>
              <td i18n="BaseComponent|cumulatedStorage">Stockage de {{ effect.key }}</td>
              <td>{{ utilsService.nFormatter(effect.value) }}</td>
              <td *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">{{
                utilsService.nFormatter(selectedBuilding.levels[selectedBuilt.level
                +1].cumulatedStorage[effect.key]) }}
              </td>
              <td>{{ utilsService.nFormatter(resources.get(effect.key).storage) }}</td>
            </tr>
            <tr
              *ngFor="let effect of selectedBuilding.levels[selectedBuilt.level].cumulatedStorageMultiplier | keyvalue" (click)=clickOnResource(effect)>
              <td i18n="BaseComponent|cumulatedStorageMultiplier">Multiplicateur de stockage de {{ effect.key }}
              </td>
              <td>{{ effect.value }} %</td>
              <td *ngIf="selectedBuilding.levels[selectedBuilt.level + 1]">
                {{selectedBuilding.levels[selectedBuilt.level
                +1].cumulatedStorageMultiplier[effect.key]}} %
              </td>
              <td></td>
            </tr>
          </table>
        </div>
      </ng-template>
    </li>
    <li *ngIf="this.selectedBuilding.levels[selectedBuilt.level].cumulatedEffects['PARALLEL_SHIP_BUILD']"
      [ngbNavItem]="2">
      <a i18n="BaseComponent|Ships tab" ngbNavLink>Vaisseaux</a>
      <ng-template ngbNavContent>
        <div i18n="BaseComponent|Ship production">Constructions en cours : {{ base.curentShipConstruction }}/{{
          base.effects["PARALLEL_SHIP_BUILD"]
          }}
        </div>
        <hr />
        <div class="buildingShips d-flex justify-content-evenly flex-wrap">
          <div *ngFor="let ship of buildableShips" class="buildingShip d-flex flex-column">
            <h3>{{ ship.name }}</h3>
            <img (click)="unitDetails(ship)" class="shipImg help" src="/assets/img/ships/{{ ship.name }}.png" />
            <p>{{ ship.description }}</p>
            <!-- <div class="buildingShipDetails" i18n="BaseComponent|Ship details">
              HP: {{ ship.hp }} Armure: {{ ship.armor }} Bouclier:
              {{ ship.shield }}<br />
              Degats: {{ ship.damage }} Portée: {{ ship.range }} Vitesse:
              {{ ship.speed }}
            </div> -->
            <div class="d-flex flex-wrap justify-content-center p-1 resource">
              <div *ngFor="let cost of ship.cost | keyvalue">
                <div [ngClass]="{
                    insufficientResource:
                      cost.value * ship.buildQuantity >
                      resources.get(cost.key).clientAmount
                  }" class="p-1 d-flex flex-column">
                  <img class="resourceImg" src="/assets/img/resources/{{ cost.key }}.png" />
                  {{ cost.value * ship.buildQuantity }}
                </div>
              </div>
            </div>

            <div class="flex-fill flex-column align-self-center p-1">
              <div class="d-flex flex-column align-self-center justify-content-center">
                <div class="d-flex justify-content-center p-1 underline">
                  Temps de construction
                </div>
                <div class="d-flex justify-content-center p-1">
                  t: {{ ship.buildingTime * ship.buildQuantity * 1000 / (1 + (base.effects['BUILD_SHIP_SPEED'] /100.0))
                  | timer: "UTC" }} s
                </div>
                <div class="d-flex justify-content-center p-1" for="quantity" i18n="BaseComponent|Amount to produce">
                  Quantité : </div>
                <div class="d-flex justify-content-center p-1">
                  <input [(ngModel)]="ship.buildQuantity" min="0" id="quantity" text="1" type="number"
                    (keyup.enter)="buildShips(ship)" />
                </div>
              </div>
            </div>

            <div class="d-flex flex-column justify-content-end align-self-center">
              <button (click)="buildShips(ship)" [disabled]="
                  ship.cost['cristal'] * ship.buildQuantity > resources.get('cristal').clientAmount ||
                  ship.cost['metal'] * ship.buildQuantity > resources.get('metal').clientAmount ||
                  ship.cost['organic'] * ship.buildQuantity > resources.get('organic').clientAmount ||
                  ship.cost['energy'] * ship.buildQuantity > resources.get('energy').clientAmount
                " class="btn btn-outline-success buildingButton" i18n="BaseComponent|Produce the ships">
                Construire
              </button>
              <button (click)="maxBuildableUnits(ship)" class="btn btn-outline-info MaxButton"
                i18n="BaseComponent|MAx amount of ships for current resources">
                Quantité max
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li *ngIf="this.selectedBuilding.levels[selectedBuilt.level].cumulatedEffects['PARALLEL_TURRET_BUILD']"
      [ngbNavItem]="3">
      <a i18n="BaseComponent|Ships tab" ngbNavLink>Tourelles</a>
      <ng-template ngbNavContent>
        <div i18n="BaseComponent|Ship production">Constructions en cours : {{ base.curentTurretConstruction }}/{{
          base.effects["PARALLEL_TURRET_BUILD"]
          }}
        </div>
        <button (click)="turretDetail()" class="btn btn-primary" i18n="BaseComponent|Manage turret">
          Gérer
        </button>
        <hr />
        <div class="buildingShips d-flex justify-content-evenly flex-wrap">
          <div *ngFor="let ship of buildableTurrets" class="buildingShip d-flex flex-column">
            <h3>{{ ship.name }}</h3>
            <img (click)="unitDetails(ship)" class="shipImg help" src="/assets/img/ships/{{ ship.name }}.png" />
            <p>{{ ship.description }}</p>
            <div class="buildingShipDetails" i18n="BaseComponent|Ship details">
              HP: {{ ship.hp }} Armure: {{ ship.armor }} Bouclier:
              {{ ship.shield }}<br />
              Degats: {{ ship.damage }} Portée: {{ ship.range }} Vitesse:
              {{ ship.speed }}
            </div>
            <div class="d-flex flex-wrap justify-content-center p-1 resource">
              <div *ngFor="let cost of ship.cost | keyvalue">
                <div [ngClass]="{
                    insufficientResource:
                      cost.value * ship.buildQuantity >
                      resources.get(cost.key).clientAmount
                  }" class="p-1 d-flex flex-column">
                  <img class="resourceImg" src="/assets/img/resources/{{ cost.key }}.png" />
                  {{ cost.value * ship.buildQuantity }}
                </div>
              </div>
            </div>

            <div class="flex-fill flex-column align-self-center p-1">
              <div class="d-flex flex-column align-self-center justify-content-center">
                <div class="d-flex justify-content-center p-1 underline">
                  Temps de construction
                </div>
                <div class="d-flex justify-content-center p-1">
                  t: {{ ship.buildingTime * ship.buildQuantity * 1000 / (1 + (base.effects['BUILD_TURRET_SPEED']
                  /100.0))
                  | timer: "UTC" }} s
                </div>
                <div class="d-flex justify-content-center p-1" for="quantity" i18n="BaseComponent|Amount to produce">
                  Quantité : </div>
                <div class="d-flex justify-content-center p-1">
                  <input [(ngModel)]="ship.buildQuantity" min="0" id="quantity" text="1" type="number"
                    (keyup.enter)="buildShips(ship)" />
                </div>
              </div>
            </div>

            <div class="d-flex flex-column justify-content-end align-self-center">
              <button (click)="buildShips(ship)" [disabled]="
                  ship.cost['cristal'] * ship.buildQuantity > resources.get('cristal').clientAmount ||
                  ship.cost['metal'] * ship.buildQuantity > resources.get('metal').clientAmount ||
                  ship.cost['organic'] * ship.buildQuantity > resources.get('organic').clientAmount ||
                  ship.cost['energy'] * ship.buildQuantity > resources.get('energy').clientAmount
                " class="btn btn-outline-success buildingButton" i18n="BaseComponent|Produce the ships">
                Construire
              </button>
              <button (click)="maxBuildableUnits(ship)" class="btn btn-outline-info MaxButton"
                i18n="BaseComponent|MAx amount of ships for current resources">
                Quantité max
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li *ngIf="this.selectedBuilding.levels[selectedBuilt.level].cumulatedEffects['PARALLEL_OFFER']"
      [ngbNavItem]="4">
      <a i18n="BaseComponent|Ships tab" ngbNavLink>Marché</a>
      <ng-template ngbNavContent>
        <app-market>
        </app-market>
      </ng-template>
    </li>
    <li *ngIf="this.selectedBuilding.levels[selectedBuilt.level].cumulatedEffects['SEARCH_AREA']" [ngbNavItem]="5">
      <a i18n="BaseComponent|Ships tab" ngbNavLink>Fouille</a>
      <ng-template ngbNavContent>
        <app-rummage>
        </app-rummage>
      </ng-template>
    </li>
    <li *ngIf="this.selectedBuilding.levels[selectedBuilt.level].cumulatedEffects['MAX_AGENT_FORMATION']"
      [ngbNavItem]="6">
      <a i18n="BaseComponent|Ships tab" ngbNavLink>Espionnage</a>
      <ng-template ngbNavContent>
        <app-spying>
        </app-spying>
      </ng-template>
    </li>
    <li *ngIf="hasEffect('RESEARCH_MAX_LINE')" [ngbNavItem]="7">
      <a i18n="BaseComponent|Ships tab" ngbNavLink>Recherches</a>
      <ng-template ngbNavContent>
        <app-research>
        </app-research>
      </ng-template>
    </li>
    <li *ngIf="hasEffect('PARALLEL_BUILD')" [ngbNavItem]="7">
      <a i18n="BaseComponent|Ships tab" ngbNavLink>Effets bonus</a>
      <ng-template ngbNavContent>
        <app-effects>
        </app-effects>
      </ng-template>
    </li>
    <li *ngIf="hasEffect('JOIN_ALLIANCE_UNLOCKED')" [ngbNavItem]="7">
      <a i18n="BaseComponent|Ships tab" ngbNavLink>Alliance</a>
      <ng-template ngbNavContent>
        <app-alliance>
        </app-alliance>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
