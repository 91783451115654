import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Alert } from 'src/app/models/Alert';
import { SpyReport } from 'src/app/models/reports/SpyReport';
import { Research } from 'src/app/models/research/Research';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { ResearchService } from 'src/app/services/research/research.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';
import { SquadronReportComponent } from '../squadron-report/squadron-report.component';

@Component({
  selector: 'app-spy-report',
  templateUrl: './spy-report.component.html',
  styleUrls: ['./spy-report.component.scss']
})
export class SpyReportComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();


  @Input()
  public getReport;
  report: SpyReport;

  knownResearch: Research[];

  constructor(
    private readonly modalService: NgbModal,
    private readonly researchService: ResearchService,
    public readonly utilsService: UtilsService,
    private readonly alertService: AlertService,
    private readonly reportService: ReportService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    this.researchService.researchUpdate$.subscribe((researchs) => {
      this.knownResearch = researchs;
    });
    if (this.getReport) {
      this.getReport.subscribe((report) => {
        report.date = new Date(Date.parse(report.date + ''));
        this.report = report;
      });
    } else {
      let urlSegments = this.router.url.split('/');
      let routerConfig = this.router.config.filter(config => {
        return config.path.split('/')[0] === urlSegments[1];
      })
      if (routerConfig.length > 0) {
        this.reportService.getSpyReportByUuid(urlSegments[2]).subscribe((report) => {
          report.date = new Date(Date.parse(report.date + ''));
          this.report = report;

          let mainLoading = document.getElementsByClassName('mainLoading');
          if (mainLoading && mainLoading[0]) {
            mainLoading[0].classList.add('d-none');
          }
        })
        this.utilsService.timer
          .pipe(takeUntil(this.destroy$))
          .subscribe((val) => {
            document.getElementsByClassName('my-3')[0]['style'].setProperty('--headerHeigth', (document.getElementsByClassName('modal-header')[0].clientHeight + 10) + 'px')
          })
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  close(reason, source) {
    if (source.shiftKey) {
      this.modalService.dismissAll()
    } else {
      this.utilsService.closeModalByEvent(source);
    }
  }

  getResearchFromId(researchId) {
    if (this.knownResearch) {
      return this.knownResearch.filter((research) => research.id === researchId)[0] || { name: researchId };
    } else {
      return { name: researchId };
    }
  }

  shareReport() {
    let alert = new Alert();
    let result = navigator.clipboard.writeText(window.location.origin + `/spy/${this.report.uuid}`);
    result.then((reason) => {
      alert.type = "success";
      alert.msg =
        `Le lien vers le rapport de combat a bien été copié
          il ne peut pas encore etre partagé à d'autres mais le lien pourrai vous être utile`;
      alert.timeout = 2000;
      this.alertService.addAlert(alert);/** */

    })
      .catch((error) => {
        console.error(error)
        if (error) {
          alert['datas'] = { error: error };
        }
        alert.type = "danger";
        alert.msg = "Le lien vers le rapport de combat n'a pas pu être copié";
        alert.timeout = 10000;
        this.alertService.addAlert(alert);/** */
      })
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  unitDetailModal;
  squadronDetails(squad) {
    let squadron = JSON.parse(JSON.stringify(squad));
    if (!this.unitDetailModal) {
      this.unitDetailModal = this.modalService.open(SquadronReportComponent, {
        windowClass: "report-SquadronReport backModalImg",
        ariaLabelledBy: "report-SquadronReport",
        scrollable: true,
        size: "lg",
        backdrop: false,
      });
    }
    this.unitDetailModal.componentInstance.addSquadron(squadron);
    this.unitDetailModal.hidden.subscribe(() => {
      this.unitDetailModal = null;
    });
  }

}
