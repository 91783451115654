<div class="modal-header">
  <h4 class="modal-title me-3" id="modal-basic-title">
    Sélecteur de coordonnées
  </h4>
  <div>
    <label for="fleet" i18n="PlanetComponent|Fleet">Planète: </label>
    <select (change)="onChangePlanet($event.target['value'])">
      <option *ngFor="let o of universe" [value]="o.id" [selected]="o.id === planet.id">
        {{ o.name }}
      </option>
    </select>
  </div>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button">
  </button>
</div>


<div class="modal-body d-flex ">
  <table *ngIf="tiles" class="table table-striped table-hover table-dark planet-table custom-bottom">
    <tbody>
      <tr>
        <th>
          <label>
            Type de bases :
          </label>
          <select id="baseTypeFilter" (change)="onFilter($event.target['value'])">
            <option *ngFor="let filter of filters" [selected]="filter.selected" [value]="filter.id">
              {{ filter.value }}
            </option>
          </select>
        </th>
        <th colspan="10">
          <input class="inputSearch" type="search" (input)="onSearch($event.target['value'])"
            placeholder="Recherche...">
        </th>
      </tr>
      <tr>
        <th id="coordinates" (click)="sortByText('coordinates')">
          <i class='bx'></i>
          Coordonnées
        </th>
        <th *ngIf="debug" id="id" (click)="sortByText('id')">
          <i class='bx'></i>
          Id de la base :
        </th>
        <th id="name">
          <i class='bx'></i>
          Nom de la base
        </th>
        <th id="ownerName">
          <i class='bx'></i>
          Nom du proprio
        </th>
        <th *ngIf="objectToMove && objectToMove.speed" id="travelTime" (click)="sortByNumber('travelTime')">
          <i class='bx'></i>
          temps estimé de trajet
        </th>
        <th *ngIf="objectToMove && !objectToMove.speed" id="distance" (click)="sortByNumber('distance')">
          <i class='bx'></i>
          distance de trajet
        </th>
      </tr>
      <tr *ngFor="let tile of tiles" (click)='select(tile["coordinates"])' class="justify-content-around p-2 openUserProfile">

        <td>{{ tile['coordinates'] }}</td>
        <td *ngIf="debug">{{ tile.base.id }}</td>
        <td>{{ tile.base.name }}</td>
        <td>{{ tile.base.ownerName }}</td>
        <td *ngIf="tile['travelTime']"> {{ tile['travelTime']*1000 | timer: "UTC"}}</td>
        <td *ngIf="!tile['travelTime'] && tile['distance']"> {{ tile['distance'] }}</td>
      </tr>
    </tbody>
  </table>
</div>
