import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, Subject, takeUntil, throwError } from "rxjs";
import { Planet } from "src/app/models/Planet/Planet";
import { environment } from "src/environments/environment";
import { BaseService } from "../bases/base.service";

@Injectable({
  providedIn: "root",
})
export class PlanetsService {
  private apiUrl = environment.apiEndpoint;
  private lastUpdateTimestamp = [];

  private planet = new BehaviorSubject<Planet>(null);
  public planetUpdate$ = this.planet.asObservable();

  private universe = new BehaviorSubject<Planet[]>(null);
  public universeUpdate$ = this.universe.asObservable();
  constructor(
    private readonly http: HttpClient,
    private readonly baseService: BaseService,
  ) {
    this.getPlanets().subscribe((universe)=> {
    });
    let destroy$ = new Subject();
    baseService.baseUpdate$
    .pipe(takeUntil(destroy$))
    .subscribe((base)=> {
      if(base) {
        this.markForPlanetUpdate(base.position.planetId);
        destroy$.next('');
      }
    })
  }

  public getPlanets(): Observable<Planet[]> {
    return this.http.get<Planet[]>(this.apiUrl + `planets`).pipe(map((universe) => {
      this.universe.next(universe);
      return universe;
    })
    ).pipe(
      catchError(err => {
        this.universe.error(err);
        return throwError(() => err);
      })
    );;
  }
  public markForPlanetUpdate(planetId) {
    if (((this.lastUpdateTimestamp[planetId] || 0) + 60000) < (Date.now())) { //si la dernière MAJ était il y a plus d'une minute
      this.lastUpdateTimestamp[planetId] = Date.now();
      this.getPlanetById(planetId).subscribe({
        next: () => { },
        error: () => {
          this.lastUpdateTimestamp[planetId] = 0;
        }
      })
    }
  }

  public getPlanetById(planetId: string): Observable<Planet> {
    return this.http.get<Planet>(this.apiUrl + `planets/${planetId}`).pipe(map((planet:Planet) => {
      this.planet.next(planet);
      this.universe.value.forEach((planets, index)=> {
        if(planets.id === planet.id) {
          this.universe.value[index] = planet;
          this.universe.next(this.universe.value)
        }
      })
      return planet;
    })
    ).pipe(
      catchError(err => {
        this.planet.error(err);
        return throwError(() => err);
      })
    );
  }
}
