<div class="d-flex custom-bottom">
  <div>
    <img *ngIf="user && user.imageLocation" alt="Card image cap" (click)="changeImg()" class="govImage"
      src="{{'/assets/img/' + user.imageLocation }}" />
    <img *ngIf="user && !user.imageLocation && selectedGovernment" (click)="changeImg()" class="govImage"
      src="{{'/assets/img/govs/' + selectedGovernment.shortName + '.png'}}" />
    <!--<ul>-->
    <!--  <li *ngFor="let base of bases">{{ base.name }}</li>-->
    <!--</ul>-->
    <div *ngIf="bases" class="baseList d-flex flex-row">
      <form id="renameBaseName">
        <textarea *ngFor="let base of bases" id="{{base.id}}" name="userDescriptionForm">{{base.name}}</textarea>
      </form>
      <button class="bx bx-save" (click)="saveBaseName()"></button>
    </div>
  </div>

  <div class="profile">
    <div *ngIf="user" class="ownerName">
      {{user.name}}
    </div>
    <div *ngIf="user" class="ownerDescription">
      <button class="bx bx-save" (click)="saveDescription('ownerDescriptionForm')"></button>
      <form>
        <textarea (input)="change($event.target)" id="ownerDescriptionForm"
          name="ownerDescriptionForm">{{user.description}}</textarea>
          <label class="mh-0 invisible">
            <label id="ownerDescriptionLabel">
              <div *ngFor="let line of descriptionTextLines">
                {{line}}
              </div>
            </label>
          </label>
      </form>
    </div>
    <div (click)="showAlliance()" *ngIf="user && user.allianceName" class="allianceName">
      {{user.allianceName}}
    </div>

    <div *ngIf="reputation" class="reputation">
      Réputations
      <div *ngFor="let reputationValue of reputation.reputationValues | keyvalue" class="reputation-container d-flex">
        <span class="reputation-text">
        {{reputation.reputationNames[reputationValue.key]}}<br>
        <i>{{reputation.iaTypes[reputationValue.key]}}</i>
        </span>
        <ngb-progressbar [max]=1000 [showValue]=true [value]=reputationValue.value
          class="reputation-progressbar d-flex flex-fill"></ngb-progressbar>
      </div>
    </div>
  </div>
</div>
