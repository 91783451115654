import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'timer'
})
export class TimerPipe implements PipeTransform {

  transform(value: number, timezone?: string, locale?: string): unknown {
    let pipe = new DatePipe('en-US');
    let ret;
    let isNeg = value < 0;
    value = Math.abs(value);
    if(value >= 86400000) { // more dthan a day
      ret =  pipe.transform(value, "H:mm:ss", timezone, locale);
      ret = (Math.floor( value / 86400000)).toString()+'j, ' + ret;
    } else if (value>= 3600000) {
      ret =  pipe.transform(value, "H:mm:ss", timezone, locale);
    } else if(value) {
      ret =  pipe.transform(value, "mm:ss", timezone, locale);
    } else {
      ret = "NaN";
    }
    if (isNeg) {
      ret = '-'+ret;
    }
    return ret;
  }
}
