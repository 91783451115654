import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router, Routes } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventService } from "src/app//services/event/event.service";
import { ReportService } from "src/app//services/reports/report.service";
import { UsersService } from "src/app//services/Users/user.service";
import { WebsocketService } from "src/app//services/webSocket/websocket.service";
import { TechnoTreeComponent } from "src/app/main/technoTree/technoTree.component";
import { User } from "src/app/models";
import { BaseRef } from "src/app/models/base/BaseRef";
import { AlertService } from "src/app/services/alert/alert.service";
import { AuthenticationService } from "src/app/services/Authentication/authentication.service";
import { BaseService } from "src/app/services/bases/base.service";
import { OptionsService } from "src/app/services/options/options.service";
import { MessageBoxComponent } from "src/app/main/message-box/message-box.component";
import { Alert } from "../models/Alert";
import { Base } from "../models/base/Base";
import { Planet } from "../models/Planet/Planet";
import { Quest } from "../models/Quest/Quest";
import { MenuService } from "../services/Menu/menu.service";
import { PlanetsService } from "../services/Planets/planets.service";
import { QuestsService } from "../services/Quests/quests.service";
import { UtilsService } from "../services/Utils/utils.service";
import { AllianceComponent } from "./alliance/alliance.component";
import { BasesComponent } from "./bases/bases.component";
import { FleetsComponent } from "./fleets/fleets/fleets.component";
import { LeaderboardComponent } from "./leaderboard/leaderboard.component";
import { MarketComponent } from "./market/market.component";
import { MenuComponent } from "./menu/menu.component";
import { DebugComponent } from "./options/debug/debug.component";
import { OptionsComponent } from "./options/options.component";
import { PlanetsComponent } from "./planets/planets.component";
import { QuestsComponent } from "./quests/quests.component";
import { ResearchComponent } from './research/research.component';
import { ProfileComponent } from "./users/profile/profile.component";
import { MessageService } from "../services/message/message.service";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss", "./style.css"],
})
export class MainComponent implements OnInit {
  static routes: Routes = [
    { path: "profile", children: ProfileComponent.routes },
    //  { path: 'users/create', children: UsersCreateComponent.routes},
    { path: "bases/:id", children: BasesComponent.routes },
    { path: "planet", children: PlanetsComponent.routes },
    { path: "fleet/:baseId", children: FleetsComponent.routes },
    { path: "messages", children: MessageBoxComponent.routes },
    { path: "research", children: ResearchComponent.routes },
    { path: "market", children: MarketComponent.routes },
    { path: "alliance", children: AllianceComponent.routes },
  ];
  newReports = 0;
  newThreads = 0;
  availableQuests: Quest[] = [];
  currentPlayer: User;
  currentBase: Base;
  currentBaseId: string;
  currentPlanet: Planet
  currentMenu: string;
  bases: BaseRef[];
  isCollapsed: boolean;
  public alerts: Map<number, Alert> = new Map<number, Alert>();
  sidebar;
  closeBtn;
  tooltip = true;

  constructor(
    private readonly router: Router,
    private readonly usersService: UsersService,
    private readonly baseService: BaseService,
    private readonly eventService: EventService,
    private readonly alertService: AlertService,
    private readonly reportService: ReportService,
    private readonly optionsService: OptionsService,
    private readonly planetService: PlanetsService,
    private readonly websocketService: WebsocketService,
    private readonly modalService: NgbModal,
    private readonly authenticationService: AuthenticationService,
    public readonly menuService: MenuService,
    public readonly utilsService: UtilsService,
    private readonly questService: QuestsService,
    private readonly messageService: MessageService,
  ) {
    this.loadPlayer();
  }
  hasLoggedIn: boolean = false;

  ngOnInit() {
    this.hasLoggedIn = true;
    //this.websocketService.register();
    //this.websocketService.send("test");
    this.currentMenu = localStorage.getItem("currentMenu");
    this.isCollapsed = true;
    // localStorage.clear();  // j'ai besoin de LocalStorage pour la mémoire des options
    localStorage.setItem("currentMenu", this.currentMenu);
    this.optionsService.init();
    this.alertService.alertsAnnounced$.subscribe({
      next: (alert) => {
        this.alerts.set(Date.now(), alert);
      }
    });
    // remove alerts when enough time has passed
    let alertSubscribe = this.utilsService.timer.subscribe((val) => {
      let now = Date.now();
      if (this.alerts) {
        for (let alert of this.alerts.keys()) {
          if (now > (alert + this.alerts.get(alert).timeout)) {
            if (!this.alerts.get(alert).focus) {
              this.removeAlert(alert);
            }
          }
        }
      }
    });

    this.messageService.numberOfThreadsAnnounced$.subscribe({
      next: (numberOfThreads)=> {
        this.newThreads = numberOfThreads;
      }
    })

    // reload not read reports when it is refreshed
    this.reportService.numberOfReportsAnnounced$.subscribe({
      next: (numberOfReports) => {
        this.newReports = numberOfReports;
      }
    });

    this.planetService.planetUpdate$.subscribe({
      next: (planet) => {
        if (planet) {
          this.currentPlanet = planet;
        }
      }
    })
    this.baseService.baseUpdate$.subscribe((base) => {
      if (base) {
        if (!this.currentBase) {
          setTimeout(() => {
            let baseSelector = this.sidebar.querySelector("select");

            baseSelector.addEventListener("blur", () => {
              this.sidebar.classList.remove("open");
              this.menuBtnChange();
            });

            baseSelector.addEventListener("click", (event) => {
              if (!this.sidebar.classList.contains('locked')) {
                this.sidebar.classList.add("open");
                this.menuBtnChange();
              }
              this.sidebar.classList.remove("locked");
            });
            this.eventService.forceUpdates();
            this.planetService.getPlanetById(base.position.planetId).subscribe();
          }, 250);
        }
        this.currentBase = base;
        this.updateMenu();
      }
    });
    this.menuService.saveMenu();
    this.questService.availableQuestsAnnounced$.subscribe((quests) => {
      this.availableQuests = quests || [];
    })
  }

  checkTool(msg, disabled) {
    return (this.tooltip && !disabled) ? msg : null;
  }

  menuBtnChange() {
    if (this.sidebar.classList.contains("open")) {
      this.tooltip = false;
      this.closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");
    } else {
      this.tooltip = true;
      this.closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");
    }
  }

  private loadPlayer() {
    this.usersService.userUpdate$.subscribe({
      next: (user) => {
        if (user && !this.currentPlayer) {
          this.currentPlayer = user;
          if (document.getElementsByClassName('mainLoading') && document.getElementsByClassName('mainLoading')[0]) {
            (<HTMLElement>document.getElementsByClassName('mainLoading')[0])['className'] = '';
          }
          localStorage.setItem("user", JSON.stringify(this.currentPlayer));
          let currentBase = this.baseService.getCurrentBaseId();
          if (!currentBase || !this.currentPlayer.baseListId[currentBase]) {
            this.currentBaseId = this.currentPlayer.mainBaseId;
            localStorage.setItem("currentBase", this.currentBaseId);
          } else {
            this.currentBaseId = currentBase;
          }

          if (!localStorage.getItem("currentMenu")) {
            this.currentMenu = "base";
            localStorage.setItem("currentMenu", "base");
          } else {
            this.currentMenu = localStorage.getItem("currentMenu");
          }

          this.loadBase();


          switch (this.currentMenu) {
            case "base": {
              this.router.navigate(["/main/bases/", this.currentBaseId]);
              break;
            }
            case "planet": {
              this.router.navigate(["/main/planet/"]);
              break;
            }
            case "fleet": {
              this.router.navigate(["/main/fleet/", this.currentBaseId]);
              break;
            }
            case "research": {
              this.router.navigate(["/main/research/"]);
              break;
            }
            case "messages": {
              this.router.navigate(["/main/messages/"]);
              break;
            }
            case "alliance": {
              this.router.navigate(["/main/alliance/"]);
              break;
            }
            case "profile": {
              this.router.navigate(["/main/profile/"]);
              break;
            }
            case "market": {
              this.router.navigate(["/main/market/"]);
              break;
            }
            default: {
              this.router.navigate(["/main/bases/", this.currentBaseId]);
              break;
            }
          }
        }
        this.currentPlayer = user;
        this.updateMenu();
      },
      error: (error) => {
        if (error instanceof HttpErrorResponse && error.status == 404) {
          this.router.navigate(["/users/create"]);
        }
      }
    });
  }

  private loadBase() {
    this.baseService.getBases().subscribe((bases) => {
      this.bases = bases;
      this.baseService.updateBase();

      this.sidebar = document.querySelector(".sidebar");
      this.closeBtn = this.sidebar.querySelector("#btn");
      let searchBtn = this.sidebar.querySelector(".bx-search");

      this.closeBtn.addEventListener("click", () => {
        this.sidebar.classList.toggle("open");
        this.menuBtnChange();
      });

      searchBtn.addEventListener("click", () => {
        this.sidebar.classList.add("open");
        this.menuBtnChange();
      });

      this.li = document.querySelectorAll('.menu_box li:not(.alwaysOn)')
    });
  }

  onChange(baseId) {
    this.sidebar.classList.add("locked");
    this.sidebar.classList.remove("open");
    this.menuBtnChange();
    this.modalService.dismissAll();
    localStorage.setItem("currentBase", baseId);
    this.baseService.updateBase(baseId);
    this.eventService.updateBaseEventNeeded();
    localStorage.setItem("currentMenu", "base");
  }


  redirect(menu: string) {
    let menuItem = this.menuService.menu.filter((mnu) => mnu.redirect === menu);
    if (menuItem.length > 0 && !menuItem[0].disabled) {

      this.currentMenu = menu;
      localStorage.setItem("currentMenu", menu);
      switch (this.currentMenu) {
        case "base": {
          this.router.navigate(["/main/bases/", this.currentBaseId]);
          break;
        }
        case "planet": {
          this.router.navigate(["/main/planet/"]);
          break;
        }
        case "fleet": {
          this.router.navigate(["/main/fleet/", this.currentBaseId]);
          break;
        }
        case "research": {
          this.router.navigate(["/main/research/"]);
          break;
        }
        case "messages": {
          this.router.navigate(["/main/messages/"]);
          break;
        }
        case "alliance": {
          this.router.navigate(["/main/alliance/"]);
          break;
        }
        case "options": {
          let modal = this.modalService.open(OptionsComponent, {
            ariaLabelledBy: "options",
            scrollable: true,
            size: "xl",
            windowClass: "options backModalImg",
            backdrop: true,
          });
          break;
        }
        case "quests": {
          let modal = this.modalService.open(QuestsComponent, {
            ariaLabelledBy: "quests",
            scrollable: true,
            size: "xl",
            windowClass: "quests backModalImg",
            backdrop: false,
          });
          break;
        }
        case "technoTree": {
          let technoTreeModal = this.modalService.open(TechnoTreeComponent, {
            ariaLabelledBy: "technoTree",
            windowClass: "technoTree backModalImg",
            scrollable: true,
            size: "lg",
            backdrop: true,
          });

          technoTreeModal.componentInstance.playerId = this.currentPlayer.id;
          break;
        }
        case "profile": {
          this.router.navigate(["/main/profile/"]);
          break;
        }
        case "market": {
          this.router.navigate(["/main/market/"]);
          break;
        }
        case "ranking": {
          let modal = this.modalService.open(LeaderboardComponent, {
            ariaLabelledBy: "options",
            size: "xl",
            windowClass: "options backModalImg",
            backdrop: true,
          });
          break;
        }
        case "discord": {
          window.open("https://discord.gg/PrrQ9Wmqhh", '_blank')
          break;
        }
        case "bookmark": {
          let modal = this.modalService.open(MenuComponent, {
            ariaLabelledBy: "customizeMenu",
            size: "xl",
            windowClass: "customizeMenu backModalImg",
            backdrop: true,
          });
          break;
        }
        default: {
          this.router.navigate(["/main/bases/", this.currentBaseId]);
          break;
        }
      }
    }
  }
  public removeAlert(index) {
    this.alerts.delete(index);
  }

  public openAlerts(index) {
    if (this.alerts.get(index)['datas']) {
      let openAlert = this.modalService.open(DebugComponent, {
        ariaLabelledBy: "options",
        scrollable: true,
        size: "xl",
        windowClass: "openAlert backModalImg",
        backdrop: false,
      });
      openAlert.componentInstance.data = this.stringify(this.alerts.get(index)['datas']);
    }
  }

  public stringify(data) {
    return JSON.stringify(data, null, 2);
  }

  li;
  onSearch(text) {
    text = text || "";
    this.li.forEach((li) => {
      if (li.getElementsByTagName('span')[0].textContent.toLocaleLowerCase().includes(text)) {
        li.classList.remove('hidden')
      } else {
        li.classList.add('hidden')
      }
    });
  }

  updateMenu() {
    let elm = document.getElementsByClassName('menu_box')[0]
    if (elm) {
      let elms = elm.querySelectorAll('li');
      elms.forEach(elm => {
        if (elm.id) {
          this.menuService.menu.forEach((menu) => {
            if (menu.redirect === elm.id) {
              if (menu.requires[0] === 'base') {
                if (this.currentBase && this.currentBase.effects) {
                  menu.disabled = (this.currentBase.effects[menu.requires[1][0]] || -1) < menu.requires[1][1];
                } else {
                  menu.disabled = true;
                }
              } else if (menu.requires[0] === 'user') {
                if (this.currentPlayer && this.currentPlayer.effects) {
                  menu.disabled = (this.currentPlayer.effects[menu.requires[1][0]] || -1) < menu.requires[1][1];
                } else {
                  menu.disabled = true;
                }
              } else {
                menu.disabled = false;
              }
              if (menu.disabled) {
                elm.setAttribute('disabled', "");
              } else {
                elm.removeAttribute('disabled')
              }
            }
          })
        }
      })
    }
  }
}
