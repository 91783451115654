import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: "app-profile-picture-selector",
  templateUrl: "./profile-picture-selector.component.html",
  styleUrls: ["./profile-picture-selector.component.scss"],
})
export class ProfilePictureSelectorComponent {
  imgTable: string[] = [
"planet.png",
"space.jpg",
"back/fond.jpg",
"buildings/Catalyseur.png",
"buildings/Chantier.png",
"buildings/Compresseur.png",
"buildings/construction.png",
"buildings/Extracteur.png",
"buildings/Generateur.png",
"buildings/Hangar.png",
"buildings/HQ.png",
"buildings/Laboratoire.png",
"buildings/Mine.png",
"buildings/Plateforme de defense.png",
"buildings/Rafinerie.png",
"buildings/Stockage.png",
"buildings/Synthetiseur.png",
"buildings/Camp des éclaireurs.png",
"govs/Dioscurie.png",
"govs/Euthenia.png",
"govs/Perseus.png",
"govs/Zreix.png",
"govs/propaganda_poster_1.png",
"govs/propaganda_poster_2.png",
"govs/propaganda_poster_3.png",
"govs/propaganda_poster_4.png",
"research/Aerospatiale.png",
"resources/cristal.png",
"resources/energy.png",
"resources/metal.png",
"resources/organic.png",
"ships/Apis.png",
"ships/Canon Laser.png",
"ships/Damascus.png",
"ships/Dragune_200.png",
"ships/Dragune_400.png",
"ships/Dragune_800.png",
"ships/Gatling.png",
"ships/Imperial F3.png",
"ships/Lance Missile.png",
"ships/Lauviah.png",
"ships/Leurre.png",
"ships/Leviathan.png",
"ships/Lug.png",
"ships/Narca.png",
"ships/RailGun.png",
"ships/Raphran.png",
"slots/CRISTAL.png",
"slots/DEFAULT.png",
"slots/METAL.png",
"slots/untitle.png",
"unused/aexlLp6q_700w_0.jpg",
"unused/Armement.png",
"unused/aWlKd7jR_700w_0.jpg",
"unused/banana-duck.jpg",
"unused/Bruhh.PNG",
"unused/Capture.PNG",
"unused/Economy.jpg",
"unused/hu.PNG",
"unused/Kitlin.png",
"unused/MacronAbonnezVous.PNG",
"unused/SurprisedPikachu.png",
"unused/UGLY_THING.jpg",
"unused/une-patate.jpg",
"unused/Unnecessary.jpg",
"unused/Wudy.jpg",
"unused/UmbreonReally.png",
"unused/Research.png"
  ];
  constructor(
    public readonly modal: NgbActiveModal,
  ) {

  }

  selectImg(url) {
    this.modal.close(url);
  }
}
