import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, take } from "rxjs";
import { Alliance } from "src/app/models/Alliance/Alliance";
import { GlobalPosition } from "src/app/models/GlobalPosition";
import { BuildingsService } from "src/app/services/Buildings/buildings.service";
import { UsersService } from 'src/app/services/Users/user.service';
import { PlanetsService } from "../Planets/planets.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RummageService {
  private apiUrl = environment.apiEndpoint;
  rummageHistory: Map<string, string[][]>

  constructor(
    private readonly http: HttpClient,
    private readonly buildingsService: BuildingsService,
    private readonly userService: UsersService,
    private readonly planetService: PlanetsService,
  ) {
    this.rummageHistory = JSON.parse(localStorage.getItem('RummageHistory'));
    if (!this.rummageHistory) {
      this.rummageHistory = new Map<string, string[][]>();
    }
    this.planetService.universeUpdate$
    .pipe(take(1))
    .subscribe((planets) => {
      for (let key in planets) {
        let planet = planets[key]
        if (!this.rummageHistory[planet.id]) {
          this.rummageHistory[planet.id] = [];
        }
      }
      localStorage.setItem('RummageHistory', JSON.stringify(this.rummageHistory));
    })
  }

  addTargetsToHistory(targets: GlobalPosition[]) {
    let tragetCoordinates = []
    targets.forEach((target) => {
      tragetCoordinates.push(target.coordinates)
    })
    this.rummageHistory[targets[0].planetId].push(tragetCoordinates);
    localStorage.setItem('RummageHistory', JSON.stringify(this.rummageHistory));

  }

  resetRummageHistory() {
    this.rummageHistory = new Map<string, string[][]>();
    this.planetService.universeUpdate$
    .pipe(take(1))
    .subscribe((planets) => {
      for (let key in planets) {
        let planet = planets[key]
        if (!this.rummageHistory[planet.id]) {
          this.rummageHistory[planet.id] = [];
        }
      }
      localStorage.setItem('RummageHistory', JSON.stringify(this.rummageHistory));
    })

  }

  public rummageByCoords(baseId: string, targets: GlobalPosition[]): Observable<Alliance> {
    return this.http.patch<Alliance>(this.apiUrl + `loot/base/${baseId}/search`, targets)
      .pipe(map((rummage) => {
        this.addTargetsToHistory(targets);
        return rummage;
      }),
      );
  }
}
