<ng-template #listUsers let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDisable|Title" id="confirmDisable">Choisisser les joueurs à inviter</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <div class="searchBar">
      <input class="inputSearch" type="search" (input)="filterUserName($event.target['value'])" placeholder="Search...">
    </div>
    <div class="users" *ngIf="usersFiltered">
      <div *ngFor="let user of usersFiltered" class="d-flex justify-content-around flex-wrap btn btn-outline-secondary"
        [attr.selected]="user['selected'] === true" (click)="select(user.name)">{{user.name}}</div>
    </div>
    <div class="d-flex justify-content-center my-0">
      <div class="d-flex justify-content-center my-0">
        <button (click)="invitePlayers($event.target)" class="btn btn-outline-success" i18n="confirmDestroy|Confirm">
          Confirmer les invitations
        </button>
      </div>
      <div class="d-flex justify-content-center my-0">
        <button class="btn btn-outline-danger" i18n="confirmDestroy|Cancel" (click)="modal.dismiss('Cancel click')">
          Annuler
        </button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #validRole let-modal>
  <i class='bx bxs-badge-check'></i>
</ng-template>

<ng-template #invalidRole let-modal>
  <i class='bx bxs-message-rounded-x'></i>
</ng-template>

<ng-template #renameRoles let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDisable|Title" id="confirmDisable">Renommer les rôles</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-striped table-hover table-dark planet-table">
      <tbody>
        <tr>
          <th>
            <label>
              Rôle
            </label>
          </th>
          <th>
            <label>
              Voir la BA
            </label>
          </th>
          <th>
            <label>
              gestion des membres de niveaux inférieurs
            </label>
          </th>
          <th>
            <label>
              Gérer la BA
            </label>
          </th>
          <th>
            <label>
              Gérer les flottes d'alliance
            </label>
          </th>
          <th>
            <label>
              Administrateur
            </label>
          </th>
        </tr>
        <tr *ngFor="let roleId of numSequence(currentAlliance.libelleRole.length-1), let i = index">
          <td>
            <label>
              <form>
                <textarea (input)="roleRename($event.target['value'], i)" id="allianceRoleForm"
                  name="allianceRoleForm">{{ utilsService.translateConstants(currentAlliance.libelleRole[i]) }}</textarea>
              </form>
            </label>
          </td>
          <td>
            <label *ngIf="i <= 3; then validRole else invalidRole">
            </label>
          </td>
          <td>
            <label *ngIf="i <= 2; then validRole else invalidRole">
            </label>
          </td>
          <td>
            <label *ngIf="i <= 1; then validRole else invalidRole">
            </label>
          </td>
          <td>
            <label *ngIf="i <= 1; then validRole else invalidRole">
            </label>
          </td>
          <td>
            <label *ngIf="i === 0; then validRole else invalidRole">
            </label>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center my-0">
      <div class="d-flex justify-content-center my-0">
        <button (click)="saveRoleName($event)" class="btn btn-outline-success" i18n="confirmDestroy|Confirm">
          Confirmer les nouveaux rangs
        </button>
      </div>
      <div class="d-flex justify-content-center my-0">
        <button class="btn btn-outline-danger" i18n="confirmDestroy|Cancel" (click)="modal.dismiss('Cancel click')">
          Annuler
        </button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #changeRolesModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDisable|Title" id="confirmDisable">Modifier les roles</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <div class="searchBar d-flex justify-content-center my-0">
      <input class="inputSearch" type="search" (input)="filterUserName($event.target['value'])" placeholder="Search...">
      <button (click)="changeRoles(renameRoles)" class="btn btn-outline-primary" i18n="confirmDestroy|Confirm">
        Renommer les rôles
      </button>
    </div>
    <!-- <div class="users" *ngIf="usersFiltered">
      <div *ngFor="let user of usersFiltered" class="d-flex justify-content-around flex-wrap btn btn-outline-secondary"
        [attr.selected]="user['selected'] === true" (click)="select(user.name)">{{user.name}}</div>
    </div> -->
    <table class="table table-striped table-hover table-dark planet-table">
      <tbody>
        <tr>
          <th>
            <label>
              Membres
            </label>
          </th>
          <th>
            <label>
              Rôle
            </label>
          </th>
        </tr>
        <tr *ngFor="let user of usersFiltered" class="justify-content-around p-2 openUserProfile">
          <td [id]="user.id" slot="{{user['role']}}">{{ user.name }}</td>
          <td *ngIf="user['role'] > currentUserRole">
            <select class="form-control roleChoice" id="roleChoice">
              <option
                *ngFor="let roleId of numSequence(currentAlliance.libelleRole.length-currentUserRole), let i = index"
                [selected]="(currentAlliance.libelleRole.length - (i+1)) === user['role']"
                [value]="(currentAlliance.libelleRole.length - (i+1))">
                {{ utilsService.translateConstants(currentAlliance.libelleRole[(currentAlliance.libelleRole.length -
                (i+1))]) }}
              </option>
            </select>
          </td>
          <td *ngIf="user['role'] <= currentUserRole">
            {{ utilsService.translateConstants(currentAlliance.libelleRole[user['role']]) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-center my-0">
      <div class="d-flex justify-content-center my-0">
        <button (click)="confirmNewRoles($event.target)" class="btn btn-outline-success" i18n="confirmDestroy|Confirm">
          Confirmer les nouveaux rôles
        </button>
      </div>
      <div class="d-flex justify-content-center my-0">
        <button class="btn btn-outline-danger" i18n="confirmDestroy|Cancel" (click)="modal.dismiss('Cancel click')">
          Annuler
        </button>
      </div>
    </div>

  </div>
</ng-template>


<div class="d-flex custom-bottom">
  <div *ngIf="currentAlliance" class="d-flex flex-row mx-auto">
    <div class="d-flex flex-column justify-content-around">
      <img *ngIf="currentAlliance.imageLocation" alt={{currentAlliance.shortName}} class="govImage"
        src="{{'/assets/img/' + currentAlliance.imageLocation }}" (click)="changeImg()" />
      <img *ngIf="!currentAlliance.imageLocation" alt={{currentAlliance.shortName}} class="govImage"
        src="{{'/assets/img/alliances/generic.png' }}" (click)="changeImg()" />

      <button *ngIf="currentUserRole <= 2" class="btn btn-primary" (click)="invite(listUsers)">
        Inviter un joueur
      </button>

      <button *ngIf="currentUserRole <= 2 && hasRequest()" class="btn btn-primary" (click)="showRequests(listUsers)">
        Voir les demandes
      </button>

      <button *ngIf="currentUserRole === 0" class="btn btn-secondary" (click)="changeRoles(changeRolesModal)">Modifier
        les rôles</button>

      <button *ngIf="currentUser" class="btn btn-danger" (click)="leave(currentAlliance)">Quitter</button>
    </div>
    <div class="profile d-flex flex-column">
      <div class="allianceName">
        Nom : {{currentAlliance.name}}
      </div>
      <div class="allianceDescription">
        <button class="bx bx-save" (click)="saveAllianceDescription('allianceDescriptionForm')"></button>
        <form>
          <textarea (input)="descriptionChange($event.target)" id="allianceDescriptionForm"
            name="allianceDescriptionForm">{{currentAlliance.description}}</textarea>
          <label class="mh-0 invisible">
            <label id="allianceDescriptionLabel">
              <div *ngFor="let line of descriptionTextLines">
                {{line}}
              </div>
            </label>
          </label>
        </form>
      </div>
      <div class="leaderName" (click)="openUserProfile(currentAlliance.leaderId)">
        {{utilsService.translateConstants(currentAlliance.libelleRole[currentAlliance.memberRoles[currentAlliance.leaderId]])}}
        : {{currentAlliance.leaderName}}
      </div>
      <table class="table table-striped table-hover table-dark planet-table">
        <tbody>
          <tr>
            <th>
              <label>
                Membres
              </label>
            </th>
            <th>
              <label>
                Rôle
              </label>
            </th>
          </tr>
          <tr *ngFor="let member of currentAlliance.members | keyvalue" (click)="openUserProfile(member.key)"
            class="justify-content-around p-2 openUserProfile">
            <td>{{ member.value }}</td>
            <td>
              {{ utilsService.translateConstants(currentAlliance.libelleRole[currentAlliance.memberRoles[member.key]])}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <button *ngIf="currentUser && !currentUser.allianceId && currentUser.effects['CREATE_ALLIANCE_UNLOCKED']"
    class="btn btn-info mx-auto" (click)="createNewAlliance()">créer une nouvelle alliance</button>
  <button *ngIf="currentUser && !currentUser.allianceId" (click)="joinAlliance()"
    class="btn btn-success mx-auto">Rejoindre une alliance</button>
</div>
