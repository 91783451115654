<div class="modal-header">
  <div *ngIf="selectedLeaderboard" class="d-flex flex-column align-items-center mx-auto">
    <h4 class="modal-title me-3" id="modal-basic-title">
      Classements
    </h4>
    <select id="leaderboardFilter" (change)="onChange($event.target['value'])">
      <option *ngFor="let leaderboard of leaderboards" [selected]="leaderboard['selected']" [value]="leaderboard.id">
        {{ leaderboard.name }}
      </option>
    </select>
  </div>

  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button">
  </button>
</div>

<div *ngIf="!selectedLeaderboard" class="modal-body loading"></div>
<div *ngIf="selectedLeaderboard" class="modal-body d-flex flex-column leaderboards align-items-center">
<div class="mb-2">
  c'est un classement pour les {{selectedLeaderboard.type}}
</div>
<div class="">
  {{selectedLeaderboard.description}}
</div>
<!-- TODO: mettre en avant le top 3 -->
<table class="table table-striped table-hover table-dark planet-table m-4">
  <tbody>
    <tr>
      <th id="ownerName" (click)="sortByText('ownerName')">
        <i class='bx'></i>
        Nom du joueur
      </th>
      <th id="score" (click)="sortByNumber('score')">
        <i class='bx'></i>
        Score
      </th>
    </tr>
    <tr *ngFor="let score of scores" class="justify-content-around p-2">
      <td class="align-middle">
        {{score.ownerName}}
      </td>
      <td class="align-middle">
        {{score.score}}
      </td>
    </tr>
  </tbody>
</table>
</div>
