import { webSocket } from "rxjs/webSocket";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

//const subject = webSocket("ws://echo.websocket.org/");
//const subject = webSocket("ws://localhost:8082/event-emitter");
//const subject = webSocket("ws://94.238.227.23:40001/event-emitter");
const subject = webSocket("wss://websocket.bastienmarais.fr/event-emitter");


export interface Message {
  author: string;
  message: string;
}

@Injectable({
  providedIn: "root",
})
export class WebsocketService {

  constructor() { }
  register() {
    subject.subscribe({
      next: msg => {// Called whenever there is a message from the server.
        console.log('message received: ' + msg)/**/
      },
      error: err => {// Called if at any point WebSocket API signals some kind of error.
        console.log(err)
      },
      complete: () => {// Called when connection is closed (for whatever reason).
        console.log('complete')
      }
    });
  }

  send(data: string) {
    subject.next(data);
  }
}
