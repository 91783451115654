import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { catchError, Observable, retry, throwError } from "rxjs";
import { Alert } from "src/app/models/Alert";
import { AlertService } from "src/app/services/alert/alert.service";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  keycloak;

  constructor(
    private readonly alertService: AlertService,
    private readonly keycloakService: KeycloakService,
  ) {
    this.keycloak = keycloakService.keycloakEvents$.subscribe({
      next: e => {
        if (e.type == KeycloakEventType.OnTokenExpired) {
          keycloakService.updateToken();
        } else if (e.type == KeycloakEventType.OnAuthRefreshError) {
          location.reload()
        }
      }
    });
  }

  retryStategy = () => (error: HttpErrorResponse, retried) => {
    return new Observable(subscriber => {
      if (error && error.error && error.error.message && retried < 2) {
        if (error.error.message.startsWith('Command failed with error 112 (WriteConflict)')
          || error.error.message.startsWith('Cannot save entity ')
        ) {
          subscriber.next(100);
        } else {
          subscriber.error(error);
        }
      } else {
        subscriber.error(error);
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(retry({
        delay: this.retryStategy(),
      }))
      .pipe(catchError(err => this.handleError(err)));
  }

  public handleError(err) {
    let alert = new Alert();
    alert.type = "danger";
    alert.msg = (err.error.message || err.message);
    alert.timeout = 10000;;
    alert['datas'] = err;
    if (err.status == 404) {
      alert.msg = err.message;
    }
    this.alertService.addAlert(alert);

    return throwError(() => err);
  }
}
