import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Agent } from 'src/app/models/Staff/Agent';
import { AgentType } from 'src/app/models/Staff/AgentType';
import { BaseService } from 'src/app/services/bases/base.service';
import { StaffService } from 'src/app/services/Staff/staff.service';
import { UsersService } from 'src/app/services/Users/user.service';
import { AgentMoveComponent } from 'src/app/main/spying/agent-move/agent-move.component';
import { SpyMissionComponent } from './spy-mission/spy-mission.component';
import { Alert } from 'src/app/models/Alert';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';
import { EventService } from 'src/app/services/event/event.service';

@Component({
  selector: 'app-spying',
  templateUrl: './spying.component.html',
  styleUrls: ['./spying.component.scss']
})
export class SpyingComponent implements OnInit, OnDestroy {

  hiredStaff: Agent[];
  originalHiredStaff: Agent[];
  hireableStaff: AgentType[];
  namedHiredStaffId: string;
  private readonly destroy$ = new Subject();
  timerMap: Map<string, string> = new Map<string, string>();

  constructor(
    private readonly staffService: StaffService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    private readonly modalService: NgbModal,
    private readonly alertService: AlertService,
    public readonly utilsService: UtilsService,
    private readonly eventService: EventService,
  ) { }

  ngOnInit(): void {

    this.staffService.getRecrutableAgentsByBaseId(this.baseService.getCurrentBaseId()).subscribe((hireableStaff) => {
      this.hireableStaff = hireableStaff
    })

    this.staffService.knownAgentsUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((staff) => {
        if (staff) {
          this.originalHiredStaff = staff;
          this.onSearch(document.getElementsByClassName('inputSearch')[0]['value']);
        }
      });

    this.refreshHiredStaff();

    this.utilsService.timer
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        for (let agent of this.originalHiredStaff) {
          if (agent.missionEnd) {
            let then = Date.parse(agent.missionEnd + '')
            let ms = then - Date.now();
            this.timerMap.set(agent.id, ms + '');
          } else {
            if (this.timerMap.has(agent.id)) {
              this.timerMap.delete(agent.id);
            }
          }
        }
      });
  }

  refreshHiredStaff() {
    this.staffService.updateAgents()
    /*this.staffService.getAgentsByUser(this.userService.currentUserId()).subscribe((hiredStaff) => {
      this.originalHiredStaff = hiredStaff;
      this.onSearch('');
    })/** */
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  sortByNumber(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.hiredStaff = this.hiredStaff.sort((a, b) => {
      return (parseInt(b[orderType], 16) - parseInt(a[orderType], 16)) * order;
    })
    this.showOrder(order);
  }



  showOrder(orderType) {
    let ths = document.getElementsByTagName('app-spying')[0].getElementsByTagName('th');
    let order = document.getElementById(orderType).getElementsByTagName('i')[0].classList.contains('bx-sort-down');
    for (let i = 0; i < ths.length; i++) {
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-down')
      ths[i].getElementsByTagName('i')[0].classList.remove('bx-sort-up')
    }
    if (order) {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-up')
    } else {
      document.getElementById(orderType).getElementsByTagName('i')[0].classList.add('bx-sort-down')
    }
    return order;
  }

  sortByText(orderType) {
    let order = this.showOrder(orderType) ? -1 : 1;
    this.hiredStaff = this.hiredStaff.sort((a, b) => {
      return (a[orderType].toLocaleLowerCase() > b[orderType].toLocaleLowerCase() ? 1 : -1) * order;
    })
    this.showOrder(order);
  }

  onSearch(value) {
    this.hiredStaff = this.originalHiredStaff.filter((offer) => {
      for (let key in offer) {
        if ((offer[key] + '').toLocaleLowerCase().includes(value)) {
          return true;
        }
      }
      return false;
    });
  }

  modalHireStaff(modal) {
    this.namedHiredStaffId = '';
    this.modalService.open(modal, {
      windowClass: "hire-staff backModalImg",
      ariaLabelledBy: "hire-staff",
      scrollable: true,
      size: "lg",
      backdrop: false,
    });

  }

  hireNewStaff(staff: AgentType, modal) {
    let renameAgentModal = this.modalService.open(modal, {
      windowClass: "hire-staff backModalImg",
      ariaLabelledBy: "hire-staff",
      scrollable: true,
      size: "lg",
      backdrop: false,
    })
    this.namedHiredStaffId = staff.id;
  }

  hireNamedStaff(event) {
    let name = event.target.parentElement.getElementsByTagName('textarea')[0].value;
    if (name) {
      this.staffService.hireNewAgent(this.baseService.getCurrentBaseId(), name, this.namedHiredStaffId).subscribe((test) => {
        this.refreshHiredStaff();
        event.target.parentElement.parentElement.remove();
        let alert = new Alert();
        alert.type = "info";
        alert.msg = `vous avez engagé ${name}, il arrivera d'ici peu`;
        alert.timeout = 2000;
        this.alertService.addAlert(alert);
      })
    } else {
      let alert = new Alert();
      alert.type = "danger";
      alert.msg = "vous devez Nommer votre personnel";
      alert.timeout = 2000;
      this.alertService.addAlert(alert);
    }
  }

  moveHiredStaff(staff) {
    let agentMove = this.modalService.open(AgentMoveComponent, {
      windowClass: "agent-move backModalImg",
      ariaLabelledBy: "agent-move",
      scrollable: true,
      size: "lg",
      backdrop: false,
    })
    agentMove.componentInstance.agent = staff;
    agentMove.dismissed.subscribe(() => {
      this.refreshHiredStaff();
    })
  }

  stringify(data): string {
    return JSON.stringify(data, null, 2);
  }

  launchMission(staff) {
    let spyMissionComponent = this.modalService.open(SpyMissionComponent, {
      windowClass: "agent-move backModalImg",
      ariaLabelledBy: "agent-move",
      scrollable: true,
      size: "lg",
      backdrop: false,
    })
    spyMissionComponent.componentInstance.agent = staff;
    spyMissionComponent.dismissed.subscribe(() => {
      this.refreshHiredStaff();
      this.eventService.updatePlayerEventNeeded();
    })
  }
}
