import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, takeUntil } from "rxjs";
import { map } from 'rxjs/operators';
import { Base } from "src/app/models/base/Base";
import { Building } from "src/app/models/Building/Building";
import { UsersService } from "src/app/services/Users/user.service";
import { BaseService } from "../bases/base.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class BuildingsService {
  private apiUrl = environment.apiEndpoint;
  public buildingsMap: Map<string, Building> = new Map<string, Building>();
  private readonly destroy$ = new Subject();
  private base: Base;

  constructor(
    private readonly http: HttpClient,
    private readonly usersService: UsersService,
    private readonly baseService: BaseService,
  ) {
    this.usersService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          if (user && this.buildingsMap.size == 0) {
            this.updateBuildings(user.id);
          }
        }, error: (err) => {
          console.error(err);
        }
      });

    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
          this.updateCurrentBaseLevels();
        }
      })
  }

  updateCurrentBaseLevels() {
    if (this.base && this.buildingsMap.size > 0) {
      for (let key of this.buildingsMap.keys()) {
        this.buildingsMap.get(key)["currentLevel"] = null;
      }
      for (let keySlot in this.base.builtLevel) {
        this.buildingsMap.get(keySlot)["currentLevel"] = this.base.builtLevel[keySlot];
      }

    }
  }

  private updateBuildings(ownerId: string) {
    this.getKnownBuildings(ownerId).subscribe({
      next: (buildings) => {

      }, error: (err) => {
        if (err.error.error === "Forbidden") {
          this.updateBuildings(this.usersService.currentUserId());
        }
      }
    });
  }

  public getBuildings(baseId: string): Observable<Building[]> {
    return this.http.get<Building[]>(this.apiUrl + `buildings/bases/${baseId}`);
  }

  public getBuildable(baseId: string, slot: number): Observable<Building[]> {
    return this.http.get<Building[]>(this.apiUrl + `buildings/bases/${baseId}/slot/${slot}`);
  }

  public getBuildingLevel(buildingId: string, level: number): Observable<Building[]> {
    return this.http.get<Building[]>(`/buildings/${buildingId}/level/${level}`);
  }

  private getKnownBuildings(ownerId: string): Observable<Building[]> {
    return this.http.get<Building[]>(this.apiUrl + `buildings/owner/${ownerId}/known`).pipe(
      map((buildings) => {
        buildings.forEach((building) => {
          if (!this.buildingsMap.has(building.id)) {
            this.buildingsMap.set(building.id, building);
          }
        });
        this.updateCurrentBaseLevels();
        return buildings;
      }),
    );
  }

  public getBuilding(buildingId: string): Observable<Building> {
    return this.http.get<Building>(this.apiUrl + `buildings/${buildingId}`).pipe(
      map((building) => {
        this.buildingsMap.get(building.id).levels = building.levels;
        return building;
      }),
    );
  }
}
