import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseSlot } from "src/app/models/base/BaseSlot";
import { GlobalPosition } from "src/app/models/GlobalPosition";
import { FightReport } from "src/app/models/reports/FightReport";
import { InviteReport } from "src/app/models/reports/InviteReport";
import { ReportRef } from "src/app/models/reports/ReportRef";
import { RummageReport } from "src/app/models/reports/RummageReport";
import { SpyReport } from "src/app/models/reports/SpyReport";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  private apiUrl = environment.apiEndpoint;
  public knownBases = new Map<GlobalPosition, Map<number, BaseSlot>>();
  constructor(
    private readonly http: HttpClient,
  ) { }

  private numberOfReports = new Subject<number>();
  public numberOfReportsAnnounced$ = this.numberOfReports.asObservable();

  public getReport(): Observable<ReportRef[]> {
    return this.http.get<ReportRef[]>(this.apiUrl + `reports`
    ).pipe(
      map(reports => {
        this.numberOfReports.next(reports.length);
        return reports;
      }),
    );
  }

  public getReportLu(): Observable<ReportRef[]> {
    return this.http.get<ReportRef[]>(this.apiUrl + `reports/lu`);
  }

  public getReportPaginated(
    start: number,
    end: number
  ): Observable<ReportRef[]> {
    return this.http.get<ReportRef[]>(this.apiUrl + `reports/start/${start}/end/${end}`);
  }

  public getFightReportByUuid(uuid: string): Observable<FightReport> {
    return this.http.get<FightReport>(this.apiUrl + `reports/${uuid}/battle`);
  }

  public getRummageReportByUuid(uuid: string): Observable<RummageReport> {
    return this.http.get<RummageReport>(this.apiUrl + `reports/${uuid}/loot`);
  }

  public getMoveReportByUuid(uuid: string): Observable<RummageReport> {
    return this.http.get<RummageReport>(this.apiUrl + `reports/${uuid}/move`);
  }

  public getSpyReportByUuid(uuid: string): Observable<SpyReport> {
    return this.http.get<SpyReport>(this.apiUrl + `reports/${uuid}/spy`).pipe(map((spyReport) => {
      if (spyReport.baseSlotMap) {
        this.knownBases.set(spyReport.position, spyReport.baseSlotMap);
      }
      return spyReport;
    }));
  }

  public getInviteReportByUuid(uuid: string): Observable<InviteReport> {
    return this.http.get<InviteReport>(this.apiUrl + `reports/${uuid}/invite`);
  }

  public deleteReportById(reportId: string) {
    return this.http.delete(this.apiUrl + `reports/${reportId}`);
  }

  public readAllReports(ownerId: string) {
    return this.http.delete(this.apiUrl + `reports/owner/${ownerId}/read`);
  }

  public deleteAllReports(ownerId: string) {
    return this.http.delete(this.apiUrl + `reports/owner/${ownerId}`);
  }

  public getReportByPosition(position: GlobalPosition): Observable<ReportRef[]> {
    return this.http.post<ReportRef[]>(
      this.apiUrl + `reports/position`,
      position
    );
  }
}
