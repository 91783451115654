<div class="d-flex test2 p-2">
  <div class="reports flex-column">
    <ul #nav="ngbNav" (navChange)="onNavChange($event)" [activeId]="2" class="nav-tabs" ngbNav>
      <li [ngbNavItem]="1">
        <a ngbNavLink>
          <div>Messages</div>
        </a>
        <ng-template ngbNavContent>
          <table class="table table-striped table-hover table-dark test">
            <tbody>
              <tr class="">
                <!-- <td class="form-switch px-5">
                  <input class="form-check-input align-self-center" type="checkbox" id="SwitchShowReportsRead"
                    (change)="reloadMessageThreads()" [(ngModel)]="showLu">
                  <label class="form-check-label" for="SwitchShowReportsRead"> Afficher les messages lus</label>
                </td> -->

                <td class="">
                  <label>
                    Rechercher :
                  </label>
                  <input _ngcontent-ruk-c159="" (input)="onSearchThreads($event.target['value'])" type="search" placeholder="Search..." class="inputSearch">
                </td>

                <td class="">
                  <button title="Crée une nouvelle discussion" (click)="createNewThread()"
                    class="btn btn-secondary mx-2">
                    Créer une nouvelle discussion
                  </button>
                </td>
              </tr>
              <tr *ngFor="let messageThread of messageThreads" class="justify-content-around p-2">
                <td>
                  {{ messageThread.name }} - {{ messageThread.id }}
                </td>
                <td>
                  <div>
                    {{ messageThread.initialMessageDate | date: 'full': '' : 'fr-FR' }}
                  </div>
                </td>
                <td>
                  <button (click)="openMessageThread(messageThread)" class="btn btn-primary">
                    Lire
                  </button>
                </td>
              </tr>
              <tr [ngStyle]="{'display':(!inError)?'':'none'}" class="loading">
              </tr>

            </tbody>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>
          <div>Rapports({{ newReport }})</div>
        </a>
        <ng-template ngbNavContent>
          <app-report-box>

          </app-report-box>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
