import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { BuildingDetailsComponent } from "src/app/main/bases/buildingDetails/building-details.component";
import { FleetStorageComponent } from 'src/app/main/fleets/fleets/fleet-storage/fleet-storage.component';
import { UnitDetailComponent } from "src/app/main/fleets/unit-detail/unit-detail.component";
import { SquadronReportComponent } from "src/app/main/message-box/report/squadron-report/squadron-report.component";
import { ResearchDetailComponent } from 'src/app/main/research/research-detail/research-detail.component';
import { StartupComponent } from 'src/app/main/startup/startup.component';
import { TechnoTreeComponent } from "src/app/main/technoTree/technoTree.component";
import { ProfilePictureSelectorComponent } from 'src/app/main/users/profile/profile-picture-selector/profile-picture-selector.component';
import { ShowProfileComponent } from 'src/app/main/users/profile/show-profile/show-profile.component';
import { HttpInterceptorService } from "src/app/services/HttpInterceptor/HttpInterceptor.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DraggableDirective } from './draggable.directive';
import { initializer } from "./keycloak-init";
import { AllianceComponent } from './main/alliance/alliance.component';
import { CreateAllianceComponent } from './main/alliance/createAlliance/createAlliance.component';
import { ShowAllianceComponent } from './main/alliance/showAlliance/showAlliance.component';
import { BasesComponent } from "./main/bases/bases.component";
import { BuildableComponent } from "./main/bases/buildable/buildable.component";
import { BuildingComponent } from "./main/bases/building/building.component";
import { DiplomacyComponent } from './main/diplomacy/diplomacy.component';
import { EventListComponent } from "./main/event-list/event-list.component";
import { FleetTravelComponent } from "./main/fleets/fleets/fleet-travel/fleet-travel.component";
import { FleetsComponent } from "./main/fleets/fleets/fleets.component";
import { AddNewSquadronComponent } from "./main/fleets/fleets/formation/edit-squadron/add-new-squadron/add-new-squadron.component";
import { AddToSquadronComponent } from "./main/fleets/fleets/formation/edit-squadron/add-to-squadron/add-to-squadron.component";
import { EditSquadronComponent } from "./main/fleets/fleets/formation/edit-squadron/edit-squadron/edit-squadron.component";
import { RemoveSquadronComponent } from "./main/fleets/fleets/formation/edit-squadron/remove-squadron/remove-squadron.component";
import { SplitSquadronComponent } from "./main/fleets/fleets/formation/edit-squadron/split-squadron/split-squadron.component";
import { TemplateSquadronComponent } from "./main/fleets/fleets/formation/edit-squadron/template-squadron.component";
import { FormationDetailComponent } from "./main/fleets/fleets/formation/formation-detail.component";
import { RefuelComponent } from './main/fleets/refuel/refuel.component';
import { GovernmentComponent } from "./main/government/government.component";
import { LeaderboardComponent } from './main/leaderboard/leaderboard.component';
import { MainComponent } from "./main/main.component";
import { CreateOfferComponent } from './main/market/createOffer/createOffer.component';
import { FulfillOfferComponent } from './main/market/fulfillOffer/fulfillOffer.component';
import { MarketComponent } from "./main/market/market.component";
import { DebugComponent } from './main/options/debug/debug.component';
import { OptionsComponent } from "./main/options/options.component";
import { QuestsComponent } from './main/quests/quests.component';
import { PlanetsComponent } from "./main/planets/planets.component";
// import { Fight3DComponent } from './main/report/fight-detail/fight-3d/fight-3d.component';
import { FightDetailComponent } from './main/message-box/report/fight-detail/fight-detail.component';
import { InviteDetailComponent } from './main/message-box/report/invite-detail/invite-detail.component';
import { RummageDetailComponent } from './main/message-box/report/rummage-detail/rummage-detail.component';
import { SpyReportComponent } from './main/message-box/report/spy-report/spy-report.component';
import { ResearchComponent } from './main/research/research.component';
import { ResourceBarComponent } from "./main/resource-bar/resource-bar.component";
import { ResourceDetailComponent } from './main/resource-bar/resource-detail/resource-detail.component';
import { RummageComponent } from './main/rummage/rummage.component';
import { AgentMoveComponent } from './main/spying/agent-move/agent-move.component';
import { SpyMissionComponent } from './main/spying/spy-mission/spy-mission.component';
import { SpyingComponent } from './main/spying/spying.component';
import { TargetSelectorComponent } from './main/targetSelector/TargetSelector.component';
import { ProfileComponent } from "./main/users/profile/profile.component";
import { UsersCreateComponent } from "./main/users/users-create/users-create.component";
import { ResizeDirective } from './resize.directive';
import { AuthenticationService } from "./services/Authentication/authentication.service";
import { TimerPipe } from './timer.pipe';
import { MenuComponent } from './main/menu/menu.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ListAlliancesComponent } from './main/alliance/list-alliances/list-alliances.component';
import { MoveDetailComponent } from './main/message-box/report/move-detail/move-detail.component';
import { MessageBoxComponent } from './main/message-box/message-box.component';
import { MessageThreadComponent } from './main/message-box/message-thread/message-thread.component';
import { MessageThreadCreateComponent } from './main/message-box/message-thread-create/message-thread-create.component';
registerLocaleData(localeFr);
import { EffectsComponent } from './main/effects/effects/effects.component';
import { EffectDetailComponent } from './main/effects/effect-detail/effect-detail.component';
import { ReportBoxComponent } from './main/message-box/report/report-box.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    GovernmentComponent,
    UsersCreateComponent,
    ProfileComponent,
    BasesComponent,
    TechnoTreeComponent,
    BuildingComponent,
    BuildableComponent,
    PlanetsComponent,
    OptionsComponent,
    FleetsComponent,
    EventListComponent,
    ResourceBarComponent,
    FleetTravelComponent,
    UnitDetailComponent,
    BuildingDetailsComponent,
    SquadronReportComponent,
    DiplomacyComponent,
    DraggableDirective,
    ResizeDirective,
    TimerPipe,
    ResearchComponent,
    FormationDetailComponent,
    TemplateSquadronComponent,
    AddNewSquadronComponent,
    AddNewSquadronComponent,
    AddToSquadronComponent,
    EditSquadronComponent,
    RemoveSquadronComponent,
    SplitSquadronComponent,
    ResourceDetailComponent,
    RefuelComponent,
    ResearchDetailComponent,
    StartupComponent,
    ProfilePictureSelectorComponent,
    ShowProfileComponent,
    FleetStorageComponent,
    MarketComponent,
    CreateOfferComponent,
    AllianceComponent,
    CreateAllianceComponent,
    ShowAllianceComponent,
    RummageComponent,
    LeaderboardComponent,
    FulfillOfferComponent,
    RummageDetailComponent,
    FightDetailComponent,
    InviteDetailComponent,
    QuestsComponent,
    DebugComponent,
//  Fight3DComponent,
    TargetSelectorComponent,
    SpyingComponent,
    AgentMoveComponent,
    SpyMissionComponent,
    SpyReportComponent,
    MenuComponent,
    ListAlliancesComponent,
    MoveDetailComponent,
    MessageBoxComponent,
    MessageThreadComponent,
    MessageThreadCreateComponent,
    EffectsComponent,
    EffectDetailComponent,
    ReportBoxComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    KeycloakAngularModule,
    NgbModule,
    FormsModule,
    ColorPickerModule,
  ],
  providers: [
    TimerPipe,
    AuthenticationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],

    },
    {
      provide: LOCALE_ID, useValue: 'fr-FR'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
