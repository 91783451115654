import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { FleetRef } from "src/app/models/Fleet/FleetRef";
import { GlobalPosition } from "src/app/models/GlobalPosition";
import { FleetMove } from "src/app/models/Fleet/FleetMove";
import { UsersService } from "../Users/user.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FleetService {
  private apiUrl = environment.apiEndpoint;
  private fleet = new BehaviorSubject<Fleet[]>(null);
  public fleetUpdate$ = this.fleet.asObservable();

  private fleetNeedUpdate = false;
  private fleetLockUpdate = false;

  constructor(
    private readonly http: HttpClient,
    private readonly userService: UsersService,
  ) {
    let destroy$ = new Subject();
    this.userService.userUpdate$
      .pipe(takeUntil(destroy$))
      .subscribe((user) => {
        if (user) {
          this.getFleets(user.id).subscribe(() => {
            destroy$.next("");
            destroy$.complete();
          });

        }
      })

    setInterval((val) => {
      //refresh Base
      if (this.fleetNeedUpdate == true && !this.fleetLockUpdate) {
        this.fleetLockUpdate = true;
        this.getFleets(this.userService.currentUserId()).subscribe((fleet) => {
          this.fleetLockUpdate = false;
          this.fleetNeedUpdate = false;
        });

      }
    }, 1000);
  }

  public updateFleets() {
    this.fleetNeedUpdate = true;
  }

  private getFleets(ownerId: string): Observable<Fleet[]> {
    return this.http.get<Fleet[]>(this.apiUrl + `fleets/owner/${ownerId}`)
      .pipe(map((fleets) => {
        if (fleets) {
          this.fleet.next(fleets);
        }
        return fleets;
      }),
      );
  }

 /* public getAllFleets(): Observable<Fleet[]> {
    return this.http.get<Fleet[]>(this.apiUrl + `fleets`);
  }/** */

  public getFleetsByBaseId(baseId: string): Observable<FleetRef[]> {
    return this.http.get<FleetRef[]>(this.apiUrl + `fleets/base/${baseId}`);
  }

  public getFleetsByFleetPos(fleetId: string): Observable<FleetRef[]> {
    return this.http.get<FleetRef[]>(this.apiUrl + `fleets/fleet/${fleetId}`);
  }

  public getFleetById(fleetId: string): Observable<Fleet> {
    return this.http.get<Fleet>(this.apiUrl + `fleets/${fleetId}`);
  }

  public getTurretFleet(baseId: string): Observable<Fleet> {
    return this.http.get<Fleet>(this.apiUrl + `fleets/def/base/${baseId}`);
  }

  //Crée une nouvelle flotte
  public createFleet(baseId: string, name: string): Observable<string> {
    return this.http.post<string>(
      this.apiUrl + `fleets/base/${baseId}`,
      name
    );
  }

  //Transfère des vaisseaux depuis une base jusqu'a un nouvel escadron
  public createBattleGroup(
    fleetId: string,
    moveTactic: string,
    shootingTactic: string,
    formationRound: number,
    line: number,
    amount: number,
    shipId: string
  ): Observable<string> {
    return this.http.post<string>(
      this.apiUrl + `fleets/fleet/${fleetId}/squadron?moveTactic=${moveTactic}&shootingTactic=${shootingTactic}&formationRound=${formationRound}&pos=${line}&amount=${amount}&shipId=${shipId}`,
      null
    );
  }

  public createBattleGroupFromGroup(
    fleetId: string,
    moveTactic: string,
    shootingTactic: string,
    formationRound: number,
    line: number,
    amount: number,
    sourceFleetId: string,
    sourceGroupId: string
  ): Observable<string> {
    return this.http.post<string>(
      this.apiUrl + `fleets/fleet/${fleetId}/squadronFromGroup?moveTactic=${moveTactic}&shootingTactic=${shootingTactic}&formationRound=${formationRound}&pos=${line}&amount=${amount}&sourceFleetId=${sourceFleetId}&sourceSquadronId=${sourceGroupId}`,
      null
    );
  }

  //Transfère des vaisseaux depuis une base jusqu'a un escadron existant
  public transferShipToFleet(
    fleetId: string,
    squadronId: string,
    shipId: string,
    amount: number
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/fleet/${fleetId}/squadron/${squadronId}/ships?shipId=${shipId}&amount=${amount}`,
      null
    );
  }

  //Transfère des vaisseaux depuis un escadron jusqu'a un autre escadron existant
  public transferShipFromToFleet(
    fleetId: string,
    squadronId: string,
    secondaryfleetId: string,
    secondarySquadronId: string,
    amount: number
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/fromFleet/${fleetId}/fromSquadron/${squadronId}/toFleet/${secondaryfleetId}/toSquadron/${secondarySquadronId}/ships?amount=${amount}`,
      null
    );
  }

  //Transfère des vaisseaux depuis une flotte vers une base
  public transferShipToBase(
    fleetId: string,
    squadronId: string,
    amount: number
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/fleet/${fleetId}/squadron/${squadronId}/ships/r?amount=${amount}`,
      null
    );
  }

  //Permet de lancer le changement de statut d'une flotte
  public changeStatus(status: string, fleetId: string): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/fleet/${fleetId}/status?status=${status}`,
      null
    );
  }

  //Permet de renommer une flotte
  public changeName(name: string, fleetId: string): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/fleet/${fleetId}`,
      name
    );
  }

  //Permet d'éditer un escadron
  public editGroup(
    squadronId: string,
    fleetId: string,
    moveTactic: string,
    shootingTactic: string,
    formationRound: number,
    pos: number
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/fleet/${fleetId}/squadron/${squadronId}?moveTactic=${moveTactic}&shootingTactic=${shootingTactic}&formationRound=${formationRound}&pos=${pos}`,
      null
    );
  }

  //Permet de lancer un déplacement
  public launchTravel(
    fleetId: string,
    move: FleetMove
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/fleet/${fleetId}/move`,
      move
    );
  }

  //Permet d'annuler un déplacement
  public cancelTravelByFleetId(
    fleetId: string
  ): Observable<string> {
    return this.http.patch<string>(
      this.apiUrl + `fleets/fleet/${fleetId}/cancelMove`,
      null
    );
  }

  //Permet de recharger une flotte
  public refuel(
    fleetId: string,
    refuel: number
  ): Observable<Fleet> {
    return this.http.patch<Fleet>(
      this.apiUrl + `fleets/fleet/${fleetId}/refuel`,
      refuel
    );
  }

  //Permet de recharger une flotte
  public transferFuel(
    sourceFleetId: string,
    fleetId: string,
    refuel: number
  ): Observable<Fleet> {
    return this.http.patch<Fleet>(
      this.apiUrl + `fleets/sourceFleet/${sourceFleetId}/targetFleet/${fleetId}/refuel`,
      refuel
    );
  }

  //Permet de changer le fuel d'une flotte
  public changeFuel(
    fleetId: string,
    fuelCode: string
  ): Observable<Fleet> {
    return this.http.patch<Fleet>(
      this.apiUrl + `fleets/fleet/${fleetId}/changeFuel`,
      fuelCode
    );
  }


  //Permet de charger la cargaison d'escadrons dans une flotte depuis la base
  public loadStorageSquadrons(
    baseId: string,
    fleetId: string,
    storageBySquadron: Map<string, Map<string, Map<string, number>>>
  ): Observable<Fleet> {
    return this.http.patch<Fleet>(
      this.apiUrl + `fleets/base/${baseId}/fleet/${fleetId}/load`,
      storageBySquadron
    );
  }

  //Permet de décharger la cargaison d'escadrons d'une flotte vers la base
  public unloadStorageSquadrons(
    baseId: string,
    fleetId: string,
    storageBySquadron: Map<string, Map<string, Map<string, number>>>
  ): Observable<Fleet> {
    return this.http.patch<Fleet>(
      this.apiUrl + `fleets/base/${baseId}/fleet/${fleetId}/unload`,
      storageBySquadron
    );
  }

  //Permet de déplacer du stoacke d'une flotte à une autre
  public transfeStorage(
    sourceFleetId: string,
    fleetId: string,
    sourceSquadronId: string,
    SquadronId: string,
    shipment: Map<string, Map<string, Map<string, number>>>
  ): Observable<Fleet> {
    return this.http.patch<Fleet>(
      this.apiUrl + `fleets/sourceFleet/${sourceFleetId}/targetFleet/${fleetId}/refuel`,
      shipment
    );
  }
}
