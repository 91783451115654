import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';

interface WindowSize {
  width: number;
  height: number;
}

@Directive({
  selector: '.modal-body'
})
export class ResizeDirective implements OnInit, OnDestroy {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  private updateAttribute(el, size: WindowSize) {
    this.renderer.setStyle(el, "width", size.width);
    this.renderer.setStyle(el, "height", size.height);
  }
  private observer: ResizeObserver;

  ngOnInit() {
    let modalContent = this.el.nativeElement.closest(".modal-content");
    let modalDialog = this.el.nativeElement.closest(".modal-dialog");
    if (modalDialog && modalContent) {
      this.renderer.addClass(modalDialog, 'resizable-modal');
      this.renderer.addClass(modalContent, 'resizable-content');

      this.observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
          let size = entry.borderBoxSize[0];
          //TODO: keep size in memory for the next time the modal is opened;
        });
      });
      this.observer.observe(modalContent);
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}



