<div *ngIf="!buildings" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="buildings" class="modal-header">
  <h4 class="modal-title" id="buildablesModal" i18n="BaseComponent|Title of the new building menu">Construction</h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button">
  </button>
</div>
<div *ngIf="buildings" class="modal-body">
  <div class="buildingShips d-flex justify-content-evenly flex-wrap">
    <div *ngFor="let building of buildings" class="buildable d-flex flex-column">
        <h3>{{ building.name }}</h3>
        <img class="shipImg" src="/assets/img/buildings/{{ building.name }}.png" />
        <p>{{ building.description }}</p>
        <div class="buildableDetails">
          <div *ngFor="
                let resource of building.levels[0].production | keyvalue
              " i18n="BaseComponent|Display de production value of the building" (click)=clickOnResource(resource)>
            Production de {{ resource.key }} : {{ resource.value }}/h
          </div>
          <div *ngFor="let storage of building.levels[0].storage | keyvalue"
            i18n="BaseComponent|Display the storage value of the building" (click)=clickOnResource(storage)>
            Stockage de {{ storage.key }} : {{ storage.value }}
          </div>
          <div *ngFor="let effect of building.levels[0].effects | keyvalue"(click)=clickOnEffect(effect)>
            {{ effect.value.libelle }} : {{ effect.value.amount }}
          </div>
          <div *ngFor="let effect of building.levels[0].productionMultiplier | keyvalue"
            i18n="BaseComponent|cumulatedProductionMultiplier" (click)=clickOnResource(effect)>
            Multiplicateur de production de {{ effect.key }} : {{ effect.value }} %
          </div>
          <div *ngFor="let effect of building.levels[0].storageMultiplier | keyvalue"
            i18n="BaseComponent|cumulatedStorageMultiplier" (click)=clickOnResource(effect)>
            Multiplicateur de stockage de {{ effect.key }} : {{ effect.value }} %
          </div>
        </div>
      <div class="d-flex flex-warp align-self-center p-1">
        <div *ngFor="let buildingCost of building.levels[0].buildingCost | keyvalue">
          <div [ngClass]="{
                insufficientResource:
                  buildingCost.value > resources.get(buildingCost.key).clientAmount
              }" class="p-1 d-flex flex-column">
            <img class="resourceImg" src="/assets/img/resources/{{ buildingCost.key }}.png" />
            {{ buildingCost.value }}
          </div>
        </div>
      </div>
      <div class="time">
      {{
      building.levels[0].buildingTime * 1000 / (1 + ((base.effects['BUILD_SPEED'] || 0) /100.0))| timer: "UTC"
      }} s
      </div>
      <div>
        <button (click)="build(building)" [disabled]="
              building.levels[0].buildingCost['cristal'] > resources.get('cristal').clientAmount ||
              building.levels[0].buildingCost['metal'] > resources.get('metal').clientAmount ||
              building.levels[0].buildingCost['organic'] > resources.get('organic').clientAmount ||
              building.levels[0].buildingCost['energy'] > resources.get('energy').clientAmount
            " class="btn btn-outline-success buildingButton" i18n="BaseComponent|Start building the selected">
          Construire
        </button>
      </div>
    </div>
  </div>
