import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TemplateSquadronComponent } from "src/app/main/fleets/fleets/formation/edit-squadron/template-squadron.component";
import { FleetService } from "src/app/services/fleets/fleet.service";


@Component({
  selector: "app-battle-group",
  templateUrl: "../template-squadron.component.html",
  styleUrls: ["../template-squadron.component.scss"],
})
export class EditSquadronComponent extends TemplateSquadronComponent implements OnInit {

  constructor(
    protected readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal, // needed in HTML
    protected readonly fleetService: FleetService,
  ) {
    super(fb, modal, fleetService);
  }

  ngOnInit() {
    if(!this.selectedShip) {
      this.selectedShip = this.availableShips[0];
      this.doSelectShip = false;
    }
    super.ngOnInit();


    this.doAmount = false;
    this.doTactic = true;
    this.title = "Modifier un escadron"
  }

  public onSubmit(): boolean {
    // Vérifier la validité du formulaire
    if (this.frmShip.valid && this.selectedFleet) {
      this.fleetService.editGroup(
        this.selectedShip['techId'],
        this.selectedFleet.id,
        this.moveTactic.value,
        this.shootingTactic.value,
        this.formationRound.value,
        this.selectedShip['line'],
      ).subscribe(() => {
          this.modal.close();
      });
    } else {
      //TODO : afficher le pourquoi de ca fonctionne pas
    }
    return false;
  }
}
