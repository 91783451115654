<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">
    Profil d'alliance : {{allianceRef.name}}
  </h4>
  <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
  </button>
</div>

<div class="modal-body">
  <div *ngIf="allianceRef" class="d-flex flex-row mx-auto">
    <div class="d-flex flex-column">
      <img *ngIf="allianceRef.imageLocation" alt={{allianceRef.shortName}} class="govImage"
        src="{{'/assets/img/' + allianceRef.imageLocation }}" />
      <img *ngIf="!allianceRef.imageLocation" alt={{allianceRef.shortName}} class="govImage"
        src="{{'/assets/img/alliances/'+allianceRef.shortName+'png' }}" />
      <button *ngIf="currentUser && !currentUser.allianceId" (click)="join()" class="btn btn-success">Rejoindre</button>

    </div>
    <div class="profile d-flex flex-column">
      <div class="allianceName">
        Nom : {{allianceRef.name}}
      </div>
      <div class="allianceDescription">
        {{allianceRef.description}}
      </div>
      <div class="leaderName" (click)="openUserProfile(allianceRef.leaderId)">
        Leader : {{allianceRef.leaderName}}
      </div>
      <table *ngIf="allianceRef['members']" class="table table-striped table-hover table-dark planet-table">
        <tbody>
          <tr>
            <th colspan="10">
              <label>
                Membres
              </label>
            </th>
          </tr>
          <tr *ngFor="let member of allianceRef['members'] | keyvalue" (click)="openUserProfile(member.key)"
            class="justify-content-around p-2 openUserProfile">
            <td>{{ member.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
