<!-- <div class="planet" [ngClass]="{'d-none': planet3D}">
  <table *ngIf="planet && planet.tiles" class="table table-striped table-hover table-dark planet-table">
    <tbody>
      <tr>
        <th colspan="3">
          <label>
            Type de bases :
          </label>
          <select id="baseFilter" (change)="onChange($event)">
            <option *ngFor="let filter of filters" [selected]="filter.selected" [value]="filter.id">
              {{ filter.value }}
            </option>
          </select>
        </th>
      </tr>
      <tr>
        <th id="type">
          Coordonnées
        </th>
        <th *ngIf="debug" id="id">
          Id de la base :
        </th>
        <th id="name">
          Nom de la base
        </th>
        <th id="ownerName">
          Nom du propriétaire
        </th>
      </tr>
      <tr *ngFor="let base of tiles | keyvalue" (click)="openUserProfile(base.value.base.ownerId)"
        class="justify-content-around p-2 openUserProfile">
        <td>&#123; {{ base.key }} &#125;</td>
        <td *ngIf="debug">{{ base.value.base.id }}</td>
        <td>{{ base.value.base.name }}</td>
        <td>{{ base.value.base.ownerName }}</td>
      </tr>
    </tbody>
  </table>
  <div [ngStyle]="{'display':(!(planet && planet.tiles))?'':'none'}" class="loading">
  </div>
</div> -->

<div id="globeViz">
</div>
<!-- <div id="showSelector">

  <div *ngFor="let filter of filters">
    <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id={{filter.id}} [checked]=filter.selected
        (click)="filter3D(filter.id, $event.target.checked)" #{{filter.id}} i18n="OptionComponent|{{filter.id}}">
      <label [ngStyle]="{'color': baseTypes.get(filter.id) || 'white'}" class="form-check-label"
        for="{{filter.id}}">{{filter.value}}</label>
    </div>
  </div>
</div> -->
<div class="d-none" id="tooltip" role="tooltip">
  <div class="modal-header">
    <h4 class="modal-title mx-auto" id="title" i18n="Battle-groupComponent|Ships assignment"></h4>
    <button (click)="dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
    </button>
  </div>
  <div id="text">
  </div>
  <div id="actions" class="d-flex justify-content-around flex-column">
    <div>
      <button class="btn btn-primary d-none" i18n="confirmDestroy|Cancel" id="fight" (click)="attack()">
        Déplacer une flotte
      </button>
    </div>
    <div>
      <button class="btn btn-outline-info d-none"
        [ngStyle]="{'display':(currentBase && currentBase.effects['SEARCH_AREA'])?'inline-block':'none'}"
        i18n="confirmDestroy|Cancel" id="rummage" (click)="rummage()">
        Fouiller
      </button>
    </div>
  </div>

  <div id="arrow" data-popper-arrow></div>
</div>
