import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { GlobalPosition } from 'src/app/models/GlobalPosition';
import { Agent } from 'src/app/models/Staff/Agent';
import { AgentMission } from 'src/app/models/Staff/AgentMission';
import { AgentType } from 'src/app/models/Staff/AgentType';
import { UsersService } from '../Users/user.service';
import { environment } from "src/environments/environment";




@Injectable({
  providedIn: "root",
})
export class StaffService {
  private apiUrl = environment.apiEndpoint;

  private knownAgents = new BehaviorSubject<Agent[]>(null);
  public knownAgentsUpdate$ = this.knownAgents.asObservable();

  private staffNeedUpdate = true;
  private staffLockUpdate = false;


  constructor(
    private readonly http: HttpClient,
    private readonly usersService: UsersService,
  ) {

    setInterval((val) => {
      if (this.staffNeedUpdate == true && !this.staffLockUpdate && this.usersService.currentUserId()) {
        this.staffLockUpdate = true;
        this.getAgentsByUser(this.usersService.currentUserId()).subscribe((base) => {
          this.staffLockUpdate = false;
          this.staffNeedUpdate = false;
        });
      }
    }, 1000);
  }

  updateAgents() {
    this.staffNeedUpdate = true;
  }

  getRecrutableAgentsByBaseId(baseId: string): Observable<AgentType[]> {
    return this.http.get<AgentType[]>(this.apiUrl + `agentRefs/base/${baseId}`);
  }

  getRecrutableAgentsByType(type: string): Observable<AgentType[]> {
    return this.http.get<AgentType[]>(this.apiUrl + `agentRefs/${type}`);
  }

  getRecrutableAgentsByCharacteristic(characteristic: string, baseId: string): Observable<AgentType[]> {
    return this.http.get<AgentType[]>(this.apiUrl + `agentRefs/characteristic/${characteristic}/base/${baseId}`);
  }

  private getAgentsByUser(ownerId: string): Observable<Agent[]> {
    return this.http.get<Agent[]>(this.apiUrl + `agents/user/${ownerId}`).pipe(map(agents=> {
      this.knownAgents.next(agents);
      return agents;
    }));
  }

  getAgentsByTypeId(ownerId: string, typeId: string): Observable<Agent[]> {
    return this.http.get<Agent[]>(this.apiUrl + `agents/type/${typeId}/user/${ownerId}`);
  }

  getAgentsByTypeIdOnBaseId(ownerId: string, typeId: string, baseId: string): Observable<Agent[]> {
    return this.http.get<Agent[]>(this.apiUrl + `agents/type/${typeId}/user/${ownerId}/base/${baseId}`);
  }

  // agent needs id and name only
  hireNewAgent(baseId: string, name: string, typeId: string) {
    return this.http.post(this.apiUrl + `agents/base/${baseId}/type/${typeId}`, name);
  }

  moveAgent(agentId: string, objective: string, target: GlobalPosition): Observable<Agent> {
    return this.http.post<Agent>(this.apiUrl + `agents/${agentId}/objective/${objective}`, target);
  }

  launchMission(agentId: string, mission: AgentMission): Observable<Agent> {
    return this.http.post<Agent>(this.apiUrl + `agents/${agentId}/mission`, mission);
  }

  getAvailableMissionByAgentId(agentType: string): Observable<AgentMission[]> {
    return this.http.get<AgentMission[]>(this.apiUrl + `agentRefs/agentType/${agentType}/missions`);
  }

  changeAgentName(agentId:string, name: string): Observable<string> {
    return this.http.put<string>(this.apiUrl + `agents/${agentId}`, name);
  }
}
