import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "src/app/models/";
import { UserProfile } from "src/app/models/user/UserProfile";
import { BaseService } from "../bases/base.service";
import { ReportService } from "../reports/report.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class UsersService {
  private apiUrl = environment.apiEndpoint;

  private user = new BehaviorSubject<User>(null);
  public userUpdate$ = this.user.asObservable();
  private userNeedUpdate = false;
  private userLockUpdate = false;

  constructor(
    private readonly http: HttpClient,
    private readonly reportService: ReportService,
    protected keycloakService: KeycloakService,
  ) {

    keycloakService.isLoggedIn().then((log) => {
      this.userNeedUpdate = log;
    });
    setInterval((val) => {
      //refresh user
      if (this.userNeedUpdate === true) {
        if (!this.userLockUpdate) {
          this.userLockUpdate = true;
          this.getMyUser().subscribe({
            next: (user) => {
              this.userNeedUpdate = false;
              this.userLockUpdate = false;
            },
            error: (error) => {
              this.userLockUpdate = true;
            }
          });
        }
      }
    }, 1000);
  }

  public updateUser() {
    this.userNeedUpdate = true;
  }

  private getMyUser(): Observable<User> {
    return this.http.get<User>(this.apiUrl + `users`
    ).pipe(map((user) => {
      localStorage.setItem("user", JSON.stringify(user));
      this.user.next(user);
      return user;
    })
    ).pipe(
      catchError(err => {
        this.user.error(err);
        return throwError(() => err);
      })
    );
  }

  public postUser(user: User): Observable<User> {
    return this.http.post<User>(
      this.apiUrl + `users`,
      user
    );
  }

  public postDescription(description: string): Observable<User> {
    return this.http.put<User>(
      this.apiUrl + `users/${this.currentUserId()}/description`,
      description
    );
  }

  public postImgURL(ImgURL: string): Observable<User> {
    return this.http.put<User>(
      this.apiUrl + `users/${this.currentUserId()}/image`,
      ImgURL
    );
  }

  public getProfiles(): Observable<UserProfile[]> {
    return this.http.get<UserProfile[]>(this.apiUrl + `users/profiles`);
  }

  public getUserByUserId(userId: string): Observable<UserProfile> {
    return this.http.get<UserProfile>(this.apiUrl + `users/${userId}`);
  }

  public getProfileByUserId(userId: string): Observable<UserProfile> {
    return this.http.get<UserProfile>(this.apiUrl + `users/profiles/${userId}`);
  }

  public currentUserId(): string {
    if (this.user.value) {
      return this.user.value.id
    }
  }

  public currentAllianceId(): string {
    if (this.user.value) {
      return this.user.value.allianceId;
    }
  }
}
