import { Component, ElementRef, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "report-SquadronReport",
  templateUrl: "./squadron-report.component.html",
  styleUrls: ["./squadron-report.component.scss"],
})
export class SquadronReportComponent implements OnInit {
  squadrons = [];
  squadronsId = [];

  constructor(
    public readonly modal: NgbActiveModal,
    private el: ElementRef,
  ) { }

  ngOnInit() { }
  public addSquadron(unit) {
    if (!this.squadronsId.includes(unit.techId)) {
      this.squadrons.push(unit);
      this.squadronsId.push(unit.techId);
    }
    this.el.nativeElement.closest('.modal').focus();
  }

  remove(unit) {
    this.squadrons.splice(this.squadrons.indexOf(unit), 1);
    this.squadronsId.splice(this.squadrons.indexOf(unit.techId), 1);
    if (this.squadrons.length < 1) {
      this.modal.dismiss('Cross click')
    }
  }
}
