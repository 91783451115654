import { Component, OnDestroy } from '@angular/core';
import { Routes } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil } from 'rxjs';
import { Alert } from 'src/app/models/Alert';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthenticationService } from "src/app/services/Authentication/authentication.service";
import { OptionsService } from 'src/app/services/options/options.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';
import { DebugComponent } from '../options/debug/debug.component';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss','w3.scss']
})
export class StartupComponent implements OnDestroy {
  static routes: Routes = [{ path: "", component: StartupComponent }];
  public alerts: Map<number, Alert> = new Map<number, Alert>();
  private readonly destroy$ = new Subject();
  public releaseDate = new Date('2023-04-01T15:00:00+0100');
  public endDate = new Date('2023-04-10T23:00:00+0100');
  public timeBeforeRelease = 10000;
  public timeBeforeEnd = 10000;
  public byPassTime = true;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly alertService: AlertService,
    private readonly modalService: NgbModal,
    private readonly optionsService: OptionsService,
    public readonly utilsService: UtilsService,
  ) {

    this.byPassTime = this.optionsService.getOption('byPassTime');
    (<HTMLElement>document.getElementsByClassName('mainLoading')[0])['className'] = '';
    this.alertService.alertsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (alert) => {
          let temp = alert["msg"].split('[');
          alert["msg"] = temp[temp.length - 1];
          this.alerts.set(Date.now(), alert);
        }
      });
    // remove alerts when enough time has passed
    this.utilsService.timer
    .pipe(takeUntil(this.destroy$))
    .subscribe((val) => {
      let now = Date.now();
      if (this.alerts) {
        for (let alert of this.alerts.keys()) {
          if (now > (alert + this.alerts.get(alert).timeout)) {
            if (!this.alerts.get(alert).focus) {
              this.removeAlert(alert);
            }
          }
        }
      }
      this.timeBeforeRelease =  Date.parse(this.releaseDate.toISOString()) - now;
      this.timeBeforeEnd =  Date.parse(this.endDate.toISOString()) - now;
    });
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }

  public removeAlert(index) {
    this.alerts.delete(index);
  }

  public openAlerts(index, content) {
    if (this.alerts.get(index)['datas']) {
      let openAlert = this.modalService.open(DebugComponent, {
        ariaLabelledBy: "options",
        size: "xl",
        windowClass: "openAlert backModalImg",
        backdrop: false,
        scrollable: true,
      });
      openAlert.componentInstance.data = this.stringify(this.alerts.get(index)['datas']);
    }
  }

  public stringify(data) {
    return JSON.stringify(data, null, 2);
  }

  doLogin() {
    this.authenticationService.doLogin();
  }

  doLogout() {
    this.authenticationService.doLogout();
  }

  doRegister() {
    this.authenticationService.doRegister();
  }
}
