import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TargetSelectorComponent } from "src/app/main/targetSelector/TargetSelector.component";
import { Alert } from "src/app/models/Alert";
import { BaseSlot } from "src/app/models/base/BaseSlot";
import { Fleet } from "src/app/models/Fleet/Fleet";
import { FleetMove } from "src/app/models/Fleet/FleetMove";
import { FleetRef } from "src/app/models/Fleet/FleetRef";
import { GlobalPosition } from "src/app/models/GlobalPosition";
import { Planet } from "src/app/models/Planet/Planet";
import { AlertService } from "src/app/services/alert/alert.service";
import { BaseService } from "src/app/services/bases/base.service";
import { EventService } from "src/app/services/event/event.service";
import { FleetService } from "src/app/services/fleets/fleet.service";
import { PlanetsService } from "src/app/services/Planets/planets.service";
import { ReportService } from "src/app/services/reports/report.service";
import { UtilsService } from "src/app/services/Utils/utils.service";

@Component({
  selector: "app-fleet-travel",
  templateUrl: "./fleet-travel.component.html",
  styleUrls: ["./fleet-travel.component.scss"],
})
export class FleetTravelComponent implements OnInit {
  @Input()
  public selectedFleet: Fleet;

  @Input()
  public fleets: FleetRef[];

  @Input()
  public selectedTarget: GlobalPosition;
  public returnTarget: GlobalPosition;

  public distance: number;
  public consumption: number;
  public travelTime: number;
  private optimalFuel: number;
  public autonomy: number;
  public planet: Planet;

  public fleetMove: FleetMove;
  public objectives: string[];
  public resources = new Map<string, number>();
  public frmTravel: FormGroup;
  public bombardTargets: Map<number, BaseSlot>;

  get fleet() {
    return this.frmTravel.get("fleet");
  }
  get x() {
    return this.frmTravel.get("x");
  }
  get y() {
    return this.frmTravel.get("y");
  }
  get refill() {
    return this.frmTravel.get("refill");
  }
  get objective() {
    return this.frmTravel.get("objective");
  }

  constructor(
    private readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal,
    public readonly baseService: BaseService,
    private readonly fleetService: FleetService,
    private readonly eventService: EventService,
    private readonly alertService: AlertService,
    private readonly planetService: PlanetsService,
    private readonly modalService: NgbModal,
    public readonly utilsService: UtilsService,
    public readonly reportService: ReportService,
  ) {
    this.planetService.planetUpdate$.subscribe((planet) => {
      if (planet) {
        this.planet = planet;
      }
    })
  }



  ngOnInit() {
    if (!this.selectedFleet) {
      this.frmTravel = this.fb.group({
        fleet: ['', Validators.required],
        objective: ["ATTACK", Validators.required],
        targetX: [0, Validators.required],
        targetY: [0, Validators.required],
        returnX: [0, Validators.required],
        returnY: [0, Validators.required],
        refill: [0, Validators.required],
      });
      this.autonomy = 0;
      setTimeout(() => { this.ngOnInit() }, 250);
    } else {
      let x: number;
      let y: number;
      if (this.selectedTarget) {
        x = +this.selectedTarget.coordinates.split(";")[0];
        y = +this.selectedTarget.coordinates.split(";")[1];
      } else {
        this.selectedTarget = new GlobalPosition();
        this.selectedTarget.planetId = this.selectedFleet.position.planetId;
        this.selectedTarget.coordinates = ";";
        x = +this.selectedFleet.position.coordinates.split(";")[0];
        y = +this.selectedFleet.position.coordinates.split(";")[1];
      }
      this.returnTarget = JSON.parse(JSON.stringify(this.selectedFleet.position))
      //TODO : change Validators for the fleet to check if the amount is > 0
      this.frmTravel = this.fb.group({
        fleet: [this.selectedFleet.id, Validators.required],
        objective: ["ATTACK", Validators.required],
        targetX: [x, Validators.required],
        targetY: [y, Validators.required],
        returnX: [this.returnTarget.coordinates.split(';')[0], Validators.required],
        returnY: [this.returnTarget.coordinates.split(';')[1], Validators.required],
        refill: [0, Validators.required],
      });
      this.initialiseObjectives();
      this.autonomy = this.selectedFleet.currentFuel / this.selectedFleet.consumption * 3600
      this.selectedTarget.coordinates = x + ";" + y;

      let currentResources = this.baseService.getCurrentBaseRessources();
      let ratio = Math.floor(100 / (currentResources.size * 1.0));
      this.fleetMove = new FleetMove();
      this.fleetMove.pillageRate = new Map<string, number>();
      for (let resourceName of currentResources.keys()) {
        this.fleetMove.pillageRate[resourceName] = ratio
        this.resources.set(resourceName, ratio)
      }
      for (let key in this.fleetMove.pillageRate) {
        setTimeout(() => this.onChange({ target: { getAttribute: () => key, value: this.fleetMove.pillageRate[key] } }), 100);
        break;
      }
      this.fleetMove.bombardRound = 0;
      this.fleetMove.bombardTargets = [];
    }
  }

  initialiseObjectives() {
    this.objectives = ["ATTACK", "SUPPORT", "MOVE", "DELIVERY"];
    if (this.selectedFleet.capacities["BUILD_BASE"]) {
      this.objectives.push("BUILD_BASE");
      this.objectives.push("BUILD_BASE_ALLIANCE");//create base alliance
    }
  }

  onChangeObjective(objective) {
    if (objective === 'ATTACK') {
      for (let key in this.fleetMove.pillageRate) {
        setTimeout(() => this.onChange({ target: { getAttribute: () => key, value: this.fleetMove.pillageRate[key] } }), 100);
        break;
      }
    }
  }

  onChange(event) {
    event.target.value = +event.target.value
    if (isNaN(event.target.value)) {
      event.target.value = 0;
    }
    event.target.value = Math.min(100, event.target.value);
    let totalResource: number = 0;
    let resource = event.target.getAttribute('resource');
    for (let key in this.fleetMove.pillageRate) {
      if (key !== resource) {
        totalResource += +this.fleetMove.pillageRate[key];
      }
    }
    totalResource += +event.target.value;


    if (totalResource > 100) {
      let toRemove = totalResource - 100;
      for (let key in this.fleetMove.pillageRate) {
        if (key !== resource && toRemove > 0) {
          if ((this.fleetMove.pillageRate[key] - toRemove) > 0) {
            this.fleetMove.pillageRate[key] = this.fleetMove.pillageRate[key] - toRemove;
            toRemove = 0;
          } else {
            toRemove -= this.fleetMove.pillageRate[key];
            this.fleetMove.pillageRate[key] = 0;
          }
        }
      }
    }
    if (!isNaN(this.fleetMove.pillageRate[resource])) {
      this.fleetMove.pillageRate[resource] = +event.target.value;
    }
    this.refreshStorageRatio();
  }

  onClickOutput(event, resource) {
    if (event.target.parentElement.getElementsByTagName('input').length == 0) {
      let input = document.createElement('input')

      input.setAttribute('type', 'text')
      input.setAttribute('size', '2');
      input.setAttribute('value', this.fleetMove.pillageRate[resource.key] + '');
      input.setAttribute('resource', event.target.getAttribute('resource'));
      input.addEventListener('change', (event) => this.onExitOutput(event, resource.key));
      input.addEventListener('blur', (event) => this.onExitOutput(event, resource.key));
      event.target.parentElement.append(input);
      event.target.classList.add('hidden')
      input.focus();
      input.select();
    }
  }

  onExitOutput(event, resource) {
    event.target.resource = resource;
    this.onChange(event)
    event.target.removeAllListeners();
    event.target.parentElement.getElementsByTagName('label')[1].classList.remove('hidden')
    event.target.parentElement.removeChild(event.target)
  }

  refreshStorageRatio() {
    for (let key in this.fleetMove.pillageRate) {
      let value = this.fleetMove.pillageRate[key];
      document.getElementById(key + "")['value'] = value;
      if (value > 0) {
        document.getElementById(key + "").style['background'] = 'linear-gradient(to right, transparent 0%, transparent '
          + value + '%, rgba(0,0,0, 0.8) '
          + value + '%, rgba(0,0,0, 0.8) 100%)';
      } else {
        document.getElementById(key + "").style['background'] = 'linear-gradient(to right, transparent 0%, transparent 0%, rgba(0,0,0, 0.8) 0%, rgba(0,0,0, 0.8) 100%)';
      }
    }
  }

  public onChangeFleet(value): void {
    this.fleetService.getFleetById(value).subscribe((fleet) => {
      this.selectedFleet = fleet;
      this.autonomy = this.selectedFleet.currentFuel / this.selectedFleet.consumption * 3600
      this.returnTarget = JSON.parse(JSON.stringify(this.selectedFleet.position));
      this.frmTravel.controls.returnX.setValue(this.returnTarget.coordinates.split(';')[0]);
      this.frmTravel.controls.returnY.setValue(this.returnTarget.coordinates.split(';')[1]);
      this.recalculateDistance();
      if (this.frmTravel.value.objective === 'ATTACK') {
        for (let key in this.fleetMove.pillageRate) {
          setTimeout(() => this.onChange({ target: { getAttribute: () => key, value: this.fleetMove.pillageRate[key] } }), 100);
          break;
        }
      }
      this.initialiseObjectives();
    });
  }

  public onChangeX(event, target): void {
    event.value = +event.value;
    event.value = Math.max(event.min, event.value)
    event.value = Math.min(event.max, event.value)
    if (target === 'selectedTarget') {
      this.selectedTarget.coordinates = event.value + ";" + this.selectedTarget.coordinates.split(";")[1];
      this.frmTravel.controls.targetX.setValue(event.value);
    } else {
      this.returnTarget.coordinates = event.value + ";" + this.returnTarget.coordinates.split(";")[1];
      this.frmTravel.controls.returnX.setValue(event.value);
    }
    this.recalculateDistance();
  }

  public onChangeY(event, target): void {
    event.value = +event.value;
    event.value = Math.max(event.min, event.value)
    event.value = Math.min(event.max, event.value)
    if (target === 'selectedTarget') {
      this.selectedTarget.coordinates = this.selectedTarget.coordinates.split(";")[0] + ";" + event.value;
      this.frmTravel.controls.targetY.setValue(event.value);
    } else {
      this.returnTarget.coordinates = this.returnTarget.coordinates.split(";")[0] + ";" + event.value;
      this.frmTravel.controls.returnY.setValue(event.value);
    }
    this.recalculateDistance();
  }

  public onChangeFuel(value): void {
    this.autonomy = (this.selectedFleet.currentFuel + value) / this.selectedFleet.consumption * 3600;
  }

  public recalculateDistance() {
    if (this.selectedFleet.position && this.selectedTarget) {
      this.distance = this.utilsService.calculateDistance(this.selectedFleet.position, this.selectedTarget)
      this.travelTime = Math.round(this.distance / this.selectedFleet.speed);
      this.consumption = Math.ceil(
        ((this.selectedFleet.consumption * this.travelTime)) / 3600
      );
      this.optimalFuel = (this.consumption * 2) + this.selectedFleet.consumption;     //2* la conso du trajer +1h sur place
    } else {
      this.distance = 0;
      this.travelTime = 0;
      this.consumption = 0;
      this.optimalFuel = 0;
    }
    if (this.selectedTarget) {
      this.bombardTargets = this.reportService.knownBases.get(this.selectedTarget)
    }
  }



  public sendFleet() {
    if (this.frmTravel.valid && this.selectedFleet) {
      this.fleetMove.target = this.selectedTarget;
      if (this.frmTravel.value.objective === 'ATTACK') {
        this.fleetMove.returnPosition = this.returnTarget;
      } else {
        this.fleetMove.returnPosition = this.selectedFleet.position;
      }
      this.fleetMove.objective = this.frmTravel.value.objective;
      this.fleetMove.unexpectedStrategy = this.selectedFleet.strategy;

      this.fleetMove.bombardTargets = [];
      for (let elm in this.bombardTargets) {
        let slot = this.bombardTargets[elm];
        console.warn(elm)
        if(slot.selected) {
          this.fleetMove.bombardTargets.push(slot);
        }
      }
      this.fleetService
        .launchTravel(
          this.selectedFleet.id,
          this.fleetMove
        ).subscribe(() => {
          this.fleetService
            .getFleetById(this.selectedFleet.id)
            .subscribe((fleet) => {
              this.selectedFleet = fleet;
              this.baseService.updateBase();
              this.eventService.updatePlayerEventNeeded();
              this.modal.dismiss();
            });
        });
    } else {
      let alert = new Alert();
      alert.type = "danger";
      alert.timeout = 10000;
      for (let control in this.frmTravel.controls) {
        if (!this.frmTravel.controls[control].valid) {
          alert.msg += 'le paramètre :\"' + control + "\" n'est pas valide : ";
          for (let error in this.frmTravel.controls[control].errors) {
            if (error === 'required') {
              alert.msg += "il faut une valeur";
            } else if (error === 'min') {
              alert.msg += "la valeur min attendue est " + this.frmTravel.controls[control].errors[error].min + ' hors la valeur encodée est ' + this.frmTravel.controls[control].errors[error].actual;
            } else if (error === 'max') {
              alert.msg += "la valeur max attendue est " + this.frmTravel.controls[control].errors[error].max + ' hors la valeur encodée est ' + this.frmTravel.controls[control].errors[error].actual;
            } else {
              alert.msg += "erreur inconnue (" + error + ")";
            }
          }
          alert.msg += '<br/>'
        }
      }
      if (alert.msg) {
        alert.msg = alert.msg.slice(0, -5);
        this.alertService.addAlert(alert);
      }
      //TODO : afficher le pourquoi de ca fonctionne pas
    }
  }

  openTargetSelector(target) {
    let targetSelector = this.modalService.open(TargetSelectorComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "base_openBuilding backModalImg",
    });
    targetSelector.componentInstance.objectToMove = this.selectedFleet;
    targetSelector.closed.subscribe((reason) => {
      if (reason && reason["coordinates"]) {
        let coords = reason["coordinates"].split(";")

        if (target === 'selectedTarget') {
          this.selectedTarget.planetId = reason["planetId"];
          this.selectedTarget.coordinates = reason["coordinates"];
          this.frmTravel.controls.targetX.setValue(coords[0]);
          this.frmTravel.controls.targetY.setValue(coords[1]);
        } else {
          this.returnTarget.planetId = reason["planetId"];
          this.returnTarget.coordinates = reason["coordinates"];
          this.frmTravel.controls.returnX.setValue(coords[0]);
          this.frmTravel.controls.returnY.setValue(coords[1]);
        }
        this.recalculateDistance();
      }
    })
  }
}
