<div *ngIf="!mainFleet" class="modalLoading">
  <label class="modal-header">loading !
    <button (click)="utilsService.closeModalByEvent($event)" aria-label="Close" class="btn btn-close" type="button"></button>
  </label>
  <label class="modal-body loading"></label>
</div>

<div *ngIf="mainFleet" class="modal-header d-flex flex-row justify-content-between">
  <div>
    <div>
      <h4 class="modal-title me-3" id="fleetManagement" i18n="PlanetComponent|Title: fleet moving">
        Gestion de flottes
      </h4>
    </div>
  </div>
  <div *ngIf="localFleets">
    <label for="fleet" i18n="PlanetComponent|Fleet">Flotte: </label>
    <select (change)="onChangeFleetSecondary($event.target['value'])">
      <option *ngFor="let o of localFleets" [value]="o.id">
        {{ o.name }}({{ o.power }})
      </option>
    </select>
  </div>
  <button (click)="utilsService.closeModalByEvent($event)" aria-label="Close" class="btn btn-close" type="reset" ngbAutofocus>
  </button>
</div>
<div *ngIf="mainFleet" class="modal-body d-flex flex-row mainFleet">
  <div *ngIf="selectedFleetBase" (mouseenter)="selectFleet(null)" class="d-flex flex-column">
    <div class="d-flex justify-content-center" i18n="Fleet-detailComponent|Fleet in the base">Base</div>
    <div (dragend)="cleanAfterDrag()" (dragenter)="dragenter($event)" (dragleave)="dragleave($event)"
      (dragover)="(false)" (drop)="removeSquad($event)"
      class="backLine baseLine flex-fill m-2 flex-column justify-content-center">
      <div class="d-flex justify-content-center"
        style="backgroundColor:transparent;color:transparent;user-select: none">Blank</div>
      <label (click)="removeSquad($event)" class="d-flex flex-fill justify-content-center battleGroup addBattleGroup">
        -
      </label>
      <div (dragstart)="selectUnitToMove($event, unit)" *ngFor="let unit of unitsRef" draggable="true">
        <img alt="{{ unit.name }}" class="shipImage" src="{{ '/assets/img/ships/' + unit.name + '.png' }}"
          title="{{ unit.name }}" (click)="unitDetails(unit)" />
        x {{ unit.amount }}
      </div>
    </div>
  </div>
  <div class="formation" (mouseenter)="selectFleet(mainFleet)">
    <div class="d-flex flex-fill">
      <!--Name-->
      <h4 *ngIf="!isNameUpdating" class="modal-title me-3 d-flex flex-fill" id="FleetShowName">
        {{ mainFleet.name }}
      </h4>
      <button (click)="isNameUpdating = true" *ngIf="!isNameUpdating" class="btn btn-primary bx bx-edit" type="button"
        i18n="Fleet-detailComponent|Button edit">
      </button>
      <input *ngIf="isNameUpdating" [(ngModel)]="mainFleet.name" class="nomdeflotte" type="text" />
      <button (click)="editFleetName()" *ngIf="isNameUpdating" class="btn btn-warning bx bx-save" type="button"
        i18n="Fleet-detailComponent|Button edit">
      </button>
      <!--Status-->
      <h4 *ngIf="!isStatusUpdating" class="modal-title me-3 d-flex flex-fill" id="modal-basic-title">
        {{ utilsService.translateConstants(mainFleet.status) }}
      </h4>
      <button (click)="isStatusUpdating = true; editedStatus = this.mainFleet.status" *ngIf="!isStatusUpdating"
        class="btn btn-primary" type="button" i18n="Fleet-detailComponent|Button edit">
        Modifier
      </button>
      <select *ngIf="isStatusUpdating" class="d-flex flex-fill" [(ngModel)]="editedStatus">
        <option *ngFor="let s of status" value="{{ s }}">{{ utilsService.translateConstants(s) }}</option>
      </select>`
      <button (click)="editFleetStatus($event)" *ngIf="isStatusUpdating" class="btn btn-warning" type="button"
        i18n="Fleet-detailComponent|Button edit">
        Modifier
      </button>
    </div>
    <!--POWER + SPEED-->
    <div class="d-flex flex-fill">
      <h4 class="d-flex flex-fill" i18n="Fleet-detailComponent|Fleet power">Puissance : {{ mainFleet.power }}</h4>
      <h4 class="d-flex flex-fill" *ngIf="mainFleet.speed" i18n="Fleet-detailComponent|Fleet speed">Vitesse : {{
        mainFleet.speed }}</h4>
    </div>
    <!--FUEL-->
    <div class="d-flex flex-fill">
      <h4 class="d-flex flex-fill" i18n="Fleet-detailComponent|Fleet consumption">Conso : {{ mainFleet.consumption }}
      </h4>
      <h4 *ngIf="!isTurrets" class="d-flex flex-fill" i18n="Fleet-detailComponent|Fleet consumption">Stock : {{
        mainFleet.currentFuel }}/{{ mainFleet.cumulatedFuelTank }}({{ mainFleet.fuelTime*1000 | timer: "UTC" }})</h4>
      <button (click)="refuel('mainFleet')" class="btn btn-primary" *ngIf="canRefuel" type="button"
        i18n="Fleet-detailComponent|Button edit">
        +
      </button>
    </div>
    <!-- FUEL_TYPE-->
    <div class="d-flex flex-fill">
      <h4 *ngIf="!isFuelUpdating" class="modal-title me-3 d-flex flex-fill" id="modal-basic-title">
        Fuel : {{ mainFleet.fuelCode }} ({{ mainFleet.currentFuelEfficiency }}%)
      </h4>
      <button (click)="isFuelUpdating = true; editedFuel = this.mainFleet.fuelCode" *ngIf="!isFuelUpdating"
        class="btn btn-primary" type="button" i18n="Fleet-detailComponent|Button edit">
        Modifier
      </button>
      <select *ngIf="isFuelUpdating" class="d-flex flex-fill" [(ngModel)]="editedFuel">
        Fuel : <option *ngFor="let s of fuelCodes" value="{{ s }}">{{ utilsService.translateConstants(s) }}</option>
      </select>`
      <button (click)="editFleetFuel($event)" *ngIf="isFuelUpdating" class="btn btn-warning" type="button"
        i18n="Fleet-detailComponent|Button edit">
        Modifier
      </button>
    </div>
    <div class="d-flex justify-content-center" i18n="Fleet-detailComponent|Formation">Formation</div>
    <div class="d-flex flex-row-reverse justify-content-end position-relative me-3">
      <!--      <input type="range" class="form-range" (change)="warn($event.target.value)" *ngIf="mainFleet.cumulatedFuelTank > 0" [max]=mainFleet.cumulatedFuelTank [striped]="true" [value]=mainFleet.currentFuel class="resource-progressbar d-flex flex-fill position-absolute">-->
      <div *ngFor="let line of numSequence(formationMaxLines); let i = index"
        class="backLine flex-fill m-2 justify-content-center">
        <div class="d-flex flex-fill justify-content-center" i18n="Fleet-detailComponent|n line">{{ i + 1 }} è{{i === 0?
          'r':'m'}}e ligne</div>
        <label (dragend)="cleanAfterDrag()" (dragenter)="dragenter($event)" (dragleave)="dragleave($event)"
          (dragover)="(false)" (drop)="addSquad($event, i,mainFleet)" (click)="addSquad($event, i,mainFleet)"
          class="d-flex flex-fill justify-content-center battleGroup addBattleGroup">
          +
        </label>
        <div>
          <div *ngFor="let group of mainFleet.squadrons | keyvalue; let indexOfelement = index">
            <div (click)="editGroup(group.value)" (dragend)="cleanAfterDrag()" (dragenter)="dragenter($event)"
              (dragleave)="dragleave($event)" (dragover)="(false)" (dragstart)="selectSquadToMove(group.value)"
              (drop)="dragOnSquad($event, i, group.key, mainFleet)" *ngIf="group.value.line === i" class="battleGroup">
              <label>
                <img alt="{{ group.value.name }}" class="shipImage" src="{{
                  '/assets/img/ships/' + group.value.name + '.png'
                }}" title="{{ group.value.name }}" />
                x {{ group.value.amount ? group.value.amount : 0 }}</label><br />
              <!--<label i18n="Fleet-detailComponent|Strategy">Strat : {{ group.value.moveTactic }} - {{group.value.shootingTactic}}</label>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Flotte secondaire-->
  <div *ngIf="localFleets && secondaryFleet" class="formation" (mouseenter)="selectFleet(secondaryFleet)">
    <div class="d-flex flex-fill">
      <h4 *ngIf="!isNameUpdatingSecondary" class="modal-title me-3 d-flex flex-fill" id="FleetShowName">
        {{ secondaryFleet.name }}
      </h4>
      <button (click)="isNameUpdatingSecondary = true" *ngIf="!isNameUpdatingSecondary"
        class="btn btn-primary bx bx-edit" type="button" i18n="Fleet-detailComponent|Button edit">
      </button>
      <input *ngIf="isNameUpdatingSecondary" [(ngModel)]="secondaryFleet.name" class="nomdeflotte" type="text" />
      <button (click)="editFleetName()" *ngIf="isNameUpdatingSecondary" class="btn btn-warning bx bx-save" type="button"
        i18n="Fleet-detailComponent|Button edit">
      </button>
      <h4 *ngIf="!isStatusUpdatingSecondary" class="modal-title me-3 d-flex flex-fill" id="modal-basic-title">
        {{ secondaryFleet.status }}
      </h4>
      <button (click)="isStatusUpdatingSecondary = true; editedStatus = this.secondaryFleet.status"
        *ngIf="!isStatusUpdatingSecondary" class="btn btn-primary" type="button"
        i18n="Fleet-detailComponent|Button edit">
        Modifier
      </button>
      <select *ngIf="isStatusUpdatingSecondary" class="d-flex flex-fill" [(ngModel)]="editedStatus">
        <option *ngFor="let s of status" value="{{ s }}">{{ s }}</option>
      </select>`
      <!--<input  class="form-control" *ngIf="isStatusUpdating" type="text" [(ngModel)]="secondaryFleet.status"/>-->
      <button (click)="editFleetStatus($event)" *ngIf="isStatusUpdatingSecondary" class="btn btn-warning" type="button"
        i18n="Fleet-detailComponent|Button edit">
        Modifier
      </button>
    </div>
    <div class="d-flex flex-fill">
      <h4 class="d-flex flex-fill" i18n="Fleet-detailComponent|Fleet power">Puissance : {{ secondaryFleet.power }}</h4>
      <h4 class="d-flex flex-fill" *ngIf="secondaryFleet.speed" i18n="Fleet-detailComponent|Fleet speed">Vitesse : {{
        secondaryFleet.speed }}</h4>
    </div>
    <div class="d-flex flex-fill">
      <h4 class="d-flex flex-fill" i18n="Fleet-detailComponent|Fleet consumption">
        Conso : {{ secondaryFleet.consumption}}
      </h4>
      <h4 class="d-flex flex-fill" i18n="Fleet-detailComponent|Fleet consumption">
        Stock : {{ secondaryFleet.currentFuel }}/{{ secondaryFleet.cumulatedFuelTank }}({{ secondaryFleet.fuelTime*1000 | timer: "UTC" }})
      </h4>
      <button (click)="refuel('secondaryFleet')" class="btn btn-primary" *ngIf="canRefuel" type="button"
        i18n="Fleet-detailComponent|Button edit">
        +
      </button>
    </div>
    <div class="d-flex justify-content-center" i18n="Fleet-detailComponent|Formation">Formation</div>

    <div class="d-flex flex-row-reverse justify-content-end position-relative me-3">

      <!--        <input type="range" class="form-range" (change)="warn($event.target.value)" *ngIf="secondaryFleet.cumulatedFuelTank > 0" [max]=secondaryFleet.cumulatedFuelTank [striped]="true" [value]=secondaryFleet.currentFuel class="resource-progressbar d-flex flex-fill position-absolute">-->
      <div *ngFor="let line of numSequence(formationMaxLines); let i = index"
        class="backLine flex-fill m-2 justify-content-center">
        <div *ngIf="i !== 0" class="d-flex flex-fill justify-content-center" i18n="Fleet-detailComponent|n line">{{ i +
          1 }}ème ligne</div>
        <div *ngIf="i === 0" class="d-flex flex-fill justify-content-center" i18n="Fleet-detailComponent|first line">
          1ère ligne</div>
        <label (dragend)="cleanAfterDrag()" (dragenter)="dragenter($event)" (dragleave)="dragleave($event)"
          (dragover)="(false)" (drop)="addSquad($event, i,secondaryFleet)" (click)="addSquad($event, i,secondaryFleet)"
          class="d-flex flex-fill justify-content-center battleGroup addBattleGroup">
          +
        </label>
        <div>
          <div *ngFor="let group of secondaryFleet.squadrons | keyvalue; let indexOfelement = index">
            <div (click)="editGroup(group.value)" (dragend)="cleanAfterDrag()" (dragenter)="dragenter($event)"
              (dragleave)="dragleave($event)" (dragover)="(false)" (dragstart)="selectSquadToMove(group.value)"
              (drop)="dragOnSquad($event, i, group.key,secondaryFleet)" *ngIf="group.value.line === i"
              class="battleGroup">
              <label>
                <img alt="{{ group.value.name }}" class="shipImage" src="{{
                    'assets/img/ships/' + group.value.name + '.png'
                  }}" title="{{ group.value.name }}" />
                x {{ group.value.amount ? group.value.amount : 0 }}</label><br />
              <!--<label i18n="Fleet-detailComponent|Strategy">Strat : {{ group.value.moveTactic }} - {{group.value.shootingTactic}}</label>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
