<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">{{title}}</h4>
  <button
    (click)="modal.dismiss('Cross click')"
    aria-label="Close"
    class="btn btn-close"
    type="reset"
  >
  </button>
</div>
<div class="modal-body">
  <div class="">
    <form
      #form="ngForm"
      (ngSubmit)="onSubmit()"
      [formGroup]="frmShip"
      class="d-flex flex-column"
      novalidate
    >
      <img *ngIf="selectedShip.name" class="shipImage shipName " src="{{ '/assets/img/ships/' + selectedShip.name + '.png' }}" title="{{ selectedShip.name }}" />
      <div *ngIf="doSelectShip">
        <label for="availableShips" i18n="Battle-groupComponent|Available ships">Type de vaisseaux: </label>
        <select class="availableShips"  #selectShip  (change)="onSelectedShip(selectShip.value)" formControlName="availableShips">

          <option  *ngFor="let o of availableShips; index as i" [value]="i">
            {{ (o['line'] > -1) ? (o.name + ' {ligne: ' + (o['line']+1)+'}') : o.name }}
          </option>
        </select>
      </div>
      <div *ngIf="doAmount">
        <label for="amount" i18n="Battle-groupComponent|Amount">Quantité : </label>
        <input
          formControlName="amount"
          id="amount"
          max="{{ selectedShip.amount }}"
          min="0"
          required="required"
          type="number"
        />
      </div>
      <div *ngIf="doTactic && selectedShip">
        <label for="moveTactic" i18n="Battle-groupComponent|Move tactic">Stratégie de mouvement: </label>
        <select formControlName="moveTactic">
          <option *ngFor="let o of moveTactics" [selected]="selectedShip['moveTactic']===o">
            {{ o }}
          </option>
        </select>
      <!-- <input formControlName="moveTactic" class="form-control" id="moveTactic" type="text"/>-->
        <label for="shootingTactic" i18n="Battle-groupComponent|Shooting tactic">Stratégie de tir: </label>
        <select formControlName="shootingTactic">
          <option *ngFor="let o of shootingTactics" [selected]="selectedShip['shootingTactic']===o">
            {{ o }}
          </option>
        </select>
      <!-- <input formControlName="shootingTactic" class="form-control" id="shootingTactic" type="text"/>-->
        <label for="formationRound" i18n="Battle-groupComponent|Number of rounds of training to keep the formation">Nbre de tour de formation : </label>
        <input
          formControlName="formationRound"
          id="formationRound"
          min="0"
          required="required"
          type="number"
        />
      </div>
      <div *ngIf="isJSONNotEmpty(selectedShip['storage'])" class="d-flex flex-warp align-self-center p-1">
        <div *ngFor="let amount of selectedShip['storage'] | keyvalue">
          <div class="p-1 d-flex flex-column">
            <img class="resourceImg" src="/assets/img/resources/{{ amount.key }}.png" />
            {{ amount.value }}
          </div>
        </div>
      </div>
      <br>
      <button (click)="modal.dismiss('Cancel click')" type="reset" class="btn btn-outline-danger" i18n="Battle-groupComponent|Button cancel">
        Annuler
      </button>
      <button class="btn btn-outline-success" data-dismiss="modal" ngbAutofocus type="submit" i18n="Battle-groupComponent|Button accept">
        Affecter
      </button>
    </form>
  </div>
</div>
