import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { AuthenticationService } from "src/app/services/Authentication/authentication.service";
import { NotificationsService } from "src/app/services/Notifications/notifications.service";
import { OptionsService } from "src/app/services/options/options.service";
import { DebugComponent } from "./debug/debug.component";

@Component({
  templateUrl: "./options.component.html",
  styleUrls: ["./options.component.scss"],
})
export class OptionsComponent implements OnInit {

  public options;
  private readonly destroy$ = new Subject();
  public debug: boolean = false;
  public optionsHtml = [
    ['deleteUnreadReport', 'Affiche le bouton pour supprimer les rapports non lu quand l\'option \'afficher les lus\' n\'est pas cochée'],
    ['debug', 'Affiche les infos de debug (id des flottes et des bases)'],
    ['heightWiseTechTree', 'Affiche le sens de l\'arbre techno vers la droite plutôt que vers le bas'],
  //  ['planet3D', 'Affiche la vue planète en vue 3D']
  ]
  constructor(
    public readonly modal: NgbActiveModal,
    private readonly optionsService: OptionsService,
    private readonly authenticationService: AuthenticationService,
    private readonly modalService: NgbModal,
    private readonly notificationService: NotificationsService,
  ) {
  }

  ngOnInit() {
    this.options = this.optionsService.getOptions();
    for (let option in this.options) {
      let element: HTMLElement = document.getElementById(option) as HTMLElement;
      if (element) {
        if (this.options[option] == true) {
          element.click();
        }
      }
    }
    this.debug = this.optionsService.getOption('debug');
    this.optionsService.optionsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((option) => {
        if (option["debug"]) {
          this.debug = option["debug"] === 'true';
        }
      });
  }

  setOption(key, value) {
    this.optionsService.setOption(key, value)
  }

  public logout(): void {
    this.authenticationService.doLogout();
  }

  public openDebug(): void {
    this.modalService.open(DebugComponent, {
      ariaLabelledBy: "debugMenu",
      size: "xl",
      windowClass: "debugMenu backModalImg",
      backdrop: false,
      scrollable: true,
    });
  }

  askForNotifications() {
    this.notificationService.askForPermission();
  }
}
