import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { Routes } from "@angular/router";
import { NgbModal, NgbModalRef, NgbNavChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { ElementScrollPercentage } from "src/app/element-scroll-percentage";
import { User } from "src/app/models";
import { ReportService } from "src/app/services/reports/report.service";
import { UsersService } from "src/app/services/Users/user.service";
import { UtilsService } from "src/app/services/Utils/utils.service";
import { MessageThread } from "src/app/models/message/MessageThread";
import { MessageService } from "src/app/services/message/message.service";
import { MessageThreadCreateComponent } from "./message-thread-create/message-thread-create.component";
import { MessageThreadComponent } from "./message-thread/message-thread.component";


@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit, OnDestroy {
  static routes: Routes = [{ path: "", component: MessageBoxComponent }];
  private loaded: number;
  private inLoading: boolean = false;
  public inError: boolean = false;
  public reloading: boolean = false;
  private scrollEvent;
  messageThreads: MessageThread[];
  rawThreads: MessageThread[];
  user: User;
  showLu: boolean = false;
  private readonly destroy$ = new Subject();
  searchedThreads: string = '';
  newReport: number = 0;
  newThreads: number = 0;

  constructor(
    private readonly modalService: NgbModal,
    private readonly elementScrollPercentage: ElementScrollPercentage,
    private readonly elRef: ElementRef,
    private readonly usersService: UsersService,
    public readonly utilsService: UtilsService,
    public readonly messageService: MessageService,
    public readonly reportService: ReportService,
  ) { }

  ngOnInit() {
    //  this.user = JSON.parse(localStorage.getItem("user"));
    this.usersService.userUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (!this.rawThreads) {
          this.inError = true;
          this.reloadMessageThreads();
          this.countNew();
          this.showLu = false;
        }
      });

    this.reportService.numberOfReportsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((numberOfReports) => {
        this.newReport = numberOfReports;
      });

    this.messageService.numberOfThreadsAnnounced$
      .pipe(takeUntil(this.destroy$))
      .subscribe((numberOfThreads) => {
        this.newThreads = numberOfThreads;
      });

    this.scrollEvent = this.elementScrollPercentage.getScrollAsStream(this.elRef.nativeElement.parentElement)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (percent: number): void => {
          if (percent > 80) {
            if (!this.inLoading && this.showLu) {
              if (this.loaded % 20 == 0) {
                this.inLoading = true;
                this.messageService.getThreadsPaginated(this.loaded, this.loaded + 19).subscribe({
                  next: (threads) => {
                    if (threads && threads.length > 0) {
                      /*for (let report in threads) {
                        threads[report].date = new Date(Date.parse(reports[report].date + ''));
                      }/** */
                      this.rawThreads = this.rawThreads.concat(threads);
                      this.loaded += threads.length;
                      this.inLoading = false;
                      this.onSearchThreads(this.searchedThreads)
                    } else {

                    }
                  },
                  error: (err) => {
                    this.inError = true;
                  }
                });
              } else {
                this.inError = true;
              }
            } else {
              this.inError = true;
            }
          } else {
            if (this.inError) {
              this.inError = true;
              this.inLoading = false;
            }
          }
        });
  }

  ngOnDestroy() {
    //this.usersService.updateUser()
    this.messageService.getThreads().subscribe(() => { });
    this.destroy$.next("");
    this.destroy$.complete();
  }

  onSearchThreads(value: string) {

  }

  countNew() {
    this.newThreads = 0;
    if (this.rawThreads)
      this.newThreads = this.rawThreads.length;
  }

  //Temporaire : quand la gestion des event sera faite, y aura plus besoin de refresh hors event
  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 1) {
      this.reloadMessageThreads();
    }
  }

  //MessageThread
  openMessageThread(selectedMessageThread: MessageThread): NgbModalRef {
    //Open Modal
    let modal: NgbModalRef;

    modal = this.modalService.open(MessageThreadComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "report-detail backModalImg",
    });
    modal.componentInstance.messageThread = selectedMessageThread;

    if (modal) {
      modal.dismissed.subscribe((reason) => {
        this.reloadMessageThreads();
      })
    } else {

    }
    return modal;
  }

  //MessageThread
  createNewThread(): NgbModalRef {
    //Open Modal
    let modal = this.modalService.open(MessageThreadCreateComponent, {
      ariaLabelledBy: "modal-basic-title",
      scrollable: true,
      size: "xl",
      windowClass: "report-detail backModalImg",
    });


    if (modal) {
      modal.dismissed.subscribe((reason) => {
        this.reloadMessageThreads();
      })
    } else {

    }
    return modal;
  }

  reloadMessageThreads() {
    if (!this.reloading) {

      this.reloading = true; // to add the loading animation
      this.inError = false;
      this.messageThreads = [];
      //this.messageService.getThreadsPaginated(0, 19).subscribe((messages) => {
      this.messageService.getThreads().subscribe((messages) => {
        for (let message in messages) {
          messages[message].initialMessageDate = new Date(Date.parse(messages[message].initialMessageDate + ''));
        }
        this.loaded = messages.length;
        if (messages.length != 20) {
          this.inError = true;
        }
        this.messageThreads = messages;
        this.reloading = false;
        this.onSearchThreads(this.searchedThreads);
      });
    }
  }
}
