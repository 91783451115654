<ng-template #listUsers let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n="confirmDisable|Title" id="confirmDisable">Choisisser les joueurs à inviter</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <div class="searchBar">
      <input class="inputSearch" type="search" (input)="filterUserName($event.target['value'])" placeholder="Search...">
    </div>
    <div class="users" *ngIf="usersFiltered">
      <div *ngFor="let user of usersFiltered" class="d-flex justify-content-around flex-wrap btn btn-outline-secondary"
        [attr.selected]="user['selected'] === true" (click)="select(user.name)">{{user.name}}</div>
    </div>
    <div class="d-flex justify-content-center my-0">
      <div class="d-flex justify-content-center my-0">
        <button (click)="invitePlayers($event.target)" class="btn btn-outline-success" i18n="confirmDestroy|Confirm">
          Confirmer les invitations
        </button>
      </div>
      <div class="d-flex justify-content-center my-0">
        <button class="btn btn-outline-danger" i18n="confirmDestroy|Cancel" (click)="modal.dismiss('Cancel click')">
          Annuler
        </button>
      </div>
    </div>

  </div>
</ng-template>

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">
      Nouvelle discussion
    </h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="reset">
    </button>
  </div>

  <div class="modal-body">
    <div class="d-flex flex-column">
      <div class="profile">
        <div class="threadName">
          Nom de la discussion
          <form>
            <textarea (input)="change($event.target['value'], 'name')" id="threadNameForm"
              name="threadNameForm">{{messageThread.name}}</textarea>
          </form>
        </div>
        <button class="btn btn-primary" (click)="invite(listUsers)">
          Inviter un joueur
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <div class="d-flex justify-content-center">
          <button (click)="confirmNewThread()" class="btn btn-outline-success" i18n="confirmDestroy|Confirm">
            Créer la discussion
          </button>
        </div>
        <div class="d-flex justify-content-center">
          <button (click)="modal.dismiss('Cancel click')" class="btn btn-outline-danger" i18n="confirmDestroy|Cancel">
            Annuler
          </button>
        </div>
      </div>

    </div>
