<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" i18n="Battle-groupComponent|Ships assignment">Refuel</h4>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn btn-close"
      type="reset"
    >
    </button>
</div>
<div class="modal-body">
    <div class="">
        <form
            #form="ngForm"
            (ngSubmit)="onSubmit()"
            [formGroup]="frmFuel"
            class="d-flex flex-column"
            novalidate
        >
        <label for="sourceChoice" i18n="Battle-groupComponent|Move tactic">Source: </label>
        <select formControlName="sourceChoice">
          <option *ngFor="let o of sourceChoices" [selected]="o === sourceChoices[0]">
            {{ o }}
          </option>
        </select>
        <div>
            <label for="amount" i18n="Battle-groupComponent|Amount">Quantité : </label>
            <input
            formControlName="amount"
            id="amount"
            max="{{ fleet.cumulatedFuelTank }}"
            min="0"
            required="required"
            type="number"
            />
        </div>
        <br>
        <button (click)="modal.dismiss('Cancel click')" type="reset" class="btn btn-outline-danger" i18n="Battle-groupComponent|Button cancel">
            Annuler
        </button>
        <button class="btn btn-outline-success" data-dismiss="modal" ngbAutofocus type="submit" i18n="Battle-groupComponent|Button accept">
            Transférer
        </button>
        </form>
    </div>
</div>
