<div class="event-tab">
  <ul #nav="ngbNav" (navChange)="onNavChange($event)" class="nav-tabs" ngbNav>
    <li [ngbNavItem]="1">
      <a ngbNavLink i18n="Event-listComponent|Tab for the events linked to the base">Base</a>
      <ng-template ngbNavContent>
        <ng-container [ngTemplateOutlet]="eventTable"
        [ngTemplateOutletContext]="{events:baseEvents}">
      </ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" *ngIf="hostileEvents.length > 0" id="alert-danger" class="alert-danger">
      <a ngbNavLink i18n="Event-listComponent|Tab for the events linked to the player">Flottes</a>
      <ng-template ngbNavContent>
        <ng-container [ngTemplateOutlet]="eventTable"
        [ngTemplateOutletContext]="{events:hostileEvents}">
      </ng-container>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink i18n="Event-listComponent|Tab for the events linked to the player">Joueur</a>
      <ng-template ngbNavContent>
        <ng-container [ngTemplateOutlet]="eventTable"
        [ngTemplateOutletContext]="{events:playerEvents}">
      </ng-container>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>


<ng-template #eventTable let-events="events">
  <table  class="table table-striped table-hover table-dark">
    <tbody>
      <tr class="justify-content-around p-2">
        <th class="type" (click)="showOrder('type')">
          <i class='bx'></i>
          Type
        </th>
        <th class="description" (click)="showOrder('description')">
          <i class='bx'></i>
          détails
        </th>
        <th class="resolution" (click)="showOrder('resolution')">
          <i class='bx'></i>
          temps restant
        </th>
      </tr>
      <tr *ngFor="let event of events" [ngClass]="event.hostile ?'stripped-red':''" class="justify-content-around p-2">
        <td>{{ utilsService.translateConstants(event.type) }}</td>
        <td>{{ event.description }}</td>
        <td>
          <div *ngIf="timerMap">
            {{ +timerMap.get(event.id) | timer: 'UTC' }}
          </div>
        </td>
        <!--<div class="flex-row" *ngIf="event.type != 'TRANSITING'">
          <button class="btn btn-outline-danger" (click)="cancelEvent(event)">Cancel</button>
      </div>-->
        <td>
          <div *ngIf="event.type === 'MOVE' && !event.hostile">
            <button (click)="cancelMove(event.id)" aria-label="Close" class="btn-close" type="button">
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div [ngStyle]="{'display':(!events)?'':'none'}" class="loading">
  </div>
</ng-template>
