import { KeyValue } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Routes } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, takeUntil } from "rxjs";
import { ResearchDetailComponent } from 'src/app/main/research/research-detail/research-detail.component';
import { User } from 'src/app/models';
import { Base } from 'src/app/models/base/Base';
import { Research } from 'src/app/models/research/Research';
import { AllianceService } from 'src/app/services/Alliance/alliance.service';
import { BaseService } from 'src/app/services/bases/base.service';
import { BuildingsService } from 'src/app/services/Buildings/buildings.service';
import { EventService } from "src/app/services/event/event.service";
import { ResearchService } from 'src/app/services/research/research.service';
import { UsersService } from 'src/app/services/Users/user.service';
import { UtilsService } from 'src/app/services/Utils/utils.service';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss']
})
export class ResearchComponent implements OnDestroy, OnInit {
  static routes: Routes = [{ path: "", component: ResearchComponent }];
  private readonly destroy$ = new Subject();
  public researchList: Research[];
  public researchMap: Map<string, Research>;

  public user: User;

  resources;
  base: Base;

  timerMap: Map<string, string> = new Map<string, string>();
  transparentBackGround: Boolean = false;
  abc;

  public isRequirementsFulfilled: Map<string, boolean> = new Map<string, boolean>();
  private isCostRequirements: Map<string, boolean> = new Map<string, boolean>();
  private isBuildingsRequirements: Map<string, boolean> = new Map<string, boolean>();
  private isResearchRequirements: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    protected readonly researchService: ResearchService,
    private readonly baseService: BaseService,
    private readonly userService: UsersService,
    public readonly buildingsService: BuildingsService,
    private readonly eventService: EventService,
    private readonly modalService: NgbModal,
    public readonly utilsService: UtilsService,
    public readonly allianceService: AllianceService,
  ) {
    this.constructionTimer();
    //Récup des recherches


    //Récup de l'owner
    this.baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          if (base.type.startsWith('ALLY')) {
            allianceService.allianceUpdate$
              .pipe(takeUntil(this.destroy$))
              .subscribe((alliance) => {
                if (alliance) {
                  this.user.researchMap = alliance.researchMap;
                  setTimeout(() => this.checkRequirements(), 0);
                }
              });

          } else {
            userService.userUpdate$
              .pipe(takeUntil(this.destroy$))
              .subscribe((user) => {
                if (user) {
                  this.user = user;
                  setTimeout(() => this.checkRequirements(), 0);
                }
              });
          }
        }
      });

    researchService.researchUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((researchList) => {
        if (researchList) {
          this.researchList = researchList;
          this.researchMap = new Map();
          researchList.forEach((research) => {
            this.researchMap.set(research.id, research);
          })
        }
      });

    //Récup de la base et des ressources
    baseService.baseUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((base) => {
        if (base) {
          this.base = base;
          this.resources = base.ressources;
          setTimeout(() => this.checkRequirements(), 0);
        }
      });
  }

  ngOnInit(): void {

    // timer toutes les secondes qui met à jour les ressources approximativement
    this.baseService.resourcesUpdate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((updatedResources) => {
        if (updatedResources.size > 0) {
          this.resources = updatedResources;
          for (let key in this.researchList) {
            let research = this.researchList[key];
            this.isCostRequirements[research.id] = true;
            for (let res in updatedResources.keys()) {
              let resource = updatedResources.get(res);
              if (research.levels[(this.user.researchMap[research.id] + 1)]) {
                if (this.user.researchMap[research.id] >= 0) {
                  this.isCostRequirements[research.id] = this.isCostRequirements[research.id] && (resource.clientAmount > research.levels[(this.user.researchMap[research.id] + 1)].cost[res]);
                } else {
                  this.isCostRequirements[research.id] = this.isCostRequirements[research.id] && (resource.clientAmount > research.levels[0].cost[res]);
                }
              }
            }
            this.isRequirementsFulfilled[research.id] = this.isCostRequirements[research.id] && this.isResearchRequirements.get(research.id) && this.isBuildingsRequirements.get(research.id);
          }
        }
      });
  }

  checkRequirements() {

    let HQHasLevel: boolean = false
    for (let key of this.buildingsService.buildingsMap.keys()) {
      let building = this.buildingsService.buildingsMap.get(key);
      if (building.name === "HQ") {
        HQHasLevel = building["currentLevel"] >= 0;
      }
    }
    if (this.researchList && HQHasLevel) {
      this.researchList.forEach((research) => {
        this.isResearchRequirements.set(research.id, true);
        this.isBuildingsRequirements.set(research.id, true);
        if (this.user.researchMap[research.id] >= 0) {
          if (research.levels[(this.user.researchMap[research.id] + 1)]) {
            //research requirements if research is already unlocked
            for (let req in research.levels[(this.user.researchMap[research.id] + 1)].researchReq) {
              if (this.user.researchMap[req] >= 0) {
                this.isResearchRequirements.set(research.id, (this.isResearchRequirements.get(research.id) && (this.user.researchMap[req] >= research.levels[(this.user.researchMap[research.id] + 1)].researchReq[req])));
              } else {
                this.isResearchRequirements.set(research.id, false);
              }
            }


            //building requirements if research is already unlocked
            for (let req in research.levels[(this.user.researchMap[research.id] + 1)].buildingReq) {
              if (this.buildingsService.buildingsMap.get(req)["currentLevel"] >= 0) {
                this.isBuildingsRequirements.set(research.id, (this.isBuildingsRequirements.get(research.id) && (this.buildingsService.buildingsMap.get(req)["currentLevel"] >= research.levels[(this.user.researchMap[research.id] + 1)].buildingReq[req])));
              } else {
                this.isBuildingsRequirements.set(research.id, false);
              }
            }
          }
        } else {
          //research requirements if research is not unlocked
          for (let req in research.levels[0].researchReq) {
            if (this.user.researchMap[req] >= 0) {
              this.isResearchRequirements.set(research.id, this.isResearchRequirements.get(research.id) && (this.user.researchMap[req] >= research.levels[0].researchReq[req]));
            } else {
              this.isResearchRequirements.set(research.id, false);
            }
          }


          //building requirements if research is not unlocked
          for (let req in research.levels[0].buildingReq) {
            if (this.buildingsService.buildingsMap.get(req)["currentLevel"] >= 0) {
              this.isBuildingsRequirements.set(research.id, (this.isBuildingsRequirements.get(research.id) && (this.buildingsService.buildingsMap.get(req)["currentLevel"] >= research.levels[0].buildingReq[req])));
            } else {
              this.isBuildingsRequirements.set(research.id, false);
            }
          }
        }
      })
    } else {
      setTimeout(() => this.checkRequirements(), 200);
    }
  }

  ngOnDestroy() {
    this.destroy$.next("");
    this.destroy$.complete();
  }


  launchResearch(research: Research) {
    this.researchService.launchResearch(this.user.id, this.base.id, research.id).subscribe(() => {
      this.eventService.updateBaseEventNeeded();
      this.eventService.updatePlayerEventNeeded();
      this.userService.updateUser();
      this.baseService.updateBase();
    })
  }

  constructionTimer() {
    if (!this.abc) {
      this.abc = this.utilsService.timer
        .pipe(takeUntil(this.destroy$))
        .subscribe((val) => {
          let needUpdate = false;
          if (this.user) {
            for (const [researchId, timer] of Object.entries(this.user.pendingResearch)) {
              let then = Date.parse(timer + '')
              let ms = then - Date.now();
              this.timerMap.set(researchId, ms + '');
            }
          }
        });
    }
  }

  researchDetailModal;
  researchDetail(research: Research) {
    if (!this.researchDetailModal) {
      this.researchDetailModal = this.modalService.open(ResearchDetailComponent, {
        ariaLabelledBy: "ResearchDetail",
        scrollable: true,
        size: "lg",
        windowClass: "researchDetail backModalImg",
        backdrop: true,
      });
    }
    this.researchDetailModal.componentInstance.currentLvl = this.user.researchMap[research.id];
    this.researchDetailModal.componentInstance.research = research;
    this.researchDetailModal.hidden.subscribe(() => {
      this.researchDetailModal = null;
    });
  }
}
