<div class="container my-5 building-slots">
  <div *ngIf="base" class="d-flex justify-content-around flex-wrap custom-bottom">
    <div *ngFor="let slot of base.slots | keyvalue: utilsService.originalOrder" class="slots transparent_bg building-slot">
      <img *ngIf="!slot.value.construction && slot.value.built" alt="Image du batiment pour ce slot" class="buildingImg"
        [ngClass]="!slot.value.built.active ? 'grayscale': ''" (click)="openBuilding(slot.key)"
        src="{{ '/assets/img/buildings/' + slot.value.built.building.name + '.png' }}" />

      <div *ngIf="slot.value.construction">
        <img *ngIf="slot.value.built" alt="Image du batiment pour ce slot" class="buildingImg"
        src="{{ '/assets/img/buildings/' + slot.value.built.building.name + '.png' }}" />
        <img  alt="Image de slot en construction" class="constructionImg"
        (click)="cancelBuilding(slot.key, confirmCancelModal)" src="/assets/img/buildings/construction.png" />
      </div>

      <img *ngIf="!slot.value.built && slot.value.type !== 'LOCKED'" alt="Image du slot non construit"
        class="buildableImg" (click)="openBuildables(slot.key)"
        src="{{ '/assets/img/slots/' + slot.value.type + '.png' }}" />

      <img *ngIf="slot.value.type === 'LOCKED'" alt="Image du slot bloqué" class="lockedSlotImg"
        src="{{ '/assets/img/slots/LOCKED.png' }}" />


      <div *ngIf="slot.value.built" class="level-bubble">
        <span class="level">{{ slot.value.built.level + 1 }}</span>
      </div>

      <div class="card-body">
        <h5 *ngIf="!slot.value.built" class="card-title" i18n="BaseComponent|Building Location (when empty)">
          Emplacement {{ slot.key }}
        </h5>
        <!--<h5 *ngIf="slot.value.built" class="card-title invisible my-1"
          i18n="BaseComponent|Building Location (when populated)">
          {{ slot.value.built.building.name }}
        </h5>-->
        <h5 *ngIf="slot.value.built" class="card-title" i18n="BaseComponent|Building Location (when populated)">
          {{ slot.value.built.building.name }}
        </h5>
        <!--<p class="card-text" *ngIf='!slot.value.built'>Emplacement libre à la construction</p>
        <p class="card-text" *ngIf='slot.value.built'>{{slot.value.built.building.description}}</p>-->
        <!-- <p *ngIf="slot.value.built" class="card-text" i18n="BaseComponent|Health point of the building left">
          PV: {{ slot.value.built.hp }}
        </p> -->
        <p *ngIf="slot.value.construction" class="card-text mx-auto" i18n="BaseComponent|Building being modified">
          {{ +timerMap.get(+slot.key) | timer: 'UTC'}}
        </p>
      </div>

      <div class="card-footer justify-content-center">
        <div *ngIf="!slot.value.construction && slot.value.type !== 'LOCKED'">
          <button (click)="openBuildables(slot.key)" *ngIf="!slot.value.built" class="btn btn-outline-success"
            i18n="BaseComponent|Build new building in empty slot">
            Construire
          </button>
          <button (click)="openBuilding(slot.key)" *ngIf="slot.value.built" class="btn btn-outline-primary"
            i18n="BaseComponent|Enter the building">
            Entrer
          </button>
        </div>
        <div *ngIf="slot.value.construction" class="d-flex justify-content-center">
          <button (click)="cancelBuilding(slot.key, confirmCancelModal)" class="btn btn-outline-danger"
            i18n="BaseComponent|Cancel the modification">
            Annuler
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmCancelModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="confirmCancel" i18n="confirmDestroy|Title">Confirmer l'annulation</h4>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn btn-close" type="button">
    </button>
  </div>
  <div class="modal-body">
    <div class="danger">êtes-vous sûr de vouloir annuler la modification en cours sur ce bâtiment ?</div>
    <br><br><br>
    <div class="d-flex justify-content-center">
      <div>
        <button (click)="confirmCancel(confirmCancelModal['slot'])" class="btn btn-outline-danger"
          i18n="confirmDestroy|Confirm">
          Confirmer l'annulation
        </button>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn btn-outline-warning" i18n="confirmDestroy|Cancel" (click)="modal.dismiss('Cancel click')">
          Annuler
        </button>
      </div>
    </div>
  </div>
</ng-template>