import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TemplateSquadronComponent } from "src/app/main/fleets/fleets/formation/edit-squadron/template-squadron.component";
import { FleetService } from "src/app/services/fleets/fleet.service";


@Component({
  selector: "app-battle-group",
  templateUrl: "../template-squadron.component.html",
  styleUrls: ["../template-squadron.component.scss"],
})
export class RemoveSquadronComponent extends TemplateSquadronComponent implements OnInit {

  constructor(
    protected readonly fb: FormBuilder,
    public readonly modal: NgbActiveModal, // needed in HTML
    protected readonly fleetService: FleetService,
  ) {
    super(fb, modal, fleetService);
  }

  ngOnInit() {
    let availableShips = this.availableShips;
    this.availableShips = [];
    for(let shipId in availableShips) {
      this.availableShips.push(availableShips[shipId]);
    }
    if(!this.selectedShip) {
      this.selectedShip = this.availableShips[0];
      this.doSelectShip = true;
    }

    super.ngOnInit();

    this.doTactic = false;
    this.title = "Rangement des vaisseaux"
  }

  public onSubmit(): boolean {
    // Vérifier la validité du formulaire
    if (this.frmShip.valid && this.selectedFleet) {
      this.fleetService.transferShipToBase(
        this.selectedFleet.id,
        this.selectedShip['techId'],
        this.amount.value
      )
      .subscribe(() => {
          this.modal.close();
      });
    } else {
      //TODO : afficher le pourquoi de ca fonctionne pas
    }
    return false;
  }
}
