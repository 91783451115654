import { Squadron } from "../Squadron";
import { FleetRef } from "./FleetRef";
import { GlobalPosition } from "../GlobalPosition";

export class Fleet extends FleetRef {
  capacities: Map<string, number>;
  consumption: number;
  cumulatedFuelTank: number;
  currentBaseId: string;
  currentFuel: number;
  destination: GlobalPosition;
  fuelTime: number;
  instantConsumption: number;
  // linkedBaseId: string; //not used
  maxRange: number;
  minRange: number;
  position: GlobalPosition;
  speed: number;
  squadrons: Map<string, Squadron>;
  staticConsumption: number;
  strategy: string;
  travelTime: Date;
  warmupTime: number;
  fuelCode: string;
  currentFuelEfficiency: number;
}
